import axios from 'axios';

export default {
  create(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/collections/modular-template-categories`,
      data: params,
    });
  },
  update(id, params = {}, version = 'v1') {
    return axios({
      method: 'put',
      url: `/${version}/collections/modular-template-categories/${id}`,
      data: params,
    });
  },
  updateOrder(params = {}, version = 'v1') {
    return axios({
      method: 'put',
      url: `/${version}/collections/modular-template-categories-reorder`,
      data: params,
    });
  },
  delete(id, version = 'v1') {
    return axios({
      method: 'delete',
      url: `/${version}/collections/modular-template-categories/${id}`,
    });
  },
};
