import Vue from 'vue'; // eslint-disable-line
import Cookies from 'js-cookie';

import { randomId } from '@/assets/scripts/utilities'; // eslint-disable-line

const cookiePrefix = 'offeo_';

const inspirationList = {
  namespaced: true,
  state: {
    tmpUserId: '',
    files: [],
    heroIndex: -1,
    tmpUserFiles: [],
    clippingImageJson: {}, // clipping json file
    isLogo: false,
    inspirationTemplateTags: [],
    showRemoveBgOption: false,
    confirmRemoveBg: true, // set confirm remove bg to always run the remove.bg on upload
    removeBgItem: {}, // saved image file
    userFileId: '', // users_files.id
    isTemporaryFile: false, // if the file is temporary uploaded or users uploaded file.
    selectedInspirationListItem: {},
    showRemoveBgFailedDialog: false,
    failedRemoveBgImageUrl: '',
    isTeamFileUpload: false,
    /**
     * for Mobile Smart Create and Lite mode
     */
    heroElements: {
      with_mask: {},
      without_mask: {},
    },
    removeBgSuccess: false,
  },
  getters: {

  },
  mutations: {
    setTmpUserId(state, item) {
      state.tmpUserId = item;
    },
    clearFiles(state) {
      // this state will hold the uploaded file from UI.
      state.files = [];
    },
    setFiles(state, item) {
      state.files = item;
    },
    setHeroIndex(state, item) {
      state.heroIndex = item;
    },
    setTmpUserFiles(state, item) {
      // this state will store the temporary saved file into the server.
      state.tmpUserFiles = item;
    },
    setClippingImageJson(state, item) {
      state.clippingImageJson = item;
    },
    setIsLogo(state, item) {
      state.isLogo = item;
    },
    setInspirationTemplateTags(state, item) {
      state.inspirationTemplateTags = item;
    },
    setShowRemoveBgOption(state, item) {
      state.showRemoveBgOption = item;
    },
    setConfirmRemoveBg(state, item) {
      state.confirmRemoveBg = item;
    },
    setRemoveBgItem(state, item) {
      state.removeBgItem = item;
    },
    setUserFileId(state, item) {
      state.userFileId = item;
    },
    setIsTemporaryFile(state, item) {
      state.isTemporaryFile = item;
    },
    setSelectedInspirationListItem(state, item) {
      state.selectedInspirationListItem = item;
    },
    setShowRemoveBgFailedDialog(state, item) {
      state.showRemoveBgFailedDialog = item;
    },
    setFailedRemoveBgImageUrl(state, item) {
      state.failedRemoveBgImageUrl = item;
    },
    setRemoveBgSuccess(state, item) {
      state.removeBgSuccess = item;
    },
    setIsTeamFileUpload(state, item) {
      state.isTeamFileUpload = item;
    },
  },
  actions: {
    // eslint-disable-next-line
    async setTmpUserId({ commit, dispatch }) {
      const cookieParam = `${cookiePrefix}tmp_user_id`;
      let tmpUserId = Cookies.get(cookieParam);
      if (tmpUserId) {
        commit('setTmpUserId', tmpUserId);
        return;
      }

      tmpUserId = randomId();
      commit('setTmpUserId', tmpUserId);
      Cookies.set(cookieParam, tmpUserId, { expires: 1 });
    },
  },
};

export default inspirationList;
