<template lang="pug">
.canvas-header
  BaseButton.icon-offeo.logo__link(
    :class="{'lite-mode': isLiteMode, 'is-black': isBlack }"
    :is-light="true"
    :is-icon="true"
    :plain-hover="true"
    padding="5px"
    @click="confirmLeaveCanvas"
  )

  slot

  BaseDialog(
    :isShow="showConfirmLeaveCanvas && hasChanges"
    :isPrimary="true"
    :noButton="true"
  )
    p You are about to leave the design canvas
    p.changes-being-saved Please wait while changes are being saved...

  BaseDialog(
    :isShow="showConfirmLeaveCanvas && !hasChanges"
    :isPrimary="true"
    button1Text="Leave"
    @confirm="leaveCanvas"
    @closeOverlay="showConfirmLeaveCanvas=false"
  )
    p You are about to leave the design canvas
    p Changes have been saved.

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { dashboardUrl } from '@/assets/scripts/variables';

export default {
  name: 'BaseHeader',
  props: {
    isCanvas: { type: Boolean, default: false },
    isBlack: { type: Boolean, default: false },
  },
  data() {
    return {
      showConfirmLeaveCanvas: false,
    };
  },
  computed: {
    ...mapState('canvasElements', ['hasChanges']),
    ...mapState('team', ['teamSlug']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('team', ['isInTeamWorkspace']),
  },
  methods: {
    ...mapActions('canvasElements', ['saveProject']),
    confirmLeaveCanvas() {
      if (this.isCanvas) {
        this.saveProject();
        this.showConfirmLeaveCanvas = true;
      } else this.leaveCanvas();
    },
    leaveCanvas() {
      const personalDashboardUrl = `${dashboardUrl()}${this.isLiteMode ? '?mode=lite' : ''}`;
      const teamDashboardUrl = `${dashboardUrl()}/team/${this.teamSlug}/projects`;
      const getUrl = this.isInTeamWorkspace ? teamDashboardUrl : personalDashboardUrl;

      window.location.href = getUrl;
    },
  },
};
</script>

<style lang="scss">
.canvas-header {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px !important;
  line-height: 0 !important;
  width: 40px;
  height: 30px;
  margin-right: 5px;
  min-width: 25px;

  &.is-black {
    color: $black;
    background: transparent;
  }

  .lite-logo {
    font-family: $baseFont;
    position: absolute;
    font-size: 9px;
    bottom: 4px;
    letter-spacing: 0.1px;
    font-weight: 600;
  }

  &.lite-mode {
    line-height: 0.5 !important;
    width: 50px;
    height: 35px;
  }
}

#main-header {
  background: $darkGrey900;
  padding: 8px 22px;
  height: 54px;
  position: fixed;
  width: 100%;
  z-index: 23;
  transition: height 0.25s ease-in-out, background 0.25s ease-in-out;

  &.header--low-z-index {
    z-index: 1; // so overlay in user-menu can also overlap header
  }
}

#main-content {
  background: $darkBg;
  padding-top: 54px;
  transition: margin 0.25s ease-in-out;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;

  @include non-desktop {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
}
.changes-being-saved {
  font-weight: 600;
}
</style>
