import { designTabs } from './enums';

const homeUrl = () => process.env.VUE_APP_PUBLIC_URL;
const canvasUrl = () => process.env.VUE_APP_CANVAS_URL;
const dashboardUrl = () => process.env.VUE_APP_DASHBOARD_URL;

const isDevMode = () => process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'development';
const isStaging = () => process.env.VUE_APP_ENV === 'staging';
const isPreprod = () => process.env.VUE_APP_ENV === 'preprod';
const isProduction = () => process.env.VUE_APP_ENV === 'production';

const iframeParentURL = () => (window.location !== window.parent.location ? document.referrer : document.location.href);


// eslint-disable-next-line
const _time = (fullTime = false) => {
  // Math.floor(new Date().getTime() / 1000);
  const date = new Date();
  // cache only every one hour

  let time = `${date.getFullYear()}${`0${date.getMonth() + 1}`.slice(
    -2,
  )}${`0${date.getDate()}`.slice(-2)}${date.getHours()}`;

  if (fullTime) time += date.getMinutes() + date.getSeconds();

  return time;
};

const defaultFallbackFont = 'Work Sans';
const defaultFontSize = {
  h1: 120,
  h2: 85,
  body: 50,
};
const fauxZero = 0.03;

// margin * 5 is the distance of a second,
// because previously there is only 5 lines on a second
const margin = 20;
const snap = margin / 2;
const minDuration = {
  fullAnimation: 0.1,
  animateInOut: 0.3,
  showAnimation: 0.8,
  scene: 1,
  autoResize: 0.5,
  liteScene: 2,
};
const maxDuration = {
  animateInOut: 3,
  scene: 30,
  freeUserProject: 30,
  payingUserProject: 180,
  liteProject: 15,
  liteScene: 5,
};
const snapTolerance = 5;
const layerTimelineHeight = {
  compact: 30,
  default: 43,
};

function pixelToSec(value) {
  return parseFloat(parseFloat(value / margin / 5).toFixed(1));
}

function secToPixel(value) {
  return parseInt(value * margin * 5, 10);
}

// GUIDE FOR FILTERS
//                slider     filter (db)       css      default slider     default css
// opacity        0 - 100       0 - 1         0 - 1          100                1
// contrast    -100 - 100       0 - 2         0 - 2           0                 1
// saturate    -100 - 100       0 - 2         0 - 2           0                 1
// brightness  -100 - 100       0 - 2         0 - 2           0                 1
// sepia          0 - 100       0 - 1         0 - 1           0                 0
// blur           0 - 100       0 - 100       0 - 100         0                 0
// hue         -180 - 180       0 - 360       0 - 360         0                 0

const filterList = {
  aden: {
    hue: -20,
    contrast: 0.9,
    saturate: 0.85,
    brightness: 1.2,
  },
  brannan: {
    sepia: 0.5,
    contrast: 1.4,
  },
  brooklyn: {
    contrast: 0.9,
    brightness: 1.1,
  },
  clarendon: {
    contrast: 1.2,
    saturate: 1.35,
  },
  hudson: {
    brightness: 1.2,
    contrast: 0.9,
    saturate: 1.1,
  },
  inkwell: {
    sepia: 0.3,
    constrast: 1.1,
    brightness: 1.1,
    grayscale: 1,
  },
  lofi: {
    saturate: 1.1,
    constrast: 1.5,
  },
  maven: {
    sepia: 0.25,
    brightness: 0.95,
    contrast: 0.95,
    saturate: 1.5,
  },
  mayfair: {
    contrast: 1.1,
    saturate: 1.1,
  },
  nashville: {
    sepia: 0.2,
    contrast: 1.2,
    brightness: 1.05,
    saturate: 1.2,
  },
  perpetua: {},
  reyes: {
    sepia: 0.22,
    brightness: 1.1,
    contrast: 0.85,
    saturate: 0.75,
  },
  rise: {
    brightness: 1.05,
    sepia: 0.2,
    contrast: 0.9,
    saturate: 0.9,
  },
  slumber: {
    saturate: 0.66,
    brightness: 1.05,
  },
  stinson: {
    contrast: 0.75,
    saturate: 0.85,
    brightness: 1.15,
  },
  valencia: {
    contrast: 1.08,
    brightness: 1.08,
    sepia: 0.08,
  },
  greyscale: {
    greyscale: 1,
  },
  walden: {
    brightness: 1.1,
    hue: -10,
    sepia: 0.3,
    saturate: 1.6,
  },
};

const sceneFormat = {
  0: {
    id: 0,
    description: null,
    elements: [],
    background: '#fff',
    // background: null,
    duration: 5,
    // transition: null,
    transition: {
      value: '',
      direction: '',
    },
    modular_category_id: null,
    is_hero_w_bg: false,
    is_hero_wo_bg: false,
  },
};

const elementFormat = {
  data: {
    background: false,
    color: [], // need to be empty because animated json and svg shouldn't have color, the default will be added in setupElementData()
    groundShadow: {
      isEnabled: false,
      color: '#000000',
      blur: 4,
      opacity: 0.5,
      y: 22,
      scaleX: 35,
      scaleY: 35,
    },
    reflection: {
      // blur: 10,
      isEnabled: false,
      opacity: 0.3,
      size: 50,
      distance: 0,
    },
    mediaShadow: {
      blur: 20,
      color: '#000000',
      isEnabled: false,
      opacity: 0.5,
      x: 10,
      y: 10,
    },
    mediaBlur: {
      blur: 2,
      isEnabled: false,
    },
    mediaGlow: {
      isEnabled: false,
      color: '#ffffff',
      opacity: 0.5,
      radius: 50,
    },
    mediaLightSweep: {
      isEnabled: false,
      intensity: 1.5,
      speed: 50,
      radius: 3,
      isLoop: false,
      selectedAngle: {
        x: 'right',
        y: 'bottom',
      },
    },
    mediaGlitch: {
      isEnabled: false,
      distance: 3,
      speed: 50,
    },
    mediaRoundCorners: {
      rounding: 30,
      isEnabled: false,
    },
    mediaBorder: {
      isEnabled: false,
      color: '#ff00c1',
      size: 0, // defined 0 instead of 4 (base value defined as 'defaultMediaBorderSize' below) so the old value start from 0 & won't disturb the flow.
    },
  },
  flipX: false,
  flipY: false,
  isCustom: false,
  isImage: false,
  lock: false,
  opacity: 1,
  position: {
    x: 0,
    y: 0,
  },
  rotate: 0,
  show: true,
  size: {
    width: 'auto',
    height: 'auto',
  },
  time_in: 0,
  time_out: 5,
  timeline_settings: {
    animationDuration: 5,
    animateInValue: '',
    animateInDirection: '',
    animateInDuration: 1,
    animateInOption: 'default',
    animateDuringValue: '',
    animateDuringSpeed: 1,
    animateDuringDirection: '',
    animateOutValue: '',
    animateOutDirection: '',
    animateOutDuration: 1,
    animateOutStart: 4,
    animateOutOption: 'default',
    isAnimateGlitchEffect: false,
    glitchEffectSpeed: 1,
    glitchEffectSettings: {},
    isAnimateLightsweepEffect: false,
    lightsweepEffectSpeed: 1,
    lightsweepEffectSettings: {},
  },
  type: '',
  is_brand: false,
  isNew: true,
  menu: '',
  category: '',
  sub_category_id: '',
  sub_category_name: '',
};

const textDataFormat = {
  content: 'Edit text',
  fontCategory: 'latin',
  fontFamily: defaultFallbackFont,
  fontSize: defaultFontSize.h2,
  letterSpacing: 0,
  lineHeight: 1,
  hasNotChanged: true,
  name: 'Edit text',
  textAlign: 'center',
  verticalAlign: 'middle',
  textBackground: {
    borderRadius: 0,
    color: '#0284FE',
    isEnabled: false,
    opacity: 1,
    x: 0,
    y: 0,
  },
  fontWeight: 400,
  textItalic: false,
  textShadow: {
    blur: 20,
    color: '#000000',
    isEnabled: false,
    opacity: 0.5,
    x: 10,
    y: 10,
  },
  textBlur: {
    blur: 2,
    isEnabled: false,
  },
  textGlow: {
    isEnabled: false,
    color: '#ffffff',
    opacity: 0.9,
    radius: 0,
  },
  textLightSweep: {
    isEnabled: false,
    color: '#ffffff',
    speed: 50,
    radius: 15,
    isLoop: false,
    selectedAngle: {
      x: 'right',
      y: 'bottom',
    },
  },
  textGlitch: {
    isEnabled: false,
    color1: '#ff00c1',
    color2: '#00fff9',
    distance: 1,
    speed: 80,
  },
  textOutline: {
    isEnabled: false,
    color: '#ff00c1',
    size: 2,
    isFillText: false,
  },
  text3D: {
    isEnabled: false,
    color: '#ff00c1',
    distance: 0,
    selectedAngle: {
      x: 'left',
      y: 'top',
    },
  },
  mediaShadow: undefined,
  mediaBlur: undefined,
  textUppercase: false,
};

const watermarkFormat = {
  url: '',
  isEnabled: false,
  size: {
    width: 100,
    height: 100,
  },
  opacity: 50,
  /**
   * Direction list:
   *  'left-top'   ||   'top'  ||   'right-top'
   *    'left'     || 'center' ||     'right'
   * 'left-bottom' || 'bottom' || 'right-bottom'
   */
  direction: 'center',
  position: {
    x: 20,
    y: 20,
  },
  background: {
    color: '#fff', // '#fff' || { degree: 0, points: [] }
    /**
     * scale will be for transform scale(x, y)
     */
    scale: {
      x: 1,
      y: 1,
    },
    borderRadius: 0,
    opacity: 100,
    isEnabled: false,
  },
};

// eslint-disable-next-line
const get3RandomDigit = () => parseInt(
  new Date()
    .getTime()
    .toString()
    .slice(-3),
);

const modularCategoryFormat = {
  id: get3RandomDigit(),
  name: 'New Category',
  slug: 'new-category',
  background_image: null,
  children: [],
};

function secToHour(value) {
  const sec = value;
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  let centi = sec - hours * 3600 - minutes * 60 - seconds * 60;

  centi = parseInt(centi, 10);

  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;
  if (centi < 10) centi = `0${centi}`;

  return `${minutes} : ${seconds} : ${centi}`;
}

function hourToSec(value) {
  const p = value.split(':');
  const m = 0;
  const s = 1;
  const c = 2;

  let seconds = parseInt(p[s], 10);

  seconds += parseInt(p[m], 10) * 60;
  seconds += parseInt(p[c], 10) / 100;

  return seconds;
}

const layerTagsArray = [
  {
    name: 'Overlay',
    value: 'overlay',
  },
  {
    name: 'FG',
    value: 'fg',
  },
  {
    name: 'Logo',
    value: 'logo',
  },
  {
    name: 'Hero',
    value: 'hero',
  },
  {
    name: 'H1',
    value: 'h1',
  },
  {
    name: 'H2',
    value: 'h2',
  },
  {
    name: 'H3',
    value: 'h3',
  },
  {
    name: 'BG',
    value: 'bg',
  },
  {
    name: 'Hero Mask',
    value: 'hero-bg',
  },
];

const designsPerRow = {
  [designTabs.ALLDESIGNS]: {
    square: 7,
    vertical: 8,
    landscape: 5,
  },
  [designTabs.BRANDEDDESIGNS]: {
    square: 7,
    vertical: 8,
    landscape: 5,
  },
};
const designsRows = {
  [designTabs.ALLDESIGNS]: {
    square: 3,
    vertical: 2,
    landscape: 4,
  },
  [designTabs.BRANDEDDESIGNS]: {
    square: 3,
    vertical: 2,
    landscape: 4,
  },
};

const canvasSizesArray = ['square', 'vertical', 'landscape'];

const defaultCategory = {
  id: -1,
  slug: 'all',
};

// somehow the order of timelineZooms is changed automatically
// therefore should declare the order here
const timelineZoomsKey = ['-4', '-3', '-2', '-1', '0', '1', '2', '3', '4'];

// please update timelineZoomsKey if you add new key here
const timelineZooms = {
  '-4': {
    multiplier: 0.2,
    margin: 1,
  },
  '-3': {
    multiplier: 0.4,
    margin: 2,
  },
  '-2': {
    multiplier: 0.6,
    margin: 4,
  },
  '-1': {
    multiplier: 0.8,
    margin: 6,
  },
  0: {
    multiplier: 1,
    margin: 8,
  },
  1: {
    multiplier: 1.5,
    margin: 13,
  },
  2: {
    multiplier: 2,
    margin: 18,
  },
  3: {
    multiplier: 2.5,
    margin: 23,
  },
  4: {
    multiplier: 3,
    margin: 28,
  },
};

const windowWidth = {
  fbRequirement: 768,
  smallLaptopSize: 1600,
};

const projectDimensionLimit = {
  min: 50,
  max: 3000,
};

const directionIcons = {
  default: {
    icon: 'icon-from-center',
  },
  'from-left': {
    icon: 'icon-from-left',
  },
  'from-right': {
    icon: 'icon-from-right',
  },
  'from-top': {
    icon: 'icon-from-top',
  },
  'from-bottom': {
    icon: 'icon-from-bottom',
  },
  'from-centre': {
    icon: 'icon-from-center',
  },
  horizontal: {
    icon: 'icon-horizontal',
  },
  vertical: {
    icon: 'icon-vertical',
  },
  'to-left': {
    icon: 'icon-to-left',
  },
  'to-right': {
    icon: 'icon-to-right',
  },
  'to-top': {
    icon: 'icon-to-top',
  },
  'to-bottom': {
    icon: 'icon-to-bottom',
  },
  'to-centre': {
    icon: 'icon-from-center',
  },
  'to-small': {
    image: 'from-big',
  },
  'to-big': {
    image: 'from-small',
  },
  'from-small': {
    image: 'from-small',
  },
  'from-big': {
    image: 'from-big',
  },
};
const allowNonLoginUserUpload = () => process.env.VUE_APP_ALLOW_NON_LOGIN_USER_UPLOAD === 'true';

const fallbackLightsweepSelectedAngle = { x: 'right', y: 'bottom' };

// ---------------------
// default effect values
// ---------------------
const defaultMediaBorderSize = 4;
const defaultTextGlowRadius = 50;
const defaultText3dDistance = 4;
const maxPalette = {
  free: 2,
  premium: 30,
};
const maxColor = {
  free: 5,
  premium: 10,
};

// -----------------------
// glitch effect sequences
// -----------------------
const textGlitchSequence = [
  {
    clipBefore: [0, 0],
    clipAfter: [0, 0],
  },
  {
    clipBefore: [112, 100],
    clipAfter: [106, 118],
  },
  {
    clipBefore: [58, 20],
    clipAfter: [190, 132],
  },
  {
    clipBefore: [156, 26],
    clipAfter: [130, 88],
  },
  {
    clipBefore: [144, 84],
    clipAfter: [54, 134],
  },
  {
    clipBefore: [84, 54],
    clipAfter: [136, 192],
  },
  {
    clipBefore: [188, 46],
    clipAfter: [114, 24],
  },
  {
    clipBefore: [186, 26],
    clipAfter: [42, 56],
  },
  {
    clipBefore: [198, 46],
    clipAfter: [8, 182],
  },
  {
    clipBefore: [76, 122],
    clipAfter: [58, 46],
  },
  {
    clipBefore: [18, 148],
    clipAfter: [112, 174],
  },
  {
    clipBefore: [102, 178],
    clipAfter: [100, 32],
  },
  {
    clipBefore: [122, 8],
    clipAfter: [82, 108],
  },
  {
    clipBefore: [150, 114],
    clipAfter: [2, 74],
  },
  {
    clipBefore: [116, 112],
    clipAfter: [32, 76],
  },
  {
    clipBefore: [186, 136],
    clipAfter: [2, 180],
  },
  {
    clipBefore: [2, 86],
    clipAfter: [134, 136],
  },
  {
    clipBefore: [20, 38],
    clipAfter: [160, 44],
  },
  {
    clipBefore: [166, 12],
    clipAfter: [120, 32],
  },
  {
    clipBefore: [114, 122],
    clipAfter: [98, 86],
  },
];

const imageGlitchSequence = {
  imageGlitchItem2: {
    totalClipPathSequence: 42,
    totalOpacityTransformSequence: 10,
    clipPathSequence: [0, 3, 5, 7, 9, 11, 13, 15, 17, 19, 20, 23, 25, 27, 29, 31, 33, 35, 37, 39, 40, 43, 45, 47, 49, 51, 55, 55, 57, 59, 60, 63, 65, 67, 69, 71, 77, 75, 77, 79, 80, 100], // eslint-disable-line
    opacityTransformSequence: [0, 21, 22, 42, 44, 63, 66, 81, 82, 100],
    clipPathValues: [
      'polygon(0 25%, 100% 25%, 100% 30%, 0 30%)',
      'polygon(0 3%, 100% 3%, 100% 3%, 0 3%)',
      'polygon(0 5%, 100% 5%, 100% 20%, 0 20%)',
      'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
      'polygon(0 40%, 100% 40%, 100% 40%, 0 40%)',
      'polygon(0 52%, 100% 52%, 100% 59%, 0 59%)',
      'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
      'polygon(0 75%, 100% 75%, 100% 75%, 0 75%)',
      'polygon(0 65%, 100% 65%, 100% 40%, 0 40%)',
      'polygon(0 45%, 100% 45%, 100% 50%, 0 50%)',
      'polygon(0 14%, 100% 14%, 100% 33%, 0 33%)',
      'polygon(0 45%, 100% 45%, 100% 50%, 0 50%)',
      'polygon(0 3%, 100% 3%, 100% 3%, 0 3%)',
      'polygon(0 5%, 100% 5%, 100% 20%, 0 20%)',
      'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
      'polygon(0 40%, 100% 40%, 100% 40%, 0 40%)',
      'polygon(0 52%, 100% 52%, 100% 59%, 0 59%)',
      'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
      'polygon(0 75%, 100% 75%, 100% 75%, 0 75%)',
      'polygon(0 65%, 100% 65%, 100% 40%, 0 40%)',
      'polygon(0 14%, 100% 14%, 100% 33%, 0 33%)',
      'polygon(0 45%, 100% 45%, 100% 50%, 0 50%)',
      'polygon(0 3%, 100% 3%, 100% 3%, 0 3%)',
      'polygon(0 5%, 100% 5%, 100% 20%, 0 20%)',
      'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
      'polygon(0 52%, 100% 52%, 100% 59%, 0 59%)',
      'polygon(0 40%, 100% 40%, 100% 40%, 0 40%)',
      'polygon(0 75%, 100% 75%, 100% 75%, 0 75%)',
      'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
      'polygon(0 65%, 100% 65%, 100% 40%, 0 40%)',
      'polygon(0 14%, 100% 14%, 100% 33%, 0 33%)',
      'polygon(0 3%, 100% 3%, 100% 3%, 0 3%)',
      'polygon(0 5%, 100% 5%, 100% 20%, 0 20%)',
      'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
      'polygon(0 40%, 100% 40%, 100% 40%, 0 40%)',
      'polygon(0 52%, 100% 52%, 100% 59%, 0 59%)',
      'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
      'polygon(0 75%, 100% 75%, 100% 75%, 0 75%)',
      'polygon(0 65%, 100% 65%, 100% 40%, 0 40%)',
      'polygon(0 45%, 100% 45%, 100% 50%, 0 50%)',
      'polygon(0 14%, 100% 14%, 100% 33%, 0 33%)',
      'polygon(0 0, 0 0, 0 0, 0 0)',
    ],
    opacityValues: [1, 1, 1, 1, 1, 1, 1, 1, 0, 1],
  },
  imageGlitchItem3: {
    totalClipPathSequence: 74,
    totalOpacityTransformSequence: 10,
    clipPathSequence: [0, 1.5, 2, 2.5, 3, 5, 5.5, 7, 8, 9, 10.5, 11, 13, 14, 14.5, 15, 16, 18, 20, 21.5, 22, 22.5, 23, 25, 25.5, 27, 28, 29, 30.5, 31, 33, 34, 34.5, 35, 36, 38, 40, 41.5, 42, 42.5, 43, 45, 45.5, 47, 48, 49, 50.5, 51, 53, 54, 54.5, 55, 56, 58, 60, 61.5, 62, 62.5, 63, 65, 65.5, 67, 68, 69, 70.5, 71, 73, 74, 74.5, 75, 76, 78, 80, 100], // eslint-disable-line
    opacityTransformSequence: [0, 21, 22, 42, 44, 63, 66, 81, 82, 100],
    clipPathValues: [
      'polygon(0 1%, 100% 1%, 100% 3%, 0 3%)',
      'polygon(0 10%, 100% 10%, 100% 9%, 0 9%)',
      'polygon(0 5%, 100% 5%, 100% 6%, 0 6%)',
      'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
      'polygon(0 10%, 100% 10%, 100% 10%, 0 10%)',
      'polygon(0 30%, 100% 30%, 100% 25%, 0 25%)',
      'polygon(0 15%, 100% 15%, 100% 16%, 0 16%)',
      'polygon(0 40%, 100% 40%, 100% 39%, 0 39%)',
      'polygon(0 20%, 100% 20%, 100% 21%, 0 21%)',
      'polygon(0 60%, 100% 60%, 100% 55%, 0 55%)',
      'polygon(0 30%, 100% 30%, 100% 31%, 0 31%)',
      'polygon(0 70%, 100% 70%, 100% 69%, 0 69%)',
      'polygon(0 40%, 100% 40%, 100% 41%, 0 41%)',
      'polygon(0 80%, 100% 80%, 100% 75%, 0 75%)',
      'polygon(0 50%, 100% 50%, 100% 51%, 0 51%)',
      'polygon(0 90%, 100% 90%, 100% 90%, 0 90%)',
      'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
      'polygon(0 100%, 100% 100%, 100% 99%, 0 99%)',
      'polygon(0 70%, 100% 70%, 100% 71%, 0 71%)',
      'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
      'polygon(0 100%, 100% 100%, 100% 99%, 0 99%)',
      'polygon(0 10%, 100% 10%, 100% 9%, 0 9%)',
      'polygon(0 5%, 100% 5%, 100% 6%, 0 6%)',
      'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
      'polygon(0 10%, 100% 10%, 100% 10%, 0 10%)',
      'polygon(0 30%, 100% 30%, 100% 25%, 0 25%)',
      'polygon(0 15%, 100% 15%, 100% 16%, 0 16%)',
      'polygon(0 40%, 100% 40%, 100% 39%, 0 39%)',
      'polygon(0 20%, 100% 20%, 100% 21%, 0 21%)',
      'polygon(0 60%, 100% 60%, 100% 55%, 0 55%)',
      'polygon(0 30%, 100% 30%, 100% 31%, 0 31%)',
      'polygon(0 70%, 100% 70%, 100% 69%, 0 69%)',
      'polygon(0 40%, 100% 40%, 100% 41%, 0 41%)',
      'polygon(0 80%, 100% 80%, 100% 75%, 0 75%)',
      'polygon(0 50%, 100% 50%, 100% 51%, 0 51%)',
      'polygon(0 90%, 100% 90%, 100% 90%, 0 90%)',
      'polygon(0 70%, 100% 70%, 100% 71%, 0 71%)',
      'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
      'polygon(0 100%, 100% 100%, 100% 99%, 0 99%)',
      'polygon(0 10%, 100% 10%, 100% 9%, 0 9%)',
      'polygon(0 5%, 100% 5%, 100% 6%, 0 6%)',
      'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
      'polygon(0 10%, 100% 10%, 100% 10%, 0 10%)',
      'polygon(0 30%, 100% 30%, 100% 25%, 0 25%)',
      'polygon(0 15%, 100% 15%, 100% 16%, 0 16%)',
      'polygon(0 60%, 100% 60%, 100% 55%, 0 55%)',
      'polygon(0 30%, 100% 30%, 100% 31%, 0 31%)',
      'polygon(0 40%, 100% 40%, 100% 39%, 0 39%)',
      'polygon(0 20%, 100% 20%, 100% 21%, 0 21%)',
      'polygon(0 80%, 100% 80%, 100% 75%, 0 75%)',
      'polygon(0 50%, 100% 50%, 100% 51%, 0 51%)',
      'polygon(0 70%, 100% 70%, 100% 69%, 0 69%)',
      'polygon(0 40%, 100% 40%, 100% 41%, 0 41%)',
      'polygon(0 90%, 100% 90%, 100% 90%, 0 90%)',
      'polygon(0 70%, 100% 70%, 100% 71%, 0 71%)',
      'polygon(0 10%, 100% 10%, 100% 9%, 0 9%)',
      'polygon(0 5%, 100% 5%, 100% 6%, 0 6%)',
      'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
      'polygon(0 10%, 100% 10%, 100% 10%, 0 10%)',
      'polygon(0 30%, 100% 30%, 100% 25%, 0 25%)',
      'polygon(0 15%, 100% 15%, 100% 16%, 0 16%)',
      'polygon(0 40%, 100% 40%, 100% 39%, 0 39%)',
      'polygon(0 20%, 100% 20%, 100% 21%, 0 21%)',
      'polygon(0 60%, 100% 60%, 100% 55%, 0 55%)',
      'polygon(0 30%, 100% 30%, 100% 31%, 0 31%)',
      'polygon(0 70%, 100% 70%, 100% 69%, 0 69%)',
      'polygon(0 40%, 100% 40%, 100% 41%, 0 41%)',
      'polygon(0 80%, 100% 80%, 100% 75%, 0 75%)',
      'polygon(0 50%, 100% 50%, 100% 51%, 0 51%)',
      'polygon(0 90%, 100% 90%, 100% 90%, 0 90%)',
      'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
      'polygon(0 100%, 100% 100%, 100% 99%, 0 99%)',
      'polygon(0 70%, 100% 70%, 100% 71%, 0 71%)',
      'polygon(0 0, 0 0, 0 0, 0 0)',
    ],
    opacityValues: [1, 1, 1, 1, 1, 1, 1, 1, 0, 1],
  },
};

// 200 etc is converted mask size, refers to mask-size of 200%.
const imageStraightLightSweepMaskPosition = {
  'straight-horizontal': {
    200: {
      maskStartPoint: '120% 50%',
      maskEndPoint: '-20% 50%',
    },
    300: {
      maskStartPoint: '90% 50%',
      maskEndPoint: '10% 50%',
    },
    400: {
      maskStartPoint: '80% 50%',
      maskEndPoint: '20% 50%',
    },
    500: {
      maskStartPoint: '75% 50%',
      maskEndPoint: '25% 50%',
    },
    600: {
      maskStartPoint: '75% 50%',
      maskEndPoint: '25% 50%',
    },
  },
  'straight-vertical': {
    200: {
      maskStartPoint: '50% 120%',
      maskEndPoint: '50% -20%',
    },
    300: {
      maskStartPoint: '50% 90%',
      maskEndPoint: '50% 10%',
    },
    400: {
      maskStartPoint: '50% 80%',
      maskEndPoint: '50% 20%',
    },
    500: {
      maskStartPoint: '50% 75%',
      maskEndPoint: '50% 25%',
    },
    600: {
      maskStartPoint: '50% 75%',
      maskEndPoint: '50% 25%',
    },
  },
};

export {
  homeUrl,
  canvasUrl,
  dashboardUrl,
  isDevMode,
  isStaging,
  isProduction,
  isPreprod,
  iframeParentURL,
  _time,
  fauxZero,
  margin,
  snap,
  layerTimelineHeight,
  pixelToSec,
  secToPixel,
  minDuration,
  maxDuration,
  filterList,
  sceneFormat,
  elementFormat,
  textDataFormat,
  watermarkFormat,
  snapTolerance,
  modularCategoryFormat,
  get3RandomDigit,
  secToHour,
  hourToSec,
  layerTagsArray,
  designsPerRow,
  designsRows,
  canvasSizesArray,
  defaultCategory,
  timelineZoomsKey,
  timelineZooms,
  windowWidth,
  projectDimensionLimit,
  defaultFallbackFont,
  defaultFontSize,
  directionIcons,
  allowNonLoginUserUpload,
  fallbackLightsweepSelectedAngle,
  defaultMediaBorderSize,
  defaultTextGlowRadius,
  defaultText3dDistance,
  maxPalette,
  maxColor,
  textGlitchSequence,
  imageGlitchSequence,
  imageStraightLightSweepMaskPosition,
};
