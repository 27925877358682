const level = {
  GROUP: 'group',
  SUBGROUP: 'subgroup',
  CATEGORY: 'category',
  KEYWORD: 'keyword',
};

const groupsOrder = {
  designs: 1,
  texts: 2,
  elements: 3,
  media: 4,
  backgrounds: 5,
  music: 6,
  'my-files': 7,
  favourites: 8,
  style: 9,
};

const groups = {
  BRANDEDDESIGNS: 'branded-designs',
  DESIGNS: 'designs',
  STYLE: 'style',
  TEXTS: 'texts',
  ELEMENTS: 'elements',
  BRAND: 'brand',
  MEDIA: 'media',
  BACKGROUNDS: 'backgrounds',
  MUSIC: 'music',
  MYFILES: 'my-files',
  FAVOURITES: 'favourites',
  SIZE: 'size',
  SHORTCUT: 'shortcut',
};

const subgroups = {
  PHOTOS: 'photo',
  VIDEOS: 'video',
  MASKS: 'masks',
  IMAGES: 'images',
  UPLOAD: 'upload',
  TEAM_UPLOAD: 'team-upload',
  COLOUR: 'colour',
  LOGO: 'logos',
  MUSIC: 'music',
  CUSTOMELEMENTS: 'customElements',
  COLOR: 'color',
  FONT: 'font',
  GRAPHICS: 'graphics',
  TEMPLATES: 'templates',
};

const fileCategories = {
  MYUPLOADS: 'personal',
  TEAMUPLOADS: 'team',
};

// since the naming of assets in FE is different with
// the BE, need to have 2 different enums
const favouriteFilters = {
  // DESIGNS: 'design',
  // TEXTS: 'text',
  ELEMENTS: 'elements',
  BACKGROUNDS: 'backgrounds',
  MYFILES: 'myfiles',
  MUSIC: 'audio',
};

const favouriteFiltersName = {
  DESIGNS: 'designs',
  TEXTS: 'texts',
  ELEMENTS: 'graphics',
  BACKGROUNDS: 'BG',
  MYFILES: 'upload',
  MUSIC: 'music',
};

const userRoles = {
  ADMIN: 1,
  MASTERDESIGNER: 2,
  DESIGNER: 3,
  NORMALUSER: 4,
  MARKETER: 5,
};

const userLevels = {
  BUSINESS: 'business',
  PREMIUM: 'premium',
  FREE: 'free',
};

const permutableTypes = {
  PRODUCT_W_BG: 'product-w-bg',
  PRODUCT_WO_BG: 'product-wo-bg',
  LOGO: 'logo',
  TEXT_LOGO: 'text-logo',
  TEXT: 'text',
};

const designTabs = {
  BRANDEDDESIGNS: 'branded',
  ALLDESIGNS: 'other',
};

const fontWeights = {
  100: 'thin',
  200: 'extra-light',
  300: 'light',
  400: 'regular',
  500: 'medium',
  600: 'semi-bold',
  700: 'bold',
  800: 'extra-bold',
  900: 'black',
};

const designerElementUploadOptions = {
  OWN: 'own',
  ELEMENTS: 'elements',
  BACKGROUNDS: 'backgrounds',
};

const editTabs = {
  ADJUST: 'adjust',
  FILTER: 'filter',
  EFFECTS: 'effects',
  UPDATE: 'edit',
};

// slowest/biggest value of an attribute in an effect controller.
// oftenly used because slider value is reversed. Like speed, the smaller it is the faster it should be
const effectsMaxValues = {
  IMAGE_GLITCH_SPEED: 7,
  TEXT_GLITCH_SPEED: 5,
  MEDIA_LIGHTSWEEP_SPEED: 5,
  TEXT_LIGHTSWEEP_SPEED: 3,
};

// fastest/lowest value of an attribute in an effect controller.
const effectsMinValues = {
  IMAGE_GLITCH_SPEED: 0.35,
  TEXT_GLITCH_SPEED: 0.25,
  MEDIA_LIGHTSWEEP_SPEED: 0.25,
  TEXT_LIGHTSWEEP_SPEED: 0.15,
};

export {
  level,
  groupsOrder,
  groups,
  subgroups,
  fileCategories,
  favouriteFilters,
  favouriteFiltersName,
  userRoles,
  userLevels,
  permutableTypes,
  designTabs,
  fontWeights,
  designerElementUploadOptions,
  editTabs,
  effectsMaxValues,
  effectsMinValues,
};
