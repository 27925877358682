import { mapState } from 'vuex';

const darkModeMixin = {
  computed: {
    ...mapState(['theme']),
    isDarkMode() {
      return this.theme === 'dark';
    },
  },
};

export default darkModeMixin;
