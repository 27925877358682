import axios from 'axios';

export default {
  teamDetails(teamId, version = 'v2') {
    return axios({
      method: 'get',
      url: `/${version}/teams/detail/${teamId}`,
    });
  },
  getTeamBrands(teamId, version = 'v2') {
    return axios({
      method: 'get',
      url: `/${version}/teams/brands/${teamId}`,
    });
  },
  getTeamLogos(teamId, brandId, version = 'v2') {
    return axios({
      method: 'get',
      url: `/${version}/teams/brands/logo/${teamId}/${brandId}`,
    });
  },
  addTeamLogo(teamId, brandId, file, version = 'v2') {
    const dataLogo = new FormData();
    dataLogo.append('file', file);
    dataLogo.append('provider', 'local');

    const headers = {
      is_logo: true,
      team_id: teamId,
      brand_id: brandId,
    };

    return axios({
      method: 'post',
      url: `/${version}/upload-file`,
      data: dataLogo,
      headers: {
        'Content-Type': 'multipart/form-data',
        'upload-metadata-additional': JSON.stringify(headers),
      },
    });
  },
  deleteTeamLogo(teamId, brandId, logoFileId, version = 'v2') {
    return axios({
      method: 'delete',
      url: `/${version}/teams/brands/logo/${teamId}`,
      data: {
        brand_id: brandId,
        logo_file_id: logoFileId,
      },
    });
  },
  toggleIsShowTeamBrandSetupLogo(brandId, version = 'v2') {
    return axios({
      method: 'put',
      url: `/${version}/teams/brands/delete-initial-logo/${brandId}`,
    });
  },
  addTeamBrand(teamId, brandName, version = 'v2') {
    return axios({
      method: 'post',
      url: `/${version}/teams/brands/create-custom-team-brand/${teamId}`,
      params: {
        brand_name: brandName,
      },
    });
  },
  renameTeamBrand(data, params = {}, version = 'v2') {
    return axios({
      method: 'put',
      url: `/${version}/teams/brands/rename/${data.teamId}/${data.brandId}`,
      params,
    });
  },
  deleteTeamBrand(teamId, brandId, version = 'v2') {
    return axios({
      method: 'delete',
      url: `/${version}/teams/brands/${teamId}/${brandId}`,
    });
  },

  addTeamColor(teamId, params = {}, version = 'v2') {
    return axios({
      method: 'post',
      url: `/${version}/teams/brands/colors/${teamId}`,
      params,
    });
  },
  deleteTeamColor(teamId, params = {}, version = 'v2') {
    return axios({
      method: 'delete',
      url: `/${version}/teams/brands/colors/${teamId}`,
      params,
    });
  },
  changeTeamColor(teamId, params = {}, version = 'v2') {
    return axios({
      method: 'put',
      url: `/${version}/teams/brands/colors/${teamId}`,
      params,
    });
  },

  addTeamPalette(teamId, params = {}, version = 'v2') {
    return axios({
      method: 'post',
      url: `/${version}/teams/brands/palette/${teamId}`,
      params,
    });
  },
  renameTeamPalette(data, params = {}, version = 'v2') {
    return axios({
      method: 'put',
      url: `/${version}/teams/brands/palette/${data.teamId}/${data.brandId}`,
      params,
    });
  },
  deleteTeamPalette(data, params = {}, version = 'v2') {
    return axios({
      method: 'delete',
      url: `/${version}/teams/brands/palette/${data.teamId}/${data.brandId}`,
      params,
    });
  },
  getTeamBrandCollection(data, version = 'v2') {
    return axios({
      method: 'get',
      url: `/${version}/teams/brands/collection/${data.teamId}/${data.brandId}`,
    });
  },

  getTeamBrandSetupDetails(data, params = {}, version = 'v2') {
    return axios({
      method: 'get',
      url: `/${version}/teams/branded-designs/team-brand-setup-details/${data.teamId}/${data.brandId}`,
      params,
    });
  },
  getTeamBrandFontStyles(data, version = 'v2') {
    return axios({
      method: 'get',
      url: `/${version}/teams/brands/brand-font-style/${data.teamId}/${data.brandId}`,
    });
  },
  addTeamBrandFontStyle(data, version = 'v2') {
    return axios({
      method: 'post',
      url: `/${version}/teams/brands/add-brand-font-style/${data.teamId}/${data.brandId}`,
    });
  },
  updateTeamBrandFontStyle(params, data, version = 'v2') {
    let path = 'update-brand-font-style';
    if (params.type === 'heading') path = 'update-brand-font-heading';
    if (params.type === 'sub-heading') path = 'update-brand-font-sub-heading';

    let url = `/${version}/teams/brands/${path}/${params.teamId}/${params.brandId}`;
    if (params.styleName) url += `?style_name=${params.styleName}`;

    return axios({
      method: 'put',
      url,
      data,
    });
  },
  deleteTeamBrandFontStyle(data, params, version = 'v2') {
    return axios({
      method: 'delete',
      url: `/${version}/teams/brands/delete-brand-font-style/${data.teamId}/${data.brandId}`,
      params,
    });
  },

  getAllTeamCustomFont(teamId, version = 'v2') {
    return axios({
      method: 'get',
      url: `/${version}/teams/brands/custom-fonts/${teamId}/-1`,
    });
  },
  addTeamCustomFont(fontData, fontFiles = {}, version = 'v2') {
    const { teamId, brandId, fontName } = fontData;

    const uploadFontData = new FormData();
    uploadFontData.append('font_name', fontName);
    uploadFontData.append('provider', 'custom-font');
    // eslint-disable-next-line
    for (let key of Object.keys(fontFiles)) {
      uploadFontData.append(`file[${key}]`, fontFiles[key]);
    }

    const additionalMetadata = {
      is_logo: false,
      team_id: teamId,
      brand_id: brandId,
    };

    return axios({
      method: 'post',
      url: `/${version}/upload-file`,
      data: uploadFontData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Upload-Metadata-Additional': JSON.stringify(additionalMetadata),
      },
    });
  },
  updateTeamCustomFont(fontData, fontFiles = {}, version = 'v2') {
    const { teamId, brandId, fontName, fileId } = fontData; // eslint-disable-line

    const uploadFontData = new FormData();
    if (fileId) {
      uploadFontData.append('file_id', fileId);
    }

    uploadFontData.append('font_name', fontName);
    uploadFontData.append('provider', 'custom-font');
    // eslint-disable-next-line
    for (let key of Object.keys(fontFiles)) {
      uploadFontData.append(`file[${key}]`, fontFiles[key]);
    }

    // pass in post request but add _method=PUT to treat as put request.
    // no able to pass any file via put method
    uploadFontData.append('_method', 'PUT');

    return axios({
      method: 'post',
      url: `/${version}/teams/brands/custom-fonts/update-fonts/${teamId}/${brandId}`,
      data: uploadFontData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteTeamCustomFont(data, params = {}, version = 'v2') {
    return axios({
      method: 'delete',
      url: `/${version}/teams/brands/custom-fonts/${data.teamId}/${data.brandId}`,
      data: params,
    });
  },
};
