<template lang="pug">
.preloader(
  :class="{ 'skeleton': type >= 8 && type <= 10 }"
)

  .preloader__image(
    :style="styleObject"
  )
    //- Need to separate as there will be a blink if no named transition is used
    template(v-if="transition !== ''")
      transition(:name="transition")
        Lottie(
          class="has-transition"
          :options="selectedLottieOption"
          :key="type"
        )
    template(v-else)
      Lottie(
        :options="selectedLottieOption"
        :key="type"
      )

  .preloader__text(
    v-if="text"
    v-html="text"
  )
</template>

<script>
import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';
import PreloaderJson from '@/assets/data/preloader.json';
import PreloaderDotsJson from '@/assets/data/preloader-dots.json';
import PreloaderInspiration1Json from '@/assets/data/preloader-inspirations-1.json';
import PreloaderInspiration2Json from '@/assets/data/preloader-inspirations-2.json';
import PreloaderInspiration3Json from '@/assets/data/preloader-inspirations-3.json';
import PreloaderSmartCreateJson from '@/assets/data/preloader-smart-create.json';
import PreloaderCanvasScene from '@/assets/data/preloader-canvas-scene.json';
import preloaderCanvasPlain from '@/assets/data/canvas-loader.json';
import preloaderCanvasWithBg from '@/assets/data/canvas-loader-with-bg.json';
import SkeletonAssetsMusic from '@/assets/data/skeleton-assets-music-dark.json';
import SkeletonAssetsMusicInner from '@/assets/data/skeleton-assets-music-inner-dark.json';
import SkeletonAssetsGeneral from '@/assets/data/skeleton-assets-general-dark.json';

export default {
  name: 'BasePreloader',
  components: {
    Lottie,
  },
  props: {
    text: { type: String, default: '' },
    width: { type: Number, default: 0 },
    height: { type: Number, default: 0 },
    type: { type: Number, default: 1 },
    transition: { type: String, default: '' },
    isSmartCreate: { type: Boolean, default: false },
  },
  computed: {
    styleObject() {
      const styleObject = {};

      if (this.width) styleObject.width = `${this.width}px`;
      if (this.height) styleObject.height = `${this.height}px`;

      return styleObject;
    },
    preloaderInspiration1Options() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: PreloaderInspiration1Json,
      };
    },
    preloaderInspiration2Options() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: PreloaderInspiration2Json,
      };
    },
    preloaderInspiration3Options() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: PreloaderInspiration3Json,
      };
    },
    preloaderSmartCreateOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: PreloaderSmartCreateJson,
      };
    },
    preloaderCanvasSceneOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: PreloaderCanvasScene,
      };
    },
    preloaderCanvasOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: preloaderCanvasPlain,
      };
    },
    preloaderCanvasWithBgOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: preloaderCanvasWithBg,
      };
    },
    preloaderDotsOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: PreloaderDotsJson,
      };
    },
    preloaderOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: PreloaderJson,
      };
    },
    skeletonAssetsMusicOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: SkeletonAssetsMusic,
      };
    },
    skeletonAssetsMusicInnerOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: SkeletonAssetsMusicInner,
      };
    },
    skeletonAssetsGeneralOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: SkeletonAssetsGeneral,
      };
    },
    selectedLottieOption() {
      let selectedLottieOption = this.preloaderDotsOptions;

      // switch is faster than if else
      switch (this.type) {
        case 4:
          selectedLottieOption = this.preloaderOptions;
          break;
        case 5:
          selectedLottieOption = this.preloaderInspiration1Options;
          break;
        case 6:
          selectedLottieOption = this.preloaderInspiration2Options;
          break;
        case 7:
          selectedLottieOption = this.preloaderInspiration3Options;
          break;
        case 8:
          selectedLottieOption = this.skeletonAssetsGeneralOptions;
          break;
        case 9:
          selectedLottieOption = this.skeletonAssetsMusicOptions;
          break;
        case 10:
          selectedLottieOption = this.skeletonAssetsMusicInnerOptions;
          break;
        case 11:
          selectedLottieOption = this.preloaderCanvasSceneOptions;
          break;
        case 12:
          selectedLottieOption = this.preloaderCanvasOptions;
          break;
        case 13:
          selectedLottieOption = this.preloaderCanvasWithBgOptions;
          break;
        default:
          break;
      }

      if (this.isSmartCreate) {
        selectedLottieOption = this.preloaderSmartCreateOptions;
      }

      return selectedLottieOption;
    },
  },
};
</script>

<style lang="scss">
.preloader {
  display: block;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
  flex-direction: column;

  &.skeleton {
    justify-content: flex-start;

    .preloader__image {
      width: 100%;
    }

    svg {
      height: auto !important;
      margin-top: 10px;
    }
  }
}

.preloader__image {
  width: 60px;
  margin: 0 auto;
  position: relative;

  .has-transition {
    position: absolute;
  }
}

.preloader__text {
  display: block;
  font-size: 0.875rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
</style>
