<template lang="pug">
.canvas-preloader
  .loader__container
    Lottie.loader(:options="canvasLoaderOptions")
    p.loader__text {{ text }}
</template>

<script>
import VueCookies from 'vue-cookies';
import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';
import OffeoLightLoader from '@/assets/data/offeo-light-loader.json';
import OffeoDarkLoader from '@/assets/data/offeo-dark-loader.json';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'CanvasPreloader',
  props: {
    text: { type: String, default: 'Preparing everything' },
  },
  components: {
    Lottie,
  },
  mixins: [darkModeMixin],
  computed: {
    getOffeoTheme() {
      const offeoThemeCookie = process.env.VUE_APP_OFFEO_THEME;
      const getOffeoTheme = VueCookies.get(offeoThemeCookie);
      return getOffeoTheme ? getOffeoTheme : 'dark'; // eslint-disable-line
    },
    selectedOffeoLoader() {
      if (this.getOffeoTheme === 'dark') {
        return OffeoDarkLoader;
      }
      return OffeoLightLoader;
    },
    canvasLoaderOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'none',
        },
        animationData: this.selectedOffeoLoader,
      };
    },
  },
};
</script>

<style lang="scss">
.canvas-preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: var(--lightgrey100-darkgrey600);
  font-family: $baseFont;
  overflow: hidden;

  .loader__container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .loader {
    width: 400px !important;
    height: 240px !important;
  }

  .loader__text {
    position: absolute;
    bottom: 100px;
    font-size: 1em;
    color: var(--black-white);
  }
}
</style>
