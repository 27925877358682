<template lang="pug">
.tool-modal(
  :class="{ 'is-show': isShow }"
)
  .tool-modal__header
    h3.tool-modal__title(v-if="title") {{ title }}
    button.tool-modal__closebtn(
      type='button'
      @click="closeModal"
    )
      i.icon.icon-cancel
  .tool-modal__main
    slot
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  name: 'ToolModal',
  data() {
    return {
      isLoaded: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    title: { type: String, default: '' },
    isShow: { type: Boolean, default: false },
  },
  methods: {
    closeModal(isOutside) {
      this.$emit('closeOverlay', isOutside);
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoaded = true;
    }, 100);
  },
};
</script>

<style lang="scss">
.tool-modal {
  position: absolute;
  border-radius: 4px;
  box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  background: #fff;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translate(0, 10px);
  transition: transform 0.25s ease-in-out,
              opacity 0.25s ease-in-out,
              visibility 0.25s ease-in-out;

  &.is-show {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transform: translate(0, 0);

  }
}

.tool-modal__closebtn {
  background: none;
  border: 0;
  cursor: pointer;
  color: #fff;
  z-index: 2;
  font-size: 1em;
  margin-left: auto;

  &:hover,
  &:focus {
    background: transparent;
  }
}

.tool-modal__header {
  padding: 20px;
  background: $blue;
  color: #fff;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tool-modal__title {
    font-size: 1.125em;
    font-weight: 500;
    margin: 0;
    line-height: 1;
  }
}

.tool-modal__main {
  padding: 20px;
}

.colorpicker .tool-modal__header {
  background-color: $darkBg3;
}

.colorpicker .tool-modal__main {
  background-color: $darkBg2;
}
</style>
