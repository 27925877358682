/* eslint-disable */
import Vue from 'vue';
import { TimelineLite } from 'gsap/TweenMax';
import {
  randomId,
  arrayMove,
  rgbToHex,
  checkForDuplicateArray,
  getImageSize,
  getVideoSize,
  unwrapTag,
  reverseHTMLandStrip,
  calcAspectRatio,
  getHTMLWrapper,
  isEmpty,
  isMobile,
  getElementProportion,
  isEmptyObject,
  getHasFontWeight,
  getHasBoldFontWeight,
  getHasItalicFontWeight,
} from '@/assets/scripts/utilities';
import { sceneFormat, elementFormat, textDataFormat, minDuration, maxDuration } from '@/assets/scripts/variables';
import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi';
import Api from '@/services/api/Api';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

import ModularTemplateCategoryApi from '@/services/api/ModularTemplateCategoryApi';
import { watermarkFormat } from '../assets/scripts/variables';
import { groups } from '../assets/scripts/enums';

const unwrapContentsHTMLTag = (element) => {
  // if text is wrapped by an html tag, unwrap it
  // eg <b>TEXT</b> should just be TEXT with bold
  const contentObject = getHTMLWrapper(element.data.content);
  // console.log('~~unwrapContentsHTMLTag~~', {...contentObject})

  element.data.content = contentObject.content;

  if (typeof contentObject.italic !== 'undefined') {
    element.data.textItalic = !!contentObject.italic;
  }

  if (typeof contentObject.bold !== 'undefined') {
    element.data.fontWeight = !!contentObject.bold ? 700 : 400;
  }

  if (typeof contentObject.color !== 'undefined') {
    element.data.color = [contentObject.color];
  }
};

const removeAllTag = (element) => {
  // remove <span> tag inside content for lite mode
  element.data.content = reverseHTMLandStrip(element.data.content, true);
};

const updateMissingSceneKey = (scene) => {
  // console.log('scene', typeof scene.transition, scene.transition)
  if (typeof scene.transition === 'undefined' || scene.transition === null) {
    Vue.set(scene, 'transition', {
      value: '',
      direction: '',
    });
  }
  if (
    typeof scene.background === 'undefined' ||
    scene.background === null ||
    Object.keys(scene.background).length === 0
  ) {
    Vue.set(scene, 'background', {
      color: '#fff',
    });
  }
  if (
    scene.background &&
    scene.background.color1 &&
    scene.background.color2 &&
    scene.background.degree
  ) {
    const background = JSON.parse(JSON.stringify(scene.background));
    scene.background = {
      points: [
        {
          color: background.color1 ?? '#000000',
          percentage: 0,
        },
        {
          color: background.color2 ?? '#808080',
          percentage: 100,
        },
      ],
      degree: background.degree ?? 0,
    };
  } else if (typeof scene.background === 'string') {
    // scene background should stay as object
    scene.background = { color: scene.background.color };
  }

  if (typeof scene.transition.value === 'undefined') Vue.set(scene.transition, 'value', '');
  if (typeof scene.transition.direction === 'undefined') Vue.set(scene.transition, 'direction', '');

  // will be used for modular scenes
  if (typeof scene.modular_category_id === 'undefined') Vue.set(scene, 'modular_category_id', null);

  if (typeof scene.description === 'undefined') Vue.set(scene, 'description', null);
  if (typeof scene.preview === 'undefined') Vue.set(scene, 'preview', null);

  if (typeof scene.is_hero_w_bg === 'undefined') Vue.set(scene, 'is_hero_w_bg', false);

  if (typeof scene.is_hero_wo_bg === 'undefined') Vue.set(scene, 'is_hero_wo_bg', false);

  if (typeof scene.is_branded === 'undefined') Vue.set(scene, 'is_branded', false);
};

const updateMissingElementKey = (element, isLiteMode, rootGetters) => {
  if (typeof element.opacity === 'undefined') Vue.set(element, 'opacity', 1);

  if (typeof element.flipX === 'undefined') Vue.set(element, 'flipX', false);
  if (typeof element.flipY === 'undefined') Vue.set(element, 'flipY', false);

  const isText = element.type === 'texts';

  if (isText) {
    if (
      element.data.textBackground &&
      element.data.textBackground.color &&
      element.data.textBackground.color.color
    ) {
      Vue.set(
        element.data.textBackground,
        'color',
        JSON.parse(JSON.stringify(element.data.textBackground.color.color))
      );
    }

    if (
      element.data.textBackground &&
      element.data.textBackground.color &&
      element.data.textBackground.color.color1
    ) {
      const { color1, color2, degree } = element.data.textBackground.color;

      Vue.set(element.data.textBackground, 'color', {
        points: [
          {
            color: color1 ?? '#000000',
            percentage: 0,
          },
          {
            color: color2 ?? '#808080',
            percentage: 100,
          },
        ],
        degree: degree ?? 0,
      });
    }

    if (typeof element.data.textBackground === 'undefined') {
      Vue.set(
        element.data,
        'textBackground',
        JSON.parse(JSON.stringify(textDataFormat.textBackground))
      );
    }
    // some old template has string text shadow
    // e.g. textShadow: "10px 10px 20px rgba(0, 0, 0, 0.25)"
    if (typeof element.data.textShadow === 'string') {
      if (element.data.textShadow === 'none') {
        Vue.set(element.data, 'textShadow', JSON.parse(JSON.stringify(textDataFormat.textShadow)));
      } else {
        const textShadowArr = element.data.textShadow.split(' ');
        console.log('textShadowArr', textShadowArr);
        const x = parseInt(textShadowArr[0]);
        const y = parseInt(textShadowArr[1]);
        const blur = parseInt(textShadowArr[2]);
        const r = parseInt(textShadowArr[3].split('rgba(')[1]);
        const g = parseInt(textShadowArr[4]);
        const b = parseInt(textShadowArr[5]);
        const opacity = parseFloat(textShadowArr[6]);
        const color = rgbToHex(r, g, b);

        Vue.set(element.data, 'textShadow', {
          blur,
          color,
          isEnabled: true,
          opacity,
          x,
          y,
        });
      }
    }

    if (
      element.data.textShadow &&
      element.data.textShadow.color &&
      element.data.textShadow.color.color
    ) {
      Vue.set(
        element.data.textShadow,
        'color',
        JSON.parse(JSON.stringify(element.data.textShadow.color.color))
      );
    }
    if (element.data.textShadow === 'none') {
      Vue.set(element.data, 'textShadow', JSON.parse(JSON.stringify(textDataFormat.textShadow)));
    }

    if (typeof element.data.textBlur === 'undefined') {
      Vue.set(element.data, 'textBlur', JSON.parse(JSON.stringify(textDataFormat.textBlur)));
    }

    if (typeof element.data.textGlow === 'undefined') {
      Vue.set(element.data, 'textGlow', JSON.parse(JSON.stringify(textDataFormat.textGlow)));
    }

    if (typeof element.data.textLightSweep === 'undefined') {
      Vue.set(element.data, 'textLightSweep', JSON.parse(JSON.stringify(textDataFormat.textLightSweep)));
    }

    if (typeof element.data.textOutline === 'undefined') {
      Vue.set(element.data, 'textOutline', JSON.parse(JSON.stringify(textDataFormat.textOutline)));
    }

    if (typeof element.data.text3D === 'undefined') {
      Vue.set(element.data, 'text3D', JSON.parse(JSON.stringify(textDataFormat.text3D)));
    }

    if (typeof element.data.textGlitch === 'undefined') {
      Vue.set(element.data, 'textGlitch', JSON.parse(JSON.stringify(textDataFormat.textGlitch)));
    }

    if (typeof element.data.fontWeight === 'undefined') {
      Vue.set(element.data, 'fontWeight', 400);

      if (element.data.textBold) Vue.set(element.data, 'fontWeight', 700);
    }

    /*
    --- Validation on Text Elements Font Weights and Italic Settings ---
    checker if current fontWeight and italic settings eligible for the font, if not fix the settings.
    commonly happens when a text is using a custom font that is deleted, while the font weight and italic settings doesnt fit with fallback font.
    */
    const { fontFamily, fontWeight, textItalic } = element.data; // eslint-disable-line
    if (rootGetters && fontWeight) {
      const selectedFont = rootGetters.getActiveOffeoFonts.find((font) => font.fontFamily === fontFamily); // eslint-disable-line

      if (selectedFont) {
        const hasSelectedFontWeight = getHasFontWeight(fontWeight, selectedFont, 'all');
        const hasItalic = getHasItalicFontWeight(fontWeight, selectedFont);

        if (textItalic && !hasItalic) element.data.textItalic = false;
        if (!hasSelectedFontWeight) element.data.fontWeight = 400;
      }
    }

    unwrapContentsHTMLTag(element);

    if (isLiteMode) {
      // remove any html tag inside
      removeAllTag(element);
    }

    if (isMobile()) {
      element.data.verticalAlign = 'middle';
    }
  }

  if (!isText) {
    if (
      element.data.mediaShadow &&
      element.data.mediaShadow.color &&
      element.data.mediaShadow.color.color
    ) {
      Vue.set(
        element.data.mediaShadow,
        'color',
        JSON.parse(JSON.stringify(element.data.mediaShadow.color.color))
      );
    }
    if (typeof element.data.mediaShadow === 'undefined') {
      Vue.set(
        element.data,
        'mediaShadow',
        JSON.parse(JSON.stringify(elementFormat.data.mediaShadow))
      );
    }
    if (typeof element.data.mediaBlur === 'undefined') {
      Vue.set(element.data, 'mediaBlur', JSON.parse(JSON.stringify(elementFormat.data.mediaBlur)));
    }

    if (typeof element.data.mediaGlow === 'undefined') {
      Vue.set(element.data, 'mediaGlow', JSON.parse(JSON.stringify(elementFormat.data.mediaGlow)));
    }

    if (typeof element.data.mediaLightSweep === 'undefined') {
      Vue.set(element.data, 'mediaLightSweep', JSON.parse(JSON.stringify(elementFormat.data.mediaLightSweep)));
    }

    if (typeof element.data.mediaGlitch === 'undefined') {
      Vue.set(element.data, 'mediaGlitch', JSON.parse(JSON.stringify(elementFormat.data.mediaGlitch)));
    }

    if (typeof element.data.mediaRoundCorners === 'undefined') {
      Vue.set(element.data, 'mediaRoundCorners', JSON.parse(JSON.stringify(elementFormat.data.mediaRoundCorners)));
    }

    if (typeof element.data.mediaBorder === 'undefined') {
      Vue.set(element.data, 'mediaBorder', JSON.parse(JSON.stringify(elementFormat.data.mediaBorder)));
    }

    // for animated json and svg, shouldn't set default color
    const isSVG =
      element.type !== 'masks' && element.data.url && element.data.url.split('.').pop() === 'svg';
    if (typeof element.data.color === 'undefined' && !element.animated && !isSVG) {
      Vue.set(element.data, 'color', JSON.parse(JSON.stringify(elementFormat.data.color)));
    }
  }

  if (typeof element.data.reflection === 'undefined') {
    Vue.set(element.data, 'reflection', JSON.parse(JSON.stringify(elementFormat.data.reflection)));
  }

  // if (element.type === 'texts' && typeof element.data.textGradient === 'undefined') {
  //   Vue.set(element.data, 'textGradient', {
  //     isEnabled: false,
  //   });
  // }

  if (
    element.data.groundShadow &&
    element.data.groundShadow.color &&
    element.data.groundShadow.color.color
  ) {
    Vue.set(
      element.data.groundShadow,
      'color',
      JSON.parse(JSON.stringify(element.data.groundShadow.color.color))
    );
  }
  if (typeof element.data.groundShadow === 'undefined') {
    Vue.set(
      element.data,
      'groundShadow',
      JSON.parse(JSON.stringify(elementFormat.data.groundShadow))
    );
  }

  if (!element.data.aspectRatio) {
    // const canCalcAspectRatio = !Number.isNaN(element.size.width) || !Number.isNaN(element.size.height)
    element.data.aspectRatio = {
      wh: 'auto',
      hw: 'auto',
    };
  }

  if (!element.data.originalSize) {
    element.data.originalSize = {
      width: {
        value: 'auto',
        multiplier: '100%',
      },
      height: {
        value: 'auto',
        multiplier: '100%',
      },
    };
  }

  if (typeof element.data.layerTag === 'undefined') {
    Vue.set(element.data, 'layerTag', {
      logo: false,
      fg: false,
      overlay: false,
      hero: false,
      h1: false,
      h2: false,
      h3: false,
      bg: false,
    });
  } else {
    // if h1 or h2 is already set when adding new heading/sub-heading text
    if (typeof element.data.layerTag.logo === 'undefined') element.data.layerTag.logo = false;
    if (typeof element.data.layerTag.fg === 'undefined') element.data.layerTag.fg = false;
    if (typeof element.data.layerTag.overlay === 'undefined') element.data.layerTag.overlay = false;
    if (typeof element.data.layerTag.hero === 'undefined') element.data.layerTag.hero = false;
    if (typeof element.data.layerTag.h1 === 'undefined') element.data.layerTag.h1 = false;
    if (typeof element.data.layerTag.h2 === 'undefined') element.data.layerTag.h2 = false;
    if (typeof element.data.layerTag.h3 === 'undefined') element.data.layerTag.h3 = false;
    if (typeof element.data.layerTag.bg === 'undefined') element.data.layerTag.bg = false;
  }

  if (element.type === 'texts' && typeof element.data.letterSpacing === 'undefined')
    Vue.set(element.data, 'letterSpacing', 0);
  if (element.type === 'texts' && typeof element.data.lineHeight === 'undefined')
    Vue.set(element.data, 'lineHeight', 1);

  if (element.type !== 'texts') {
    if (typeof element.position.x === 'undefined' && typeof element.position.left !== 'undefined') {
      Vue.set(element.position, 'x', element.position.left + element.size.width / 2);
      delete element.position.left;
    }
    if (typeof element.position.y === 'undefined' && typeof element.position.top !== 'undefined') {
      Vue.set(element.position, 'y', element.position.top + element.size.height / 2);
      delete element.position.top;
    }
  }

  if (
    (element.type === 'images' || element.type === 'videos') &&
    typeof element.blend === 'undefined'
  )
    Vue.set(element, 'blend', '');
  if (
    (element.type === 'images' || element.type === 'videos') &&
    typeof element.tint === 'undefined'
  )
    Vue.set(element, 'tint', 0);
  if (element.type === 'images' || element.type === 'videos' || element.isImage) {
    if (typeof element.filters === 'undefined') {
      Vue.set(element, 'filters', {
        blur: 0,
        brightness: 1,
        contrast: 1,
        hue: 0,
        name: '',
        saturate: 1,
        sepia: 0,
      });
    }
    if (typeof element.filters.blur === 'undefined') Vue.set(element.filters, 'blur', 0);
    if (typeof element.filters.brightness === 'undefined')
      Vue.set(element.filters, 'brightness', 1);
    if (typeof element.filters.contrast === 'undefined') Vue.set(element.filters, 'contrast', 1);
    if (typeof element.filters.hue === 'undefined') Vue.set(element.filters, 'hue', 0);
    if (typeof element.filters.name === 'undefined') Vue.set(element.filters, 'name', '');
    if (typeof element.filters.saturate === 'undefined') Vue.set(element.filters, 'saturate', 1);
    if (typeof element.filters.sepia === 'undefined') Vue.set(element.filters, 'sepia', 0);
  }

  if (element.isImage && typeof element.data.has_removed_bg === 'undefined') {
    Vue.set(element.data, 'has_removed_bg', false);
  }

  if (
    (element.type === 'images' || element.isImage || element.type === 'videos') &&
    typeof element.data.image === 'undefined'
  ) {
    Vue.set(element.data, 'image', {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    });
  }
  // console.debug('updateElementMissingKeys:color', {
  //   element: JSON.parse(JSON.stringify(element)),
  // });
  const color = element.data.color;
  if (color && typeof color === 'object') {
    color.forEach((currentColor, key) => {
      // console.debug('currentColor', currentColor);
      if (
        currentColor &&
        currentColor.color1 &&
        currentColor.color2 &&
        (currentColor.degree ?? currentColor.angle) !== undefined
      ) {
        // console.log('Need to restructure color:1');
        const oldColorObj = JSON.parse(JSON.stringify(currentColor));
        element.data.color[key] = {
          points: [
            {
              color: oldColorObj.color1 ?? '#000000',
              percentage: 0,
            },
            {
              color: oldColorObj.color2 ?? '#808080',
              percentage: 100,
            },
          ],
          degree: oldColorObj.degree ?? oldColorObj.angle ?? 0,
        };
      } else if (currentColor.color && !element.animated) {
        // don't restructure if this is an animated json
        // since animated json need `nm` key
        // console.log('Need to restructure color:2');
        element.data.color[key] = JSON.parse(JSON.stringify(currentColor.color));
      }
      // else {
      //   console.log('No need to restructure color');
      // }
    });
  }

  if (element.type === 'videos' && typeof element.data.time_start === 'undefined')
    Vue.set(element.data, 'time_start', 0);
  if (element.type === 'videos' && typeof element.data.time_end === 'undefined')
    Vue.set(element.data, 'time_end', element.time_out);

  // if (typeof element.timeline_settings.animationDuration === 'undefined') Vue.set(element.timeline_settings, 'animationDuration', 5);
  if (typeof element.timeline_settings.animationDuration === 'undefined')
    Vue.set(element.timeline_settings, 'animationDuration', element.time_out - element.time_in);

  if (typeof element.timeline_settings.animateInValue === 'undefined')
    Vue.set(element.timeline_settings, 'animateInValue', '');
  if (typeof element.timeline_settings.animateInDirection === 'undefined')
    Vue.set(element.timeline_settings, 'animateInDirection', '');
  if (typeof element.timeline_settings.animateInDuration === 'undefined')
    Vue.set(element.timeline_settings, 'animateInDuration', 1);
  if (typeof element.timeline_settings.animateInOption === 'undefined')
    Vue.set(element.timeline_settings, 'animateInOption', 'default');

  if (typeof element.timeline_settings.animateDuringValue === 'undefined')
    Vue.set(element.timeline_settings, 'animateDuringValue', '');
  if (typeof element.timeline_settings.animateDuringSpeed === 'undefined')
    Vue.set(element.timeline_settings, 'animateDuringSpeed', 1);
  if (typeof element.timeline_settings.animateDuringDirection === 'undefined')
    Vue.set(element.timeline_settings, 'animateDuringDirection', '');

  if (typeof element.timeline_settings.animateOutValue === 'undefined')
    Vue.set(element.timeline_settings, 'animateOutValue', '');
  if (typeof element.timeline_settings.animateOutDirection === 'undefined')
    Vue.set(element.timeline_settings, 'animateOutDirection', '');
  if (typeof element.timeline_settings.animateOutDuration === 'undefined')
    Vue.set(element.timeline_settings, 'animateOutDuration', 1);
  if (typeof element.timeline_settings.animateOutStart === 'undefined')
    Vue.set(element.timeline_settings, 'animateOutStart', '');
  if (typeof element.timeline_settings.animateOutOption === 'undefined')
    Vue.set(element.timeline_settings, 'animateOutOption', 'default');

  if (typeof element.isCustom === 'undefined') Vue.set(element, 'isCustom', false);

  if (typeof element.data.verticalAlign === 'undefined')
    Vue.set(element.data, 'verticalAlign', 'top');
};

const convertElementToMask = (element, maskElement) => {
  element.size.height = element.size.width;
  element.type = 'masks';
  element.isImage = false;
  Vue.set(element.data, 'image', maskElement.data.image);
  element.data.image.url = element.data.url;
  element.data.image.removedBgUrl = element.data.removedBgUrl;

  element.data.url = maskElement.data.url;
  element.data.urlHd = maskElement.data.urlHd;
  element.data.thumb = maskElement.data.thumb;

  delete element.data.color;
  delete element.data.groundShadow;
  delete element.data.mediaBlur;
  delete element.data.mediaShadow;
  delete element.data.originalSize;
  delete element.data.reflection;
  delete element.data.removedBgThumb;
  delete element.data.removedBgUrl;
  delete element.data.removedBgUrlHd;
  delete element.data.urlHd;
};

const moveTextOutsideCanvas = (element, canvasWidth, canvasHeight) => {
  const halfWidth = element.size.width / 2;
  const halfHeight = element.size.height / 2;

  const left = element.position.x - halfWidth;
  const right = element.position.x + halfWidth;
  const top = element.position.y - halfHeight;
  const bottom = element.position.y + halfHeight;

  if (left < 0) element.position.x = halfWidth;
  if (right > canvasWidth) element.position.x = canvasWidth - halfWidth;
  if (top < 0) element.position.y = halfHeight;
  if (bottom > canvasHeight) element.position.y = canvasHeight - halfHeight;
};

const updateSelectedMusicDuration = (selectedMusic, sceneDuration) => {
  if (!selectedMusic || !selectedMusic.url) return;
  const urlArray = selectedMusic.url.split('#t=');
  let newUrl = urlArray[0] || ''; // if originally empty, let it stay empty
  const time = urlArray[1]; // '0.2,8.2

  if (time) {
    const maxDuration = selectedMusic.max_duration;
    let timeStart = time.split(',')[0];
    let timeEnd = parseFloat(timeStart) + sceneDuration;

    // if the new timeEnd is actually beyond max duration
    // set it to be maxDuration, and change timeStart
    if (timeEnd > maxDuration) {
      timeEnd = maxDuration;
      timeStart = parseFloat(timeEnd) - parseFloat(sceneDuration);
    }
    if (timeStart < 0) {
      timeStart = 0;
    }
    newUrl += `#t=${Math.round(timeStart * 10) / 10},${Math.round(timeEnd * 10) / 10}`;
  }

  return newUrl;
};

// this is for putting the element into swap element state
const setSwappedMediaSizeCover = (item) => {
  const { element, newElement, width, height, maxWidth, maxHeight } = item;

  element.data.url = newElement.data.url;
  element.data.urlHd = newElement.data.urlHd;
  element.data.thumb = newElement.data.thumb;

  if (newElement.data.src_id) {
    element.data.src_id = newElement.data.src_id;
  }

  let newWidth,
    newHeight,
    ratio = 0;

  if (width < maxWidth || newWidth == null) {
    // Check if the current width is less than the max
    ratio = maxWidth / width; // get ratio for scaling image
    newHeight = height * ratio; // Reset height to match scaled image
    newWidth = maxWidth; // Reset width to match scaled image
  }

  // Check if new height is still less than max
  if (newHeight < maxHeight || newHeight == null) {
    ratio = maxHeight / height; // get ratio for scaling image
    newWidth = width * ratio; // Reset width to match scaled image
    newHeight = maxHeight; // Reset height to match scaled image
  }

  if (newWidth == 0 && newHeight == 0) {
    newWidth = width;
    newHeight = height;
  }

  element.size.width = newWidth;
  element.size.height = newHeight;

  element.data.image.width = '100%';
  element.data.image.height = '100%';
  element.data.image.top = '0%';
  element.data.image.left = '0%';
};

// this is for putting the element into swap element state
const setSwappedMediaSizeContain = (item) => {
  const { element, newElement, width, height, maxWidth, maxHeight } = item;

  element.data.url = newElement.data.url;
  element.data.urlHd = newElement.data.urlHd;
  element.data.thumb = newElement.data.thumb;

  if (newElement.data.src_id) {
    element.data.src_id = newElement.data.src_id;
  }

  let newWidth,
    newHeight,
    ratio = 0;

  if (width > maxWidth || newWidth == null) {
    // Check if the current width is larger than the max
    ratio = maxWidth / width; // get ratio for scaling image
    newHeight = height * ratio; // Reset height to match scaled image
    newWidth = maxWidth; // Reset width to match scaled image
  }

  // Check if new height is still larger than max
  if (newHeight > maxHeight || newHeight == null) {
    ratio = maxHeight / height; // get ratio for scaling image
    newWidth = width * ratio; // Reset width to match scaled image
    newHeight = maxHeight; // Reset height to match scaled image
  }

  if (newWidth == 0 && newHeight == 0) {
    newWidth = width;
    newHeight = height;
  }

  element.size.width = newWidth;
  element.size.height = newHeight;

  element.data.image.width = '100%';
  element.data.image.height = '100%';
  element.data.image.top = '0%';
  element.data.image.left = '0%';
};

// this is putting the element into the canvas elements state
const confirmSwappedMediaSizeCover = (item) => {
  const { element, newElement } = item;
  let { width, height } = item;

  const ratio = width / height;

  // make the width same size first
  if (width !== element.size.width) {
    width = element.size.width;
    height = width / ratio;
  }

  // if the height is smaller, then change the height to match
  if (height < element.size.height) {
    height = element.size.height;
    width = height * ratio;
  }

  const difference = {
    width: width - element.size.width,
    height: height - element.size.height,
  };

  element.data.image = {
    width: difference.width === 0 ? '100%' : `${(width / element.size.width) * 100}%`,
    height: difference.height === 0 ? '100%' : `${(height / element.size.height) * 100}%`,
    top:
      difference.height === 0
        ? '0%'
        : `${(((difference.height / 2) * -1) / element.size.height) * 100}%`,
    left:
      difference.width === 0
        ? '0%'
        : `${(((difference.width / 2) * -1) / element.size.width) * 100}%`,
  };

  element.data.aspectRatio = calcAspectRatio(element);

  element.data.has_removed_bg = !!newElement.has_removed_bg;

  element.data.url = newElement.url;
  element.data.urlHd = newElement.urlHd;
  element.data.thumb = newElement.thumb;

  const originalSize = checkOriginalSize(element);
  Vue.set(element.data, 'originalSize', originalSize);
};

// this is putting the element into the canvas elements state
const confirmSwappedMediaSizeContain = (item) => {
  const { element, newElement } = item;
  let { width, height } = item;

  const ratio = width / height;

  // make the width same size first
  if (width !== element.size.width) {
    width = element.size.width;
    height = width / ratio;
  }

  console.log('width 1', width, height);

  // if the height is bigger, then change the height to match
  if (height > element.size.height) {
    height = element.size.height;
    width = height * ratio;
  }

  console.log('width 2', width, height);

  element.size.width = width;
  element.size.height = height;

  element.data.image = {
    width: '100%',
    height: '100%',
    top: '0%',
    left: '0%',
  };

  element.data.aspectRatio = calcAspectRatio(element);

  element.data.has_removed_bg = !!newElement.has_removed_bg;

  element.data.url = newElement.url;
  element.data.urlHd = newElement.urlHd;
  element.data.thumb = newElement.thumb;

  const originalSize = checkOriginalSize(element);
  Vue.set(element.data, 'originalSize', originalSize);
};

const checkOriginalSize = (element) => {
  let { originalSize } = element;

  if (!originalSize) {
    // eslint-disable-next-line radix
    const percentageToInt = (percentage) => parseInt(percentage, 10);
    const optionalPercentageToInt = (percentage) =>
      typeof percentage === 'number' ? percentage : percentageToInt(percentage);
    // eslint-disable-next-line max-len
    const calcWholeValue = (item, key) => {
      return item.size[key] * (percentageToInt(item.data.image[key]) / 100);
    };
    const calcElementWholeSize = (key) => parseFloat(calcWholeValue(element, key).toFixed(3));
    const topMultiplier = optionalPercentageToInt(element.data.image.top);
    const leftMultiplier = optionalPercentageToInt(element.data.image.left);

    originalSize = {
      width: {
        value: calcElementWholeSize('width'),
        multiplier: percentageToInt(element.data.image.width),
      },
      height: {
        value: calcElementWholeSize('height'),
        multiplier: percentageToInt(element.data.image.height),
      },
      top: {
        value: element.size.height * (topMultiplier / 100),
        multiplier: topMultiplier,
      },
      left: {
        value: element.size.width * (leftMultiplier / 100),
        multiplier: leftMultiplier,
      },
    };
  }

  return originalSize;
};

const convertElementToFitCanvas = (element, canvasSize) => {
  element.size.width = canvasSize.width;
  element.size.height = canvasSize.height;

  if (!element.data.isHtml && !element.type !== 'shapes') {
    const newElement = cloneDeep(element);
    const item = {
      element,
      newElement,
      width: newElement.size.width,
      height: newElement.size.height,
      maxWidth: canvasSize.width,
      maxHeight: canvasSize.height,
    };

    setSwappedMediaSizeCover(item);
  }

  element.position.x = canvasSize.width / 2;
  element.position.y = canvasSize.height / 2;
};

const removeEmphasisAnimation = (element) => {
  element.timeline_settings.animateDuringValue = '';
};

const baseHistory = {
  type: 'mutation',
  name: '',
  history: {},
};

const storyBoardPreviewsKey = 'offeo_story_board_previews';

// each scene elements list is stored in scenesList.byId[].elements
// to get the index of the element, please use the one from sceneslist
// elements list by id is not in order

const canvasElements = {
  namespaced: true,
  state: {
    activeElementsIds: [],
    scenesList: {
      byId: JSON.parse(JSON.stringify(sceneFormat)),
      allIds: [0],
    },
    elementsList: {
      byId: {},
      allIds: [],
    },
    project: {
      id: 0,
      projectId: 'thisIsATestId123',
      duration: 5, // this is equal to allScenesDuration
      name: 'Project Name',
      isModular: false,
      isMultiScene: false,
      isPermutation: false,
      isLayout: false,
      isLogo: false,
      scenes: [0],
      audio: {},
      previews: [],
      isShareable: false,
      brandId: 0,
      /**
       * refer to watermarkFormat
       */
      watermark: {},
    },
    previousSceneDuration: 0,
    // template modular scenes list is for when user just click on a modular template
    templateModularScenesList: {
      byId: {},
      allIds: [],
    },
    templateModularElementsList: {
      byId: {},
      allIds: [],
    },
    selectedTemplateModular: {
      id: 0,
      name: '',
    },
    templateModularId: 0,
    // last selected template is for when user last selected modular template
    // so that when they open modular template, it doesn't need to call api again
    lastSelectedTemplateId: 0,
    lastSelectedTemplateScenesList: {
      byId: {},
      allIds: [],
    },
    lastSelectedTemplateElementsList: {
      byId: {},
      allIds: [],
    },
    // last selected lite template is to check if it has 1 or more scene
    // if it only has 1 scene, auto add it when user add new scene
    // if more, show the option
    lastSelectedLiteTemplateId: 0,
    lastSelectedLiteTemplateScenesList: {
      byId: {},
      allIds: [],
    },
    // permutation id is only to be set one time
    // when user select from smart create
    permutationId: 0,
    // read the comment by siska in setModularCategories()
    permutationScenesIds: {},
    modularCategories: {
      byId: {},
      allIds: [],
    },
    activeSceneId: 0,
    activeColorId: null,
    canvasSize: {
      width: 1080,
      height: 1080,
    },
    canvasSizeWithTemplate: {
      width: 1080,
      height: 1080,
    },
    mainTimeline: new TimelineLite({ paused: true }),
    allScenesTimeline: new TimelineLite({ paused: true }),
    genieTimeline: new TimelineLite({ paused: true }),
    previewTimeline: new TimelineLite({ paused: true }),
    sceneTimeline: {
      0: new TimelineLite({ paused: true }),
    },
    projectColors: {
      solid: [],
      gradient: [],
      additional: [],
    },
    swappedElement: {},
    trimmedElement: {},
    hoveredElement: {},
    multipleSwappedElements: {},
    croppedElement: '',
    isTrimmingMusic: false, // cover when selectedMusic is trimmed in music-toolbar's trimmer, will not reload
    selectedMusic: {
      url: '',
      volume: 100,
      fade_in: false,
      fade_out: false,
    },
    edittedTextIndex: null,
    edittedTextId: '',
    activeFontFamily: '',
    smartGuides: [],
    removeBgElement: {},
    savingStatus: '',
    savingTries: 0,
    maxSavingTries: 3,
    canvasSizeText: 'Square',
    maxNewSceneDuration: '',
    showScenesOverlay: false,
    swapContainer: {
      angle: 0,
      height: 0,
      width: 0,
      x: 0,
      y: 0,
    },
    timelineSpeed: 1,
    autoSaveInterval: null,
    hasChanges: false,
    reloadCanvas: false,
    activeElementsToChange: '',
    copiedElements: [],
    copiedSceneId: null,
    activeOverlay: false, // this toggle is for designer tags (puppeteer)
    activeFGWhiteBg: false, // this toggle is for designer tags (puppeteer)
    activeFGBlackBg: false, // this toggle is for designer tags (puppeteer)
    activeBackgroundElements: false, // this toggle is for designer tags (puppeteer)
    temporaryHeroImageUrl: '',
    heroSceneWithBg: {},
    heroSceneWithoutBg: {},
    storyBoardPreviews: {}, //,
    storyBoardApiRequestIds: [], // list of ids currently have request to api, to avoid multiple calls
    isDoingSplitText: false,
    hasAutoWidthElement: false,
    isSwappingElement: false,
    isChangingElements: false,
    videoElements: [],
    loadedVideoElements: [],
    selectedAnimationDirection: 'default',
    cachedVideos: {},
    shutterstockLoadingIds: {},
    defaultBackground: {
      thumb: '',
      url: '',
      urlHd: '',
      color: '',
    },
    changeBackgroundCount: 0,
  },
  getters: {
    getActiveElements: (state) => {
      if (state.activeElementsIds.length === 0) return [];
      return state.activeElementsIds.map((elementId) => state.elementsList.byId[elementId]);
    },
    getActiveElementsIndexes: (state) => {
      if (state.activeElementsIds.length === 0) return [];
      return state.activeElementsIds.map((elementId) =>
        state.scenesList.byId[state.activeSceneId].elements.indexOf(elementId)
      );
    },
    getActiveElementsIds: (state) => state.activeElementsIds,
    getActiveElementToChange: (state) => state.activeElementsToChange,
    getCanvasElements: (state) => {
      if (state.elementsList.allIds.length === 0) return [];
      return state.scenesList.byId[state.activeSceneId].elements.map(
        (elementId) => state.elementsList.byId[elementId]
      );
    },
    getLastCanvasElement: (state) => {
      if (state.elementsList.allIds.length === 0) return [];
      const [ lastElementId ] = state.scenesList.byId[state.activeSceneId].elements.slice(-1);
      return state.elementsList.byId[lastElementId];
    },
    getCanvasElementsIds: (state) => {
      if (state.elementsList.allIds.length === 0) return [];
      return state.scenesList.byId[state.activeSceneId].elements;
    },
    getCanvasElementById: (state) => (elementId) => {
      return state.elementsList.byId[elementId];
    },
    getCanvasElementIndexById: (state) => (elementId) => {
      return state.scenesList.byId[state.activeSceneId].elements.indexOf(elementId);
    },
    getCanvasElementsAnimation: (state) => {
      if (state.elementsList.allIds.length === 0) return [];
      const sceneObject = state.scenesList.byId[state.activeSceneId];
      return sceneObject.elements.map(
        (elementId) => state.elementsList.byId[elementId].timeline_settings
      );
    },
    getSceneCanvasElementsByType: (state) => (type) => {
      if (state.elementsList.allIds.length === 0) return [];
      const sceneObject = state.scenesList.byId[state.activeSceneId];
      return sceneObject.elements
        .map((elementId) => state.elementsList.byId[elementId])
        .filter((element) => element.type === type);
    },
    getCanvasBackground: (state) => {
      if (
        state.scenesList.allIds.length === 0 ||
        !state.scenesList.byId[state.activeSceneId].background
      )
        return { color: '#fff' };
      return state.scenesList.byId[state.activeSceneId].background;
      // return state.canvasBackground;
    },
    getScenes: (state) => state.project.scenes.map((sceneId) => state.scenesList.byId[sceneId]),
    getModularTemplateScenes: (state) =>
      state.templateModularScenesList.allIds.map(
        (sceneId) => state.templateModularScenesList.byId[sceneId]
      ),
    getLastSelectedTemplateScenes: (state) =>
      state.lastSelectedTemplateScenesList.allIds.map(
        (sceneId) => state.lastSelectedTemplateScenesList.byId[sceneId]
      ),
    getSceneById: (state) => (sceneId) => {
      return state.scenesList.byId[sceneId];
    },
    getScenesElementsById: (state) => (sceneId) => {
      if (!state.scenesList.byId[sceneId]) return [];
      const elements = state.scenesList.byId[sceneId].elements;
      if (!elements || elements.length === 0) return [];
      return elements.map((elementId) => state.elementsList.byId[elementId]);
    },
    getModularTemplateScenesElementsById: (state) => (sceneId) => {
      if (!state.templateModularScenesList.byId[sceneId]) return [];
      const elements = state.templateModularScenesList.byId[sceneId].elements;
      if (!elements || elements.length === 0) return [];
      return elements.map((elementId) => state.templateModularElementsList.byId[elementId]);
    },
    getScenesIndexById: (state) => (sceneId) => {
      return state.scenesList.allIds.indexOf(sceneId);
    },
    getCanvasSize: (state) => state.canvasSize,
    getCanvasSizeWithTemplate: (state) => state.canvasSizeWithTemplate,
    getMainTimeline: (state) => state.mainTimeline,
    getAllScenesTimeline: (state) => state.allScenesTimeline,
    getAllScenesDuration: (state) => {
      return state.project.duration;
    },
    getSceneDurationById: (state) => (sceneId) => {
      if (!state.scenesList.byId[sceneId]) return null;
      return state.scenesList.byId[sceneId].duration;
    },
    getCurrentSceneDuration: (state) => {
      if (!state.scenesList.byId[state.activeSceneId]) return 5;
      return state.scenesList.byId[state.activeSceneId].duration;
    },
    getCurrentSceneElementsTime: (state) => {
      // this is used to ensure scene preview is updated when each element duration is changed
      // read more about how this works at CanvasElement.vue: HOW ANIMATION WORKS
      const currentScene = state.scenesList.byId[state.activeSceneId];
      const currentSceneElementsTime = {};

      if (!currentScene) return currentSceneElementsTime;

      for (let i = 0; i < currentScene.elements.length; i++) {
        const elementId = currentScene.elements[i];
        const element = state.elementsList.byId[elementId];

        currentSceneElementsTime[elementId] = {
          timeIn: element.time_in,
          timeOut: element.time_out,
        };
      }

      return currentSceneElementsTime;
    },
    getScenesElementsTransition: (state) => {
      return (id) => {
        console.log('getScenesElementsTransition', id);
        if (!state.scenesList.byId[id].transition) {
          state.scenesList.byId[id].transition = {};
        }
        return state.scenesList.byId[id].transition;
      };
    },
    getActiveSceneId: (state) => state.activeSceneId,
    getActiveSceneIndex: (state) => {
      if (state.elementsList.allIds.length <= 1) return 0;
      return state.elementsList.allIds.indexOf(state.activeSceneId);
    },
    getActiveSceneDetails: (state) => {
      return state.scenesList.byId[state.activeSceneId];
    },
    getPermutationSceneIdBySlug: (state) => {
      return (type) => {
        return state.permutationScenesIds[type];
      };
    },
    getProjectColors: (state) => state.projectColors,
    getActiveColorId: (state) => state.activeColorId,
    getSwappedElement: (state) => state.swappedElement,
    getCroppedElement: (state) => state.croppedElement,
    getHoveredElement: (state) => state.hoveredElement,
    getTrimmedElement: (state) => state.trimmedElement,
    getMultipleSwappedElements: (state) => state.multipleSwappedElements,
    getSelectedMusic: (state) => state.selectedMusic,
    getEdittedTextId: (state) => state.edittedTextId,
    getEdittedTextIndex: (state) => state.edittedTextIndex,
    getSmartGuides: (state) => state.smartGuides,
    getRemoveBgElement: (state) => state.removeBgElement,
    getSavingTries: (state) => state.savingTries,
    getMaxSavingTries: (state) => state.maxSavingTries,
    getSavingStatus: (state) => state.savingStatus,
    getSaveButtonText: (state) => {
      switch (state.savingStatus) {
        case 'failed':
          return `Failed (${state.savingTries})`;
          break;

        case 'trying':
          return `Trying (${state.savingTries})`;
          break;

        default:
          return state.savingStatus || 'Save';
      }
    },
    getCanvasSizeText: (state) => {
      let width = parseInt(state.canvasSize.width),
        height = parseInt(state.canvasSize.height);

      if (width > height) {
        return 'Landscape';
      } else if (width < height) {
        return 'Vertical';
      } else {
        // default square
        return 'Square';
      }
    },
    getMaxNewSceneDuration: (state, getters, rootState, rootGetters) => {
      let maxProjectDuration = rootGetters.isLiteMode
        ? maxDuration.liteProject
        : maxDuration.payingUserProject;

      if (rootGetters['isFreeUser']) maxProjectDuration = maxDuration.freeUserProject;

      const maxNewSceneDuration = maxProjectDuration - state.project.duration;

      return Math.round(maxNewSceneDuration * 10) / 10;
    },
    showScenesOverlay: (state) => state.showScenesOverlay,
    getProjectDetails: (state) => state.project,
    getProjectId: (state) => state.project.projectId,
    getProjectBrandId: state => state.project.brandId,
    getModularCategories: (state) => {
      return state.modularCategories.allIds.map((id) => state.modularCategories.byId[id]);
    },
    getModularCategoriesIds: (state) => {
      return state.modularCategories.allIds;
    },
    getCurrentAudioTime: (state, getters, rootState, rootGetters) => {
      let timeStart = 0;

      if (state.selectedMusic.url) {
        let splitTime = state.selectedMusic.url.split('#t=');
        if (splitTime.length > 1) {
          let time = splitTime[1].split(',');
          timeStart = parseFloat(time[0]);
        }
      }

      // console.log('state.scenesList', state.project.scenes, state.scenesList.allIds, state.scenesList.byId);
      if (!rootGetters['getIsAllScenesPlaying']) {
        if (state.project.scenes.length) {
          for (let i = 0; i < state.project.scenes.length; i++) {
            const sceneId = state.project.scenes[i];
            const scene = state.scenesList.byId[sceneId];
            if (state.activeSceneId === sceneId) break;

            if (typeof scene !== 'undefined') timeStart += parseFloat(scene.duration);
          }
        }
      }

      let addTime = rootGetters['getIsAllScenesPlaying']
        ? rootGetters['getCurrentAllScenesTime']
        : rootGetters['getCurrentTime'];
      // console.log('getCurrentSceneDuration', addTime, rootGetters['getIsAllScenesPlaying'], rootGetters['getCurrentAllScenesTime'], rootGetters['getCurrentTime'], (timeStart + addTime) );

      return timeStart + addTime;
    },
    getCopiedElements: (state) => state.copiedElements,
    getHeroElement: (state) => {
      if (state.elementsList.allIds.length === 0) return {};

      const elementId = state.elementsList.allIds.filter((elementId) => {
        const element = state.elementsList.byId[elementId];
        return typeof element.data.layerTag !== 'undefined' && element.data.layerTag.hero;
      });
      if (elementId.length) return state.elementsList.byId[elementId[0]];
      return {};
    },
    getHeroSceneWithBg: (state) => state.heroSceneWithBg,
    getHeroSceneWithoutBg: (state) => state.heroSceneWithoutBg,
    getCanvasElementsGroups: (state, getters) => {
      if (state.isChangingElements) return;

      const canvasElements = cloneDeep(getters['getCanvasElements']);
      const newGroup = [];
      const groupList = [];
      let i = 0;

      canvasElements.forEach((element, index) => {
        element.index = index;

        if (element.data.isGroup && element.data.groupId) {
          if (Object.keys(groupList).indexOf(element.data.groupId) === -1) {
            groupList[element.data.groupId] = [i];

            const groupData = {
              groupElement: true,
              groupName: element.data.groupName,
              groupId: element.data.groupId,
              lock: element.data.groupLock,
              show: element.data.groupShow,
              groupFold: element.data.groupFold,
              index: Math.floor(Math.random() * 1000),
            };

            newGroup.push(groupData);
          } else {
            groupList[element.data.groupId].push(i);
          }
        } else {
          newGroup.push(element);
        }

        i += 1;
      });

      const length = newGroup.length;
      for (let i = 0; i < length; i += 1) {
        if (newGroup[i].groupElement) {
          const groupId = newGroup[i].groupId;
          const groupArr = [];

          groupList[groupId].forEach((index) => {
            groupArr.push(canvasElements[index]);
          });

          newGroup[i].elements = groupArr;
          newGroup[i].data = {};
          newGroup[i].data.layerTag = {};
          newGroup[i].timeline_settings = [];
        }
      }

      return newGroup;
    },
    getProjectPreviews: (state) => state.project.previews,
    isDoingSplitText: (state) => state.isDoingSplitText,
    hasAutoWidthElement: (state) => state.hasAutoWidthElement,
    getOriginalSizeById: (state) => (id) => {
      const element = state.elementsList.byId[id];
      return element ? element.data.originalSize : null;
    },
    getWatermark: state => state.project.watermark,
    getLiteTemplateScenes: (state) =>
      state.lastSelectedLiteTemplateScenesList.allIds.map(
        (id) => state.lastSelectedLiteTemplateScenesList.byId[id]
      ),
  },
  mutations: {
    // =======================
    // ACTIVE ELEMENT
    // =======================
    updateElementListIds(state, item) {
      if (item.length) {
        for (let i = 0; i < item.length; i++) {
          let element = item[i];
          // insert generated id from DB to differentiate if element is already saved in the DB
          if (state.elementsList.byId[element.data.id]) {
            state.elementsList.byId[element.data.id].element_id = element.element_id;
          }
        }
      }
    },
    changeActiveElements(state, elementId) {
      if (state.elementsList.byId[elementId].lock) return;
      state.activeElementsIds.length = 0;
      this.commit('canvasElements/addSingleActiveElement', elementId);
    },
    updateActiveElementToChange(state, elementId) {
      state.activeElementsToChange = elementId;
    },
    addSingleActiveElement(state, elementId) {
      // NOTE:
      // this does not have lock checker and meant to be for background
      // please use `addActiveElements` for any other elements
      state.activeColorId = null;
      const existingIndex = state.activeElementsIds.indexOf(elementId);

      if (existingIndex < 0) {
        state.activeElementsIds.push(elementId);
      }

      // if the hovered id is same as active element, remove it
      if (elementId === state.hoveredElement.id) {
        this.commit('canvasElements/removeHoveredElement');
      }

      const element = state.elementsList.byId[state.activeElementsIds[0]];
      if (typeof element !== 'undefined') {
        if (element.type === 'texts') {
          let activeFontFamily = element.data.fontFamily;

          state.activeElementsIds.forEach((id) => {
            const element = state.elementsList.byId[id];
            if (element.type !== 'texts' || element.data.fontFamily !== activeFontFamily) {
              activeFontFamily = '';
              return true;
            }
          });

          state.activeFontFamily = activeFontFamily;
        } else {
          state.activeFontFamily = '';
        }
      }
    },
    addActiveElements(state, elementId) {
      if (state.elementsList.byId[elementId].lock) return;

      this.commit('canvasElements/addSingleActiveElement', elementId);

      console.log('closeExtraLeftSidebar');
      this.commit('closeExtraLeftSidebar');
    },
    toggleActiveElements(state, elementId) {
      const existingIndex = state.activeElementsIds.indexOf(elementId);

      console.log('toggleActiveElements', elementId);

      if (existingIndex < 0) {
        this.commit('canvasElements/addActiveElements', elementId);
      } else {
        state.activeColorId = null;
        state.activeElementsIds.splice(existingIndex, 1);
      }
    },
    emptyActiveElements(state) {
      console.log('emptyActiveElements');
      state.activeColorId = null;
      state.activeFontFamily = '';
      state.activeElementsIds.length = 0;
      state.activeElementsIds.pop(); // to trigger any watcher
    },
    // =======================
    // CANVAS ELEMENT
    // =======================
    reorderCanvasElements(state, item) {
      const {
        isGroupSelect,
        selectedId,
        newElements,
        getCanvasElementsGroups
      } = item;

      if (!isGroupSelect) {
        newElements.forEach((item, index) => {
          // item.data means this is not the group holder
          if (item.data && selectedId === item.data.id) {
            const isFirstElement = index === 0;
            const isLastElement = index === newElements.length - 1;

            if (!isFirstElement && !isLastElement) {
              const prevElement = newElements[index - 1];
              const nextElement = newElements[index + 1];

              if (prevElement && nextElement) {
                const prevGroupId = prevElement.groupId || (prevElement.data && prevElement.data.groupId);
                const nextGroupId = nextElement.groupId || (nextElement.data && nextElement.data.groupId);
                const prevGroupName = prevElement.groupName || (prevElement.data && prevElement.data.groupName);

                if (prevGroupId && nextGroupId && prevGroupId === nextGroupId) {
                  // check whether it's dragged to be part of the group
                  // if the element before and after is part of a group,
                  // add it into the group
                  item.data.groupId = prevGroupId;
                  item.data.groupName = prevGroupName;
                  item.data.isGroup = true;

                  const neighbourData = prevElement.data || nextElement.data;
                  item.data.groupLock = neighbourData.groupLock;
                  item.data.groupShow = neighbourData.groupShow;
                } else {
                  item.data.isGroup = false;
                  delete item.data.groupId;
                  delete item.data.groupName;
                  delete item.data.groupLock;
                  delete item.data.groupShow;
                }
              }
            } else {
              item.data.isGroup = false;
              delete item.data.groupId;
              delete item.data.groupName;
              delete item.data.groupLock;
              delete item.data.groupShow;
            }
          }
        });
      } else {
        // if its a group layer sorting - move all the elements inside that group
        const getGroupElements = getCanvasElementsGroups.find(element => element.groupId === selectedId).elements;
        const groupElementsLength = getGroupElements.length;

        const getGroupIndex = newElements.findIndex(element => element.groupId === selectedId);
        const getFirstGroupElementIndex = newElements.findIndex(element => element.data && element.data.id === getGroupElements[0].data.id);
        const isMoveToBottom = getGroupIndex > getFirstGroupElementIndex;
        const destinationIndex = isMoveToBottom ? getGroupIndex - groupElementsLength : getGroupIndex; // ensure correct destination_index as when move to bottom, we delete element first that can affect the index marking

        // ---
        const prevElement = newElements[getGroupIndex - 1];
        const nextElement = newElements[getGroupIndex + 1];
        let isDragToGroup = false;

        if (prevElement && nextElement) {
          const prevGroupId = prevElement.groupId || (prevElement.data && prevElement.data.groupId);
          const nextGroupId = nextElement.groupId || (nextElement.data && nextElement.data.groupId);
          isDragToGroup = prevGroupId && nextGroupId && prevGroupId === nextGroupId;
        }

        if (!isDragToGroup) {
          newElements.splice(getFirstGroupElementIndex, groupElementsLength); // remove the element as we move them to new location
          getGroupElements.forEach((element, index) => {
            newElements.splice(destinationIndex + index, 0, getGroupElements[index]); // re-add the element in the new location with the same order
          })
        }
      }
      /* eslint-enable */

      const idArrays = newElements.reduce((filtered, element) => {
        if (element.data && element.data.id) {
          filtered.push(element.data.id);
        }
        return filtered;
      }, []);

      Vue.set(state.scenesList.byId[state.activeSceneId], 'elements', idArrays);

      // we also need to update the elements groupId
      newElements.forEach((element) => {
        if (element.data && element.data.id) {
          const { id } = element.data;

          Vue.set(state.elementsList.byId, id, element);
        }
      });
    },
    updateCanvasElementTimelineSettings(state, item) {
      const {
        animationDuration,
        animateInValue,
        animateInDirection,
        animateInDuration,
        animateOutValue,
        animateOutDirection,
        animateOutDuration,
        animateOutStart,
        animateDuringValue,
        animateDuringSpeed,
        animateDuringDirection,
        id,
        animateInOption,
        animateOutOption,
        time_in,
        time_out,
        time_start,
        time_end,
        isAnimateGlitchEffect,
        glitchEffectSpeed,
        glitchEffectSettings,
        isAnimateLightsweepEffect,
        lightsweepEffectSpeed,
        lightsweepEffectSettings,
      } = item;

      const element = state.elementsList.byId[id];
      const timelineSettings = element.timeline_settings;

      if (typeof animationDuration !== 'undefined') { timelineSettings.animationDuration = animationDuration; }

      if (typeof animateInValue !== 'undefined') timelineSettings.animateInValue = animateInValue;
      if (typeof animateInDirection !== 'undefined') { timelineSettings.animateInDirection = animateInDirection; }
      if (typeof animateInDuration !== 'undefined') { timelineSettings.animateInDuration = animateInDuration; }
      if (typeof animateInOption !== 'undefined') { timelineSettings.animateInOption = animateInOption; }

      if (typeof animateDuringValue !== 'undefined') { timelineSettings.animateDuringValue = animateDuringValue; }
      if (typeof animateDuringSpeed !== 'undefined') { timelineSettings.animateDuringSpeed = animateDuringSpeed; }
      if (typeof animateDuringDirection !== 'undefined') { timelineSettings.animateDuringDirection = animateDuringDirection; }

      if (typeof animateOutValue !== 'undefined') { timelineSettings.animateOutValue = animateOutValue; }
      if (typeof animateOutDirection !== 'undefined') { timelineSettings.animateOutDirection = animateOutDirection; }
      if (typeof animateOutDuration !== 'undefined') { timelineSettings.animateOutDuration = animateOutDuration; }
      if (typeof animateOutStart !== 'undefined') { timelineSettings.animateOutStart = animateOutStart; }
      if (typeof animateInOption !== 'undefined') { timelineSettings.animateInOption = animateInOption; }
      if (typeof animateOutOption !== 'undefined') { timelineSettings.animateOutOption = animateOutOption; }

      if (typeof isAnimateGlitchEffect !== 'undefined') timelineSettings.isAnimateGlitchEffect = isAnimateGlitchEffect;
      if (typeof glitchEffectSpeed !== 'undefined') timelineSettings.glitchEffectSpeed = glitchEffectSpeed;
      if (typeof glitchEffectSettings !== 'undefined') timelineSettings.glitchEffectSettings = glitchEffectSettings;
      if (typeof isAnimateLightsweepEffect !== 'undefined') timelineSettings.isAnimateLightsweepEffect = isAnimateLightsweepEffect;
      if (typeof lightsweepEffectSpeed !== 'undefined') timelineSettings.lightsweepEffectSpeed = lightsweepEffectSpeed;
      if (typeof lightsweepEffectSettings !== 'undefined') timelineSettings.lightsweepEffectSettings = lightsweepEffectSettings;

      // make sure time_in and time_out is rounded to nearest 0.1 sec
      if (typeof time_in !== 'undefined') element.time_in = Math.round(time_in * 10) / 10;
      if (typeof time_out !== 'undefined') element.time_out = Math.round(time_out * 10) / 10;

      if (typeof time_start !== 'undefined') element.data.time_start = time_start;
      if (typeof time_end !== 'undefined') element.data.time_end = time_end;
    },
    updateCanvasElementAlignment(state, item) {
      const { canvasSize } = state;
      console.log('updateCanvasElementAlignment', state.activeElementsIds);

      const { id, direction } = item;
      const element = state.elementsList.byId[id];

      console.log(element.size.height);

      if (direction === 'left') {
        element.position.x = 0 + element.size.width / 2;
      } else if (direction === 'right') {
        element.position.x = canvasSize.width - element.size.width / 2;
      } else if (direction === 'center') {
        element.position.x = canvasSize.width / 2;
      } else if (direction === 'top') {
        element.position.y = 0 + element.size.height / 2;
      } else if (direction === 'bottom') {
        element.position.y = canvasSize.height - element.size.height / 2;
      } else if (direction === 'middle') {
        element.position.y = canvasSize.height / 2;
      } else if (direction === 'mc') {
        element.position.x = canvasSize.width / 2;
        element.position.y = canvasSize.height / 2;
      }
    },
    updateCanvasElementRect(state, item) {
      const {
        id, position, rotate, size, borderRadius, image,
      } = item;
      const element = state.elementsList.byId[id];

      if (typeof element === 'undefined') return;

      // console.log('updateCanvasElementRect', id, cloneDeep(position), cloneDeep(size), element.size);

      if (position && typeof position.x !== 'undefined') {
        if (typeof element.position.x === 'undefined') {
          Vue.set(element.position, 'x', position.x);
        } else {
          element.position.x = position.x;
        }
        if (typeof element.position.left !== 'undefined') delete element.position.left;
      }

      if (position && typeof position.y !== 'undefined') {
        if (typeof element.position.y === 'undefined') {
          Vue.set(element.position, 'y', position.y);
        } else {
          element.position.y = position.y;
        }
        if (typeof element.position.top !== 'undefined') delete element.position.top;
      }
      if (typeof rotate !== 'undefined') {
        element.rotate = rotate;
      }
      // for width and height, don't update if the size passed is 0
      if (size && typeof size.width !== 'undefined' && size.width) {
        element.size.width = size.width;
      }
      if (size && typeof size.height !== 'undefined' && size.height) {
        element.size.height = size.height;
      }

      if (borderRadius && typeof borderRadius !== 'undefined') {
        element.data.borderRadius = borderRadius;
      }

      if (image) {
        if (image.top) element.data.image.top = image.top;
        if (image.left) element.data.image.left = image.left;
        if (image.height) element.data.image.height = image.height;
        if (image.width) element.data.image.width = image.width;
      }

      // console.log('move', item);
    },
    updateCanvasElementFlip(state, item) {
      // for (let i = 0; i < state.activeElementsIds.length; i++) {
      //   const id = state.activeElementsIds[i];
      //   const element = state.elementsList.byId[id];

      //   if (item === 'x') element.flipX = !element.flipX;
      //   if (item === 'y') element.flipY = !element.flipY;
      // };

      const { id, x, y } = item;
      const element = state.elementsList.byId[id];

      console.log('updateCanvasElementFlip mutation', item);

      if (x) element.flipX = !element.flipX;
      if (y) element.flipY = !element.flipY;
    },
    updateCanvasElementIndex(state, item) {
      for (let i = 0; i < state.activeElementsIds.length; i++) {
        const elementId = state.activeElementsIds[i];
        const { elements } = state.scenesList.byId[state.activeSceneId];
        const currentIndex = elements.indexOf(elementId);

        const oldIndex = currentIndex;
        let newIndex = currentIndex;

        if (item === 'forward') newIndex -= 1;
        if (item === 'backward') newIndex += 1;
        if (item === 'front') newIndex = i;
        if (item === 'back') newIndex = elements.length - 1;

        if (newIndex < 0) newIndex = 0;
        if (newIndex >= elements.length) newIndex = elements.length - 1;

        if (!isMobile() || newIndex !== elements.length - 1) {
          // for mobile, don't allow user to move it beyond the background
          arrayMove(elements, oldIndex, newIndex);
        }
      }
    },
    updateCanvasElementOriginalSize(state, item) {
      const { id } = item;
      const element = state.elementsList.byId[id];
      if (!element) return;

      const originalSize = checkOriginalSize(element);

      // element.data.originalSize = originalSize;
      Vue.set(element.data, 'originalSize', originalSize);
      // console.debug('updateCanvasElementOriginalSize', { originalSize, id: element.data.id });
    },
    updateCanvasElementAspectRatio(state, data) {
      const { id, aspectRatio } = data;
      const element = state.elementsList.byId[id];
      // console.debug('updateCanvasElementAspectRatio:element', element);
      if (!element) return;

      element.data.aspectRatio = aspectRatio;
      // console.log('aspect ratio', id, aspectRatio.hw, aspectRatio.wh)
      // console.debug('updateCanvasElementAspectRatio', aspectRatio);
    },
    updateCanvasElementSizeFirstTime(state, item) {
      const {
        id, size, position, max_duration,
      } = item;
      // console.log('updateCanvasElementSizeFirstTime', item)

      const element = state.elementsList.byId[id];

      element.size = {
        width: size.width,
        height: size.height,
      };
      element.position = {
        x: position.x,
        y: position.y,
      };
      delete element.position.top;
      delete element.position.left;
      element.isNew = false;

      if (max_duration) {
        element.max_duration = max_duration;
        // if the video animation type is none
        // set the time_out to be same as time_in + max_duration

        const duration = element.time_out - element.time_in;

        if (element.animation_type === 'none' && max_duration < duration) {
          element.time_out = element.time_in + max_duration;
          element.timeline_settings.animationDuration = max_duration;
          element.timeline_settings.animateOutStart = max_duration - 1;
        }
      }
    },
    updateCanvasElementColor(state, item) {
      const {
        id, color, isNew, silent, isWatermark, isBrandPalette, index,
      } = item;
      // id of element
      // color should be an array [];
      // console.log('updateCanvasElementColor', {
      //   id,
      //   color,
      //   isNew,
      //   silent
      // });

      if (!color || !color[0]) return;

      // if (color && color[0]) console.log('updateCanvasElementColor nm', color[0].nm, color[0].color, color)

      let allColorArr = [].concat(state.projectColors.solid).concat(state.projectColors.gradient);

      if (isBrandPalette) {
        console.log('changing brand palette color', color);
      } else if (isWatermark) {
        console.log('changing watermark color', color);
        state.project.watermark.background.color = color[0];
      } else if (!id) {
        // console.log('changing background color')
        state.scenesList.byId[state.activeSceneId].background = color[0];
        // commit('updateCanvasBackground', color)

        allColorArr.push(color[0]);
      } else {
        const selectedElement = state.elementsList.byId[id];
        const { isTextBackground } = this.getters;
        const { isTextShadow } = this.getters;
        const { isGroundShadow } = this.getters;
        const { isMediaShadow } = this.getters;
        const { isTextGlow } = this.getters;
        const { isMediaGlow } = this.getters;
        const { isTextLightSweep } = this.getters;
        const { isTextGlitch } = this.getters;
        const { getActiveTextGlitchColorType } = this.getters;
        const { isTextOutline } = this.getters;
        const { isText3D } = this.getters;
        const { isMediaBorder } = this.getters;
        // console.log('changing element color', selectedElement)

        if (isTextBackground) {
          // console.log('is text background');
          Vue.set(selectedElement.data.textBackground, 'color', color[0]);
        } else if (isTextShadow) {
          // console.log('is text shadow')
          if (typeof selectedElement.data.textShadow.color === 'undefined') {
            Vue.set(selectedElement.data.textShadow.color, 'color', '#000000');
          }

          this.commit('canvasElements/updateCanvasElementTextShadow', { color: color[0] });
        } else if (isGroundShadow) {
          if (typeof selectedElement.data.groundShadow.color === 'undefined') {
            Vue.set(selectedElement.data.groundShadow, 'color', '#000000');
          }

          this.commit('canvasElements/updateCanvasElementGroundShadow', { color: color[0] });
        } else if (isMediaShadow) {
          if (typeof selectedElement.data.mediaShadow.color === 'undefined') {
            Vue.set(selectedElement.data.mediaShadow, 'color', '#000000');
          }

          this.commit('canvasElements/updateCanvasElementMediaShadow', { color: color[0] });
        } else if (isTextGlow) {
          if (typeof selectedElement.data.textGlow.color === 'undefined') {
            Vue.set(selectedElement.data.textGlow, 'color', '#ffffff');
          }

          this.commit('canvasElements/updateCanvasElementTextGlow', { color: color[0] });
        } else if (isMediaGlow) {
          if (typeof selectedElement.data.mediaGlow.color === 'undefined') {
            Vue.set(selectedElement.data.mediaGlow, 'color', '#ffffff');
          }

          this.commit('canvasElements/updateCanvasElementMediaGlow', { color: color[0] });
        } else if (isTextLightSweep) {
          if (typeof selectedElement.data.textLightSweep.color === 'undefined') {
            Vue.set(selectedElement.data.textLightSweep, 'color', '#ffffff');
          }

          this.commit('canvasElements/updateCanvasElementTextLightSweep', { color: color[0] });
        } else if (isTextGlitch) {
          const isColor1 = getActiveTextGlitchColorType === 'color1';
          const selectedColor = isColor1 ? 'color1' : 'color2';
          const fallbackColor = isColor1 ? '#ff00c1' : '#00fff9';

          if (typeof selectedElement.data.textGlitch[selectedColor] === 'undefined') {
            Vue.set(selectedElement.data.textGlitch, selectedColor, fallbackColor);
          }

          if (isColor1) this.commit('canvasElements/updateCanvasElementTextGlitch', { color1: color[0] });
          if (!isColor1) this.commit('canvasElements/updateCanvasElementTextGlitch', { color2: color[0] });
        } else if (isTextOutline) {
          if (typeof selectedElement.data.textOutline.color === 'undefined') {
            Vue.set(selectedElement.data.textOutline, 'color', '#ff00c1');
          }

          this.commit('canvasElements/updateCanvasElementTextOutline', { color: color[0] });
        } else if (isText3D) {
          if (typeof selectedElement.data.text3D.color === 'undefined') {
            Vue.set(selectedElement.data.text3D, 'color', '#ff00c1');
          }

          this.commit('canvasElements/updateCanvasElementText3D', { color: color[0] });
        } else if (isMediaBorder) {
          if (typeof selectedElement.data.mediaBorder.color === 'undefined') {
            Vue.set(selectedElement.data.mediaBorder, 'color', '#ffffff');
          }

          this.commit('canvasElements/updateCanvasElementMediaBorder', { color: color[0] });
        } else {
          if (selectedElement?.type === 'texts') {
            const { content } = selectedElement.data;
            const dom = new DOMParser().parseFromString(content, 'text/html');
            const { children } = dom;

            // if the child has font color, clear those
            if (children.length && document.getElementsByClassName('text-temp')[0]) {
              const textTemp = document
                .getElementsByClassName('text-temp')[0]
                .getElementsByClassName('text-content')[0];

              unwrapTag(children, 'font');
              setTimeout(() => {
                // NOTE: #updatingContent
                selectedElement.data.content = reverseHTMLandStrip(
                  dom.children[0].children[1].innerHTML,
                );

                if (textTemp) textTemp.innerHTML = selectedElement.data.content;
                console.log('element is', selectedElement.data.content);
              }, 300);
            }

            // if color is gradient, some effects need to be disabled since not support
            if (typeof color[0] === 'object') {
              if (selectedElement.data.textLightSweep.isEnabled) this.commit('canvasElements/updateCanvasElementTextLightSweep', { isEnabled: false });
            }
          }

          if (selectedElement.background && selectedElement.type === 'shapes' && !selectedElement.show) {
            Vue.set(selectedElement, 'show', true);
          }

          if (typeof selectedElement.data.color === 'undefined') {
            Vue.set(selectedElement.data, 'color', ['#fff']);
          }

          const selectedIds = typeof id !== 'undefined' ? [id] : state.activeElementsIds;

          selectedIds.forEach((id) => {
            const element = state.elementsList.byId[id];
            element.data.color.length = 0;
          });

          for (let i = 0; i < color.length; i++) {
            const currentColor = color[i];

            if (
              typeof currentColor === 'object'
              && selectedElement.data.url
              && selectedElement.data.url.split('.').pop() === 'svg'
            ) {
              // Polyfill for not supporting Multiple Gradient in SVG
              currentColor.points = [
                {
                  color: currentColor.points[0].color,
                  percentage: 0,
                },
                {
                  color: currentColor.points[1].color,
                  percentage: 100,
                },
              ];
            }

            selectedIds.forEach((id) => {
              const element = state.elementsList.byId[id];
              element.data.color.push(currentColor);
            });
          }
        }

        // for animated json, the color will be an object {color, nm}
        // to save on project color, need to change back to just string
        const duplicateColor = [];
        color.forEach((c) => {
          let newC = cloneDeep(c);
          if (newC.color) newC = newC.color;
          duplicateColor.push(newC);
          // console.log('c', cloneDeep(newC));
        });
        // console.log('duplicateColor', duplicateColor);
        allColorArr = allColorArr.concat(duplicateColor);
      }

      if (!silent) {
        const { newArr, gradientArr } = checkForDuplicateArray(allColorArr);
        state.projectColors.solid = newArr;
        state.projectColors.gradient = gradientArr;
      }
    },
    updateCanvasElementStroke(state, item) {
      const { stroke, isNew, silent } = item;
      // index of element
      // stroke should be an array [];
      // console.log('updateCanvasElementStroke', { stroke }, isNew);

      if (stroke && stroke.length) {
        const defaultShapeColor = '#a3a3a3';

        state.activeElementsIds.forEach((id) => {
          if (typeof id !== 'undefined') {
            const selectedElement = state.elementsList.byId[id];

            if (isNew) {
              Vue.set(selectedElement.data, 'stroke', [{ stroke: 0, strokeColor: '#a3a3a3' }]);
            }

            selectedElement.data.stroke.length = 0;

            for (let i = 0; i < stroke.length; i++) {
              selectedElement.data.stroke.push(stroke[i]);

              if (!silent) {
                const strokeColor = stroke[i].strokeColor
                  ? stroke[i].strokeColor.toLowerCase()
                  : defaultShapeColor;
                // console.log('stroke[i].strokeColor.toLowerCase()', state.projectColors.solid, strokeColor, state.projectColors.solid.indexOf(strokeColor));
                if (state.projectColors.solid.indexOf(strokeColor) === -1) {
                  // TODO: Remove previously selected color, but check if color to remove is being used in project
                  state.projectColors.solid.push(strokeColor);
                }
              }
            }
          }
        });
      }
    },
    updateCanvasElementTime(state, item) {
      const {
        id,
        timeIn,
        timeOut,
        animateInDuration,
        animateOutDuration,
        animateOutStart,
        animationDuration,
      } = item;

      // make sure timeIn and timeOut is rounded to nearest 1 decimal
      if (typeof timeIn !== 'undefined') state.elementsList.byId[id].time_in = Math.round(timeIn * 10) / 10;
      if (typeof timeOut !== 'undefined') state.elementsList.byId[id].time_out = Math.round(timeOut * 10) / 10;

      if (typeof animateInDuration !== 'undefined') { state.elementsList.byId[id].timeline_settings.animateInDuration = animateInDuration; }
      if (typeof animateOutDuration !== 'undefined') { state.elementsList.byId[id].timeline_settings.animateOutDuration = animateOutDuration; }
      if (typeof animateOutStart !== 'undefined') { state.elementsList.byId[id].timeline_settings.animateOutStart = animateOutStart; }
      if (typeof animationDuration !== 'undefined') { state.elementsList.byId[id].timeline_settings.animationDuration = animationDuration; }

      if (typeof timeIn !== 'undefined') {
        state.elementsList.byId[id].timeline_settings.animationDuration = timeOut - timeIn;
        state.elementsList.byId[id].timeline_settings.animateOutStart = timeOut - state.elementsList.byId[id].timeline_settings.animateOutDuration;
      }
    },
    updateCanvasElementFontSize(state, item) {
      const { id, fontSize } = item;
      state.elementsList.byId[id].data.fontSize = fontSize;
    },
    updateCanvasElementTextAlignment(state, item) {
      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];
        element.data.textAlign = item;
      });
    },
    updateCanvasElementTextAlignmentArabicHebrew(state, item) {
      state.elementsList.byId[item.id].data.textAlign = item.text_align;
    },
    updateCanvasElementVerticalAlignment(state, item) {
      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];
        element.data.verticalAlign = item;
      });
    },
    updateCanvasElementFontFamily(state, item) {
      const { fontId, selectedFont } = item;
      const elementIds = fontId ? [fontId] : state.activeElementsIds;

      elementIds.forEach((id) => {
        const selectedElement = state.elementsList.byId[id];

        const { textItalic, content, fontWeight } = selectedElement.data; // eslint-disable-line
        const { fontFamily, fontCategory } = item;

        selectedElement.data.fontFamily = fontFamily;
        selectedElement.data.fontCategory = fontCategory;

        // upon updating font-family, check if the font previous font-style settings (font-weight & font-style) also exist with the new font-family
        const hasSelectedFontWeight = getHasFontWeight(fontWeight, selectedFont, 'all');
        const hasBold = getHasBoldFontWeight(selectedFont);
        const hasItalic = getHasItalicFontWeight(fontWeight, selectedFont);

        if (!hasSelectedFontWeight) {
          selectedElement.data.fontWeight = 400;
        }
        if (textItalic && !hasItalic) {
          selectedElement.data.textItalic = false;
        }

        const dom = new DOMParser().parseFromString(content, 'text/html');
        const { children } = dom;

        // if the child has bold/italic element
        // and the new font doesn't have them, clear those
        if (children.length && document.getElementsByClassName('text-temp')[0]) {
          // console.log('dom', dom, content, dom.children[0].children[1].innerHTML)
          const textTemp = document
            .getElementsByClassName('text-temp')[0]
            .getElementsByClassName('text-content')[0];
          if (!hasBold) {
            unwrapTag(children, 'b');
            setTimeout(() => {
              // NOTE: #updatingContent
              selectedElement.data.content = reverseHTMLandStrip(
                dom.children[0].children[1].innerHTML,
              );
              if (textTemp) textTemp.innerHTML = selectedElement.data.content;
            }, 300);
          }
          if (!hasItalic) {
            unwrapTag(children, 'i');
            setTimeout(() => {
              // NOTE: #updatingContent
              selectedElement.data.content = reverseHTMLandStrip(
                dom.children[0].children[1].innerHTML,
              );
              if (textTemp) textTemp.innerHTML = selectedElement.data.content;
            }, 300);
          }
        }
      });
    },
    updateCanvasElementFontWeight(state, data) {
      const { fontWeight, enableItalic } = data;
      const elementIds = state.activeElementsIds;

      elementIds.forEach((id) => {
        const selectedElement = state.elementsList.byId[id];

        selectedElement.data.fontWeight = fontWeight;
        selectedElement.data.textItalic = enableItalic;
      });
    },
    updateCanvasElementTextStyle(state, item) {
      const { fontId } = item;
      const elementIds = fontId ? [fontId] : state.activeElementsIds;

      const { style, value } = item;
      // console.log('updateCanvasElementTextStyle', item)

      elementIds.forEach((id) => {
        const selectedElement = state.elementsList.byId[id];
        selectedElement.data[style] = value;

        const { content } = selectedElement.data;
        const dom = new DOMParser().parseFromString(content, 'text/html');
        const { children } = dom;

        // if the child has bold/italic element, clear those
        if (children.length && document.getElementsByClassName('text-temp')[0]) {
          const textTemp = document
            .getElementsByClassName('text-temp')[0]
            .getElementsByClassName('text-content')[0];
          if (style === 'fontWeight') {
            if (value === 700) {
              // if it's bold, clear all <b> tag
              unwrapTag(children, 'b');
              setTimeout(() => {
                // NOTE: #updatingContent
                selectedElement.data.content = reverseHTMLandStrip(
                  dom.children[0].children[1].innerHTML,
                );
                if (textTemp) textTemp.innerHTML = selectedElement.data.content;
                // console.log('selectedElement is', selectedElement.data.content);
              }, 300);
            }
          }

          if (style === 'textItalic') {
            if (value) {
              // if it's italic, clear all <i> tag
              unwrapTag(children, 'i');
              setTimeout(() => {
                // NOTE: #updatingContent
                selectedElement.data.content = reverseHTMLandStrip(
                  dom.children[0].children[1].innerHTML,
                );
                if (textTemp) textTemp.innerHTML = selectedElement.data.content;
                // console.log('selectedElement is', selectedElement.data.content);
              }, 300);
            }
          }
        }
      });
    },
    updateCanvasElementFilter(state, item) {
      const {
        blend, tint, blur, brightness, contrast, hue, name, saturate, sepia,
      } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof blur !== 'undefined') Vue.set(element.filters, 'blur', blur);
        if (typeof brightness !== 'undefined') Vue.set(element.filters, 'brightness', brightness);
        if (typeof contrast !== 'undefined') Vue.set(element.filters, 'contrast', contrast);
        if (typeof hue !== 'undefined') Vue.set(element.filters, 'hue', hue);
        if (typeof name !== 'undefined') Vue.set(element.filters, 'name', name);
        if (typeof saturate !== 'undefined') Vue.set(element.filters, 'saturate', saturate);
        if (typeof sepia !== 'undefined') Vue.set(element.filters, 'sepia', sepia);

        if (typeof blend !== 'undefined') Vue.set(element, 'blend', blend);
        if (typeof tint !== 'undefined') Vue.set(element, 'tint', tint);
      });
    },
    updateCanvasElementContent(state, item) {
      // const id = state.edittedTextId;
      const {
        content, rect, id, color,
      } = item;
      const selectedId = id || (state.edittedTextId !== ''
        ? state.edittedTextId
        : state.activeElementsIds[0]);
      const selectedElement = state.elementsList.byId[selectedId];
      // console.log('updateCanvasElementContent', id, selectedId, selectedElement)

      if (selectedElement) {
        if (rect && rect.h) {
          const prevHeight = selectedElement.size.height;
          selectedElement.size.height = rect.h;

          // To calculate y, first we calculate top with previous height
          const top = selectedElement.position.y - prevHeight / 2;
          // and then calculate y with new height
          selectedElement.position.y = top + selectedElement.size.height / 2;

          // selectedElement.size.width = rect.w + 1;
          // selectedElement.position.x = rect.x;
        }
        // console.log('updateCanvasElementContent', content, reverseHTMLandStrip(content))
        // NOTE: if this is updated, check for #updatingContent
        // need to reverse html cause currently with multi format,
        // we are keeping the html tag but only <b><i><font><span>
        selectedElement.data.content = reverseHTMLandStrip(content);
        unwrapContentsHTMLTag(selectedElement);

        // color will exist when user adding multi color into a gradient text
        // this will reset the gradient to plain color
        if (color) {
          Vue.set(selectedElement.data, 'color', color);
        }
      }
    },
    updateCanvasElementTextBackground(state, item) {
      const {
        borderRadius, color, isEnabled, opacity, id,
      } = item;

      const selectedId = typeof id !== 'undefined' ? id : state.activeElementsIds[0];
      const element = state.elementsList.byId[selectedId];
      const { textBackground } = element.data;

      if (typeof borderRadius !== 'undefined') textBackground.borderRadius = borderRadius;
      if (typeof color !== 'undefined') textBackground.color = color;
      if (typeof isEnabled !== 'undefined') textBackground.isEnabled = isEnabled;
      if (typeof opacity !== 'undefined') textBackground.opacity = opacity;

      // update the element size based on the textBackground
      if (typeof isEnabled !== 'undefined') {
        const width = textBackground.x * 2;
        const height = textBackground.y * 2;

        if (textBackground.isEnabled) {
          element.size.width = element.size.width + width;
          element.size.height = element.size.height + height;
        } else {
          element.size.width = element.size.width - width;
          element.size.height = element.size.height - height;
        }
      }
    },
    updateCanvasElementTextBackgroundX(state, x) {
      const selectedId = state.activeElementsIds[0];
      const element = state.elementsList.byId[selectedId];
      const { textBackground } = element.data;

      const oldX = textBackground.x;
      let newX = 0;

      if (typeof x !== 'undefined') textBackground.x = x;
      newX = x !== null && x !== undefined ? x : 0;
      element.size.width = element.size.width - 2 * oldX + 2 * newX;
    },
    updateCanvasElementTextBackgroundY(state, y) {
      const selectedId = state.activeElementsIds[0];
      const element = state.elementsList.byId[selectedId];
      const { textBackground } = element.data;

      const oldY = textBackground.y;
      let newY = 0;

      if (typeof y !== 'undefined') textBackground.y = y;
      newY = y !== null && y !== undefined ? y : 0;
      element.size.height = element.size.height - 2 * oldY + 2 * newY;
    },
    updateCanvasElementTextShadow(state, item) {
      const {
        blur, color, isEnabled, opacity, x, y, id,
      } = item;
      const selectedIds = typeof id !== 'undefined' ? [id] : state.activeElementsIds;

      selectedIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        const elementShadow = element.data.textShadow
          ? element.data.textShadow
          : element.data.mediaShadow;

        if (typeof blur !== 'undefined') elementShadow.blur = blur;
        if (typeof color !== 'undefined') elementShadow.color = color;
        if (typeof isEnabled !== 'undefined') elementShadow.isEnabled = isEnabled;
        if (typeof opacity !== 'undefined') elementShadow.opacity = opacity;
        if (typeof x !== 'undefined') elementShadow.x = x;
        if (typeof y !== 'undefined') elementShadow.y = y;
      });
    },
    // updateCanvasElementTextGradient(state, item) {
    //   const { isEnabled } = item;
    //
    //   const id = state.activeElementsIds[0];
    //   const element = state.elementsList.byId[id];
    //
    //   const textGradient = element.data.textGradient;
    //
    //   if (typeof isEnabled !== 'undefined') textGradient.isEnabled = isEnabl
    // },
    updateCanvasElementTextBlur(state, item) {
      const { blur, isEnabled } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        const elementBlur = element.data.textBlur ? element.data.textBlur : element.data.mediaBlur;

        if (typeof blur !== 'undefined') elementBlur.blur = blur;
        if (typeof isEnabled !== 'undefined') elementBlur.isEnabled = isEnabled;
      });
    },
    updateCanvasElementTextGlow(state, item) {
      const {
        isEnabled, color, opacity, radius, isModifyingRadius,
      } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof element.data.textGlow === 'undefined') Vue.set(element.data, 'textGlow', JSON.parse(JSON.stringify(textDataFormat.textGlow)));
        const elementTextGlow = element.data.textGlow;

        const hasPreviousRadiusSet = !!elementTextGlow.radius;
        const isTextGlowEffectDisabled = isEnabled === false;

        // to work well within zoom in and out
        let oldRadius = elementTextGlow.radius * 1.15;
        let newRadius = radius * 1.15;

        if (isEnabled && hasPreviousRadiusSet && !isModifyingRadius) oldRadius = 0; // so can use the previous modified radius correctly when enabled the effect
        if (isTextGlowEffectDisabled) newRadius = 0; // if effect is disabled, need to set the element size back to normal

        // -------------------------------------------

        const isText3dEffectEnabled = element.data.text3D.isEnabled && element.data.text3D.distance;
        const isOtherEffectModifyingElementSizeExist = isText3dEffectEnabled;
        const needElementSizeChange = typeof radius !== 'undefined';

        if (needElementSizeChange) {
          if (isOtherEffectModifyingElementSizeExist) {
            // special usage - section to check when this / the other effect also change the element size (to avoid repetitive size update)
            let getElementBaseWidth = element.size.width;
            let getElementBaseHeight = element.size.height;
            let previousSizeSelectedIsText3d = false;

            if (isText3dEffectEnabled && !elementTextGlow.isEnabled) {
              previousSizeSelectedIsText3d = true;

              // times 10 followed from setting set on 'updateCanvasElementText3D'
              getElementBaseWidth = element.size.width - (element.data.text3D.distance * 10);
              getElementBaseHeight = element.size.height - (element.data.text3D.distance * 10);
            } else if (isText3dEffectEnabled && elementTextGlow.isEnabled) {
              previousSizeSelectedIsText3d = element.data.text3D.distance * 10 > elementTextGlow.radius * 1.15; // checking which one is larger

              if (previousSizeSelectedIsText3d) {
                getElementBaseWidth = element.size.width - (element.data.text3D.distance * 10);
                getElementBaseHeight = element.size.height - (element.data.text3D.distance * 10);
              } else {
                getElementBaseWidth = element.size.width - (elementTextGlow.radius * 1.15);
                getElementBaseHeight = element.size.height - (elementTextGlow.radius * 1.15);
              }
            }

            let getElementWidthWithText3d = 0;
            let getElementHeightWithText3d = 0;

            if (isText3dEffectEnabled) {
              getElementWidthWithText3d = getElementBaseWidth + (element.data.text3D.distance * 10);
              getElementHeightWithText3d = getElementBaseHeight + (element.data.text3D.distance * 10);
            }

            const proposedWidthValue = getElementBaseWidth + newRadius;
            const proposedHeightValue = getElementBaseHeight + newRadius;
            const ableToUseProposedValue = proposedWidthValue >= getElementWidthWithText3d && proposedHeightValue >= getElementHeightWithText3d;

            // so when we disable / change this effect value that previously is the selected proposed value but now the proposed element size here is smaller than the other element size set by another effect,
            // the element size could go back to the 2nd accepted proposed value and the future process can run well.
            const doElementSizeUpdateBasedOnSecondSelected = !ableToUseProposedValue && isText3dEffectEnabled && (isTextGlowEffectDisabled || !previousSizeSelectedIsText3d);
            // console.log('text glow', isEnabled, oldRadius, newRadius, {hasPreviousRadiusSet, previousSizeSelectedIsText3d, isText3dEffectEnabled, ableToUseProposedValue, getElementBaseWidth, getElementBaseHeight}, '---', proposedWidthValue, proposedHeightValue, 'compare', getElementWidthWithText3d, getElementHeightWithText3d);

            // ----------------------------------------------------------
            // update canvas element size to fit with wipe/mask animation
            // ----------------------------------------------------------
            if (ableToUseProposedValue) {
              element.size.width = proposedWidthValue;
              element.size.height = proposedHeightValue;
            } else if (doElementSizeUpdateBasedOnSecondSelected) {
              element.size.width = Math.max(getElementWidthWithText3d, proposedWidthValue);
              element.size.height = Math.max(getElementHeightWithText3d, proposedHeightValue);
            }
          } else {
            // section of normal usage
            element.size.width = element.size.width - oldRadius + newRadius;
            element.size.height = element.size.height - oldRadius + newRadius;
          }

          // console.log('final result', element.size.width, element.size.height);
        }

        if (typeof isEnabled !== 'undefined') elementTextGlow.isEnabled = isEnabled;
        if (typeof color !== 'undefined') elementTextGlow.color = color;
        if (typeof opacity !== 'undefined') elementTextGlow.opacity = opacity;
        if (typeof radius !== 'undefined') elementTextGlow.radius = radius;
      });
    },
    updateCanvasElementTextLightSweep(state, item) {
      const {
        isEnabled, color, speed, selectedAngle, radius, isLoop,
      } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof element.data.textLightSweep === 'undefined') Vue.set(element.data, 'textLightSweep', JSON.parse(JSON.stringify(textDataFormat.textLightSweep)));
        const elementTextLightSweep = element.data.textLightSweep;
        const timelineSettingsObj = {
          id,
          lightsweepEffectSettings: {
            ...element.timeline_settings.lightsweepEffectSettings,
            elementType: element.type,
          },
        };

        if (typeof isEnabled !== 'undefined') {
          elementTextLightSweep.isEnabled = isEnabled;
          timelineSettingsObj.isAnimateLightsweepEffect = isEnabled;

          // ensure latest setting sync with the animation
          if (isEnabled) {
            timelineSettingsObj.lightsweepEffectSpeed = elementTextLightSweep.speed;
            timelineSettingsObj.lightsweepEffectSettings = {
              ...timelineSettingsObj.lightsweepEffectSettings,
              color: elementTextLightSweep.color,
              selectedAngle: elementTextLightSweep.selectedAngle,
              radius: elementTextLightSweep.radius,
              isLoop: elementTextLightSweep.isLoop,
            };
          }
        }
        if (typeof color !== 'undefined') {
          elementTextLightSweep.color = color;
          timelineSettingsObj.lightsweepEffectSettings.color = color;
        }
        if (typeof speed !== 'undefined') {
          elementTextLightSweep.speed = speed;
          timelineSettingsObj.lightsweepEffectSpeed = speed;
        }
        if (typeof selectedAngle !== 'undefined') {
          // use Vue.set here since some elements still doesn't have this 'selectedAngle' attribute because it's an updated feature
          Vue.set(elementTextLightSweep, 'selectedAngle', selectedAngle);
          Vue.set(timelineSettingsObj.lightsweepEffectSettings, 'selectedAngle', selectedAngle);
        }
        if (typeof radius !== 'undefined') {
          elementTextLightSweep.radius = radius;
          timelineSettingsObj.lightsweepEffectSettings.radius = radius;
        }
        if (typeof isLoop !== 'undefined') {
          elementTextLightSweep.isLoop = isLoop;
          timelineSettingsObj.lightsweepEffectSettings.isLoop = isLoop;
        }

        this.commit('canvasElements/updateCanvasElementTimelineSettings', timelineSettingsObj);
      });
    },
    updateCanvasElementTextGlitch(state, item) {
      const {
        isEnabled, color1, color2, distance, speed,
      } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof element.data.textGlitch === 'undefined') Vue.set(element.data, 'textGlitch', JSON.parse(JSON.stringify(textDataFormat.textGlitch)));
        const elementTextGlitch = element.data.textGlitch;
        const timelineSettingsObj = {
          id,
          glitchEffectSettings: {
            ...element.timeline_settings.glitchEffectSettings,
            elementType: element.type,
          },
        };

        if (typeof isEnabled !== 'undefined') {
          elementTextGlitch.isEnabled = isEnabled;
          timelineSettingsObj.isAnimateGlitchEffect = isEnabled;

          // ensure latest setting sync with the animation
          if (isEnabled) {
            timelineSettingsObj.glitchEffectSpeed = elementTextGlitch.speed;
            timelineSettingsObj.glitchEffectSettings = {
              ...timelineSettingsObj.glitchEffectSettings,
              color1: elementTextGlitch.color1,
              color2: elementTextGlitch.color2,
              distance: elementTextGlitch.distance,
            };
          }
        }
        if (typeof color1 !== 'undefined') {
          elementTextGlitch.color1 = color1;
          timelineSettingsObj.glitchEffectSettings.color1 = color1;
        }
        if (typeof color2 !== 'undefined') {
          elementTextGlitch.color2 = color2;
          timelineSettingsObj.glitchEffectSettings.color2 = color2;
        }
        if (typeof distance !== 'undefined') {
          elementTextGlitch.distance = distance;
          timelineSettingsObj.glitchEffectSettings.distance = distance;
        }
        if (typeof speed !== 'undefined') {
          elementTextGlitch.speed = speed;
          timelineSettingsObj.glitchEffectSpeed = speed;
        }

        this.commit('canvasElements/updateCanvasElementTimelineSettings', timelineSettingsObj);
      });
    },
    updateCanvasElementTextOutline(state, item) {
      const {
        isEnabled, color, size, isFillText,
      } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof element.data.textOutline === 'undefined') Vue.set(element.data, 'textOutline', JSON.parse(JSON.stringify(textDataFormat.textOutline)));
        const elementTextOutline = element.data.textOutline;

        if (typeof isEnabled !== 'undefined') elementTextOutline.isEnabled = isEnabled;
        if (typeof color !== 'undefined') elementTextOutline.color = color;
        if (typeof size !== 'undefined') elementTextOutline.size = size;
        if (typeof isFillText !== 'undefined') elementTextOutline.isFillText = isFillText;
      });
    },
    updateCanvasElementText3D(state, item) {
      const {
        isEnabled, color, distance, selectedAngle, isModifyingDistance,
      } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof element.data.text3D === 'undefined') Vue.set(element.data, 'text3D', JSON.parse(JSON.stringify(textDataFormat.text3D)));
        const elementText3D = element.data.text3D;

        const hasPreviousDistanceSet = !!elementText3D.distance;
        const isText3dEffectDisabled = isEnabled === false;

        // to work well within zoom in and out & also angle changes
        let oldDistance = elementText3D.distance * 10;
        let newDistance = distance * 10;

        if (isEnabled && hasPreviousDistanceSet && !isModifyingDistance) oldDistance = 0; // so can use the previous modified radius correctly when enabled the effect
        if (isText3dEffectDisabled) newDistance = 0; // if effect is disabled, need to set the element size back to normal

        // -------------------------------------------

        const isTextGlowEffectEnabled = element.data.textGlow.isEnabled && element.data.textGlow.radius;
        const isOtherEffectModifyingElementSizeExist = isTextGlowEffectEnabled;
        const needElementSizeChange = typeof distance !== 'undefined';

        if (needElementSizeChange) {
          if (isOtherEffectModifyingElementSizeExist) {
            // special usage - section to check when this / the other effect also change the element size (to avoid repetitive size update)
            let getElementBaseWidth = element.size.width;
            let getElementBaseHeight = element.size.height;
            let previousSizeSelectedIsTextGlow = false;

            if (isTextGlowEffectEnabled && !elementText3D.isEnabled) {
              previousSizeSelectedIsTextGlow = true;

              // times 1.15 followed from setting set on 'updateCanvasElementTextGlow'
              getElementBaseWidth = element.size.width - (element.data.textGlow.radius * 1.15);
              getElementBaseHeight = element.size.height - (element.data.textGlow.radius * 1.15);
            } else if (isTextGlowEffectEnabled && elementText3D.isEnabled) {
              previousSizeSelectedIsTextGlow = element.data.textGlow.radius * 1.15 > elementText3D.distance * 10; // checking which one is larger

              if (previousSizeSelectedIsTextGlow) {
                getElementBaseWidth = element.size.width - (element.data.textGlow.radius * 1.15);
                getElementBaseHeight = element.size.height - (element.data.textGlow.radius * 1.15);
              } else {
                getElementBaseWidth = element.size.width - (elementText3D.distance * 10);
                getElementBaseHeight = element.size.height - (elementText3D.distance * 10);
              }
            }

            let getElementWidthWithTextGlow = 0;
            let getElementHeightWithTextGlow = 0;

            if (isTextGlowEffectEnabled) {
              getElementWidthWithTextGlow = getElementBaseWidth + (element.data.textGlow.radius * 1.15);
              getElementHeightWithTextGlow = getElementBaseHeight + (element.data.textGlow.radius * 1.15);
            }

            const proposedWidthValue = getElementBaseWidth + newDistance;
            const proposedHeightValue = getElementBaseHeight + newDistance;
            const ableToUseProposedValue = proposedWidthValue >= getElementWidthWithTextGlow && proposedHeightValue >= getElementHeightWithTextGlow;

            // so when we disable / change this effect value that previously is the selected proposed value but now the proposed element size here is smaller than the other element size set by another effect,
            // the element size could go back to the 2nd accepted proposed value and the future process can run well.
            const doElementSizeUpdateBasedOnSecondSelected = !ableToUseProposedValue && isTextGlowEffectEnabled && (isText3dEffectDisabled || !previousSizeSelectedIsTextGlow);
            // console.log('text 3D', isEnabled, oldDistance, newDistance, {hasPreviousDistanceSet, previousSizeSelectedIsTextGlow, isTextGlowEffectEnabled, ableToUseProposedValue, getElementBaseWidth, getElementBaseHeight}, '---', proposedWidthValue, proposedHeightValue, 'compare', getElementWidthWithTextGlow, getElementHeightWithTextGlow);

            // ----------------------------------------------------------
            // update canvas element size to fit with wipe/mask animation
            // ----------------------------------------------------------
            if (ableToUseProposedValue) {
              element.size.width = proposedWidthValue;
              element.size.height = proposedHeightValue;
            } else if (doElementSizeUpdateBasedOnSecondSelected) {
              element.size.width = Math.max(getElementWidthWithTextGlow, proposedWidthValue);
              element.size.height = Math.max(getElementHeightWithTextGlow, proposedHeightValue);
            }
          } else {
            // section of normal usage
            element.size.width = element.size.width - oldDistance + newDistance;
            element.size.height = element.size.height - oldDistance + newDistance;
          }

          // console.log('final result', element.size.width, element.size.height);
        }

        if (typeof isEnabled !== 'undefined') elementText3D.isEnabled = isEnabled;
        if (typeof color !== 'undefined') elementText3D.color = color;
        if (typeof distance !== 'undefined') elementText3D.distance = distance;
        if (typeof selectedAngle !== 'undefined') elementText3D.selectedAngle = selectedAngle;
      });
    },
    updateCanvasElementMediaShadow(state, item) {
      const {
        blur, color, isEnabled, opacity, x, y, id,
      } = item;
      const selectedIds = typeof id !== 'undefined' ? [id] : state.activeElementsIds;

      selectedIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        const elementShadow = element.data.textShadow
          ? element.data.textShadow
          : element.data.mediaShadow;

        if (typeof blur !== 'undefined') elementShadow.blur = blur;
        if (typeof color !== 'undefined') elementShadow.color = color;
        if (typeof isEnabled !== 'undefined') elementShadow.isEnabled = isEnabled;
        if (typeof opacity !== 'undefined') elementShadow.opacity = opacity;
        if (typeof x !== 'undefined') elementShadow.x = x;
        if (typeof y !== 'undefined') elementShadow.y = y;
      });
    },
    updateCanvasElementMediaGlow(state, item) {
      const {
        isEnabled, color, opacity, radius,
      } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof element.data.mediaGlow === 'undefined') Vue.set(element.data, 'mediaGlow', JSON.parse(JSON.stringify(elementFormat.data.mediaGlow)));
        const elementMediaGlow = element.data.mediaGlow;

        if (typeof isEnabled !== 'undefined') elementMediaGlow.isEnabled = isEnabled;
        if (typeof color !== 'undefined') elementMediaGlow.color = color;
        if (typeof opacity !== 'undefined') elementMediaGlow.opacity = opacity;
        if (typeof radius !== 'undefined') elementMediaGlow.radius = radius;
      });
    },
    updateCanvasElementMediaLightSweep(state, item) {
      const {
        isEnabled, intensity, speed, selectedAngle, radius, isLoop,
      } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof element.data.mediaLightSweep === 'undefined') Vue.set(element.data, 'mediaLightSweep', JSON.parse(JSON.stringify(elementFormat.data.mediaLightSweep)));
        const elementMediaLightSweep = element.data.mediaLightSweep;
        const elementWidth = element.size.width;
        const elementHeight = element.size.height;
        const elementRatio = Number((Math.max(elementWidth, elementHeight) / Math.min(elementWidth, elementHeight)).toFixed(2));
        const elementProportion = getElementProportion(elementRatio, elementWidth, elementHeight);

        const timelineSettingsObj = {
          id,
          lightsweepEffectSettings: {
            ...element.timeline_settings.lightsweepEffectSettings,
            elementType: element.type,
            elementRatio,
            elementProportion,
          },
        };

        if (typeof isEnabled !== 'undefined') {
          elementMediaLightSweep.isEnabled = isEnabled;
          timelineSettingsObj.isAnimateLightsweepEffect = isEnabled;

          // ensure latest setting sync with the animation
          if (isEnabled) {
            timelineSettingsObj.lightsweepEffectSpeed = elementMediaLightSweep.speed;
            timelineSettingsObj.lightsweepEffectSettings = {
              ...timelineSettingsObj.lightsweepEffectSettings,
              intensity: elementMediaLightSweep.intensity,
              selectedAngle: elementMediaLightSweep.selectedAngle,
              radius: elementMediaLightSweep.radius,
              isLoop: elementMediaLightSweep.isLoop,
            };
          }
        }
        if (typeof intensity !== 'undefined') {
          elementMediaLightSweep.intensity = intensity;
          timelineSettingsObj.lightsweepEffectSettings.intensity = intensity;
        }
        if (typeof speed !== 'undefined') {
          elementMediaLightSweep.speed = speed;
          timelineSettingsObj.lightsweepEffectSpeed = speed;
        }
        if (typeof selectedAngle !== 'undefined') {
          // use Vue.set here since some elements still doesn't have this 'selectedAngle' attribute because it's an updated feature
          Vue.set(elementMediaLightSweep, 'selectedAngle', selectedAngle);
          Vue.set(timelineSettingsObj.lightsweepEffectSettings, 'selectedAngle', selectedAngle);
        }
        if (typeof radius !== 'undefined') {
          elementMediaLightSweep.radius = radius;
          timelineSettingsObj.lightsweepEffectSettings.radius = radius;
        }
        if (typeof isLoop !== 'undefined') {
          elementMediaLightSweep.isLoop = isLoop;
          timelineSettingsObj.lightsweepEffectSettings.isLoop = isLoop;
        }

        this.commit('canvasElements/updateCanvasElementTimelineSettings', timelineSettingsObj);
      });
    },
    updateCanvasElementMediaGlitch(state, item) {
      const { isEnabled, distance, speed } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof element.data.mediaGlitch === 'undefined') Vue.set(element.data, 'mediaGlitch', JSON.parse(JSON.stringify(elementFormat.data.mediaGlitch)));
        const elementMediaGlitch = element.data.mediaGlitch;
        const timelineSettingsObj = {
          id,
          glitchEffectSettings: {
            ...element.timeline_settings.glitchEffectSettings,
            elementType: element.type,
          },
        };

        if (typeof isEnabled !== 'undefined') {
          elementMediaGlitch.isEnabled = isEnabled;
          timelineSettingsObj.isAnimateGlitchEffect = isEnabled;

          // ensure latest setting sync with the animation
          if (isEnabled) {
            timelineSettingsObj.glitchEffectSpeed = elementMediaGlitch.speed;
            timelineSettingsObj.glitchEffectSettings.distance = elementMediaGlitch.distance;
          }
        }
        if (typeof distance !== 'undefined') {
          elementMediaGlitch.distance = distance;
          timelineSettingsObj.glitchEffectSettings.distance = distance;
        }
        if (typeof speed !== 'undefined') {
          elementMediaGlitch.speed = speed;
          timelineSettingsObj.glitchEffectSpeed = speed;
        }

        this.commit('canvasElements/updateCanvasElementTimelineSettings', timelineSettingsObj);
      });
    },
    updateCanvasElementMediaRoundCorners(state, item) {
      const { isEnabled, rounding } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof element.data.mediaRoundCorners === 'undefined') Vue.set(element.data, 'mediaRoundCorners', JSON.parse(JSON.stringify(elementFormat.data.mediaRoundCorners)));
        const elementMediaRoundCorners = element.data.mediaRoundCorners;

        if (typeof isEnabled !== 'undefined') elementMediaRoundCorners.isEnabled = isEnabled;
        if (typeof rounding !== 'undefined') elementMediaRoundCorners.rounding = rounding;
      });
    },
    updateCanvasElementMediaBorder(state, item) {
      const {
        isEnabled, color, size, isModifyingSize,
      } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        if (typeof element.data.mediaBorder === 'undefined') Vue.set(element.data, 'mediaBorder', JSON.parse(JSON.stringify(elementFormat.data.mediaBorder)));
        const elementMediaBorder = element.data.mediaBorder;

        const isMedia = element.type === 'videos' || element.type === 'images' || element.type === 'masks';
        const isMask = element.type === 'masks';
        const isPng = element.data.url && element.data.url.split('.').pop() === 'png';
        const isWebm = element.data.url && element.data.url.split('.').pop() === 'webm';
        const noNeedElementSizeCalculation = isMedia && !isMask && !isPng && !isWebm;

        const hasPreviousSizeSet = !!elementMediaBorder.size;
        const isMediaBorderDisabled = isEnabled === false;

        // to cover top & bottom, right & left
        let oldSize = elementMediaBorder.size * 2;
        let newSize = size * 2;

        if (isEnabled && hasPreviousSizeSet && !isModifyingSize) oldSize = 0; // so can use the previous modified radius correctly when enabled the effect
        if (isMediaBorderDisabled) newSize = 0; // if effect is disabled, need to set the element size back to normal

        if (typeof isEnabled !== 'undefined') elementMediaBorder.isEnabled = isEnabled;
        if (typeof color !== 'undefined') elementMediaBorder.color = color;
        if (typeof size !== 'undefined') elementMediaBorder.size = size;

        // ----------------------------------------------------------
        // update the element size based on the media border settings ( only for border that is using drop-shadow css )
        // ----------------------------------------------------------
        if (!noNeedElementSizeCalculation) {
          if (typeof size !== 'undefined' || isMediaBorderDisabled) {
            element.size.width = element.size.width - oldSize + newSize;
            element.size.height = element.size.height - oldSize + newSize;
          }
        }
      });
    },
    updateCanvasElementGroundShadow(state, item) {
      const {
        blur, color, isEnabled, opacity, y, id, scaleX, scaleY,
      } = item;
      const selectedIds = typeof id !== 'undefined' ? [id] : state.activeElementsIds;

      selectedIds.forEach((id) => {
        const element = state.elementsList.byId[id];
        const { groundShadow } = element.data;

        if (typeof blur !== 'undefined') groundShadow.blur = blur;
        if (typeof color !== 'undefined') groundShadow.color = color;
        if (typeof isEnabled !== 'undefined') groundShadow.isEnabled = isEnabled;
        if (typeof opacity !== 'undefined') groundShadow.opacity = opacity;
        if (typeof y !== 'undefined') groundShadow.y = y;
        if (typeof scaleX !== 'undefined') Vue.set(groundShadow, 'scaleX', scaleX);
        if (typeof scaleY !== 'undefined') Vue.set(groundShadow, 'scaleY', scaleY);
      });
    },
    updateCanvasElementMediaBlur(state, item) {
      const { blur, isEnabled } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        const elementBlur = element.data.textBlur ? element.data.textBlur : element.data.mediaBlur;

        if (typeof blur !== 'undefined') elementBlur.blur = blur;
        if (typeof isEnabled !== 'undefined') elementBlur.isEnabled = isEnabled;
      });
    },
    updateCanvasElementReflection(state, item) {
      const {
        distance, isEnabled, opacity, size,
      } = item;

      state.activeElementsIds.forEach((id) => {
        const element = state.elementsList.byId[id];

        const { reflection } = element.data;

        // if (typeof blur !== 'undefined') reflection.blur = blur;
        if (typeof distance !== 'undefined') reflection.distance = distance;
        if (typeof isEnabled !== 'undefined') reflection.isEnabled = isEnabled;
        if (typeof opacity !== 'undefined') reflection.opacity = opacity;
        if (typeof size !== 'undefined') reflection.size = size;
      });
    },
    updateCanvasElementOpacity(state, item) {
      const { id, opacity } = item;
      // console.log('updateCanvasElementOpacity', id, opacity)
      state.elementsList.byId[id].opacity = opacity;
    },
    updateCanvasElementName(state, item) {
      const { id, name, hasChanged } = item;

      if (name) {
        const element = state.elementsList.byId[id];

        element.data.name = name;
        // console.log('updateCanvasElementName', hasChanged, element.data.hasNotChange)
        if (hasChanged) element.data.hasNotChanged = false;
        // console.log('element.data.hasNotChange', element.data.hasNotChange)
      }
    },
    toggleCanvasElementLock(state, elementId) {
      const element = state.elementsList.byId[elementId];
      const id = state.activeElementsIds[0];

      element.lock = !element.lock;
      if (
        element.lock
        && typeof state.activeElementsIds !== 'undefined'
        && state.activeElementsIds.length
      ) {
        if (id === element.data.id) {
          state.activeElementsIds.length = 0;
          state.activeElementsIds.pop();
        }
      }
    },
    toggleCanvasElementShow(state, elementId) {
      const element = state.elementsList.byId[elementId];

      element.show = !element.show;
    },
    toggleCanvasElementMute(state, elementId) {
      const element = state.elementsList.byId[elementId];
      // console.log('toggleCanvasElementMute', elementId, element.data.is_mute)

      element.data.is_mute = !element.data.is_mute;
    },
    setCanvasElementHasAudio(state, { elementId, hasAudio }) {
      const element = state.elementsList.byId[elementId];

      if (typeof element.data.has_audio === 'undefined') {
        Vue.set(element.data, 'has_audio', hasAudio);
        Vue.set(element.data, 'is_mute', false);
      } else {
        element.data.has_audio = hasAudio;
      }
    },
    addTextTemplateToCanvas(state, item) {
      let allColorArr = [].concat(state.projectColors.solid).concat(state.projectColors.gradient);

      for (let i = item.length - 1; i >= 0; i--) {
        const element = item[i];
        // if text element and content is null, skip this
        if (element.type === 'texts' && element.data.content === null) {
          continue;
        }

        const newId = randomId();
        element.data.id = newId;

        updateMissingElementKey(element);

        if (element.data.color) {
          allColorArr = allColorArr.concat(element.data.color);
        }

        state.scenesList.byId[state.activeSceneId].elements.unshift(newId);
        Vue.set(state.elementsList.byId, newId, element);
        state.elementsList.allIds.push(newId);
        this.commit('canvasElements/addActiveElements', newId);
      }

      const { newArr, gradientArr } = checkForDuplicateArray(allColorArr);
      state.projectColors.solid = newArr;
      state.projectColors.gradient = gradientArr;
    },
    deleteElementFromCanvas(state, item) {
      // item should be the element id
      const sceneElIndex = state.scenesList.byId[state.activeSceneId].elements.indexOf(item);
      state.scenesList.byId[state.activeSceneId].elements.splice(sceneElIndex, 1);

      const elIndex = state.elementsList.allIds.indexOf(item);
      state.elementsList.allIds.splice(elIndex, 1);
      delete state.elementsList.byId[item];

      const activeElIndex = state.activeElementsIds.indexOf(item);
      state.activeElementsIds.splice(activeElIndex, 1);
    },
    addElementToCanvas(state, item) {
      const { elements } = state.scenesList.byId[state.activeSceneId];

      const elementId = item.data.id;
      const newElement = JSON.parse(JSON.stringify(item));

      // if text element and content is null, skip this
      if (newElement.type === 'texts' && newElement.data.content === null) {
        return;
      }

      updateMissingElementKey(newElement);

      state.elementsList.byId[elementId] = newElement;
      state.elementsList.allIds.push(elementId);
      state.activeElementsIds.length = 0;
      state.activeElementsIds.push(elementId);

      if (newElement.background) {
        // Check if elements is not empty and last item is background
        if (elements.length && state.elementsList.byId[elements[elements.length - 1]].background) {
          // Remove last element
          this.dispatch('canvasElements/deleteElementFromCanvas', elements[elements.length - 1]);
          state.changeBackgroundCount++;
        }
        if (typeof state.elementsList.byId[elementId] !== 'undefined') {
          state.elementsList.byId[elementId].data.name = 'Background';
          state.elementsList.byId[elementId].lock = true;
        }
        elements.splice(elements.length, 0, elementId);
      } else {
        elements.unshift(elementId);
      }

      this.commit('canvasElements/addActiveElements', elementId);
    },
    setElementNeedRemask(state, data) {
      // to avoid issue where changing mask elements can make mask element cutted off because incorrect image.width & image.height, need to give sign to do remask
      const { id, boolean } = data;
      if (!state.elementsList.byId[id]) return;

      const currentElement = state.elementsList.byId[id];
      currentElement.data.image.needRemask = boolean;
    },
    insertElementToCanvas(state, item) {
      // this is when slotting in between elements
      const {
        id,
        isDuplicate,
        isSwap,
        element,
        forceResize,
        isElementConvertion,
        convertionType,
      } = item;
      // console.log('id', id, isSwap, element);

      if (isDuplicate) {
        if (!state.elementsList.byId[id]) return;

        const newElement = JSON.parse(JSON.stringify(state.elementsList.byId[id]));

        const newId = randomId();
        newElement.data.id = newId;
        newElement.position.x += 20;
        newElement.position.y += 20;

        if (typeof newElement.element_id !== 'undefined') delete newElement.element_id;

        const index = state.scenesList.byId[state.activeSceneId].elements.indexOf(id);

        Vue.set(state.elementsList.byId, newId, newElement);
        state.elementsList.allIds.push(newId);
        state.scenesList.byId[state.activeSceneId].elements.splice(index, 0, newId);

        this.commit('canvasElements/addActiveElements', newId);
      }

      if (isSwap) {
        const replaceOrDelete = (prop) => {
          console.log('prop', prop, newElement.data[prop]);
          if (newElement.data[prop] !== undefined) {
            currentElement.data[prop] = newElement.data[prop];
          } else if (currentElement.data[prop]) {
            delete currentElement.data[prop];
          }
        };
        const newElement = JSON.parse(JSON.stringify(element));
        console.log('insertElementToCanvas:isSwap');
        const currentElement = state.elementsList.byId[id];

        // don't include newElement.isImage because it can be graphics
        const isImage = newElement.type === 'images';
        const isGraphicImage = newElement.isImage;
        const isWebm = newElement.data.url && newElement.data.url.split('.').pop() === 'webm';
        // don't include graphic video
        const isVideo = newElement.type === 'videos' && !isWebm;

        if (isMobile()) {
          replaceOrDelete('shape');
          replaceOrDelete('urlHd');
          currentElement.data.thumb = currentElement.data.url;
          currentElement.data.isHtml = !!newElement.data.isHtml;
          currentElement.data.sub_category_id = newElement.data.sub_category_id;
          currentElement.data.sub_category_meta = newElement.data.sub_category_meta;
          currentElement.data.sub_category_name = newElement.data.sub_category_name;
          console.log('currentElement.data.isHtml', currentElement.data.isHtml, isImage, isVideo);
        }

        if (isElementConvertion || isMobile()) {
          currentElement.type = newElement.type;
        }

        newElement.data.id = id;

        // console.log('this.getters.isLiteMode)', this.getters.isLiteMode, currentElement.type, newElement.type, isImage || (isVideo && !isWebm))

        // if this is image or video,
        // make it auto crop
        if (isImage || (isVideo && !isWebm) || isElementConvertion) {
          // console.log('newElement', cloneDeep(item), cloneDeep(newElement))
          // remove the crop to fit new image in bounding box
          currentElement.data.image = {
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
          };
        } else if (isVideo && !isWebm) {
          let mediaWidth = newElement.size.width;
          let mediaHeight = newElement.size.height;
          const ratio = mediaWidth / mediaHeight;

          const comparisonWidth = forceResize ? forceResize.width : currentElement.size.width;
          const comparisonHeight = forceResize ? forceResize.height : currentElement.size.height;

          if (mediaWidth < comparisonWidth) {
            mediaWidth = comparisonWidth;
            mediaHeight = mediaWidth / ratio;
          }

          if (mediaHeight < comparisonHeight) {
            mediaHeight = comparisonHeight;
            mediaWidth = mediaHeight * ratio;
          }

          newElement.size.width = comparisonWidth;
          newElement.size.height = comparisonHeight;

          const difference = {
            width: mediaWidth - newElement.size.width,
            height: mediaHeight - newElement.size.height,
          };

          currentElement.data.image = {
            width:
              difference.width === 0 ? '100%' : `${(mediaWidth / newElement.size.width) * 100}%`,
            height:
              difference.height === 0 ? '100%' : `${(mediaHeight / newElement.size.height) * 100}%`,
            top:
              difference.height === 0
                ? '0%'
                : `${(((difference.height / 2) * -1) / newElement.size.height) * 100}%`,
            left:
              difference.width === 0
                ? '0%'
                : `${(((difference.width / 2) * -1) / newElement.size.width) * 100}%`,
          };
          // console.table(currentElement.data.image)
        } else if (isGraphicImage || isWebm) {
          let mediaWidth = newElement.size.width;
          let mediaHeight = newElement.size.height;
          const ratio = mediaWidth / mediaHeight;

          const comparisonWidth = forceResize ? forceResize.width : currentElement.size.width;
          const comparisonHeight = forceResize ? forceResize.height : currentElement.size.height;

          if (mediaWidth > comparisonWidth) {
            mediaWidth = comparisonWidth;
            mediaHeight = mediaWidth / ratio;
          }

          if (mediaHeight > comparisonHeight) {
            mediaHeight = comparisonHeight;
            mediaWidth = mediaHeight * ratio;
          }

          newElement.size.width = mediaWidth;
          newElement.size.height = mediaHeight;

          currentElement.data.image = {
            width: '100%',
            height: '100%',
            height: '0%',
            height: '0%',
          };
        }

        if (isElementConvertion) {
          const convertionObj = {
            element: currentElement,
            newElement,
            width: newElement.size.width,
            height: newElement.size.height,
            maxWidth: currentElement.size.width,
            maxHeight: currentElement.size.height,
          };

          currentElement.data.name = newElement.data.name;

          if (convertionType === 'mask-to-mask') {
            // avoid issue where convertion of mask to mask gonna make element become smaller and smaller
            convertionObj.maxWidth = newElement.data.image.initialSize.width;
            convertionObj.maxHeight = newElement.data.image.initialSize.height;
          }

          // if mask to mask, need to see the image whether we should keep height/width, make sure to keep the right thing
          if (convertionType === 'mask-to-image') {
            setSwappedMediaSizeCover(convertionObj);
          } else {
            setSwappedMediaSizeContain(convertionObj);
          }

          // avoid issue where convertion of mask to mask gonna make element become smaller and smaller
          if (!currentElement.data.image.initialSize) currentElement.data.image.initialSize = newElement.data.image.initialSize;

          this.commit('canvasElements/setElementNeedRemask', { id, boolean: true });
        } else {
          currentElement.size.width = newElement.size.width;
          currentElement.size.height = newElement.size.height;
        }

        currentElement.position.x = newElement.position.x;
        currentElement.position.y = newElement.position.y;

        if (newElement.animated) {
          currentElement.animation_preview = newElement.animation_preview;
          currentElement.animation_type = newElement.animation_type;
          currentElement.data = newElement.data;
        } else if (currentElement.type !== 'masks' || isElementConvertion) {
          currentElement.data.thumb = newElement.data.thumb;
          currentElement.data.url = newElement.data.url;
          currentElement.data.urlHd = newElement.data.urlHd;
        }

        currentElement.data.has_removed_bg = !!newElement.data.has_removed_bg;

        // data image in image and video is handled on top
        if (!(isImage || isVideo) && newElement.data.image) {
          // console.log('isSwap', newElement.data.image.url, !!newElement.data.has_removed_bg);

          if (typeof newElement.data.image.url !== 'undefined') { currentElement.data.image.url = newElement.data.image.url; }
          if (typeof newElement.data.image.width !== 'undefined') { currentElement.data.image.width = newElement.data.image.width; }
          if (typeof newElement.data.image.height !== 'undefined') { currentElement.data.image.height = newElement.data.image.height; }
          if (typeof newElement.data.image.top !== 'undefined') { currentElement.data.image.top = newElement.data.image.top; }
          if (typeof newElement.data.image.left !== 'undefined') { currentElement.data.image.left = newElement.data.image.left; }

          // isNew need to be true first so that it gets updated
          currentElement.data.image.isNew = true;
        }

        if (currentElement.data.image) {
          currentElement.data.aspectRatio = calcAspectRatio(currentElement);

          const originalSize = checkOriginalSize(currentElement);
          Vue.set(currentElement.data, 'originalSize', originalSize);
        }

        // console.table(currentElement)
        // console.log('swap', currentElement.data.aspectRatio.hw,currentElement.data.aspectRatio.wh)

        if (newElement.type === 'videos') {
          const videos = document.body.querySelectorAll('.scene video');

          for (let i = 0; i < videos.length; i++) {
            videos[i].load();
          }
        }

        if (newElement.type === 'shapes') {
          replaceOrDelete('borderRadius');
          replaceOrDelete('color');
          replaceOrDelete('isHtml');
          replaceOrDelete('shape');
          replaceOrDelete('stroke');
          replaceOrDelete('thumb');
          replaceOrDelete('url');
          replaceOrDelete('urlHd');
        }
      }
    },
    updateCanvasElementsEndingTime(state, item) {
      const { sceneId, oldValue, value } = item;
      const selectedSceneId = sceneId || state.activeSceneId;
      const currentSceneElements = state.scenesList.byId[selectedSceneId].elements;

      for (let i = 0; i < currentSceneElements.length; i += 1) {
        const id = currentSceneElements[i];
        const element = state.elementsList.byId[id];

        // resize both when user increase and decrease the duration
        if (element.time_out === oldValue && value !== oldValue) {
          let duration = value - element.time_in;

          // if the layer duration ended up less than the minDuration.autoResize
          // don't change the duration
          if (duration > minDuration.autoResize) {
            if (element.max_duration
              && value > element.max_duration
              && element.animation_type === 'none') {
              // make sure element.max_duration is not 0 (for elements other than video)
              element.time_out = element.max_duration + element.time_in;
              element.data.time_end = element.max_duration;
              duration = element.max_duration;
            } else {
              element.time_out = value;
              element.data.time_end = value;
            }

            element.timeline_settings.animationDuration = duration;

            // need to put this after the time_out is set
            const animateOutStart = element.time_out - element.timeline_settings.animateOutDuration;
            element.timeline_settings.animateOutStart = Math.round(animateOutStart * 100) / 100;
          }
        }
      }
    },

    replaceUserUploadWithCanvasAssets(state, item) {
      // this handles the selected user uploaded file from canvas and to convert into element packs
      // file_id = '';
      // menu = '';
      // data.menu = ''
      // data.is_user_file = false
      // data.thumb = replace;
      // data.url = replace;
      // data.urlHd = replace;

      const {
        id, thumb, url, urlHd,
      } = item;

      if (typeof url === 'undefined') return;

      const tmpFileId = JSON.parse(JSON.stringify(state.elementsList.byId[id].file_id));
      // console.log('replaceUserUploadWithCanvasAssets', tmpFileId, item);
      // update all uploaded elements with same file_id
      Array.from(state.elementsList.allIds).filter((elementId) => {
        if (state.elementsList.byId[elementId].file_id === tmpFileId) {
          state.elementsList.byId[elementId].file_id = '';
          state.elementsList.byId[elementId].menu = '';
          state.elementsList.byId[elementId].data.menu = '';
          state.elementsList.byId[elementId].data.is_user_file = '';
          state.elementsList.byId[elementId].data.thumb = thumb || url;
          state.elementsList.byId[elementId].data.url = url;
          state.elementsList.byId[elementId].data.urlHd = urlHd || url;
        }
      });
    },
    // =======================
    // PROJECT
    // =======================
    updateCanvasSize(state, item) {
      // please don't use this directly
      // use actions cause it has history saving
      // unless it's on first load
      // then pass notSmartResize as `true`
      // sceneId will be used if you only want to change position of 1 scene
      const {
        width, height, notSmartResize, sceneId,
      } = item;
      const oldWidth = item.oldWidth || state.canvasSize.width;
      const oldHeight = item.oldHeight || state.canvasSize.height;

      const selectedElementIds = sceneId && state.scenesList.byId[sceneId]
        ? state.scenesList.byId[sceneId].elements
        : state.elementsList.allIds;

      // console.table({
      //   width,
      //   height,
      //   oldWidth,
      //   oldHeight,
      //   notSmartResize,
      //   sceneId,
      // });

      // the element position and size
      // will update based on the ratio
      // https://docs.google.com/presentation/d/1t55ELi6o5Jf5ogbpiyt71dcNVkw6C0Qrr0AKm06uCrY/edit#slide=id.p
      // only run this if the size is different
      if (!notSmartResize && !(width === oldWidth && height === oldHeight)) {
        for (let i = 0; i < selectedElementIds.length; i += 1) {
          const id = selectedElementIds[i];
          const element = state.elementsList.byId[id];

          const isOverlay = element.sub_category_meta
            && element.sub_category_meta.findIndex(meta => meta.key === 'is_overlay') > -1;
          const isText = element.type === 'texts';

          // UPDATE POSITION
          const oldPosition = {
            x: element.position.x,
            y: element.position.y,
          };

          console.log(id, 'old position', oldPosition.x, oldPosition.y);
          console.log(id, 'old size', element.size.width, element.size.height);

          const positionRatioX = oldPosition.x / oldWidth;
          const positionRatioY = oldPosition.y / oldHeight;

          element.position.x = width * positionRatioX;
          element.position.y = height * positionRatioY;

          // UPDATE SIZE
          let sizeRatio = (width + height) / (oldWidth + oldHeight);

          if (isText) {
            if (width !== oldWidth) {
              // text only check the width
              sizeRatio = width / oldWidth;

              // some template has text with bigger width than canvas,
              // make it to be maximum canvas size
              if (element.size.width > oldWidth) {
                element.size.width = oldWidth;
              }

              // for texts, only resize if the width changed
              const fontRatio = parseFloat((element.data.fontSize / element.size.width).toFixed(3));
              element.size.width *= sizeRatio;
              element.size.height *= sizeRatio;
              element.data.fontSize = fontRatio * element.size.width;
            }
            // if text is outside the canvas, move it
            moveTextOutsideCanvas(element, width, height);
          } else {
            element.size.width *= sizeRatio;
            element.size.height *= sizeRatio;
          }

          if (
            !isText
            && (element.background
              || element.data.layerTag.bg
              || element.data.layerTag['hero-bg']
              || isOverlay
              || element.data.layerTag.overlay)
          ) {
            // if the background or overlay is smaller than canvas
            // resize it to full
            const elRatio = element.size.width / element.size.height;

            if (element.size.width < width) {
              element.size.width = width;
              element.size.height = width / elRatio;
            }

            if (element.size.height < height) {
              element.size.height = height;
              element.size.width = height * elRatio;
            }
          }
        }
      }

      state.canvasSize.width = width;
      state.canvasSize.height = height;
    },
    updateCanvasSizeWithTemplate(state, item) {
      const { width, height } = item;

      state.canvasSizeWithTemplate.width = width;
      state.canvasSizeWithTemplate.height = height;
    },
    deleteProjectColors(state, item) {
      const { category, index } = item;
      state.projectColors[category].splice(index, 1);
    },
    setProjectBrandId(state, item) {
      if (item) {
        state.project.brandId = item;
      }
    },
    updateProjectDetails(state, item) {
      const {
        isTemplate,
        id,
        project_id,
        project_name,
        template_name,
        duration,
        scenes,
        is_modular,
        is_multi_scene,
        // is_permutation,
        // permutation_id,
        audio,
        selected_template,
        is_shareable,
        brand_id,
      } = item;
      // console.log('update project details,', item);

      if (typeof id !== 'undefined') state.project.id = id;

      // for template, it will use the id rather than project_id
      if (isTemplate && typeof id !== 'undefined') state.project.projectId = id;
      if (typeof project_id !== 'undefined') state.project.projectId = project_id;

      if (typeof project_name !== 'undefined') state.project.name = project_name;
      else if (typeof template_name !== 'undefined') state.project.name = template_name;

      if (typeof duration !== 'undefined') state.project.duration = duration;

      if (typeof scenes !== 'undefined') state.project.scenes = scenes.map(scene => scene.id);

      if (typeof is_modular !== 'undefined') state.project.isModular = is_modular;

      if (typeof is_shareable !== 'undefined') state.project.isShareable = is_shareable;

      if (typeof is_multi_scene !== 'undefined') {
        state.project.isMultiScene = is_multi_scene;

        if (is_multi_scene) {
          state.project.isModular = false;
        }
      }

      if (
        selected_template
        && typeof selected_template.template_category !== 'undefined'
        && selected_template.template_category.length
        && selected_template.template_category.includes('permutable')
      ) {
        // is permutable template
        state.project.isPermutation = true;
        state.permutationId = selected_template.id;

        if (selected_template.scenes.length) {
          selected_template.scenes.filter((scene) => {
            if (scene.scene_category_type === 'product-w-bg') {
              if (scene.is_hero_w_bg) { state.permutationScenesIds[scene.scene_category_type] = scene.id; }
            } else if (scene.scene_category_type === 'product-wo-bg') {
              if (scene.is_hero_wo_bg) { state.permutationScenesIds[scene.scene_category_type] = scene.id; }
            } else if (scene.scene_category_type) state.permutationScenesIds[scene.scene_category_type] = scene.id;
          });
        }
      }

      if (
        typeof item.template_category !== 'undefined'
        && item.template_category.length
        && item.template_category.includes('intros-outros-and-logos')
      ) {
        state.project.isLogo = item.template_category.includes('intros-outros-and-logos');
      }

      if (typeof audio !== 'undefined' && audio !== null && !Array.isArray(audio)) {
        // BE is returning [] if user has removed audio
        state.project.audio = audio; // TODO: find better approach for updating states accordingly
        state.selectedMusic = audio;
      }

      if (brand_id) state.project.brandId = brand_id;
    },
    // =======================
    // SCENES
    // =======================
    updateSceneDurationById(state, item) {
      const { sceneId, duration } = item;

      const maxDurationScene = this.isLiteMode ? maxDuration.liteScene : maxDuration.scene;

      if (typeof duration === 'number' && duration <= maxDurationScene) {
        const currentSceneDuration = duration > 0 ? Math.round(duration * 10) / 10 : 1;

        state.scenesList.byId[sceneId].duration = currentSceneDuration;
      }

      state.project.duration = state.project.scenes.reduce((totalDuration, sceneId) => state.scenesList.byId[sceneId].duration + totalDuration, 0);
      // also need to update music duration
      state.selectedMusic.url = updateSelectedMusicDuration(
        state.selectedMusic,
        state.project.duration,
      );
    },
    updateCurrentSceneDuration(state, duration) {
      const maxDurationScene = this.isLiteMode ? maxDuration.liteScene : maxDuration.scene;
      // console.log('updateCurrentSceneDuration', state.activeSceneId, state.scenesList.byId[state.activeSceneId].duration, duration)
      if (typeof duration === 'number' && duration <= maxDurationScene) {
        const currentSceneDuration = duration > 0 ? Math.round(duration * 10) / 10 : 1;

        state.scenesList.byId[state.activeSceneId].duration = currentSceneDuration;
      }

      state.project.duration = state.project.scenes.reduce((totalDuration, sceneId) => state.scenesList.byId[sceneId].duration + totalDuration, 0);
      // also need to update music duration
      state.selectedMusic.url = updateSelectedMusicDuration(
        state.selectedMusic,
        state.project.duration,
      );
    },
    setPreviousSceneDuration(state, duration) {
      state.previousSceneDuration = duration;
    },
    updateActiveSceneId(state, item) {
      // console.log('activeSceneId', item, state.project.duration, state.project.scenes, state.scenesList.byId)
      state.activeSceneId = parseInt(item);
    },
    updateScenes(state, item) {
      // used when reordering scene
      console.log('updateScenes', item);
      const idArray = item.map(scene => scene.id);
      state.project.scenes = idArray;
    },
    deleteSceneFromArray(state, item) {
      const sceneId = item;
      const sceneArrayIndex = state.scenesList.allIds.indexOf(sceneId);
      const arrayIndex = state.project.scenes.indexOf(sceneId);

      delete state.scenesList.byId[sceneId];
      Vue.delete(state.sceneTimeline, sceneId);
      state.scenesList.allIds.splice(sceneArrayIndex, 1);
      state.project.scenes.splice(arrayIndex, 1);

      state.project.duration = state.project.scenes.reduce((totalDuration, sceneId) => state.scenesList.byId[sceneId].duration + totalDuration, 0);
      // also need to update music duration
      state.selectedMusic.url = updateSelectedMusicDuration(
        state.selectedMusic,
        state.project.duration,
      );
    },
    addSceneToArray(state, item) {
      if (!item) {
        item = {};
      }
      let {
        afterSceneId,
        isDuplicate,
        isModular,
        activeCategory,
        newId,
        elements,
        background,
        duration,
      } = item;
      const lastId = state.scenesList.allIds[state.scenesList.allIds.length - 1];

      if (!afterSceneId) {
        afterSceneId = lastId;
      }
      console.log('afterSceneId', afterSceneId, item);
      const arrayIndex = state.project.scenes.indexOf(afterSceneId);
      const newScene = JSON.parse(JSON.stringify(sceneFormat[0]));

      newScene.id = newId;
      if (duration) newScene.duration = duration;
      state.sceneTimeline[newId] = new TimelineLite({ paused: true });
      Vue.set(state.scenesList.byId, newId, newScene);
      state.scenesList.allIds.push(newId);

      state.project.scenes.splice(arrayIndex + 1, 0, newId);
      state.project.duration = state.project.scenes.reduce((totalDuration, sceneId) => state.scenesList.byId[sceneId].duration + totalDuration, 0);
      // also need to update music duration
      state.selectedMusic.url = updateSelectedMusicDuration(
        state.selectedMusic,
        state.project.duration,
      );

      if (isModular) state.scenesList.byId[newId].modular_category_id = activeCategory;

      if (background && Object.keys(background).length) { state.scenesList.byId[newId].background = background; }
      if (duration) state.scenesList.byId[newId].duration = duration;

      if (elements) {
        for (let i = 0; i < elements.length; i++) {
          const element = JSON.parse(JSON.stringify(elements[i]));
          const newElementId = randomId();
          element.data.id = newElementId;

          state.elementsList.allIds.push(newElementId);
          state.elementsList.byId[newElementId] = element;
          state.scenesList.byId[newId].elements.push(newElementId);
        }
      }
    },
    updateSceneTransition(state, item) {
      const { id, value, direction } = item;
      console.log('updateSceneTransition', item);
      const sceneObject = state.scenesList.byId[id];
      sceneObject.transition.value = value;
      sceneObject.transition.direction = direction;
    },
    clearScene(state) {
      // state.activeSceneId = '0';
      const firstSceneId = state.scenesList.allIds[0];
      state.activeElementsIds.length = 0;

      console.log('clearscene', firstSceneId, state.scenesList.byId[firstSceneId]);
      state.scenesList.byId[firstSceneId].elements.length = 0;
      state.scenesList.byId[firstSceneId].elements.pop();
      state.scenesList.byId[firstSceneId].background = JSON.parse(
        JSON.stringify(sceneFormat),
      ).background;
      state.scenesList.byId[firstSceneId].transition = JSON.parse(
        JSON.stringify(sceneFormat),
      ).transition;
      state.activeSceneId = firstSceneId;

      // only keep the first scene
      for (let i = 0; i < state.scenesList.allIds.length; i++) {
        if (i > 0) state.scenesList.allIds.splice(i, 1);
      }

      if (Object.keys(state.scenesList.byId).length) {
        for (const key of Object.keys(state.scenesList.byId)) {
          if (key !== firstSceneId) delete state.scenesList.byId[key];
        }
      }

      state.elementsList.allIds.length = 0;
      if (Object.keys(state.elementsList.byId).length) {
        for (const key of Object.keys(state.elementsList.byId)) {
          console.log('key is', key);
          delete state.elementsList.byId[key];
        }
      }
    },
    updateActiveColorId(state, item) {
      // make sure it's always string
      if (item !== null) state.activeColorId = item.toString();
      else state.activeColorId = item;
    },
    updateSwappedElementMaskImage(state, item) {
      // console.log('updateSwappedElementMaskImage', item.width, item.height, item)
      if (item.url) state.swappedElement.data.image.url = item.url;
      state.swappedElement.data.image.width = item.width;
      state.swappedElement.data.image.height = item.height;
      state.swappedElement.data.image.left = item.left;
      state.swappedElement.data.image.top = item.top;
      state.swappedElement.data.image.isNew = item.isNew || false;
    },
    updateSwappedElement(state, item) {
      const newElement = cloneDeep(item);
      console.log('updateSwappedElement', this.getters.isSwapOpened, state.swappedElement, newElement);

      state.isSwappingElement = true;

      if (state.swappedElement.data && item.data) {
        if (this.getters.isSwapOpened) {
          if (state.swappedElement.type === 'masks') {
            if (typeof state.swappedElement.data.image.url === 'undefined') {
              Vue.set(state.swappedElement.data.image, 'url', newElement.data.urlHd);
            } else {
              state.swappedElement.data.image.url = newElement.data.urlHd;
            }
            // for shutterstock
            if (newElement.data.src_id) {
              state.swappedElement.data.src_id = newElement.data.src_id;
            } else {
              delete state.swappedElement.data.src_id;
            }
            state.swappedElement.data.image.isNew = true;
          } else {
            const maxWidth = state.swapContainer.width / this.getters.getCanvasZoom;
            const maxHeight = state.swapContainer.height / this.getters.getCanvasZoom;
            const isWebm = newElement.data.url && newElement.data.url.split('.').pop() === 'webm';

            // if (newElement.type === 'images') {
            //   // don't include newElement.isImage because it can be graphics
            //   // this swap is like background-size cover
            //   getImageSize(newElement.data.url, (width, height) => {
            //     setSwappedMediaSizeCover({
            //       element: state.swappedElement,
            //       newElement,
            //       width,
            //       height,
            //       maxWidth,
            //       maxHeight,
            //     });
            //     console.log('newElement.data.has_removed_bg', newElement.data.has_removed_bg)

            //     state.swappedElement.data.has_removed_bg = !!newElement.data.has_removed_bg;
            //     state.isSwappingElement = false;
            //   });
            // }
            if (newElement.type === 'images' || newElement.isImage) {
              // for graphics images, always fit to width/height
              // this swap is like background-size contain
              getImageSize(newElement.data.url, (width, height) => {
                setSwappedMediaSizeContain({
                  element: state.swappedElement,
                  newElement,
                  width,
                  height,
                  maxWidth,
                  maxHeight,
                });

                state.swappedElement.data.has_removed_bg = !!newElement.data.has_removed_bg;
                state.isSwappingElement = false;
              });
            } else if (isWebm) {
              // for graphics webm, always fit to width/height
              // this swap is like background-size contain
              getVideoSize(newElement.data.url, (width, height) => {
                setSwappedMediaSizeContain({
                  element: state.swappedElement,
                  newElement,
                  width,
                  height,
                  maxWidth,
                  maxHeight,
                });
                state.isSwappingElement = false;
              });
            } else if (newElement.type === 'videos') {
              // this swap is like background-size cover
              getVideoSize(newElement.data.url, (width, height) => {
                setSwappedMediaSizeContain({
                  element: state.swappedElement,
                  newElement,
                  width,
                  height,
                  maxWidth,
                  maxHeight,
                });
                state.isSwappingElement = false;
              });
            } else {
              state.swappedElement.data.url = newElement.data.url;
              state.swappedElement.data.urlHd = newElement.data.urlHd;
              state.swappedElement.data.thumb = newElement.data.thumb;

              // both are JSON elements
              if (state.swappedElement.data.path && newElement.data.path) {
                state.swappedElement.data.path = newElement.data.path;
                if (state.swappedElement.data.color && newElement.data.color) {
                  state.swappedElement.data.color = newElement.data.color;
                }
              }

              if ('image' in state.swappedElement.data) {
                state.swappedElement.data.image.width = '100%';
                state.swappedElement.data.image.height = '100%';
                state.swappedElement.data.image.top = '0%';
                state.swappedElement.data.image.left = '0%';
              }

              if (state.swappedElement.type === 'shapes') {
                const replaceOrDelete = (prop) => {
                  if (newElement.data[prop] !== undefined) {
                    state.swappedElement.data[prop] = newElement.data[prop];
                  } else if (state.swappedElement.data[prop]) {
                    delete state.swappedElement.data[prop];
                  }
                };

                replaceOrDelete('borderRadius');
                replaceOrDelete('isHtml');
                replaceOrDelete('shape');
                replaceOrDelete('stroke');
                replaceOrDelete('thumb');
                replaceOrDelete('url');
                replaceOrDelete('urlHd');

                const maxWidth = state.swapContainer.width / this.getters.getCanvasZoom;
                const maxHeight = state.swapContainer.height / this.getters.getCanvasZoom;

                let newWidth;
                let newHeight;
                let ratio;
                let width;
                let height = 0;

                fetch(newElement.data.url, { cache: 'reload' })
                  .then(response => response.text())
                  .then((svg) => {
                    const doc = new DOMParser().parseFromString(svg, 'text/html');
                    const svgElement = doc.getElementsByTagName('svg')[0];

                    width = svgElement.viewBox.baseVal.width;
                    height = svgElement.viewBox.baseVal.height;

                    if (width > maxWidth || newWidth == null) {
                      // Check if the current width is larger than the max
                      ratio = maxWidth / width; // get ratio for scaling image
                      newHeight = height * ratio; // Reset height to match scaled image
                      newWidth = maxWidth; // Reset width to match scaled image
                    }

                    // Check if new height is still larger than max
                    if (newHeight > maxHeight || newHeight == null) {
                      ratio = maxHeight / height; // get ratio for scaling image
                      newWidth = width * ratio; // Reset width to match scaled image
                      newHeight = maxHeight; // Reset height to match scaled image
                    }

                    if (newWidth == 0 && newHeight == 0) {
                      newWidth = width;
                      newHeight = height;
                    }

                    state.swappedElement.size.width = newWidth;
                    state.swappedElement.size.height = newHeight;
                    state.isSwappingElement = false;
                  });
              } else {
                state.isSwappingElement = false;
              }
            }
          }
        } else if (state.swappedElement.type === 'masks') {
          state.swappedElement.data.image.url = newElement.data.image.url;
          state.swappedElement.data.image.top = newElement.data.image.top;
          state.swappedElement.data.image.left = newElement.data.image.left;
          state.swappedElement.data.image.width = newElement.data.image.width;
          state.swappedElement.data.image.height = newElement.data.image.height;
          state.swappedElement.data.image.isNew = newElement.data.image.isNew;
        } else {
          const currentId = state.swappedElement.data.id;
          const { width } = state.swappedElement.size;
          const { height } = state.swappedElement.size;
          const { x } = state.swappedElement.position;
          const { y } = state.swappedElement.position;

          newElement.data.id = currentId;
          newElement.size.width = width;
          newElement.size.height = height;
          newElement.position.x = x;
          newElement.position.y = y;
          state.swappedElement = newElement;
        }
      } else {
        state.swappedElement = newElement;
      }
    },
    updateShutterstockElement(state, item) {
      const { id, elementData, isMask } = item;
      const selectedElement = state.elementsList.byId[id];

      if (selectedElement) {
        if (isMask) {
          selectedElement.data.image.url = elementData.data.url;
        } else {
          selectedElement.data.url = elementData.data.url;
          selectedElement.data.urlHd = elementData.data.urlHd;
        }
      }
    },
    updateMasksImage(state, item) {
      const { id, image } = item;
      const selectedElement = state.elementsList.byId[id];

      if (!selectedElement) return;

      if (image.url) selectedElement.data.image.url = image.url;
      selectedElement.data.image.left = image.left;
      selectedElement.data.image.top = image.top;
      selectedElement.data.image.width = image.width;
      selectedElement.data.image.height = image.height;
      selectedElement.data.image.isNew = image.isNew;
    },
    updateCroppedElement(state, item) {
      const newElement = cloneDeep(item);
      state.croppedElement = newElement;
    },
    confirmCropElement(state) {
      const { id } = state.croppedElement.data;
      const selectedElement = state.elementsList.byId[id];

      selectedElement.size.width = state.croppedElement.size.width;
      selectedElement.size.height = state.croppedElement.size.height;
      selectedElement.position.x = state.croppedElement.position.x;
      selectedElement.position.y = state.croppedElement.position.y;

      selectedElement.data.image.left = state.croppedElement.data.image.left;
      selectedElement.data.image.top = state.croppedElement.data.image.top;
      selectedElement.data.image.width = state.croppedElement.data.image.width;
      selectedElement.data.image.height = state.croppedElement.data.image.height;

      // for the fluency of element to mask convertions on MaskTab.vue
      if (selectedElement.data.image && selectedElement.data.image.initialSize) Vue.delete(selectedElement.data.image, 'initialSize');
    },
    updateTrimmedElement(state, item) {
      const newElement = JSON.parse(JSON.stringify(item));
      state.trimmedElement = newElement;
    },
    confirmTrimmedElement(state) {
      if (state.trimmedElement.type === 'audio') {
        state.isTrimmingMusic = true;
        const musicUrl = state.selectedMusic.url.split('#t=')[0];
        const timeStart = state.trimmedElement.data.time_start;
        const timeEnd = state.trimmedElement.data.time_end;

        const newAudioUrl = `${musicUrl}#t=${timeStart},${timeEnd}`;

        // console.log('newAudioUrl', newAudioUrl)
        state.project.audio.url = newAudioUrl; // TODO: find better approach for updating states accordingly
        state.selectedMusic.url = newAudioUrl;

        setTimeout(() => {
          state.isTrimmingMusic = false;
        }, 100);
      } else {
        const { id } = state.trimmedElement.data;
        const selectedElement = state.elementsList.byId[id];
        selectedElement.data.time_start = state.trimmedElement.data.time_start;
        selectedElement.data.time_end = state.trimmedElement.data.time_end;

        const duration = selectedElement.data.time_end - selectedElement.data.time_start;

        selectedElement.time_out = selectedElement.time_in + duration;

        selectedElement.timeline_settings.animationDuration = state.trimmedElement.timeline_settings.animationDuration;
        selectedElement.timeline_settings.animateOutStart = state.trimmedElement.timeline_settings.animateOutStart;
      }
    },
    updateHoveredElement(state, item) {
      const newElement = cloneDeep(item);
      state.hoveredElement = newElement;
    },
    removeHoveredElement(state, item) {
      state.hoveredElement = {};
    },
    updateMultipleSwappedElements(state, item) {
      const newElement = cloneDeep(item);
      state.multipleSwappedElements = newElement;
    },
    updateSingleMultipleSwappedElements(state, item) {
      const {
        id, url, urlHd, thumb, has_removed_bg,
      } = item;
      console.log('updateSingleMultipleSwappedElements', item);

      state.multipleSwappedElements[id].url = url;

      if (typeof thumb !== 'undefined') state.multipleSwappedElements[id].thumb = thumb;
      if (typeof urlHd !== 'undefined') state.multipleSwappedElements[id].urlHd = urlHd;
      state.multipleSwappedElements[id].has_removed_bg = has_removed_bg;
    },
    confirmMultipleSwappedElements(state) {
      for (const key of Object.keys(state.multipleSwappedElements)) {
        const swappedElement = state.multipleSwappedElements[key];
        const element = state.elementsList.byId[key];
        // console.log('key is', key, swappedElement.url, element.type, element.data.image.url)

        if (element.type === 'masks') {
          // only swap if the element is changed
          if (swappedElement.url !== element.data.image.url) {
            console.log('mask is UPDATED', swappedElement.url);
            element.data.image.isNew = true;
            element.data.image.top = null;
            element.data.image.left = null;
            element.data.image.url = swappedElement.url;
          }
        } else {
          // only swap if the element is changed
          if (swappedElement.url !== element.data.url) {
            getImageSize(swappedElement.url, (width, height) => {
              confirmSwappedMediaSizeContain({
                element,
                newElement: swappedElement,
                width,
                height,
              });
            });
          }
        }
      }
    },
    updateSelectedMusic(state, item) {
      console.log('updateSelectedMusic', item);
      Vue.set(state, 'selectedMusic', item);
      Vue.set(state.project, 'audio', item); // update also project details
    },
    updateEdittedTextIndex(state, item) {
      state.edittedTextIndex = item;
    },
    updateEdittedTextId(state, item) {
      state.edittedTextId = item;
    },
    updateActiveFontFamily(state, item) {
      state.activeFontFamily = item;
    },
    updateSmartGuides(state, item) {
      state.smartGuides.length = 0;
      state.smartGuides = state.smartGuides.concat(item);
    },
    updateRemoveBgElement(state, item) {
      state.removeBgElement = JSON.parse(JSON.stringify(item));
    },
    updateSavingStatus(state, item) {
      state.savingStatus = item;
    },
    incrementSavingTries: state => state.savingTries++,
    savingCompleted(state, item) {
      state.savingTries = 0;
      state.savingStatus = item || 'Saved';
      setTimeout(() => {
        state.savingStatus = '';
      }, 1000);
    },
    updateCanvasBackground(state, item) {
      // console.log('updateCanvasBackground', item);
      // if background is a string, convert it to object
      let color = item;

      if (typeof item === 'string') {
        color = {
          color: item,
        };
      }
      Vue.set(state.scenesList.byId[state.activeSceneId], 'background', color);
    },
    updateShowScenesOverlay(state, item) {
      state.showScenesOverlay = item;
    },
    setModularCategories(state, item) {
      // console.log('modular cat', item.byId)
      const { modularCategories } = state;

      modularCategories.allIds.length = 0;
      modularCategories.byId = {};

      for (let i = 0; i < item.length; i++) {
        const modularCategory = item[i];
        const newModularCategory = cloneDeep(modularCategory);
        modularCategories.allIds.push(modularCategory.id);
        Vue.set(modularCategories.byId, modularCategory.id, newModularCategory);

        // comment by siska
        // this part may have issue if more categories is added via admin
        // cause the categories in the add new scene is not by admin
        // if this were to update, need to update the setting in StoryboardOverlay.vue
        Vue.set(state.permutationScenesIds, modularCategories.slug, null);
      }
    },
    setModular(state, item) {
      state.project.isModular = item;
    },
    setPermutation(state, item) {
      state.project.isPermutation = item;
    },
    setIsLayout(state, item) {
      state.project.isLayout = item;
    },
    updateSceneModularCategory(state, item) {
      let { sceneId, categoryId } = item;

      if (categoryId === -1) categoryId = null;

      state.scenesList.byId[sceneId].modular_category_id = categoryId;
    },
    setSelectedTemplateModular(state, item) {
      state.selectedTemplateModular.id = item.id;
      state.selectedTemplateModular.name = item.name;
    },
    updateTemplateModularId(state, item) {
      state.templateModularId = item;
    },
    updateLastSelectedTemplateId(state, item) {
      state.lastSelectedTemplateId = item;
    },
    deleteModularCategory(state, item) {
      const { id } = item;

      // const url = `${process.env.VUE_APP_OFFEO_API}/collections/modular-template-categories/${id}`;
      ModularTemplateCategoryApi.delete(id)
        .then((res) => {
          console.log('res', res);
          if (res.data.success) {
            const index = state.modularCategories.allIds.indexOf(id);
            state.modularCategories.allIds.splice(index, 1);
            delete state.modularCategories.byId[id];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addModularCategory(state, item) {
      const { name } = item;

      // may be able to add parent_id in the future
      const category = {
        name,
      };

      ModularTemplateCategoryApi.create(category)
        .then((res) => {
          console.log('res', res);
          if (res.data.success) {
            const result = res.data.results;
            if (!result) return;
            const { id } = result;
            state.modularCategories.byId[id] = {
              id,
              slug: result.slug,
              name: result.name,
            };
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateModularCategoryName(state, item) {
      // console.log('updateModularCategory', item);
      const { name, id } = item;
      const oldName = state.modularCategories.byId[id].name;

      state.modularCategories.byId[id].name = name;

      // may be able to add parent_id in the future
      const category = {
        name,
      };

      ModularTemplateCategoryApi.update(id, category)
        .then((res) => {
          // if fail to update, return to old name
          if (!res.data.success) {
            state.modularCategories.byId[id].name = oldName;
          }
        })
        .catch((error) => {
          // if fail to update, return to old name
          console.log(error);
          state.modularCategories.byId[id].name = oldName;
        });
    },
    updateModularCategoryOrder(state, item) {
      const oldCategories = state.modularCategories.allIds;
      state.modularCategories.allIds = [];
      state.modularCategories.allIds.push(...item);

      const params = {
        id: item,
      };

      ModularTemplateCategoryApi.updateOrder(params)
        .then((res) => {
          // if the put failed, return to where it was
          if (!res.data.success) {
            state.modularCategories.allIds = [];
            state.modularCategories.allIds.push(...oldCategories);
          }
        })
        .catch((error) => {
          console.log(error);
          // if the put failed, return to where it was
          state.modularCategories.allIds = [];
          state.modularCategories.allIds.push(...oldCategories);
        });
    },
    updateSwapContainer(state, item) {
      state.swapContainer = item;
    },
    updateTimelineSpeed(state, item) {
      state.timelineSpeed = item;
    },
    clearAutoSaveInterval(state) {
      if (process.env.VUE_APP_AUTOSAVE !== 'false') {
        console.log('autosave is cleared');
        clearInterval(state.autoSaveInterval);
      }
    },
    historyResetElementData(state, item) {
      const { id, element } = item;
      if (typeof state.elementsList.byId[id] !== 'undefined') {
        delete state.elementsList.byId[id]; // clear first to make it reactive
        Vue.set(state.elementsList.byId, id, element);

        this.commit('canvasElements/updateReloadCanvas', true); // to reset animation inside the canvas
      }
    },
    historyResetSceneData(state, item) {
      const { id, sceneDetails } = item;
      if (typeof state.scenesList.byId[id] !== 'undefined') { state.scenesList.byId[id] = JSON.parse(JSON.stringify(sceneDetails)); }
    },
    historyResetProjectData(state, item) {
      if (typeof state.project !== 'undefined') state.project = item;
    },
    historyResetCanvasElementState(state, item) {
      const { canvasElementState, canvasElementStateDetails } = item;
      if (typeof state[canvasElementState] !== 'undefined') {
        state[canvasElementState] = canvasElementStateDetails;
      }
    },
    updateHasChanges(state, item) {
      state.hasChanges = item;
    },
    updateReloadCanvas(state, item) {
      state.reloadCanvas = item;
      console.log('historyResetElementData', state.reloadCanvas);
    },
    updateCopiedElements(state, elements) {
      state.copiedElements = elements;
      state.copiedSceneId = state.activeSceneId;
    },
    addCopiedElements(state, newElements) {
      const firstElementId = state.copiedElements[0].data.id;
      const { elements } = state.scenesList.byId[state.activeSceneId];
      let index = elements.indexOf(firstElementId);

      if (state.copiedSceneId !== state.activeSceneId) {
        index = 0;
      }

      newElements.forEach((element) => {
        const newId = randomId();
        element.data.id = newId;

        if (state.copiedSceneId === state.activeSceneId) {
          element.position.x += 20;
          element.position.y += 20;
        }

        if (typeof element.element_id !== 'undefined') delete element.element_id;

        state.scenesList.byId[state.activeSceneId].elements.splice(index, 0, newId);
        state.elementsList.byId[newId] = element;
        state.elementsList.allIds.push(newId);

        index += 1;

        this.commit('canvasElements/addActiveElements', newId);
      });
    },
    setActiveOverlay(state, item) {
      state.activeOverlay = item;
    },
    setActiveFGWhiteBg(state, item) {
      state.activeFGWhiteBg = item;
    },
    setActiveFGBlackBg(state, item) {
      state.activeFGBlackBg = item;
    },
    setActiveBackgroundElements(state, item) {
      state.activeBackgroundElements = item;
    },
    setTemporaryHeroImageUrl(state, item) {
      state.temporaryHeroImageUrl = item;
    },
    updateLayerTag(state, item) {
      const { ids, layerTag, isRemoved } = item;

      const currentElementsIds = state.scenesList.byId[state.activeSceneId].elements;
      const clearOtherLayerTag = (id, layerTag) => {
        for (let i = 0; i < currentElementsIds.length; i += 1) {
          const currentId = currentElementsIds[i];
          const element = state.elementsList.byId[currentId];

          if (currentId !== id) {
            if (typeof element.data.layerTag[layerTag] === 'undefined') {
              Vue.set(element.data.layerTag, layerTag, false);
            } else {
              element.data.layerTag[layerTag] = false;
            }
          }
        }
      };

      for (let i = 0; i < ids.length; i += 1) {
        const id = ids[i];
        const element = state.elementsList.byId[id];

        if (typeof element.data.layerTag[layerTag] === 'undefined') {
          Vue.set(element.data.layerTag, layerTag, !isRemoved);
        } else {
          element.data.layerTag[layerTag] = !isRemoved;
        }

        // if user choose h1, h2, or h3
        // cancel the other active h1, h2 or h3
        // there is only 1 x h1, 1 x h2 and 1 x h3 allowed
        // allow multiple hx taggings for layout templates
        if (
          !isRemoved
          && !state.project.isLayout
          && (layerTag === 'h1' || layerTag === 'h2' || layerTag === 'h3')
        ) {
          clearOtherLayerTag(id, layerTag);
        }
      }
    },
    clearAllLayerTag(state, ids) {
      for (let i = 0; i < ids.length; i += 1) {
        const id = ids[i];
        const element = state.elementsList.byId[id];

        for (let j = 0; j < Object.keys(element.data.layerTag).length; j += 1) {
          const tag = Object.keys(element.data.layerTag)[j];

          element.data.layerTag[tag] = false;
        }
      }
    },
    updateHeroSceneWithBg(state, item) {
      console.log('update hero scene', item);
      const { id } = item;
      state.heroSceneWithBg = item;

      if (state.scenesList.allIds.length === 0 || !state.scenesList.byId[id]) return;

      state.scenesList.byId[id].is_hero_w_bg = true;
      for (let i = 0; i < state.scenesList.allIds.length; i += 1) {
        const sceneId = state.scenesList.allIds[i];
        const scene = state.scenesList.byId[sceneId];

        if (id !== sceneId) {
          scene.is_hero_w_bg = false;
        }
      }
    },
    updateHeroSceneWithoutBg(state, item) {
      const { id } = item;
      state.heroSceneWithoutBg = item;

      if (state.scenesList.allIds.length === 0 || !state.scenesList.byId[id]) return;

      state.scenesList.byId[id].is_hero_wo_bg = true;
      for (let i = 0; i < state.scenesList.allIds.length; i += 1) {
        const sceneId = state.scenesList.allIds[i];
        const scene = state.scenesList.byId[sceneId];

        if (id !== sceneId) {
          scene.is_hero_wo_bg = false;
        }
      }
    },
    addCanvasElementsGroup(state, activeElements) {
      const firstElementId = activeElements[0].data.id;
      const firstElementIndex = state.scenesList.byId[state.activeSceneId].elements.indexOf(
        firstElementId,
      );

      console.log('activeElements', activeElements);

      activeElements.reverse().forEach((element) => {
        const currentIndex = state.scenesList.byId[state.activeSceneId].elements.indexOf(
          element.data.id,
        );
        const currentElementId = state.scenesList.byId[state.activeSceneId].elements.splice(
          currentIndex,
          1,
        )[0];

        state.scenesList.byId[state.activeSceneId].elements.splice(
          firstElementIndex,
          0,
          currentElementId,
        );
        state.elementsList.byId[currentElementId] = element;
      });
    },
    removeCanvasElementsGroup(state, { activeElements, groupElements }) {
      const groupFirstElementId = groupElements[0].data.id;
      let groupFirstElementIndex = state.scenesList.byId[state.activeSceneId].elements.indexOf(groupFirstElementId);

      groupElements.reverse().forEach((element) => {
        const currentIndex = state.scenesList.byId[state.activeSceneId].elements.indexOf(
          element.data.id,
        );
        const currentElementId = state.scenesList.byId[state.activeSceneId].elements.splice(
          currentIndex,
          1,
        )[0];

        state.scenesList.byId[state.activeSceneId].elements.splice(
          groupFirstElementIndex,
          0,
          currentElementId,
        );

        groupFirstElementIndex += 1;
      });

      groupFirstElementIndex = state.scenesList.byId[state.activeSceneId].elements.indexOf(groupFirstElementId);

      activeElements.reverse().forEach((element) => {
        const currentIndex = state.scenesList.byId[state.activeSceneId].elements.indexOf(
          element.data.id,
        );
        const currentElementId = state.scenesList.byId[state.activeSceneId].elements.splice(
          currentIndex,
          1,
        )[0];

        state.scenesList.byId[state.activeSceneId].elements.splice(
          groupFirstElementIndex,
          0,
          currentElementId,
        );
        state.elementsList.byId[currentElementId] = element;

        groupFirstElementIndex += 1;
      });
    },
    toggleGroupLock(state, group) {
      let itemLock = false;
      let groupLock = false;

      if (!group.lock) {
        itemLock = true;
        groupLock = true;
      }

      group.elements.forEach((element) => {
        state.elementsList.byId[element.data.id].lock = itemLock;
        state.elementsList.byId[element.data.id].data.groupLock = groupLock;
      });
    },
    toggleGroupShow(state, group) {
      let itemShow = true;
      let groupShow = true;

      if (group.show) {
        itemShow = false;
        groupShow = false;
      }

      group.elements.forEach((element) => {
        state.elementsList.byId[element.data.id].show = itemShow;
        state.elementsList.byId[element.data.id].data.groupShow = groupShow;
      });
    },
    updateGroupName(state, item) {
      const { name, group } = item;

      group.elements.forEach((element) => {
        state.elementsList.byId[element.data.id].data.groupName = name;
      });
    },
    toggleGroupFold(state, item) {
      item.elements.forEach((element) => {
        state.elementsList.byId[element.data.id].data.groupFold = !element.data.groupFold;
      });
    },
    updateProjectPreviews(state, item) {
      state.project.previews = item;
    },
    setStoryBoardPreviews(state, item) {
      const { sceneId, base64 } = item;

      const maxScenePreviews = 50;

      if (Object.keys(state.storyBoardPreviews).length >= maxScenePreviews) {
        // max to 30 previews only
        const diff = Object.keys(state.storyBoardPreviews).length - maxScenePreviews;
        for (let i = 0; i < diff; i++) {
          delete state.storyBoardPreviews[Object.keys(state.storyBoardPreviews)[i]];
        }
      }

      if (!base64 && typeof state.storyBoardPreviews[sceneId] !== 'undefined') {
        delete state.storyBoardPreviews[sceneId];
      } else {
        Vue.set(state.storyBoardPreviews, sceneId, base64);
      }

      const date = new Date();
      // add a day
      const expiry = date.setDate(date.getDate() + 1);
      Vue.set(state.storyBoardPreviews, 'expiry', expiry); // will be stored in milliseconds

      localStorage.setItem(
        `${storyBoardPreviewsKey}_${state.project.projectId}`,
        JSON.stringify(state.storyBoardPreviews),
      );
    },
    addStoryBoardApiRequestIds(state, item) {
      state.storyBoardApiRequestIds.push(item);
    },
    deleteStoryBoardApiRequestIds(state, item) {
      const index = state.storyBoardApiRequestIds.indexOf(item);
      if (index !== -1) state.storyBoardApiRequestIds.splice(index, 1);
    },
    updateDoingSplitText(state, item) {
      state.isDoingSplitText = item;
    },
    updateHasAutoWidthElement(state, item) {
      // console.log('STATE updateHasAutoWidthElement', item)
      state.hasAutoWidthElement = item;
    },
    updateIsSwappingElement(state, item) {
      state.isSwappingElement = item;
    },
    setSavingStatus(state, item) {
      state.savingStatus = item;
    },
    setIsChangingElements(state, item) {
      state.isChangingElements = item;
    },
    setVideoElements(state, item) {
      state.videoElements = item;
    },
    setLoadedVideoElements(state, item) {
      state.loadedVideoElements = item;
    },
    setSelectedAnimationDirection(state, item) {
      // this will hold the previous animation direction option;
      state.selectedAnimationDirection = item;
    },
    initStoryboardPreviews(state) {
      state.storyBoardPreviews = JSON.parse(localStorage.getItem(`${storyBoardPreviewsKey}_${state.project.projectId}`))
        || {};
    },
    updateShareable(state, item) {
      state.project.isShareable = item;
    },
    updateCachedVideos(state, item) {
      const { videoUrl, data } = item;
      Vue.set(state.cachedVideos, videoUrl, data);
    },
    removeCachedVideo(state, item) {
      if (typeof state.cachedVideos[item] !== 'undefined') {
        Vue.delete(state.cachedVideos, item);
      }
    },
    setLastSelectedLiteTemplate(state, item) {
      console.log('setLastSelectedLiteTemplate', item);
      if (!item.id) return;

      state.lastSelectedLiteTemplateId = item.id;

      const sceneListById = {};
      const sceneListIds = [];

      for (let i = 0; i < item.scenes.length; i++) {
        const scene = cloneDeep(item.scenes[i]);
        const { id } = scene;

        sceneListIds.push(id);
        sceneListById[id] = scene;
      }

      state.lastSelectedLiteTemplateScenesList = {
        byId: sceneListById,
        allIds: sceneListIds,
      };
    },
    updateShutterstockLoadingIds(state, item) {
      const srcElements = state.shutterstockLoadingIds[item.srcId]
        ? state.shutterstockLoadingIds[item.srcId]
        : [];
      srcElements.push(item.elementId);
      Vue.set(state.shutterstockLoadingIds, item.srcId, srcElements);
    },
    removeShutterstockLoadingId(state, item) {
      const { elementId, srcId } = item;

      let srcElements = state.shutterstockLoadingIds[item.srcId]
        ? state.shutterstockLoadingIds[item.srcId]
        : [];
      srcElements = srcElements.filter(item => item !== elementId);

      Vue.set(state.shutterstockLoadingIds, srcId, srcElements);
    },
    setDefaultBackground(state, lastElement) {
      if (!lastElement.data.id) return;

      state.defaultBackground.url = lastElement.data.url;

      if (lastElement.type === 'shapes') {
        // eslint-disable-next-line prefer-destructuring
        state.defaultBackground.color = lastElement.data.color ? lastElement.data.color[0] : '#ffffff';
      } else {
        state.defaultBackground.thumb = lastElement.data.thumb;
        state.defaultBackground.urlHd = lastElement.data.urlHd;
      }
    },
    /** WATERMARK START */
    setDefaultWatermark(state) {
      Vue.set(state.project, 'watermark', watermarkFormat);
    },
    setWatermark(state, item) {
      const {
        url, isEnabled, size, opacity, direction, position, background,
      } = item;

      if (!state.project.watermark || isEmptyObject(state.project.watermark)) {
        Vue.set(state.project, 'watermark', watermarkFormat);
      }

      if (typeof url !== 'undefined') {
        state.project.watermark.url = url;
        // if user selects new url, auto enabled it
        // if user removes url, auto disabled it
        state.project.watermark.isEnabled = url !== '';

        // if user removes url, reset the size
        if (url === '') {
          state.project.watermark.size.width = watermarkFormat.size.width;
          state.project.watermark.size.height = watermarkFormat.size.height;
        }
      }

      if (typeof isEnabled !== 'undefined') {
        state.project.watermark.isEnabled = isEnabled;
      }

      if (typeof background !== 'undefined') {
        state.project.watermark.background = background;
      }

      if (typeof size !== 'undefined' && size.width) {
        state.project.watermark.size = {
          width: size.width,
          height: size.height,
        };

        // if border radius is bigger than the width, resize it to follow
        if (state.project.watermark.background && state.project.watermark.background.borderRadius) {
          let { borderRadius } = state.project.watermark.background;
          if (borderRadius && borderRadius > size.width) {
            borderRadius = size.width;
          }
        }
      }

      if (typeof opacity !== 'undefined') {
        state.project.watermark.opacity = opacity;
      }

      if (typeof direction !== 'undefined') {
        state.project.watermark.direction = direction;
      }

      if (typeof position !== 'undefined') {
        state.project.watermark.position = position;
      }

      if (typeof background !== 'undefined') {
        state.project.watermark.background = background;
      } else {
        Vue.set(state.project.watermark, 'background', watermarkFormat.background);
      }
    },
    setWatermarkPosition(state, item) {
      const { x, y } = item;

      if (!isEmpty(x)) {
        state.project.watermark.position.x = x;
      }

      if (!isEmpty(y)) {
        state.project.watermark.position.y = y;
      }
    },
    setWatermarkBackground(state, item) {
      const {
        color, scaleX, scaleY, borderRadius, opacity, isEnabled,
      } = item;

      if (!state.project.watermark.background || isEmptyObject(state.project.watermark.background)) {
        Vue.set(state.project.watermark, 'background', watermarkFormat.background);
      }

      if (typeof color !== 'undefined') {
        state.project.watermark.background.color = color;
      }

      if (typeof scaleX !== 'undefined') {
        state.project.watermark.background.scale.x = scaleX;
      }

      if (typeof scaleY !== 'undefined') {
        state.project.watermark.background.scale.y = scaleY;
      }

      if (typeof borderRadius !== 'undefined') {
        state.project.watermark.background.borderRadius = borderRadius;
      }

      if (typeof opacity !== 'undefined') {
        state.project.watermark.background.opacity = opacity;
      }

      if (typeof isEnabled !== 'undefined') {
        state.project.watermark.background.isEnabled = isEnabled;
      }
    },
    /** WATERMARK END */
  },
  actions: {
    // deleteElementAsync(context, item) {
    //   // deleting element need to be async action or it can't delete multiple element
    //   setTimeout(() => {
    //     context.commit('deleteElementFromCanvas', item);
    //   }, 200);
    // },
    async confirmSwappedLiteImage({ state }, item) {
      /* format required:
        {
          url: '',
          urlHd: '',
          thumb: '',
          has_removed_bg: true/false,
          id: '',
        }
      */
      console.log('confirmSwappedLiteImage', item);
      const { url, elementId } = item;
      const element = state.elementsList.byId[elementId];

      if (element.type === 'masks') {
        element.data.image.isNew = true;
        element.data.image.top = null;
        element.data.image.left = null;
        element.data.image.url = url;
      } else {
        await getImageSize(url).then((image) => {
          const { width, height } = image;
          confirmSwappedMediaSizeContain({
            element,
            newElement: item,
            width,
            height,
          });
        });
      }
    },
    async confirmedRemoveBgElement({ state }, item) {
      /* format received v1:
        {
          download_link: '',
          file_ext: '',
          file_type: '',
          original_name: '',
          thumb_link: '',
          w_960: '',
        }
      */

      /* format received v2:
        {
          ext: [],
          file_name: "",
          file: "",
          file_hd: "",
          file_name: "",
          preview_url: "",
        }
      */
      const elementId = state.removeBgElement.data.id;
      const element = state.elementsList.byId[elementId];
      console.debug('element before update', element);

      const { preview_url, file, file_hd } = item;

      const newElement = {
        url: file,
        urlHd: file_hd,
        thumb: preview_url,
        has_removed_bg: true,
      };

      await getImageSize(file).then((image) => {
        let { width, height } = image;

        const ratio = width / height;

        // make the width same size first
        if (width !== element.size.width) {
          width = element.size.width; // eslint-disable-line
          height = width / ratio;
        }

        element.size.width = width;
        element.size.height = height;

        element.data.image = {
          width: '100%',
          height: '100%',
          left: '0%',
          top: '0%',
        };

        element.data.aspectRatio = calcAspectRatio(element);

        element.data.has_removed_bg = true;

        element.data.url = newElement.url;
        element.data.urlHd = newElement.urlHd;
        element.data.thumb = newElement.thumb;

        const originalSize = checkOriginalSize(element);
        Vue.set(element.data, 'originalSize', originalSize);
      });
    },
    updateElementRectAsync(context, item) {
      setTimeout(() => {
        context.commit('updateCanvasElementRect', item);
      }, 200);
    },
    setAllActiveElementsAlignment({ commit, state, dispatch }, item) {
      dispatch('canvasHistory/catchHistory', 'element', { root: true });

      let { elements, direction } = item;

      if (typeof elements === 'undefined') elements = state.activeElementsIds;

      // update elements first before saving to history
      for (let i = 0; i < state.activeElementsIds.length; i++) {
        commit('updateCanvasElementAlignment', {
          id: state.activeElementsIds[i],
          direction: item,
        });
      }
    },
    setAllActiveElementsTimeline({ commit, state }, item) {
      const {
        type, value, direction, speed, option,
      } = item;
      console.log('setAllActiveElementsTimeline', value, direction);
      state.activeElementsIds.forEach((id) => {
        const timeline = {
          id,
        };

        if (type === 'in') {
          if (typeof value !== 'undefined') timeline.animateInValue = value;
          if (typeof direction !== 'undefined') timeline.animateInDirection = direction;
          if (typeof speed !== 'undefined') timeline.animateInDuration = speed;
          if (typeof option !== 'undefined') timeline.animateInOption = option;
        } else if (type === 'during') {
          if (typeof value !== 'undefined') timeline.animateDuringValue = value;
          if (typeof direction !== 'undefined') timeline.animateDuringDirection = direction;
          if (typeof speed !== 'undefined') timeline.animateDuringSpeed = speed;
        } else if (type === 'out') {
          if (typeof value !== 'undefined') timeline.animateOutValue = value;
          if (typeof direction !== 'undefined') timeline.animateOutDirection = direction;
          if (typeof speed !== 'undefined') timeline.animateOutDuration = speed;
          if (typeof option !== 'undefined') timeline.animateOutOption = option;
        }

        commit('updateCanvasElementTimelineSettings', timeline);
      });
    },
    applyGenieAnimation({ commit, state }, item) {
      item.forEach((element, index) => {
        const timeline = Object.assign({}, element.timeline_settings);
        timeline.id = element.data.id;
        // make sure time_in and time_out is rounded to nearest 0.1 sec
        timeline.time_in = Math.round(element.time_in * 10) / 10;
        timeline.time_out = Math.round(element.time_out * 10) / 10;

        if (element.type === 'videos') {
          timeline.time_start = element.data.time_start;
          timeline.time_end = element.data.time_end;
        }
        commit('updateCanvasElementTimelineSettings', timeline);
      });
    },
    saveProject({
      rootGetters, getters, commit, dispatch, state, rootState,
    }, item) {
      const { savingMessage, savedMessage, allScenes } = item || {};

      if (getters.getSavingStatus === 'Saving...' || getters.getSavingStatus === 'Saved') { return; } // currently saving

      const projectId = getters.getProjectId;

      const projectSceneIds = getters.getScenes.map(v => v.id);

      // console.log('getters[getScenes]', getters['getScenes'], projectScenes);

      const params = {
        canvas_width: getters.getCanvasSize.width || 1080,
        canvas_height: getters.getCanvasSize.height || 1080,
        background: getters.getCanvasBackground,
        audio: getters.getProjectDetails.audio,
        scenes: projectSceneIds,
        watermark: getters.getWatermark,
        options: 'watermark',
      };

      if (typeof params.audio.data !== 'undefined') {
        delete params.audio.data;
      }

      // Add checker if dont applied watermark, remove params.options
      if (isEmptyObject(params.watermark) || !params.watermark.isEnabled) {
        delete params.options;
      }

      const message = savingMessage || 'Saving...';

      commit('updateSavingStatus', message);

      if (rootGetters.getIsTemplate) {
        params.template_name = getters.getProjectDetails.name || 'OFFEO Template';
        params.type = 'template';

        if (rootState.isTextTemplate) {
          params.type = 'text';
        }

        if (getters.getProjectDetails.isModular) {
          params.type = 'modular';
        }
      } else {
        params.project_name = getters.getProjectDetails.name || 'OFFEO Video';
      }

      // TODO: Async it to wait for actual saving process?
      // for (let i = 0; i < getters['getProjectDetails'].scenes.length; i++) {
      //   const sceneId = getters['getProjectDetails'].scenes[i];
      //   dispatch('saveProjectScene', { sceneId, savedMessage });
      // }
      // by default, save only the active scene
      let sceneLists = [];

      if (allScenes) sceneLists = state.scenesList.allIds;
      else sceneLists.push(state.activeSceneId);

      for (let i = 0; i < sceneLists.length; i += 1) {
        // save the scene on interval to avoid spamming API
        setTimeout(() => {
          const sceneId = sceneLists[i];
          dispatch('saveProjectScene', { sceneId, savedMessage });
        }, i * 100);
      }

      const APIREQUEST = rootGetters.getIsTemplate ? TemplateApi : ProjectApi;

      APIREQUEST.update(projectId, params)
        .then((response) => {
          if (response.data.success) {
            commit('savingCompleted', savedMessage);
            commit('updateHasChanges', false);
            dispatch('resetAutoSaveInterval');
          }
        })
        .catch((error) => {
          console.log(error);
          commit('incrementSavingTries');
          setTimeout(() => {
            if (state.savingTries >= state.maxSavingTries) {
              commit('updateSavingStatus', `(${state.savingTries}) Failed`);
            } else {
              commit('updateSavingStatus', 'Retrying...');
              dispatch('saveProject', {
                savingMessage: `(${state.savingTries}) Retrying...`,
                savedMessage: 'Saved',
              });
            }
          }, 1000);
        });
    },
    saveProjectScene({
      dispatch, getters, rootGetters, commit,
    }, { sceneId, savedMessage, tries = 0 }) {
      if (tries >= 5) return;

      const projectId = getters.getProjectId;

      // if ( typeof sceneDetails.elements ) delete sceneDetails['elements']; //remove elements from scene details;
      const activeScene = getters.getSceneById(sceneId);

      let { background } = activeScene;

      // save in object format
      if (typeof background !== 'object') {
        background = {
          color: activeScene.background,
        };
      }

      const params = {
        duration: activeScene.duration,
        background,
        transition: activeScene.transition,
        scene_elements: getters.getScenesElementsById(sceneId),
        is_branded: activeScene.is_branded,
        is_hero_w_bg: activeScene.is_hero_w_bg,
        is_hero_wo_bg: activeScene.is_hero_wo_bg,
      };

      const APIREQUEST = rootGetters.getIsTemplate ? TemplateApi : ProjectApi;

      APIREQUEST.updateProjectScene(projectId, sceneId, params)
        .then((response) => {
          if (response.data.success) {
            const newSceneDetails = response.data.new_scene_details || response.data.results.new_scene_details;

            commit('savingCompleted', savedMessage);
            commit('updateElementListIds', newSceneDetails.elements);
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch('saveProjectScene', {
            sceneId,
            savedMessage,
            tries: (tries += 1),
          });
        });
    },
    setAutoSaveInterval({ state, dispatch }) {
      // save every one minute
      // only set if autosave is enabled
      if (process.env.VUE_APP_AUTOSAVE !== 'false') {
        state.autoSaveInterval = setInterval(() => {
          dispatch('saveProject', {
            savingMessage: 'Auto saving...',
            savedMessage: 'Auto saved',
          });
        }, 60000); // set 60 secs auto save interval
      }
    },
    resetAutoSaveInterval({ commit, dispatch }) {
      commit('clearAutoSaveInterval');
      dispatch('setAutoSaveInterval');
    },
    emptyScenesElements({ state }, item) {
      const { scenesList } = state;
      const { elementsList } = state;

      scenesList.allIds.length = 0;
      scenesList.byId = JSON.parse(JSON.stringify(sceneFormat));
      scenesList.allIds = [0];
      elementsList.allIds.length = 0;
      elementsList.byId = {};
      state.project.scenes = [0];
    },
    updateScenesElements({
      state, dispatch, commit, rootGetters,
    }, item) {
      // this is the function to call when selecting new template
      const {
        newScenes,
        isModularTemplate,
        assignModularAsLastTemplate,
        canvasWidth,
        canvasHeight,
        replaceWithMask,
        maskItem,
      } = item;
      // console.log('updateScenesElements', JSON.parse(JSON.stringify(newScenes)), isModularTemplate)
      // console.log('replaceWithMask', replaceWithMask, maskItem);

      // if it is a modular template, assign the scenes and element to templateModularScenesList and templateModularElementsList

      if (!newScenes || newScenes.length === 0) return;

      if (!isModularTemplate) {
        state.activeSceneId = newScenes[0].id;
        state.projectColors.length = 0;
      }

      let allColorArr = [];

      let { scenesList } = state;
      let { elementsList } = state;

      if (isModularTemplate) {
        scenesList = state.templateModularScenesList;
        elementsList = state.templateModularElementsList;
      }
      scenesList.allIds.length = 0;
      scenesList.byId = {};
      elementsList.allIds.length = 0;
      elementsList.byId = {};

      // clear all sceneTimeline
      for (let i = 0; i < Object.keys(state.sceneTimeline); i++) {
        const sceneId = Object.keys(state.sceneTimeline)[i];
        Vue.delete(state.sceneTimeline, sceneId);
      }

      if (!isModularTemplate) {
        state.project.scenes.length = 0;
      }

      for (let i = 0; i < newScenes.length; i++) {
        const scene = newScenes[i];
        const sceneId = scene.id;

        // set the tweenmax timeline for each scene
        state.sceneTimeline[sceneId] = new TimelineLite({ paused: true });

        // for lite mode, make sure the scene duration is not more than max lite scene
        if (this.getters.isLiteMode && scene.duration > maxDuration.liteScene) {
          console.log('scene duration', scene.duration, maxDuration.liteScene);
          scene.duration = maxDuration.liteScene;
        }

        updateMissingSceneKey(scene);

        if (isModularTemplate) {
          if (canvasWidth) scene.width = canvasWidth;
          if (canvasHeight) scene.height = canvasHeight;
        } else {
          const backgroundColor = cloneDeep(scene.background);
          allColorArr.push(backgroundColor);
        }

        // when first received, elements will be an array of elements object
        // in vue, scene elements is changed to be array of element id
        // move the elements to be under elementsList
        const tempElementArray = [];
        // console.log('scene', scene.elements);
        if (scene.elements) {
          for (let j = 0; j < scene.elements.length; j++) {
            const element = scene.elements[j];
            const elementId = element.data.id;

            if (elementId) {
              // assign new id for new templates
              // TODO: chaging of id will be processed in backend, observe if bug still happen
              // if (assignNewId) {
              //   elementId = randomId();
              // }

              const newElement = cloneDeep(element);
              // if text element and content is null, skip this
              if (newElement.type === 'texts' && newElement.data.content === null) {
                continue;
              }
              tempElementArray.push(elementId);
              elementsList.allIds.push(elementId);

              // console.log('element', element.data.url)

              if (replaceWithMask && element.data.layerTag.hero) {
                convertElementToMask(newElement, maskItem);
              }

              if (isMobile() && j === scene.elements.length - 1) {
                // this is mobile background
                console.log('element', element.size.width);
                convertElementToFitCanvas(newElement, state.canvasSize);
                removeEmphasisAnimation(newElement);
              }

              updateMissingElementKey(newElement, this.getters.isLiteMode, rootGetters);

              Vue.set(state.elementsList.byId, elementId, newElement);

              if (!isModularTemplate && newElement.data.color) {
                allColorArr = allColorArr.concat(cloneDeep(newElement.data.color));
              }

              if (isMobile() && j === scene.elements.length - 1) {
                commit('setDefaultBackground', newElement);
              }
            }
          }
        }

        // console.log('scene is', scene)
        scene.elements = tempElementArray;
        Vue.set(scenesList.byId, sceneId, scene);

        scenesList.allIds.push(sceneId);

        if (!isModularTemplate) {
          state.project.scenes.push(sceneId);
        }
      }

      if (!isModularTemplate) {
        state.project.duration = state.project.scenes.reduce((totalDuration, sceneId) => state.scenesList.byId[sceneId].duration + totalDuration, 0);

        setTimeout(() => {
          // issue: sometimes adding designer template scene from canvas, will have this error = "TypeError: Cannot create property 'url' on boolean 'true'"
          // solution: usually after setTimeout selectedMusic already updated to not being boolean, but in case in future the time doesn't valid, with this condition, it will avoid error.
          if (typeof state.selectedMusic !== 'boolean') {
            // also need to update music duration
            state.selectedMusic.url = updateSelectedMusicDuration(
              state.selectedMusic,
              state.project.duration,
            );
          }
        }, 100);

        const { newArr, gradientArr } = checkForDuplicateArray(allColorArr);
        state.projectColors.solid = newArr;
        state.projectColors.gradient = gradientArr;
      }

      if (assignModularAsLastTemplate) {
        dispatch('setModularAsLastTemplate');
      }
    },
    setModularAsLastTemplate({ state }) {
      const copyModularScenes = cloneDeep(state.templateModularScenesList);
      const copyModularElements = cloneDeep(state.templateModularElementsList);

      Vue.set(state, 'lastSelectedTemplateScenesList', copyModularScenes);
      Vue.set(state, 'lastSelectedTemplateElementsList', copyModularElements);
    },
    setAllActiveElementsOpacity({ commit, state, dispatch }, item) {
      let { elements, opacity } = item;

      dispatch('canvasHistory/catchHistory', 'element', { root: true });

      if (typeof elements === 'undefined') elements = state.activeElementsIds;

      // update the elements
      for (let i = 0; i < elements.length; i++) {
        const update = {
          id: elements[i],
          opacity,
        };
        commit('updateCanvasElementOpacity', update);
      }
    },
    updateCanvasBackground({
      commit, rootState, state, dispatch,
    }, item) {
      dispatch('canvasHistory/catchHistory', 'scene', { root: true });
      commit('updateCanvasBackground', item);
    },
    updateCanvasElementFlip({ commit, dispatch, state }, item) {
      // console.log('updateCanvasBackground', item);

      dispatch('canvasHistory/catchHistory', 'element', { root: true });

      let { elements, direction } = item;
      if (typeof elements === 'undefined') elements = state.activeElementsIds;

      for (let i = 0; i < elements.length; i++) {
        const update = {
          id: elements[i],
          x: direction === 'x',
          y: direction === 'y',
        };
        console.log('updateCanvasElementFlip action', update);
        commit('updateCanvasElementFlip', update);
      }
    },
    updateCanvasSize({ state, commit, dispatch }, item) {
      dispatch('canvasHistory/catchCanvasSize', item, { root: true });
      commit('updateCanvasSize', item);
    },
    updateCanvasElementFilter({ commit, dispatch, state }, item) {
      dispatch('canvasHistory/catchHistory', 'element', { root: true });
      commit('updateCanvasElementFilter', item);
    },
    deleteElementFromCanvas({ commit, dispatch }, item) {
      dispatch('canvasHistory/catchCanvasElementState', ['scenesList', 'elementsList'], {
        root: true,
      });
      commit('deleteElementFromCanvas', item);
    },
    addTextElementToCanvas({ commit, dispatch, rootGetters }, item) {
      const element = JSON.parse(JSON.stringify(elementFormat));
      const textData = JSON.parse(JSON.stringify(textDataFormat));

      const defaultFont = rootGetters.getDefaultFont;
      const isDesigner = rootGetters['userData/isDesigner'];
      const currentSceneDuration = rootGetters['canvasElements/getCurrentSceneDuration'];

      element.data = { ...element.data, ...textData };

      /**
       * item can be string:
       * item = 'heading'
       * or object:
       * item = {
       *   fontFamily: '',
       *   fontSize: '',
       *   fontWeight: '',
       *   fontColor: '',
       *   type: '', // 'heading' || 'subheading'
       * }
       * or empty
       */
      let type = '';
      if (item && item.type) type = item.type;
      else if (typeof item === 'string') type = item;
      else type = 'body';

      let fontObject = {};
      if (!item || item === 'string') {
        if (type === 'heading') {
          fontObject = defaultFont.h1;
        } else if (type === 'subheading') {
          fontObject = defaultFont.h2;
        } else {
          fontObject = defaultFont.body;
        }
      } else {
        fontObject = item;
      }

      element.data.fontFamily = fontObject.fontFamily;
      element.data.fontSize = fontObject.fontSize;
      element.data.color = [{ color: fontObject.fontColor || '#000000' }];

      // team brand font additional stylings required
      const isOnBrandTab = rootGetters['assetsSidebar/getActiveGroup'] === groups.BRAND; // only team project that has brand tab + if user add text element from brand tab = its a team brand font
      const isTeamBrandFontText = isOnBrandTab && (type === 'heading' || type === 'subheading');
      if (isTeamBrandFontText) element.data.textAlign = fontObject.align;
      if (isTeamBrandFontText) element.data.fontWeight = fontObject.fontWeight;
      if (isTeamBrandFontText) element.data.textItalic = fontObject.fontStyle === 'italic';
      if (isTeamBrandFontText) element.data.letterSpacing = fontObject.letterSpacing;
      if (isTeamBrandFontText) element.data.lineHeight = fontObject.lineHeight;

      if (item === 'heading' || item === 'heading-mobile') {
        element.data.fontSize = item === 'heading' ? 120 : 100;
        element.data.fontFamily = defaultFont.h1.fontFamily;
        if (defaultFont.h1.fontWeight && defaultFont.h1.fontWeight > 400) element.data.fontWeight = 700;
        if (defaultFont.h1.fontStyle && defaultFont.h1.fontStyle === 'italic') element.data.textItalic = true;

        if (isDesigner) {
          element.data.layerTag = {
            h1: true,
          };
        }
      } else if (item === 'subheading') {
        element.data.fontSize = 85;
        element.data.fontFamily = defaultFont.h2.fontFamily;
        if (defaultFont.h2.fontWeight && defaultFont.h2.fontWeight > 400) element.data.fontWeight = 700;
        if (defaultFont.h2.fontStyle && defaultFont.h2.fontStyle === 'italic') element.data.textItalic = true;

        if (isDesigner) {
          element.data.layerTag = {
            h2: true,
          };
        }
      }

      element.data.id = randomId();
      element.type = 'texts';
      element.time_out = currentSceneDuration;
      element.timeline_settings.animationDuration = element.time_out - element.time_in;
      element.timeline_settings.animateOutStart = element.time_out - element.timeline_settings.animateOutDuration;

      commit('emptyActiveElements');
      setTimeout(() => {
        dispatch('addElementToCanvas', element);
      }, 100);
    },
    addElementToCanvas({ commit, dispatch }, item) {
      dispatch('canvasHistory/catchCanvasElementState', ['scenesList', 'elementsList'], {
        root: true,
      });
      commit('addElementToCanvas', item);
    },
    addTextTemplateToCanvas({ commit, dispatch, state }, item) {
      dispatch('canvasHistory/catchCanvasElementState', ['scenesList', 'elementsList'], {
        root: true,
      });
      commit('addTextTemplateToCanvas', item);
    },
    updateCanvasElementContent({ commit, dispatch }, item) {
      dispatch('canvasHistory/catchCanvasElementState', ['elementsList'], { root: true });
      commit('updateCanvasElementContent', item);
    },
    copyActiveElements({ commit }, elements) {
      commit('updateCopiedElements', elements);
    },
    pasteActiveElements({ commit, state, dispatch }) {
      dispatch('canvasHistory/catchCanvasElementState', ['scenesList', 'elementsList'], {
        root: true,
      });
      const newElements = cloneDeep(state.copiedElements);
      commit('addCopiedElements', newElements);
    },
    addElementsToGroup({ commit, dispatch }, activeElements) {
      dispatch('canvasHistory/catchHistory', 'element', { root: true });
      commit('addCanvasElementsGroup', activeElements);
      commit('emptyActiveElements');
      activeElements.forEach((element) => {
        if (!element.lock) {
          commit('toggleActiveElements', element.data.id);
        }
      });
    },
    removeElementsFromGroup({ commit, dispatch, getters }, { activeElements, groupId }) {
      dispatch('canvasHistory/catchHistory', 'element', { root: true });

      const groupElements = getters.getCanvasElements.filter(el => el.data.isGroup && el.data.groupId == groupId);

      commit('removeCanvasElementsGroup', { activeElements, groupElements });
    },
    getStoryBoardPreview({ commit, state, dispatch }, item) {
      // get preview by specific scene
      const { id, sceneId, isTemplate } = item;

      // console.log('getStoryBoardPrevew', item)
      // createStoryBoardPreview
      const params = {
        id,
        scene_id: sceneId,
        is_template: isTemplate,
      };

      // need to clear the preview first
      if (
        typeof state.storyBoardPreviews[sceneId] !== 'undefined'
        && state.storyBoardPreviews[sceneId] !== ''
      ) { return; }

      // if(state.storyBoardApiRequestIds.includes(sceneId)) return; // currently requesting to api

      commit('addStoryBoardApiRequestIds', sceneId);
      Api.getStoryBoardPreview(params)
        .then((response) => {
          // if(response.data.includes('data:image/png;base64') || response.data.includes('data:image/jpeg;base64')){
          //   // state.storyBoardPreviews[sceneId] = response.data
          //   commit('setStoryBoardPreviews',{
          //     sceneId,
          //     base64: response.data,
          //   });
          // }
        })
        .catch((error) => {
          // console.log('getStoryBoardPreview error',error.response.data.default_preview);

          if (typeof error.response.data.default_preview !== 'undefined') {
            commit('setStoryBoardPreviews', {
              sceneId,
              base64: error.response.data.default_preview,
            });
          } else {
            setTimeout(() => {
              dispatch('getStoryBoardPreview', item);
            }, 1500);
          }
        })
        .then(() => {
          // commit('deleteStoryBoardApiRequestIds', sceneId);
        });
    },
    createStoryBoardPreviews({
      commit, state, dispatch, getters, rootGetters,
    }, item) {

      // use storyboard mixins instead
      // storyboard-mixins.js@createStoryBoardPreviews

      // const isReset = item && item.isReset ? item.isReset : false;

      // if (isReset) {
      //   Vue.set(state, 'storyBoardPreviews', {});
      // }
      // // get all previews by all scenes;
      // const isTemplate = rootGetters['getIsTemplate'];
      // const id = getters['getProjectId'];
      // const scenesList = state.scenesList.allIds;

      // for (let i = 0; i < scenesList.length; i++) {
      //   if (state.activeSceneId !== scenesList[i]) {
      //     setTimeout(() => {
      //       dispatch('getStoryBoardPreview', {
      //         id,
      //         sceneId: scenesList[i],
      //         isTemplate,
      //       });
      //     }, 2000 * i);
      //   }
      // }
    },
    updateCanvasElementFontFamily({ commit, rootGetters }, item) {
      const passedItem = item;
      const selectedFont = rootGetters.getActiveOffeoFonts.find((font) => font.fontFamily === item.fontFamily); // eslint-disable-line
      passedItem.selectedFont = selectedFont;

      commit('updateCanvasElementFontFamily', passedItem);
    },
    clearExpiredStoryBoardPreviews() {
      const previewsKeys = Object.keys(localStorage);

      if (previewsKeys.length) {
        previewsKeys.forEach((previewKey) => {
          if (previewKey.includes(storyBoardPreviewsKey)) {
            const item = JSON.parse(localStorage.getItem(previewKey));
            const expiry = item.expiry || null;
            const now = new Date();
            // compare the expiry time of the item with the current time
            if (now.getTime() > expiry) {
              // If the item is expired, delete the item from storage
              // and return null
              // console.log('previewKey is expired', expiry, previewKey);
              localStorage.removeItem(previewKey);
            }
            // console.log('previewKey is not expired', expiry, previewKey);
          }
        });
      }
    },
    resetStoryBoardPreviews(state) {
      Vue.set(state, 'storyBoardPreviews', {});
    },
  },
};

export default canvasElements;
