import Vue from 'vue';

const brand = {
  namespaced: true,
  state: {
    brandedStyles: [],
    fontPairings: [],
    fontPairingCategories: [],
    brandTracking: {},
    userBrand: {
      brandMeta: [],
      brandName: '',
      brandedStyleId: -1,
      colors: {},
      fonts: [],
      id: 0,
      logo: [],
    },
  },
  getters: {
    getBrandedStyles: state => state.brandedStyles,
    getFontPairings: state => state.fontPairings,
    getFontPairingCategories: state => state.fontPairingCategories,
    getUserBrand: state => state.userBrand,
    hasSetupBrand: state => state.userBrand.brandedStyleId !== null && state.userBrand.brandedStyleId > -1,
    getBrandTrackingId: state => (typeof state.brandTracking.id !== 'undefined' ? state.brandTracking.id : ''),
  },
  mutations: {
    setBrandedStyles(state, item) {
      state.brandedStyles = item;
    },
    setFontPairings(state, item) {
      Vue.set(state, 'fontPairings', item);
    },
    setFontPairingCategories(state, item) {
      state.fontPairingCategories = item;
    },
    setUserBrand(state, item) {
      Vue.set(state.userBrand, 'brandMeta', item.brand_meta);
      state.userBrand.brandName = item.brandName;
      state.userBrand.brandedStyleId = item.branded_style_id;
      Vue.set(state.userBrand, 'colors', item.colors);
      Vue.set(state.userBrand, 'fonts', item.fonts);
      state.userBrand.id = item.id;
      Vue.set(state.userBrand, 'logo', item.logo);
    },
    setBrandTracking(state, item) {
      state.brandTracking = item;
    },
  },
};

export default brand;
