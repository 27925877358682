import axios from 'axios';

export default {
  get(params = {}, version = 'v1') {
    return axios.get(`/${version}/collections/user-favorites`, { params });
  },
  addFavourite(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/collections/user-favorites?show_details=true`,
      data: params,
    });
  },
  removeFavourite(favId, version = 'v1') {
    return axios({
      method: 'delete',
      url: `/${version}/collections/user-favorites/${favId}`,
    });
  },
};
