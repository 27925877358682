<template>
  <div class="base-colorpicker" :class="{ 'is-dark': isDark }">
    <Photoshop v-model="baseColor">
    </Photoshop>
  </div>
</template>

<script>
import { Photoshop } from 'vue-color';

export default {
  name: 'BaseColorpicker',
  components: {
    Photoshop,
  },
  data() {
    return {
      activeColor: '#ffffff',
    };
  },
  props: {
    /**
     * Currently only accept 3 or 6 letter hex code
     */
    color: { type: String, default: '#ffffff' },
    isDark: { type: Boolean, default: false },
  },
  computed: {
    baseColor: {
      get() {
        return this.activeColor;
      },
      set(value) {
        this.activeColor = value.hex;
        /**
         * This will emit 'set' with this value
         * ```{
         *   a: 1,
         *   hex: '#000000',
         *   hex8: '#000000FF',
         *   hsl: {
         *     a: 1,
         *     h: 0,
         *     l: 0,
         *     s: 0
         *   },
         *   hsv: {
         *     a: 1,
         *     h: 0,
         *     s: 0,
         *     v: 0
         *   },
         *   oldHue: 0,
         *   rgba: {
         *     a: 1,
         *     b: 0,
         *     g: 0,
         *     r: 0
         *   },
         *   source: 'hsva'
         * }```
         *
         * @event set
         */
        this.$emit('set', value);
      },
    },
  },
  watch: {
    color: {
      handler(val) {
        this.activeColor = val;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.base-colorpicker {
  width: 250px;
  padding: 10px;
  border: 1px solid $lightGrey500;
  position: relative;

  .vc-photoshop {
    background: transparent;
    box-shadow: none;
    width: 100%;
    font-family: $baseFont;
  }

  .vc-ps-head {
    display: none;
  }

  .vc-ps-body {
    padding: 0;
    flex-wrap: wrap;
  }

  .vc-ps-saturation-wrap {
    width: calc(100% - 46px);
    height: 180px;
    border: 0;

    .vc-saturation-circle {
      height: 6px;
      width: 6px;
    }
  }

  .vc-ps-hue-wrap {
    height: 180px;
    width: 35px;
    margin-left: 10px;
    border: 0;
  }

  .vc-hue-picker {
    width: 37px;
    height: 6px;
    border: 2px solid #fff;
    background: transparent;
    transform: translateX(-3px);
  }

  .vc-ps-controls {
    width: 100%;
    margin: 20px 0 5px;
  }

  .vc-editable-input,
  .vc-ps-ac-btn,
  .vc-ps-previews {
    display: none;
  }

  .vc-ps-actions {
    margin: 0;
  }

  .vc-ps-fields {
    width: auto;
    height: auto;
    padding-bottom: 0;
  }

  .vc-ps-fields__hex {
    display: block;

    &::before {
      content: 'Colour Code';
      display: block;
      font-weight: 600;
      font-size: 0.875rem;
      color: $darkGrey;
      position: absolute;
      top: -20px;
    }

    .vc-input__label {
      font-size: 0.875rem;
      line-height: 31px;
      height: auto;
      line-height: 1;
      padding: 8px 0;
      left: 10px;
      display: flex;
      height: 100%;
      align-items: center;
    }

    .vc-input__input {
      border: 0;
      color: $darkGrey;
      display: block;
      font-size: 0.875rem!important;
      background: transparent;
      flex: 1;
      line-height: 1;
      margin: 0;
      transition: 0.3s;
      padding: 8px 10px 8px 20px;
      border-radius: $componentBorderRadius;
      border: 1px solid $defaultBorder;
      box-shadow: none;
      height: auto;
      width: 100%;
    }
  }

  .vc-hue-pointer {
    transform: translateY(-50%);
  }

  .vc-hue-pointer {
    transform: translateY(-50%);
  }

  &.is-dark {
    .vc-ps-fields__hex {
      display: block;

      &::before {
        color: $textBlack;
      }

      .vc-input__label {
        color: $darkGrey200;
      }

      .vc-input__input {
        color: $darkGrey200;
        background: $darkGrey800;
        border-radius: $componentBorderRadius;
        border: 1px solid $darkGrey800;

        &:focus {
          border-color: $darkGrey200;
        }
      }
    }

  }
}
</style>
