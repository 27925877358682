<template lang="pug">
.anglepicker
  .anglepicker__picker(
    ref="anglepicker"
    :style="styleObject"
    @mouseup="handleMouseUp"
    @mousedown="handleMouseDown"
    @mouseenter="trackMouseUp"
  )
    .picker
  BaseInputField.anglepicker__value(
    v-if="!noInput"
    v-model="currentAngleString"
    @change="setCurrentAngle"
    @focus.native="$event.target.select()"
    suffix="°"
    :isNumber="true"
  )
</template>

<script>
import Vector from '@minogin/vector';

export default {
  name: 'BaseAnglepicker',
  data() {
    return {
      midPosition: {
        x: 20,
        y: 20,
      },
      mousePosition: {
        x: 40,
        y: 20,
      },
      isDragging: false,
      currentAngle: 0,
    };
  },
  props: {
    angle: { type: Number, default: 0 },
    noInput: { type: Boolean, default: false },
  },
  computed: {
    styleObject() {
      const styleObject = {};

      styleObject.transform = `rotate(${this.currentAngle}deg)`;

      return styleObject;
    },
    currentAngleString: {
      get() {
        return this.currentAngle.toString();
      },
      set(val) {
        // do nothing. only update on change
      },
    },
  },
  methods: {
    handleMouseDown(e) {
      console.log('mouse is down', e.pageX, e.pageY, e);
      this.isDragging = true;
    },
    handleMouseUp(e) {
      // console.log('mouse up')
      if (this.isDragging) {
        this.isDragging = false;
        this.mousePosition.x = e.pageX;
        this.mousePosition.y = e.pageY;
      }
    },
    handleMouseMove(e) {
      // console.log('mouse is moving', e.pageX, e.pageY)
      this.mousePosition.x = e.pageX;
      this.mousePosition.y = e.pageY;
    },
    trackMouseUp() {
      // when it first load add event listener
      // console.log('+ register mouse up')
      document.body.addEventListener('mouseup', this.handleMouseUp, true);
    },
    setCurrentAngle(inputDegree) {
      let degree = inputDegree;

      if (typeof degree === 'undefined') {
        const x1 = this.midPosition.x;
        const y1 = this.midPosition.y;
        const x2 = this.mousePosition.x;
        const y2 = this.mousePosition.y;

        const angle = Math.atan2(y2 - y1, x2 - x1);
        degree = Vector.deg(angle);
      }

      this.currentAngle = parseInt(degree);
    },
  },
  mounted() {
    setTimeout(() => {
      const boundingBox = this.$refs.anglepicker.getBoundingClientRect();
      this.midPosition.x = boundingBox.x + boundingBox.width / 2;
      this.midPosition.y = boundingBox.y + boundingBox.height / 2;
      // console.log('this', this.midPosition.x, this.midPosition.y)

      // relative pos is the x and y between midPosition and mouseposition
      const relativePos = {
        x: 20,
        y: 0,
      };

      const midVector = new Vector(relativePos.x, relativePos.y);
      const radian = Vector.rad(this.angle);
      const mouseVector = midVector.rotate(radian);
      // console.log('mouseVector', radian, this.angle, mouseVector)

      relativePos.x = mouseVector.x;
      relativePos.y = mouseVector.y;

      this.mousePosition.x = this.midPosition.x + relativePos.x;
      this.mousePosition.y = this.midPosition.y + relativePos.y;
    }, 600); // timeout for the tab animation
  },
  watch: {
    isDragging(val) {
      // console.log('isDragging', val);
      if (val) {
        document.body.addEventListener('mousemove', this.handleMouseMove, true);
      } else {
        // console.log('- remove mouse up');
        document.body.removeEventListener('mouseup', this.handleMouseUp, true);
        document.body.removeEventListener('mousemove', this.handleMouseMove, true);
      }
    },
    mousePosition: {
      handler() {
        this.setCurrentAngle();
      },
      deep: true,
    },
    currentAngle(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss">
.anglepicker {
  .anglepicker__value {
    width: calc(100% - 61px);
    margin-left: 20px;
  }

  .anglepicker__value,
  .anglepicker__picker {
    display: inline-block;
    vertical-align: middle;
  }
}

.anglepicker__picker {
  width: 40px;
  height: 40px;
  background: $blue;
  border-radius: 50%;
  position: relative;
  transform: rotate(0deg);
  display: inline-block;

  .picker {
    width: 10px;
    height: 10px;
    background: $lightBg;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);
  }
}
</style>
