<template lang="pug">
.dropdown(
  :class="{ 'is-active': isActive }"
  v-click-outside="closeDropdown"
  :style="dropdownStyle"
)
  button.dropdown__selected(@click="isActive = !isActive")
    span.name {{ selectedOptionName }}
    i.icon.icon-down-arrow
  ul.dropdown__list(refs="dropdownOptions")
    li.dropdown__item(
      v-for="(option, index) in options"
      :key="option.value"
      :class="{'is-selected': option.value === value, 'is-focused': focusedIndex === index }"
      v-html="option.name"
      @click="setValue(option.value)"
      @mouseenter="resetFocusedIndex"
      @mouseleave="resetFocusedIndex"
    )
  GlobalEvents(
    :filter="(event, handler, eventName) => event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && event.target.getAttribute('contenteditable') !== true"
    @keydown.prevent.esc="closeDropdown")
  GlobalEvents(
    v-if="isActive"
    @keydown.prevent.down="setFocusedButton('down')"
    @keydown.prevent.up="setFocusedButton('up')"
    @keydown.prevent.enter="setValue()"
  )
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  name: 'BaseDropdown',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      tempValue: '',
      isActive: false,
      focusedIndex: -1,
    };
  },
  props: {
    /**
     * The options need to have value and name
     */
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    /**
     * Max width need to be with px or %
     */
    maxWidth: {
      type: String,
      default: '100%',
    },
  },
  computed: {
    selectedOptionName() {
      const selectedOption = this.options.find(opt => opt.value === this.tempValue);
      if (selectedOption && selectedOption.name) return selectedOption.name;
      return this.placeholder;
    },
    dropdownStyle() {
      const dropdownStyle = {};

      dropdownStyle.maxWidth = this.maxWidth;

      return dropdownStyle;
    },
  },
  beforeMount() {
    // if there is no placeholder,
    // use value or first options
    if (!this.placeholder) {
      if (this.value) this.tempValue = this.value;
      else this.tempValue = this.options[0].value;
    }
  },
  watch: {
    value(val) {
      this.tempValue = val;
    },
  },
  methods: {
    closeDropdown() {
      this.isActive = false;
      this.resetFocusedIndex();
    },
    setValue(value) {
      // if nothing is passed, use the one from focusedIndex
      const setValue = value || this.options[this.focusedIndex].value;
      if (!setValue) {
        console.error('Missing value');
        return;
      }
      this.tempValue = setValue;
      this.$emit('change', setValue);
      this.closeDropdown();
    },
    resetFocusedIndex() {
      this.focusedIndex = -1;
    },
    setFocusedButton(type) {
      if (type === 'down') this.focusedIndex += 1;
      if (type === 'up') this.focusedIndex -= 1;

      if (this.focusedIndex >= this.options.length) {
        this.focusedIndex = this.options.length - 1;
      }

      if (this.focusedIndex < 0) {
        this.focusedIndex = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  position: relative;
  font-size: 0.875rem;
  color: $darkGrey;
}

.dropdown__selected {
  width: 100%;
  background: $light;
  border-radius: $componentBorderRadius;
  border: 1px solid $borderGrey;
  cursor: pointer;
  position: relative;
  padding: 10px;
  text-align: left;
  max-height: 40px;
  outline: 0;

  &:focus,
  .dropdown.is-active & {
    border-color: $blue;
  }

  .dropdown.is-active & {
    .icon {
      transform: rotate(180deg);
    }
  }

  .icon {
    position: absolute;
    right: 5px;
    top: 10px;
  }
}

.dropdown__list {
  margin: 0;
  padding: 0;
  list-style: none;
  background: $light;
  box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  border-radius: 0 0 $componentBorderRadius $componentBorderRadius;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;

  .dropdown.is-active & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.dropdown__item {
  margin: 0;
  background: transparent;
  width: 100%;
  padding: 10px;
  text-align: left;
  border: 0;
  border-bottom: 1px solid $borderGrey;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  &:hover,
  &.is-focused,
  &.is-selected {
    background: $lightGrey;
  }

  &.is-focused {
    outline-color: #4d90fe;
    outline-offset: -2px;
    outline-style: auto;
    outline-width: 5px;
  }
}
</style>
