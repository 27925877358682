import axios from 'axios';
import { serialize } from '@/assets/scripts/utilities'; // eslint-disable-line

export default {
  create(version = 'v1') {
    return axios.post(`/${version}/templates`);
  },

  get(templateId, isDesigner = true, params = {}, version = 'v1') {
    const endpoint = isDesigner ? 'templates' : 'collections/designs';
    return axios.get(`/${version}/${endpoint}/${templateId}`, { params });
  },

  update(templateId, params = {}, version = 'v1') {
    // Let BE know that saving is done from canvas
    const updateParams = params;
    updateParams.from_canvas = true;

    return axios({
      method: 'put',
      url: `/${version}/templates/${templateId}`,
      data: updateParams,
    });
  },

  addProjectScene(templateId, params, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/templates/${templateId}/scenes`,
      data: params,
    });
  },

  deleteProjectScene(templateId, sceneId, version = 'v1') {
    return axios({
      method: 'delete',
      url: `/${version}/templates/${templateId}/scenes/${sceneId}`,
    });
  },

  updateProjectScene(templateId, sceneId, params = {}, version = 'v1') {
    return axios({
      method: 'put',
      url: `/${version}/templates/${templateId}/scenes/${sceneId}`,
      data: params,
    });
  },

  getLayoutSettings(params = {}, version = 'v1') {
    return axios({
      method: 'get',
      url: `/${version}/layout-settings${serialize(params)}`,
    });
  },
};
