<template>
  <div :style="style" ref="lavContainer"></div>
</template>

<script>
import lottie from 'lottie-web';
import { mapGetters, mapMutations } from 'vuex';
import {
  randomId,
  fromUnitVector,
  rgbToHex,
  rgbToHSL,
  toUnitVector,
  hexToRgb,
  hslToRGB,
  getRamp,
} from '@/assets/scripts/utilities';
import cloneDeep from 'lodash.clonedeep';

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    height: Number,
    width: Number,
    isColorChangeable: { type: Boolean, default: false },
    isCanvas: { type: Boolean, default: false },
    isSwap: { type: Boolean, default: false },
    index: { type: Number, default: 0 },
    id: { type: String, default: '' },
    noOverflow: { type: Boolean, default: false },
  },

  data() {
    return {
      style: {
        width: this.width ? `${this.width}px` : '100%',
        height: this.height ? `${this.height}px` : '100%',
        overflow: this.noOverflow ? '' : 'hidden',
        margin: '0 auto',
      },
      anim: null,
      color: null,
      fullRows: null,
      animationData: this.options.animationData,
      isNew: true,
      lottieName: '',
    };
  },

  mounted() {
    // this name is needed to destroy lottie
    this.lottieName = `lottie-${randomId()}`;
    this.setupLottie(true);
  },

  computed: {
    ...mapGetters('canvasElements', [
      'getCanvasElementById',
    ]),
    elementColor() {
      let colorArray = [];
      // console.log(this.index, this.getCanvasElements)
      if (this.isColorChangeable && this.id && this.getCanvasElementById(this.id).data.color) {
        colorArray = this.getCanvasElementById(this.id).data.color;
      }

      return colorArray;
    },
  },

  methods: {
    ...mapMutations('canvasElements', [
      'updateCanvasElementColor',
    ]),
    setupLottie(isNew) {
      // console.log('setupLottie', this.isSwap, this.options.path);
      this.anim = lottie.loadAnimation({
        container: this.$refs.lavContainer,
        renderer: 'svg',
        loop: this.options.loop !== false,
        autoplay: this.options.autoplay !== false,
        animationData: this.animationData || null,
        rendererSettings: this.options.rendererSettings,
        path: this.options.path || null,
        name: this.lottieName,
      });

      this.anim.addEventListener('DOMLoaded', () => {
        this.animationData = this.anim.animationData;
        if (this.isColorChangeable && isNew) { // only if it is canvas then set color array
          this.setColorArray(this.animationData);
        }
        this.$emit('animCreated', this.anim);
      });
    },
    getColor(tree, callback, activeId = '') {
      if (!tree.length) return;
      tree.forEach((item) => {
        const layer = item;
        let currentId = activeId;
        let replace = false;
        const strokeWidth = 0;
        let overwriteColor = false;

        if (currentId === '') {
          // eslint-disable-next-line
          if (layer.refId && layer.hasOwnProperty('ef')) {
            currentId = layer.refId;
            replace = true;
          }
        }
        // console.log('layer', layer, currentId)

        if (layer.ef) { // if there is ef, ignore the shapes color
          overwriteColor = true;
        }

        // console.log('getColor overwriteColor', overwriteColor)

        if (!overwriteColor) { // if there is stroke or effect not overwriting
          if (layer.shapes) {
            layer.shapes.forEach((shape) => {
              if (shape.it) {
                shape.it.forEach((prop) => {
                  const meta = this.shapeArrayToMeta(prop, overwriteColor, strokeWidth, currentId, replace);
                  if (meta) {
                    if (callback) callback(meta);
                  }
                });
              } else {
                const meta = this.shapeArrayToMeta(shape, overwriteColor, strokeWidth, currentId, replace);
                if (meta) {
                  if (callback) callback(meta);
                }
              }
            });
          }
        }

        if (layer.ef) {
          layer.ef.forEach((effect) => {
            if ([21].includes(effect.ty)) {
              const name = effect.nm; // get the name instead of 'Color'
              if (effect.nm.toLowerCase().startsWith('#colour')) {
                // console.log('effectArrayToMeta', effect.nm)
                effect.ef.forEach((effectchild) => {
                  const meta = this.effectArrayToMeta(effectchild, name, strokeWidth, currentId, replace);
                  if (meta) {
                    if (callback) callback(meta);
                  }
                });
              }
            }
          });
        }
      });
    },
    setColorArray(data) {
      // console.log('===setColorArray');
      // const groupToValues = [];
      const sameNmRows = [];
      const fullRows = [];
      // const noRender = false;

      if (data && data.assets && data.assets.length) {
        // console.log('has assets');
        data.assets.forEach((item) => {
          const asset = item;
          const assetId = asset.id;

          this.getColor(asset.layers, (color) => {
            if (color) {
              fullRows.push(color);
            }
          }, assetId);
        });
      }

      if (data && data.layers && data.layers.length) {
        // console.log('has layers');
        this.getColor(data.layers, (color) => {
          if (color) {
            fullRows.push(color);
          }
        });
      }

      fullRows.forEach((item) => {
        if (fullRows.length === 0) {
          // eslint-disable-next-line
          item.remove = false;
        } else if (item.replace) {
          fullRows.forEach((el) => {
            if (item.id === el.id && el.color) {
              // eslint-disable-next-line
              el.color = item.color;
              // eslint-disable-next-line
              el.remove = true;
            }
          });

          // eslint-disable-next-line
          item.remove = false;
        } else {
          // eslint-disable-next-line
          item.remove = false;
        }
      });
      // console.group();
      // console.log('clone before', cloneDeep(fullRows))

      // groupToValues = fullRows.reduce((obj, item, index) => {
      //   if (!fullRows[index].remove) {
      //     obj[item.nm] = obj[item.nm] || [];
      //     if (item.color) {
      //       obj[item.nm] = item.color;
      //     } else if (item.colorPalette) {
      //       obj[item.nm] = item.colorPalette;
      //     } else {
      //       obj[item.nm] = [item.color1, item.color2];
      //     }
      //     return obj;
      //   }

      //   return [];
      // }, {});

      // console.log('groupToValues', cloneDeep(groupToValues))
      // console.groupEnd();

      fullRows.forEach((item) => {
        const row = item;
        const newRow = {
          nm: row.nm,
        };

        if (row.color) newRow.color = row.color;
        // use new format
        if (row.color1 && row.color2) {
          newRow.points = [
            {
              color: row.color1,
              percentage: 0,
            },
            {
              color: row.color2,
              percentage: 100,
            },
          ];
        }

        sameNmRows.push(newRow);
      });

      // sameNmRows = Object.entries(groupToValues).map(([nm, color]) => ({
      //   nm,
      //   color,
      // }));

      // TODO: find out what's noRender for
      // if (!noRender) {
      //   for (let i = 0; i < sameNmRows.length; i += 1) {
      //     let color = null;
      //     let color1 = null;
      //     let color2 = null;
      //     let gradient = false;
      //     let id = sameNmRows[i].nm.replace('#', '');

      //     for (let j = 0; j < Object.keys(sameNmRows).length; j += 1) {
      //       const propName = sameNmRows[i];
      //       const propValue = sameNmRows[i][propName];
      //       if (typeof propValue === 'object') {
      //         gradient = true;
      //         color1 = propValue[0];
      //         color2 = propValue[1];
      //       }
      //     }

      //     if (typeof sameNmRows[i].color !== 'object') {
      //       color = sameNmRows[i].color;
      //     }
      //   }
      // }

      this.fullRows = cloneDeep(fullRows);

      // if (this.isCanvas) console.log('!! COLOR UPDATED !!', this.id, sameNmRows, this.elementColor.length);
      if (this.elementColor.length === 0) {
        if (this.isCanvas) {
          this.updateCanvasElementColor({
            color: sameNmRows,
            id: this.id,
            isNew: this.isNew,
          });
        }
      } else {
        // some old project may not have nm in the color
        // updateColor won't add the nm
        // nm is needed for the color to appear on the right section
        if (this.isCanvas) {
          const elementColor = cloneDeep(this.elementColor);
          elementColor.forEach((item, index) => {
            // in multigradient, this will be points
            const color = item;
            if (!color.nm) {
              color.nm = sameNmRows[index].nm;
            }
          });
          // remove duplicate of nm

          this.updateCanvasElementColor({
            color: elementColor,
            id: this.id,
          });
        }
        this.updateColor();
      }

      if (this.isCanvas) {
        this.$root.$emit('canvas-element-color-updated');
      }
      // console.log('fullRows', fullRows, groupToValues, sameNmRows)
    },
    shapeArrayToMeta(array, overwriteColor, strokeWidth, currentId, replace) {
      if (['fl', 'st'].includes(array.ty)) {
        if (array.ty === 'st') {
          // eslint-disable-next-line
          strokeWidth = array.w.k;
        }
        if (!overwriteColor && array.nm.toLowerCase().startsWith('#colour')) {
          const color = array.c.k;

          // eslint-disable-next-line
          let [r, g, b, a] = color;

          r = fromUnitVector(r);
          g = fromUnitVector(g);
          b = fromUnitVector(b);

          const meta = {
            nm: array.nm,
            id: currentId,
            strokeWidth,
            color: rgbToHex(r, g, b),
            replace,
          };

          // console.log('shapeArrayToMeta', array.ty, array.nm, color);

          return meta;
        }
      } else if (['gf'].includes(array.ty)) {
        if (!overwriteColor) {
          const color = array.g.k.k;

          if (color.length !== 8 && color.length !== 12) return false;

          let [r1, g1, b1, r2, g2, b2] = color;

          // if (color.length === 12) {
          //   const [mid, rauto, gauto, bauto] = color;
          // }

          r1 = fromUnitVector(r1);
          g1 = fromUnitVector(g1);
          b1 = fromUnitVector(b1);
          r2 = fromUnitVector(r2);
          g2 = fromUnitVector(g2);
          b2 = fromUnitVector(b2);

          let meta = false;

          if (array.nm.toLowerCase().startsWith('#gradient')) {
            meta = {
              nm: array.nm,
              id: currentId,
              strokeWidth,
              color1: rgbToHex(r1, g1, b1),
              color2: rgbToHex(r2, g2, b2),
              replace,
            };
          } else if (array.nm.toLowerCase().startsWith('#hue')) {
            const hsl1 = rgbToHSL(r1, g1, b1);
            const hsl2 = rgbToHSL(r2, g2, b2);

            let newColor = rgbToHex(r1, g1, b1);

            if (hsl1[1] < hsl2[1]) { // get whichever has higher saturation
              newColor = rgbToHex(r2, g2, b2);
            }

            meta = {
              nm: array.nm,
              id: currentId,
              strokeWidth,
              colorPalette: newColor,
              replace,
            };
          }

          return meta;
        }
      }

      return false;
    },
    effectArrayToMeta(array, name, strokeWidth, currentId, replace) {
      if ([2].includes(array.ty)) {
        const color = array.v.k;

        let [r, g, b] = color;

        r = fromUnitVector(r);
        g = fromUnitVector(g);
        b = fromUnitVector(b);

        const meta = {
          nm: name,
          id: currentId,
          strokeWidth,
          color: rgbToHex(r, g, b),
          replace,
        };

        return meta;
      }

      return false;
    },
    updateColor() {
      const data = this.animationData;
      // console.log('============updateColor ============');
      // console.log('this.fullRows', this.elementColor.length, this.fullRows);
      if (!this.fullRows) return;
      const { elementColor } = this;

      // console.log('elementColor', cloneDeep(elementColor))

      elementColor.forEach((item) => {
        // new version will have points instead of color1, color2
        const color = item;
        const { nm } = color;

        // console.log('elementColor forEach', nm, color)
        // console.table({
        //   elementColor,
        //   nm,
        //   color,
        // });

        // if (color.color) {
        this.fullRows.forEach((el) => {
          if (el.nm === color.nm) {
            // eslint-disable-next-line
            if (color.color) el.color = color.color;
            // can keep this color1 and color2
            // the points is done in changeColor()
            if (color.color1) {
              // eslint-disable-next-line
              el.color1 = color.color1;
              // eslint-disable-next-line
              el.color2 = color.color2;
            }
          }
        });

        if (data.assets && data.assets.length) {
          // console.log('data.assets', data.assets);
          data.assets.forEach((el) => {
            const asset = el;
            this.changeColor(asset.layers, nm, color);
          });
        }

        // console.log('data.layers', data.layers);
        if (data.layers && data.layers.length) {
          this.changeColor(data.layers, nm, color);
        }
      });
      // }

      if (this.$refs.lavContainer.childNodes.length) {
        this.$refs.lavContainer.childNodes[0].remove(0);
      }
      this.setupLottie();
    },

    changeColor(tree, nm, newColor) {
      if (!newColor) return;
      if (!tree.length) return;
      tree.forEach((item) => {
        const layer = item;
        // sometimes newColor is an array for gradient

        // in multi gradient, there will be points instead of color1, color2
        let { color1, color2 } = newColor;
        let overwriteColor = false;

        if (newColor.points) {
          // if new multi format gradient
          color1 = newColor.points[0].color;
          color2 = newColor.points[1].color;
        } else if (newColor.color) {
          // if solid
          color1 = newColor.color;
          color2 = newColor.color;
        } else if (typeof newColor === 'string') {
          // new format may just be newColor = '#fff'
          color1 = newColor;
          color2 = newColor;
        }

        // console.log('color1', color1, cloneDeep(newColor));
        const { r, g, b } = hexToRgb(color1);

        if (layer.ef) { // if there is ef, ignore the shapes color
          overwriteColor = true;
        }
        // console.log('changeColor layer', overwriteColor, nm);

        if (!overwriteColor) { // if there is stroke or effect not overwriting
          if (layer.shapes) {
            layer.shapes.forEach((el) => {
              const shape = el;
              if (shape.it) {
                shape.it.forEach((prop) => {
                  this.shapeArrayToChangeColor(prop, nm, color1, color2);
                });
              } else {
                this.shapeArrayToChangeColor(shape, nm, color1, color2);
              }
            });
          }
        }

        if (layer.ef) {
          layer.ef.forEach((effect) => {
            if ([21].includes(effect.ty)) {
              // console.log('effect', effect.nm, nm);
              if (effect.nm === nm) {
                effect.ef.forEach((el) => {
                  const effectchild = el;
                  if ([2].includes(effectchild.ty)) {
                    effectchild.v.k = [
                      toUnitVector(r),
                      toUnitVector(g),
                      toUnitVector(b),
                      1,
                    ];
                  }
                });
              }
            }
          });
        }
      });
    },
    shapeArrayToChangeColor(prop, nm, color1, color2) {
      if (prop.nm !== nm) return;
      const { r, g, b } = hexToRgb(color1);
      // console.log('shapeArrayToChangeColor', prop.ty, nm, r, g, b )
      if (['fl', 'st'].includes(prop.ty)) {
        // eslint-disable-next-line
        prop.c.k = [
          toUnitVector(r),
          toUnitVector(g),
          toUnitVector(b),
          1,
        ];
      }

      if (['gf'].includes(prop.ty)) {
        // console.log(prop.nm + ' == #' + id  )
        const color = prop.g.k.k;

        if (color.length !== 8 && color.length !== 12) return;

        let [r1, g1, b1, r2, g2, b2] = color;

        // if (color.length === 12) {
        //   const [mid, rauto, gauto, bauto] = color;
        // }

        r1 = fromUnitVector(r1);
        g1 = fromUnitVector(g1);
        b1 = fromUnitVector(b1);
        r2 = fromUnitVector(r2);
        g2 = fromUnitVector(g2);
        b2 = fromUnitVector(b2);

        if (nm.toLowerCase().startsWith('#hue') && prop.nm === nm) { // if it's hue
          const hsl1 = rgbToHSL(r1, g1, b1);
          const hsl2 = rgbToHSL(r2, g2, b2);

          const hue = rgbToHSL(r, g, b)[0];

          let newColor1 = {};
          let newColor2 = {};

          if (hsl1[1] > hsl2[1]) { // get whichever has higher saturation
            newColor1 = {
              r,
              g,
              b,
            };
            const [newr2, newg2, newb2] = hslToRGB(hue, hsl2[1], hsl2[2]);
            newColor2 = {
              r: newr2,
              g: newg2,
              b: newb2,
            };
          } else {
            const [newr1, newg1, newb1] = hslToRGB(hue, hsl1[1], hsl1[2]);
            newColor1 = {
              r: newr1,
              g: newg1,
              b: newb1,
            };
            newColor2 = {
              r,
              g,
              b,
            };
          }

          const midColor = getRamp(newColor1, newColor2, 1);
          // eslint-disable-next-line
          prop.g.k.k = [
            0,
            toUnitVector(newColor1.r),
            toUnitVector(newColor1.g),
            toUnitVector(newColor1.b),
            0.5,
            toUnitVector(midColor.r),
            toUnitVector(midColor.g),
            toUnitVector(midColor.b),
            1,
            toUnitVector(newColor2.r),
            toUnitVector(newColor2.g),
            toUnitVector(newColor2.b),
          ];
        } else if (nm.toLowerCase().startsWith('#gradient') && prop.nm === nm) { // if it's gradient
          const newColor1 = {
            r,
            g,
            b,
          };
          const newColor2 = hexToRgb(color2);
          // console.log('prop.g.k.k', prop.g.k.k.length, newColor1, newColor2);

          if (prop.g.k.k.length === 8) {
            // eslint-disable-next-line
            prop.g.k.k = [
              0,
              toUnitVector(newColor1.r),
              toUnitVector(newColor1.g),
              toUnitVector(newColor1.b),
              1,
              toUnitVector(newColor2.r),
              toUnitVector(newColor2.g),
              toUnitVector(newColor2.b),
            ];
          } else if (prop.g.k.k.length === 12) {
            const midColor = getRamp(newColor1, newColor2, 1);
            // console.log('midColor', midColor);
            // eslint-disable-next-line
            prop.g.k.k = [
              0,
              toUnitVector(newColor1.r),
              toUnitVector(newColor1.g),
              toUnitVector(newColor1.b),
              0.5,
              toUnitVector(midColor.r),
              toUnitVector(midColor.g),
              toUnitVector(midColor.b),
              1,
              toUnitVector(newColor2.r),
              toUnitVector(newColor2.g),
              toUnitVector(newColor2.b),
            ];
          }
        }
      }
    },
  },

  beforeDestroy() {
    // need to destroy to avoid memory leak
    this.anim.removeEventListener('DOMLoaded', () => {});
    this.anim = lottie.destroy(this.lottieName);
  },

  watch: {
    elementColor() {
      // console.log('update color', val)
      this.updateColor();
    },
    // options: {
    //   handler: function(item) {
    //     console.log('options updated', this.$refs.lavContainer.children)
    //     if (this.isCanvas || this.isSwap) this.animationData = null;
    //     if (this.isCanvas) this.isNew = true;
    //     if (this.$refs.lavContainer.children.length) {
    //       for (let i = 0; i < this.$refs.lavContainer.children.length; i += 1) {
    //         this.$refs.lavContainer.children[i].remove();
    //       }
    //     }
    //     this.setupLottie();
    //   },
    //   deep: true,
    // },
  },
};
</script>
