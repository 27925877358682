<template lang="pug">
.input-field__wrapper(
  :class="[{ 'has-label': label !== '' }]"
)
  template(v-if="label")
    label {{ label }}
  template(v-if="useAutowidth")
    input.input-field(
      :class="[{ 'input-field--dark': isDark, 'input-field--success': isSuccess, 'input-field--error': isError, 'input-field--alert': isAlert, 'input-field--tall': isTall, 'input-field--short': isShort}, extraClass]"
      :disabled="isDisabled"
      :value="currentValue"
      :placeholder="placeholder"
      :type="type"
      :readonly="isReadOnly"
      :maxlength="maxLength"
      v-autowidth="autowidthParams"
      @blur="$emit('blur', $event.target.value)"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @keypress="checkIfNumber($event)"
      @focus="$emit('focus', $event)"
      @keyup="$emit('keyup', $event)"
      @keyup.enter="$event.target.blur()"
      ref="baseInput"
      :data-lpignore="isNotPassword"
    )
  template(v-else)
    input.input-field(
      :class="[{ 'input-field--dark': isDark, 'input-field--success': isSuccess, 'input-field--error': isError, 'input-field--alert': isAlert, 'input-field--tall': isTall, 'input-field--short': isShort}, extraClass]"
      :disabled="isDisabled"
      :value="currentValue"
      :placeholder="placeholder"
      :type="type"
      :readonly="isReadOnly"
      :maxlength="maxLength"
      @blur="$emit('blur', $event.target.value)"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @keypress="checkIfNumber($event)"
      @focus="$emit('focus', $event)"
      @keyup="$emit('keyup', $event)"
      @keyup.enter="$event.target.blur()"
      ref="baseInput"
      :data-lpignore="isNotPassword"
    )
  template(v-if="msg && (isSuccess || isAlert || isError)")
    div.input-field__message(
      :class="[{ 'is-success': isSuccess, 'is-alert': isAlert, 'isError': isError }]"
    )
      template(v-if="isSuccess")
        i.icon-tick
      template(v-else-if="isAlert")
        i.icon-alert
      template(v-else-if="isError")
        i.icon-close
      | {{ msg }}
</template>

<script>
import { isNumber, isEmptyObject } from '@/assets/scripts/utilities';

export default {
  name: 'BaseInputField',
  props: {
    extraClass: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false },
    isAlert: { type: Boolean, default: false },
    isDark: { type: Boolean, default: false },
    isSuccess: { type: Boolean, default: false },
    isError: { type: Boolean, default: false },
    isTall: { type: Boolean, default: false },
    isShort: { type: Boolean, default: false },
    isNumber: { type: Boolean, default: false },
    isReadOnly: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    placeholder: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
    suffix: { type: String, default: '' },
    label: { type: String, default: '' },
    msg: { type: String, default: '' },
    autowidthParams: { type: Object, default: () => ({}) },
    maxLength: { type: Number, default: 524288 },
  },
  computed: {
    currentValue() {
      // - need to be separated cause sometimes it has suffix
      if (this.suffix) return `${this.value}${this.suffix}`;
      return this.value;
    },
    useAutowidth() {
      return !isEmptyObject(this.autowidthParams);
    },
    isNotPassword() {
      return this.type !== 'password';
    },
  },
  methods: {
    checkIfNumber($event) {
      if (!this.isNumber) return true;
      return isNumber($event);
    },
  },
};
</script>


<style lang="scss">
.input-field__wrapper {
  position: relative;

  &.has-label {
    margin-bottom: 10px;

    label {
      color: $grey;
      margin-bottom: 5px;
      display: block;
      font-size: 0.857em;
    }
  }

  .input-field {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    border-radius: $componentBorderRadius;
    border: 1px solid $defaultBorder;
    font-family: $baseFont;
    font-weight: 500;
    font-size: 0.875em;
    color: $textBlack;
    margin: 0;
    outline: 0;

    &:disabled {
      color: $textGrey;
    }

    &:focus {
      border-color: $inputFocus;
    }

    &:-moz-read-only { /* For Firefox */
      pointer-events: none;
      cursor: default;
    }

    &:read-only {
      pointer-events: none;
      cursor: default;
    }
  }

  .input-field--tall {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .input-field--short {
    padding: 8px 15px;
  }

  .input-field--dark {
    opacity: 1;
    color: $lightGrey400;
    border-color: $darkGrey2;
    background-color: $darkGrey2;

    &:focus {
      color: $lightWhite;
      border-color: $blue;
    }
  }

  .input-field--success {
    border: 1px solid $btnSuccessBackground;
  }

  .input-field--error {
    border: 1px solid $btnDangerBackground;
  }

  .input-field--alert {
    border: 1px solid $btnAlertBackground;
  }

  .input-field__message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    font-size: 0.750em;
    width: 100%;
    background: $light;
    box-shadow: 0 3px 4px rgba(10, 31, 68, 0.1);
    padding: 10px;
    z-index: 1;

    i {
      margin-right: 10px;
      font-size: 1.25em;
    }

    &.is-success {
      color: $btnSuccessHollowText;
    }

    &.is-alert {
      color: $btnAlertHollowText;
    }

    &.is-erorr {
      color: $btnDangerHollowText;
    }
  }
}
</style>
