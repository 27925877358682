<template lang="pug">
.slider(
  :class="{ 'is-dark': isDark, 'is-updating': isUpdating, 'is-disabled': isDisabled }"
)
  p.slider__title(
    v-if="title"
    v-html="title"
  )
  .slider__container
    .range-wrapper
      .range-container
        span.range-before(
          :style="sliderStyle"
        )
      input.slider__range(
        type="range"
        :step="step"
        :min="min"
        :max="max"
        :disabled="isDisabled"
        ref="slider"
        v-model="currentValue"
        @change="valueOnChange"
      )
    input.slider__value(
      v-if="!noInput"
      :disabled="isDisabled"
      @blur="onBlurInput($event)"
      @keypress="checkIfNumber($event)"
      @focus="$event.target.select()"
      @change="checkIfMinMax($event)"
      v-model.lazy="currentValueInput"
    )
</template>

<script>
import { debounce } from 'debounce';
import { isNumberInteger } from '@/assets/scripts/utilities';

// TODO
// v-model is a more vue friendly way to handle data binding of components.
// Use of v-model can be set up to have 1-way, or 2-way data binding.
// 1-way data binding is better suited to state stored in a Vuex store.
// This can make sure the state cannot be accidently modified internally
// by the component.
// This is overall better for tracking reactivity, and debugging errors.

export default {
  name: 'BaseSlider',
  props: {
    title: { type: String, default: '' },
    min: { type: Number, default: -999 },
    max: { type: Number, default: 999 },
    step: { type: Number, default: 1 },
    value: { type: Number, default: 0 },
    noInput: { type: Boolean, default: false },
    isColorWave: { type: Boolean, default: false },
    isDark: { type: Boolean, default: false },
    suffix: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false },
  },
  data() {
    return {
      isUpdating: false,
    };
  },
  computed: {
    sliderStyle() {
      const styleObject = {};

      let percentage = 100;

      if (this.isColorWave) {
        styleObject.background = 'linear-gradient(to left, red,orange,yellow,green,blue,indigo,violet)';
      } else {
        percentage = ((parseFloat(this.currentValue) - this.min) / (this.max - this.min)) * 100;
      }

      percentage = percentage || 0; // cover if all "currentValue", "min", "max" have value of "0", the percentage will be NaN

      styleObject.transform = `translateX(${percentage}%)`;

      return styleObject;
    },
    currentValueInput: {
      // need to be separated cause sometimes it has suffix
      get() {
        // Sometimes giving decimal
        const decimals = this.step % 1 !== 0 ? this.step.toString().split('.')[1].length : 0;
        let value = this.step % 1 === 0 ? Math.round(this.value) : this.value;
        value = decimals > 0 ? parseFloat(value.toFixed(decimals)) : value;

        if (this.suffix) return `${value}${this.suffix}`;

        return value;
      },
      set(value) {
        let newValue = value;
        if (this.suffix) newValue = `${newValue}${this.suffix}`;
        this.$emit('update', parseFloat(newValue));
      },
    },
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.isUpdating = true;
        this.$emit('update', parseFloat(value));
      },
    },
  },
  methods: {
    checkIfNumber($event) {
      return isNumberInteger($event);
    },
    checkIfMinMax($event) {
      if ($event.target.value < this.min) {
        this.currentValueInput = this.min;
      }

      if ($event.target.value > this.max) {
        this.currentValueInput = this.max;
      }
    },
    // eslint-disable-next-line
    valueOnChange: debounce(function() {
      this.isUpdating = false;
    }, 400),
    onBlurInput($event) {
      this.$emit('blur', $event);
    },
  },
};
</script>

<style lang="scss">
$sliderThumbSize: 14px;
$sliderThumbSizeBig: 16px;
$trackSize: 14px;
$trackRadius: 4px;

.slider {
  margin: 9px 0;

  &.is-disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}

.slider__title {
  font-size: 0.875em;
  font-weight: 500;
  margin: 0;

  .slider.is-dark & {
    color: $lightWhite;
  }
}

.slider__container {
  position: relative;
  width: 100%;
  overflow: visible;

  input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    margin: 0 auto;
    background: transparent;
    height: 100%;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: $trackSize;
    cursor: pointer;
    background: transparent;
    border-radius: $trackRadius;
  }
  input[type='range']::-webkit-slider-thumb {
    height: $sliderThumbSize;
    width: $sliderThumbSize;
    border-radius: $sliderThumbSize;
    background: $darkGrey400;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color 0.3s cubic-bezier(0, 0, 0, 1),
      transform 0.3s cubic-bezier(0, 0, 0, 1), border-radius 0.3s cubic-bezier(0, 0, 0, 1);

    .mobile-project & {
      width: 16px;
      height: 16px;
    }

    &:hover {
      transform: scale(1.3);
      border-radius: 16px;
    }
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: transparent;
  }
  input[type='range']::-moz-range-track {
    width: 100%;
    height: $trackSize;
    cursor: pointer;
    background: transparent;
    border-radius: 5px;
  }
  input[type='range']::-ms-track {
    width: 100%;
    height: $trackSize;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
    border-radius: $trackRadius;
  }
  input[type='range']::-ms-fill-lower {
    background: transparent;
    border-radius: $sliderThumbSize;

    .mobile-project & {
      border-radius: $sliderThumbSizeBig;
    }
  }
  input[type='range']::-ms-fill-upper {
    background: transparent;
    border-radius: $sliderThumbSize;

    .mobile-project & {
      border-radius: $sliderThumbSizeBig;
    }
  }
  input[type='range']::-moz-range-thumb,
  input[type='range']::-ms-thumb {
    height: $sliderThumbSize;
    width: $sliderThumbSize;
    border-radius: $sliderThumbSize;
    background: $darkGrey400;
    cursor: pointer;
    transition: background-color 0.3s cubic-bezier(0, 0, 0, 1),
      transform 0.3s cubic-bezier(0, 0, 0, 1), border-radius 0.3s cubic-bezier(0, 0, 0, 1);

    .mobile-project & {
      height: $sliderThumbSizeBig;
      width: $sliderThumbSizeBig;
    }

    &:hover {
      transform: scale(1.3);
      border-radius: 16px;
    }
  }
  input[type='range']::-ms-thumb {
    height: $sliderThumbSize;

    .mobile-project & {
      height: $sliderThumbSizeBig;
    }
  }
  input[type='range']:focus::-ms-fill-lower {
    background: transparent;
  }
  input[type='range']:focus::-ms-fill-upper {
    background: transparent;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .range-wrapper {
    max-width: calc(100% - 62px);
    position: relative;
    width: 100%;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    height: 28px;
    overflow: visible;

    .mobile-project & {
      height: 32px;
    }

    .range-container {
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .range-before {
      width: 100%;
      height: 3px;
      top: 11px;
      background: $darkGrey400;
      left: -100%;
      display: block;
      position: absolute;
      border-radius: 5px;

      .mobile-project & {
        top: 14px;
      }

      &::after {
        content: '';
        width: 100%;
        height: 3px;
        transform: translateX(100%);
        position: absolute;
        top: 0;
        left: 0;
        background: $defaultBorder;
      }
    }

    input {
      position: relative;
      z-index: 2;
    }
  }

  .slider__value {
    width: 50px;
    margin-left: 10px;
    background: transparent;
    color: $darkGrey;
    text-align: center;
    padding: 5px;
    font-size: 0.875em;
    border: 1px solid $defaultBorder;
    border-radius: $componentBorderRadius;
    display: inline-block;
  }

  .slider.is-dark & {
    .slider__value {
      color: $lightWhite;
      border: 1px solid $lightGrey900;
      border-radius: 4px;
      background: $darkGrey500;
    }

    input[type='range']::-webkit-slider-thumb {
      background: $lightGrey900;
      width: 12px;
      height: 12px;

      .mobile-project & {
        width: $sliderThumbSizeBig;
        height: $sliderThumbSizeBig;
      }

      &:hover {
        background: $lightGrey500;
      }
    }

    input[type='range']::-moz-range-thumb,
    input[type='range']::-ms-thumb {
      background: $lightGrey900;
      width: 12px;
      height: 12px;

      .mobile-project & {
        width: $sliderThumbSizeBig;
        height: $sliderThumbSizeBig;
      }

      &:hover {
        background: $lightGrey500;
      }
    }

    .range-wrapper {
      .range-before {
        background: $lightGrey900;

        &::after {
          background: $darkGrey500;
        }
      }
    }
  }
}
</style>
