import axios from 'axios';

export default {
  userDetails(version = 'v1') {
    return axios({
      method: 'get',
      url: `/${version}/accounts`,
    });
  },

  userSignup(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/users/signup`,
      data: params,
    });
  },

  userLogin(params = {}, version = 'v2') {
    return axios({
      method: 'post',
      url: `/${version}/users/login`,
      data: params,
    });
  },

  userLogout(version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/users/logout`,
    });
  },

  userResetPassword(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/users/password/request`,
      data: params,
    });
  },

  getUserFolders(params = {}, version = 'v1') {
    return axios.get(`/${version}/collections/user-folders`, { params });
  },

  updateUserFolder(folderId, params = {}, version = 'v1') {
    return axios({
      method: 'put',
      url: `/${version}/collections/user-folders/${folderId}`,
      data: params,
    });
  },

  createUserFolder(folderName = 'New folder', version = 'v1') {
    const params = {
      folder_name: folderName,
    };

    return axios({
      method: 'post',
      url: `/${version}/collections/user-folders`,
      data: params,
    });
  },

  deleteUserFile(id, params = {}, version = 'v2') {
    return axios({
      method: 'delete',
      url: `/${version}/collections/user-files/${id}`,
      data: params,
    });
  },

  deleteUserFolder(id, params = {}, version = 'v1') {
    return axios({
      method: 'delete',
      url: `/${version}/collections/user-folders/${id}`,
      data: params,
    });
  },

  moveMultipleFilesToFolder(folderId, fileId, version = 'v2') {
    const params = {
      folder_id: folderId,
      file_id: fileId.length ? fileId.toString() : fileId, // Expected fileId: '123, 456, etc'
    };
    return axios({
      method: 'post',
      url: `/${version}/collections/user-files/move-multiple-files`,
      data: params,
    });
  },
  deleteMultipleFiles(params = {}, version = 'v2') {
    // delete multiple user files in one request
    return axios({
      method: 'delete',
      url: `/${version}/delete-multiple-files`,
      data: params,
    });
  },

  doRefreshToken(params = {}, version = 'v2') {
    return axios({
      method: 'post',
      url: `/${version}/users/refresh-token`,
      data: params,
    });
  },

  getPreferences(version = 'v2') {
    return axios({
      method: 'get',
      url: `/${version}/users/preferences`,
    });
  },

  setPreference(params = {}, version = 'v2') {
    return axios({
      method: 'post',
      url: `/${version}/users/preferences`,
      data: params,
    });
  },
};
