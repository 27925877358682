<template lang="pug">
label(
    :class="['checkbox', wrapperClass]"
  )
  input(
    type="checkbox"
    :name="name"
    :checked="isChecked"
    @change="$emit('change')"
  )
  span(
    class="checkmark"
  )
  template(v-if="label") {{ label }}
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    wrapperClass: { type: String, default: '' },
    name: { type: String, default: '' },
    isChecked: { type: Boolean, default: false },
    label: { type: String, default: '' },
  },
  computed: {
  },
};
</script>

<style lang="scss">
// Check box
.checkbox {
  position: relative;
  cursor: pointer;
  user-select: none;
  display: block;

  &:hover input ~ .checkmark {
    background-color: $light;
    border: 3px solid $btnPrimaryBackground;
    transition: background-color 0.5s;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        background-color: $btnPrimaryBackground;
        border: 3px solid $btnPrimaryBackground;
        transition: background-color 0.5s;

        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: -25px;
    height: 20px;
    width: 20px;
    background-color: $light;
    border: 3px solid $checkmarkBorder;
    border-radius: $componentBorderRadius;
    transition: background-color 0.5s;

    &:after {
      content: "";
      position: absolute;
      display: none;

      left: calc(calc(100% / 2) - 4px);
      width: 5px;
      height: 10px;
      border: solid $light;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}
</style>
