import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import PageNotFound from './views/PageNotFound.vue';

import { isMobile } from '@/assets/scripts/utilities'; // eslint-disable-line

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/test',
      name: 'test',
      component: () => import(/* webpackChunkName: "_login" */ './views/Test.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "_login" */ './views/Login.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "_logout" */ './views/Logout.vue'),
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import(/* webpackChunkName: "_subscribe" */ './views/Subscribe.vue'),
    },
    {
      path: '/test',
      name: 'test',
      component: () => import(/* webpackChunkName: "_test" */ './views/Test.vue'),
    },
    {
      path: '/project/:projectId?',
      name: 'project',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // eslint-disable-next-line
      component: () => {
        // if (isMobile()) return import(/* webpackChunkName: "_mobile" */ './views/MobileProject.vue');
        return import(/* webpackChunkName: "_project" */ './views/Project.vue');
      },
    },
    {
      path: '/template/:templateId?',
      name: 'template',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "_template" */ './views/Project.vue'),
    },
    {
      path: '/capture-frame',
      name: 'capture-frame',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "_capture-frame" */ './views/CaptureFrame.vue'),
    },
    {
      path: '/smart-create/:type?',
      name: 'smart-create',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "_smart-create" */ './views/SmartCreate2.vue'),
      // TODO: change this to SmartCreate.vue when launching mobileSmartCreate
    },
    {
      path: '/auth/:provider/callback',
      name: 'social-auth-callback',
      component: {
        template: '<div class="auth-component"></div>',
      },
    },
    { path: '*', component: PageNotFound },
  ],
});
