import {
  Sine, Elastic, Linear, Back, Expo, Power0, Power2, Power3,
} from 'gsap/TweenMax';
import { effectsMaxValues, effectsMinValues, groups } from '@/assets/scripts/enums';
import { fauxZero, minDuration, textGlitchSequence, imageGlitchSequence, imageStraightLightSweepMaskPosition } from '@/assets/scripts/variables'; // eslint-disable-line
import { isVerticalLightSweep, angleToNumber, isReversedAngle, getTiltLightSweepMaskPosition, getTextLightSweepBackgroundSize } from '@/assets/scripts/utilities'; // eslint-disable-line
import { CustomEase } from '@/assets/scripts/gsap/CustomEase';
import AnimationList from '@/assets/data/animation-list.json';

const baseAnimation = {
  autoAlpha: 1,
  x: 0,
  y: 0,
  scale: 1,
  rotationZ: 0,
  rotationX: 0,
  rotationY: 0,
};

const setDirectionAxis = (item) => {
  const { animate, direction, canvasZoom } = item;
  const animateWithDirection = Object.assign({}, animate);
  const canvasDistance = 400 * canvasZoom;
  if (direction === 'from-top' || direction === 'to-top') {
    animateWithDirection.y = `-=${canvasDistance}`;
  } else if (direction === 'from-bottom' || direction === 'to-bottom') {
    animateWithDirection.y = `+=${canvasDistance}`;
  } else if (direction === 'from-left' || direction === 'to-left') {
    animateWithDirection.x = `-=${canvasDistance}`;
  } else if (direction === 'from-right' || direction === 'to-right') {
    animateWithDirection.x = `+=${canvasDistance}`;
  }

  return animateWithDirection;
};

const animateBounceIn = (item) => {
  const {
    animate, direction, canvasSize, canvasZoom,
  } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  newAnimate.ease = Sine.easeIn;
  newAnimate.autoAlpha = 0;
  animateEnding.ease = Elastic.easeOut.config(1, 0.4);

  const width = canvasSize.width / 2 * canvasZoom;
  const height = canvasSize.height / 2 * canvasZoom;

  if (direction === 'from-centre') {
    newAnimate.scale = 0;
    animateEnding.scale = 1;
  } else if (direction === 'from-top') {
    newAnimate.y = `-${height}`;
  } else if (direction === 'from-bottom') {
    newAnimate.y = `+${height}`;
  } else if (direction === 'from-left') {
    newAnimate.x = `-${width}`;
  } else if (direction === 'from-right') {
    newAnimate.x = `+${width}`;
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animateBounceOut = (item) => {
  const {
    animate, direction, canvasSize, canvasZoom,
  } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  newAnimate.autoAlpha = 0;
  newAnimate.ease = Elastic.easeIn.config(1, 0.4);

  const width = canvasSize.width / 2 * canvasZoom;
  const height = canvasSize.height / 2 * canvasZoom;

  if (direction === 'to-centre') {
    newAnimate.scale = 0;
    animateEnding.scale = 1;
  } else if (direction === 'to-top') {
    newAnimate.y = `-${height}`;
  } else if (direction === 'to-bottom') {
    newAnimate.y = `+${height}`;
  } else if (direction === 'to-left') {
    newAnimate.x = `-${width}`;
  } else if (direction === 'to-right') {
    newAnimate.x = `+${width}`;
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animateFade = (item) => {
  const { animate, direction, canvasZoom } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  newAnimate.autoAlpha = 0;
  newAnimate.ease = Linear.easeNone;

  const width = 60 * canvasZoom;
  const height = 60 * canvasZoom;

  if (direction === 'from-top' || direction === 'to-top' || direction === 'vertical') {
    newAnimate.y = `-${height}`;
  } else if (direction === 'from-bottom' || direction === 'to-bottom') {
    newAnimate.y = `+${height}`;
  } else if (direction === 'from-left' || direction === 'to-left' || direction === 'horizontal') {
    newAnimate.x = `-${width}`;
  } else if (direction === 'from-right' || direction === 'to-right') {
    newAnimate.x = `+${width}`;
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animateLightSpeed = (item) => {
  const {
    animate, direction, canvasSize, canvasZoom,
  } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  animateEnding.skewX = 0;
  animateEnding.ease = Elastic.easeOut.config(1, 1);

  const width = canvasSize.width / 2 * canvasZoom;

  if (direction === 'from-left' || direction === 'to-left') {
    newAnimate.x = `-${width}`;
    newAnimate.skewX = '-30deg';
  } else if (direction === 'from-right' || direction === 'to-right') {
    newAnimate.x = `+${width}`;
    newAnimate.skewX = '30deg';
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animatePushIn = (item) => {
  const { animate, direction, canvasZoom } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  newAnimate.autoAlpha = 0;
  newAnimate.ease = Linear.easeNone;
  // eslint-disable-next-line
  animateEnding.ease = CustomEase.create('custom', 'M0,0 C0,0 0.113,0.693 0.16,0.866 0.218,1.08 0.262,1.202 0.376,1.244 0.456,1.273 0.587,1.167 0.618,1.138 0.7,1.058 0.828,1.009 0.926,1.009 0.948,1.009 1,1 1,1 ');

  let animateWithDirection = newAnimate;

  if (direction === 'from-centre' || direction === 'from-big') {
    // keep 'from-centre' direction for people who already use this
    animateWithDirection.scale = 3;
    animateEnding.scale = 1;
  } else if (direction === 'from-small') {
    animateWithDirection.scale = 0;
    animateEnding.scale = 1;
  } else {
    animateWithDirection = setDirectionAxis({
      animate: newAnimate,
      direction,
      canvasZoom,
    });
  }

  return {
    animate: animateWithDirection,
    animateEnding,
  };
};

const animatePushOut = (item) => {
  const {
    animate, direction, canvasZoom,
  } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  newAnimate.autoAlpha = 0;
  animateEnding.ease = Back.easeOut.config(3);

  let animateWithDirection = newAnimate;

  if (direction === 'to-centre' || direction === 'to-big') {
    // keep 'to-centre' direction for people who already use this
    animateWithDirection.scale = 3;
    animateEnding.scale = 1;
  } else if (direction === 'to-small') {
    animateWithDirection.scale = 0;
    animateEnding.scale = 1;
  } else {
    animateWithDirection = setDirectionAxis({
      animate: newAnimate,
      direction,
      canvasZoom,
    });
  }

  return {
    animate: animateWithDirection,
    animateEnding,
  };
};

const animateRoll = (item) => {
  const {
    animate, direction, target, canvasSize, canvasZoom,
  } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  newAnimate.autoAlpha = 0;
  animateEnding.rotationX = 0;
  animateEnding.rotationY = 0;

  let width = canvasSize.width / 1.5 * canvasZoom;
  let height = canvasSize.height / 1.5 * canvasZoom;

  try {
    width = target.clientWidth / 1.5;
    height = target.clientHeight / 1.5;
  } catch (err) {
    if (document.querySelector(target)) {
      width = document.querySelector(target).clientWidth / 1.5;
      height = document.querySelector(target).clientHeight / 1.5;
    }
  }

  animateEnding.ease = Power2.easeOut;

  if (direction === 'from-top' || direction === 'to-top') {
    newAnimate.y = `-${height}`;
    newAnimate.rotationX = 180;
  } else if (direction === 'from-bottom' || direction === 'to-bottom') {
    newAnimate.y = `+${height}`;
    newAnimate.rotationX = 180;
  } else if (direction === 'from-left' || direction === 'to-left') {
    newAnimate.x = `-${width}`;
    newAnimate.rotationY = 180;
  } else if (direction === 'from-right' || direction === 'to-right') {
    newAnimate.x = `+${width}`;
    newAnimate.rotationY = 180;
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animateRotate = (item) => {
  const {
    animate, direction, canvasSize, canvasZoom,
  } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  newAnimate.autoAlpha = 0;
  animateEnding.rotationZ = 0;
  animateEnding.ease = Expo.easeOut;

  const width = canvasSize.width / 2 * canvasZoom;
  const height = canvasSize.height / 2 * canvasZoom;

  if (direction === 'from-top' || direction === 'to-top' || direction === 'vertical') {
    // keep 'vertical' direction for people who already use this
    newAnimate.y = `-${height}`;
    newAnimate.rotationZ = 180;
  } else if (direction === 'from-bottom' || direction === 'to-bottom') {
    newAnimate.y = `+${height}`;
    newAnimate.rotationZ = 180;
  } else if (direction === 'from-left' || direction === 'to-left' || direction === 'horizontal') {
    // keep 'horizontal' direction for people who already use this
    newAnimate.x = `-${width}`;
    newAnimate.rotationZ = 180;
  } else if (direction === 'from-right' || direction === 'to-right') {
    newAnimate.x = `+${width}`;
    newAnimate.rotationZ = 180;
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animateRubber = (item) => {
  const { animate, direction, isOut } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  animateEnding.scale = 1;

  if (isOut) {
    newAnimate.ease = Elastic.easeOut.config(1, 0.5);
  } else {
    animateEnding.ease = Elastic.easeOut.config(1, 0.3);
  }

  if (direction === 'horizontal') {
    newAnimate.scaleX = 0;
    newAnimate.scaleY = 1;
  } else if (direction === 'vertical') {
    newAnimate.scaleY = 0;
    newAnimate.scaleX = 1;
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animateSlide = (item) => {
  const {
    animate, direction, canvasSize, canvasZoom, isOut, isSlideTransition,
  } = item;

  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  const width = canvasSize.width * canvasZoom;
  const height = canvasSize.height * canvasZoom;

  if (!isSlideTransition) {
    if (isOut) {
      animateEnding.ease = Power0.easeNone;
      newAnimate.ease = Power3.easeInOut;
    } else {
      animateEnding.ease = Expo.easeOut;
    }
  } else if (isOut) {
    newAnimate.ease = Expo.easeOut;
  } else {
    animateEnding.ease = Expo.easeOut;
  }

  if (direction === 'from-top' || direction === 'to-top') {
    newAnimate.y = `-${height}`;
  } else if (direction === 'from-bottom' || direction === 'to-bottom') {
    newAnimate.y = `+${height}`;
  } else if (direction === 'from-left' || direction === 'to-left') {
    newAnimate.x = `-${width}`;
  } else if (direction === 'from-right' || direction === 'to-right') {
    newAnimate.x = `+${width}`;
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animateZoom = (item) => {
  const {
    animate, direction, canvasSize, canvasZoom, isOut, isReverse,
  } = item;

  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  const width = canvasSize.width / 2 * canvasZoom;
  const height = canvasSize.height / 2 * canvasZoom;

  newAnimate.autoAlpha = 0;

  if (isReverse) {
    newAnimate.scale = 0;
    animateEnding.scale = 1;
  } else {
    newAnimate.scale = 4;
    animateEnding.scale = 1;
  }

  if (isOut) {
    newAnimate.ease = Expo.easeIn;
  } else {
    animateEnding.ease = Expo.easeOut;
  }

  if (direction === 'from-top' || direction === 'to-top') {
    newAnimate.y = `-${height}`;
  } else if (direction === 'from-bottom' || direction === 'to-bottom') {
    newAnimate.y = `+${height}`;
  } else if (direction === 'from-left' || direction === 'to-left') {
    newAnimate.x = `-${width}`;
  } else if (direction === 'from-right' || direction === 'to-right') {
    newAnimate.x = `+${width}`;
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animateWipe = (item) => {
  const {
    animate, direction, target, isOut, isWrapper, extra,
  } = item;

  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  let extraPaddingX = 0;
  let extraPaddingY = 0;

  if (extra) {
    // eslint-disable-next-line prefer-destructuring
    if (extra.extraPaddingX) extraPaddingX = extra.extraPaddingX;
    // eslint-disable-next-line prefer-destructuring
    if (extra.extraPaddingY) extraPaddingY = extra.extraPaddingY;
  }

  const width = target.clientWidth + extraPaddingX;
  const height = target.clientHeight + extraPaddingY;

  if (isWrapper) {
    // element-wrap contain the opacity set by user
    // don't need to include autoAlpha
    delete newAnimate.autoAlpha;
    delete animateEnding.autoAlpha;
  } else {
    newAnimate.overflow = 'hidden';
  }

  if (isOut) {
    newAnimate.ease = Expo.easeIn;
  } else {
    animateEnding.ease = Expo.easeOut;
  }

  if (direction === 'from-top' || direction === 'to-top') {
    if (isWrapper) newAnimate.y = `+${height}`;
    else newAnimate.y = `-${height}`;
  } else if (direction === 'from-bottom' || direction === 'to-bottom') {
    if (isWrapper) newAnimate.y = `-${height}`;
    else newAnimate.y = `+${height}`;
  } else if (direction === 'from-left' || direction === 'to-left') {
    if (isWrapper) newAnimate.x = `+${width}`;
    else newAnimate.x = `-${width}`;
  } else if (direction === 'from-right' || direction === 'to-right') {
    if (isWrapper) newAnimate.x = `-${width}`;
    else newAnimate.x = `+${width}`;
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animateMask = (item) => {
  const {
    animate, direction, target, isOut, isWrapper,
  } = item;

  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  const width = target.clientWidth;
  const height = target.clientHeight;

  if (isWrapper) {
    // element-wrap contain the opacity set by user
    // don't need to include autoAlpha
    delete newAnimate.autoAlpha;
    delete animateEnding.autoAlpha;
  } else {
    newAnimate.overflow = 'hidden';
  }

  if (isOut) {
    newAnimate.ease = Expo.easeIn;
  } else {
    animateEnding.ease = Expo.easeOut;
  }

  if (direction === 'from-top' || direction === 'to-top') {
    if (isWrapper) newAnimate.y = `-${height}`;
  } else if (direction === 'from-bottom' || direction === 'to-bottom') {
    if (isWrapper) newAnimate.y = `+${height}`;
  } else if (direction === 'from-left' || direction === 'to-left') {
    if (isWrapper) newAnimate.x = `-${width}`;
  } else if (direction === 'from-right' || direction === 'to-right') {
    if (isWrapper) newAnimate.x = `+${width}`;
  }

  return {
    animate: newAnimate,
    animateEnding,
  };
};


const animateTypewriter = (item) => {
  // THIS ANIMATION IS FOR THE BACKGROUND / targetTextContainer
  // individual text will use display inline-block and none
  const { animate } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  newAnimate.autoAlpha = 0;
  newAnimate.ease = Linear.easeNone;

  animateEnding.autoAlpha = 1;

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const animateFly = (item) => {
  const {
    animate, direction, canvasZoom,
  } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);
  const midAnimation = Object.assign({}, baseAnimation);

  const width = 50 * canvasZoom;
  const height = 50 * canvasZoom;
  const midWidth = 20 * canvasZoom;
  const midHeight = 20 * canvasZoom;

  newAnimate.autoAlpha = 0;
  midAnimation.autoAlpha = 1;
  midAnimation.ease = Sine.easeInOut;

  if (direction === 'from-top' || direction === 'to-top') {
    newAnimate.x = 0;
    newAnimate.y = `-${1.5 * height}`;
    midAnimation.x = `+${midHeight}`;
    midAnimation.y = `+${height}`;
  } else if (direction === 'from-bottom' || direction === 'to-bottom') {
    newAnimate.x = 0;
    newAnimate.y = `+${1.5 * height}`;
    midAnimation.x = `+${midHeight}`;
    midAnimation.y = `-${height}`;
  } else if (direction === 'from-left' || direction === 'to-left') {
    newAnimate.y = 0;
    newAnimate.x = `-${1.5 * width}`;
    midAnimation.y = `+${midWidth}`;
    midAnimation.x = `+${width}`;
  } else if (direction === 'from-right' || direction === 'to-right') {
    newAnimate.y = 0;
    newAnimate.x = `+${1.5 * width}`;
    midAnimation.y = `+${midWidth}`;
    midAnimation.x = `-${width}`;
  }

  return {
    animate: newAnimate,
    midAnimation,
    animateEnding,
  };
};

const animateJack = (item) => {
  const { animate } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);
  const midAnimation = Object.assign({}, baseAnimation);

  newAnimate.scale = 0;
  newAnimate.rotationZ = '30deg';

  midAnimation.rotationZ = '-10deg';
  midAnimation.scale = 0.5;

  animateEnding.scale = 1;
  animateEnding.ease = Elastic.easeOut.config(1, 0.4);

  return {
    animate: newAnimate,
    midAnimation,
    animateEnding,
  };
};

const animateHinge = (item) => {
  const {
    animate, direction, canvasSize, canvasZoom,
  } = item;
  const newAnimate = Object.assign({}, animate);
  const animateEnding = Object.assign({}, baseAnimation);

  const height = canvasSize.height / 2 * canvasZoom;

  let transformOrigin = 'top left';
  if (direction === 'top-right') transformOrigin = 'top right';
  if (direction === 'bottom-left') transformOrigin = 'bottom left';
  if (direction === 'bottom-right') transformOrigin = 'bottom right';

  animateEnding.transformOrigin = transformOrigin;
  newAnimate.transformOrigin = transformOrigin;

  newAnimate.y = `+${height}`;
  newAnimate.autoAlpha = 0;

  return {
    animate: newAnimate,
    animateEnding,
  };
};

const getAnimateIn = (item) => {
  const {
    value,
    direction,
    target,
    isSlideTransition,
    isWrapper,
    canvasZoom,
    canvasSize,
    isTransition,
  } = item;
  // console.log('getAnimateIn--', value, direction, target);
  let animateIn = Object.assign({}, baseAnimation);
  let animateInMid = null;
  let animateInEnding = Object.assign({}, baseAnimation);
  let animate = {};

  const passedAnimation = {
    animate: animateIn,
    direction,
    target,
    isSlideTransition,
    isTransition,
    isWrapper,
    canvasZoom,
    canvasSize,
  };

  if (value === 'bounce-in') {
    animate = animateBounceIn(passedAnimation);
  } else if (value === 'fade-in') {
    animate = animateFade(passedAnimation);
  } else if (value === 'flip-in') {
    // using fade cause flip-in buggy
    animate = animateFade(passedAnimation);
  } else if (value === 'light-speed') {
    animate = animateLightSpeed(passedAnimation);
  } else if (value === 'mask-in') {
    animate = animateMask(passedAnimation);
  } else if (value === 'push-in') {
    animate = animatePushIn(passedAnimation);
  } else if (value === 'roll-in') {
    animate = animateRoll(passedAnimation);
  } else if (value === 'rotate-in') {
    animate = animateRotate(passedAnimation);
  } else if (value === 'rubber-band') {
    animate = animateRubber(passedAnimation);
  } else if (value === 'slide-in') {
    animate = animateSlide(passedAnimation);
  } else if (value === 'zoom-in') {
    animate = animateZoom(passedAnimation);
  } else if (value === 'zoom-out') {
    passedAnimation.isReverse = true;
    animate = animateZoom(passedAnimation);
  } if (value === 'wipe-in') {
    animate = animateWipe(passedAnimation);
  } else if (value === 'typewriter') {
    animate = animateTypewriter(passedAnimation);
  } else if (value === 'fly-in') {
    animate = animateFly(passedAnimation);
  } else if (value === 'jack-in-the-box') {
    animate = animateJack(passedAnimation);
  }

  animateIn = animate.animate;
  animateInEnding = animate.animateEnding;
  animateInMid = animate.midAnimation;

  return { animateIn, animateInMid, animateInEnding };
};

const getAnimateOut = (item) => {
  const {
    value,
    direction,
    target,
    extra,
    isWrapper,
    canvasZoom,
    canvasSize,
  } = item;
  // console.log('getAnimateOut--', value, direction, target);

  let animateOut = Object.assign(baseAnimation);
  let animateOutMid = null;
  let animateOutStart = Object.assign(baseAnimation);
  let animate = {};

  const passedAnimation = {
    animate: animateOut,
    direction,
    target,
    isOut: true,
    isWrapper,
    extra,
    canvasZoom,
    canvasSize,
  };

  if (value === 'bounce-out') {
    animate = animateBounceOut(passedAnimation);
  } else if (value === 'fade-out') {
    animate = animateFade(passedAnimation);
  } else if (value === 'mask-out') {
    animate = animateMask(passedAnimation);
  } else if (value === 'push-out') {
    animate = animatePushOut(passedAnimation);
  } else if (value === 'roll-out') {
    animate = animateRoll(passedAnimation);
  } else if (value === 'rotate-out') {
    animate = animateRotate(passedAnimation);
  } else if (value === 'rubber-band') {
    animate = animateRubber(passedAnimation);
  } else if (value === 'slide-out') {
    animate = animateSlide(passedAnimation);
  } else if (value === 'zoom-in') {
    animate = animateZoom(passedAnimation);
  } else if (value === 'zoom-out') {
    passedAnimation.isReverse = true;
    animate = animateZoom(passedAnimation);
  } else if (value === 'wipe-out') {
    animate = animateWipe(passedAnimation);
  } else if (value === 'typewriter') {
    animate = animateTypewriter(passedAnimation);
  } else if (value === 'fly-out') {
    animate = animateFly(passedAnimation);
  } else if (value === 'hinge') {
    animate = animateHinge(passedAnimation);
  }

  animateOut = animate.animate;
  animateOutStart = animate.animateEnding;
  animateOutMid = animate.midAnimation;

  return { animateOut, animateOutMid, animateOutStart };
};

const getTransitionIn = (item) => {
  const {
    value,
    direction,
    target,
    canvasZoom,
    canvasSize,
  } = item;

  let animation = {};
  let selectedValue = value;

  let isSlideTransition = false;
  if (selectedValue === 'slide-in') {
    isSlideTransition = true;
  }
  if (selectedValue === 'overlap') {
    selectedValue = 'slide-in';
  }

  animation = getAnimateIn({
    value: selectedValue,
    direction,
    target,
    isSlideTransition,
    canvasZoom,
    canvasSize,
    isTransition: true,
  });

  return animation;
};

const getTransitionOut = (item) => {
  const {
    value,
    direction,
    target,
    canvasZoom,
    canvasSize,
  } = item;

  let animation = {};
  let animateOut = Object.assign({}, baseAnimation);
  let animate = {};
  let animateOutStart = Object.assign({}, baseAnimation);
  let selectedDirection = direction;
  const isOut = true;

  if (selectedDirection === 'from-top') {
    selectedDirection = 'to-bottom';
  } else if (selectedDirection === 'from-bottom') {
    selectedDirection = 'to-top';
  } else if (selectedDirection === 'from-left') {
    selectedDirection = 'to-right';
  } else if (selectedDirection === 'from-right') {
    selectedDirection = 'to-left';
  }

  if (value === 'slide-in') {
    animate = animateSlide({
      animate: animateOut,
      direction: selectedDirection,
      target,
      isOut,
      isSlideTransition: true,
      isTransition: true,
      canvasZoom,
      canvasSize,
    });
  } else {
    return animation;
  }

  animateOut = animate.animate;
  animateOutStart = animate.animateEnding;

  animation = {
    animateOut,
    customAnimateOut: false,
    animateOutStart,
  };

  return animation;
};

const addTweenTimeline = (item) => {
  const {
    timeline,
    animation,
    timeFrom,
    timeTo,
  } = item;
  timeline.add(animation.tweenFromTo(timeFrom, timeTo));
};

const setTweenTimeline = (item) => {
  const {
    timeline,
    target,
    animation,
    time,
  } = item;
  // console.log('setTweenTimeline ', target.classList.value, animation, timeline, time);

  if (timeline) {
    // adding timeout to make sure the element is ready before setting up the animation
    // TODO: look for better work around here?
    setTimeout(() => {
      timeline.set(target, animation, time);
    }, 250);
  }
};

const toTweenTimeline = (item) => {
  const {
    timeline,
    target,
    duration,
    animation,
    time,
  } = item;
  // console.log('toTweenTimeline', target.classList.value, animation, time);

  if (timeline && animation) {
    // adding timeout to make sure the element is ready before setting up the animation
    // TODO: look for better work around here?
    setTimeout(() => {
      if (time) timeline.to(target, duration, animation, time);
      else timeline.to(target, duration, animation);
    }, 250);
  }
};

const fromToTweenTimeline = (item) => {
  const {
    timeline,
    target,
    speed,
    fromAnimation,
    toAnimation,
    time,
  } = item;

  if (timeline) {
    timeline.fromTo(target, speed, fromAnimation, toAnimation, time);
  }
};

const staggerTweenTimeline = (item) => {
  const {
    timeline,
    target,
    duration,
    animation,
    time,
    stagger,
  } = item;

  if (timeline) {
    timeline.staggerTo(target, duration, animation, stagger, time);
  }
};

const convertAnimationDuringSpeed = (isInput, value, speed) => {
  /**
   * https://app.clickup.com/t/1vyjpxg
   * For emphasis animation, need to convert the old value to new value
   *
   * Heartbeat - 2X speed (current 100% = new 50%)
   * Flash - 2X speed (current 100% = new 50%)
   * Float - 2X speed (current 100% = new 50%)
   * Shake - Keep
   * Slide - 5X speed (current 100% = new 20%)
   * Zoom in - 5X speed (current 100% = new 20%)
   * Zoom out - 5X speed (current 100% = new 20%)
   * Swing - 2X speed (current 100% = new 50%)
   * Rotate left/right - 5X speed (Current 100% = new 20%)
   */

  const selectedAnimation = AnimationList[1].list.find(animation => animation.value === value);
  const multiplier = (selectedAnimation && selectedAnimation.multiplier) || 1;

  // console.log('convertAnimationDuringSpeed', value, speed, multiplier);
  if (isInput) {
    return speed * multiplier;
  }

  return speed / multiplier;
};

const setAnimationDuring = (item) => {
  let { startingTime } = item;
  const {
    targetDuringElement,
    animationDuringDuration,
    timelineSettings,
    targetElementEffectContainer,
    targetElementText,
    tweenMaxTimeline,
    canvasZoom,
    fontSize,
  } = item;
  const {
    animateDuringValue,
    animateDuringDirection,
    animateDuringSpeed,
    isAnimateGlitchEffect,
    glitchEffectSpeed,
    glitchEffectSettings,
    isAnimateLightsweepEffect,
    lightsweepEffectSpeed,
    lightsweepEffectSettings,
  } = timelineSettings;

  // console.log('setAnimationDuring', targetDuringElement, animateDuringValue, startingTime, animationDuringDuration)

  if (startingTime === fauxZero) {
    startingTime = 0;
  }

  const targetDuringElementText = targetDuringElement.getElementsByClassName('split-text__during');

  if (animateDuringValue === 'shake') {
    const x = 5;
    const canvasDistance = x * canvasZoom;
    const shakeAnim = {
      x: canvasDistance,
      repeat: -1,
      yoyo: true,
      ease: Sine.easeInOut,
    };
    const speed = 0.10 / animateDuringSpeed;
    // console.log('speed is', speed, canvasDistance, canvasZoom, x);

    fromToTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetDuringElement,
      speed,
      fromAnimation: {
        x: 0,
        y: 0,
        rotation: 0,
      },
      toAnimation: shakeAnim,
      time: `Start+=${startingTime}`,
    });
  } else if (animateDuringValue === 'slide') {
    const pixelPerSec = 30;
    const distance = pixelPerSec * animationDuringDuration * animateDuringSpeed;
    const canvasDistance = distance * canvasZoom;
    // console.table({
    //   margin,
    //   pixelPerSec,
    //   animationDuringDuration,
    //   animateDuringSpeed,
    //   distance,
    //   canvasZoom,
    //   canvasDistance,
    // });
    const tweenAnimateDuringSlide = {
      ease: Linear.easeNone,
    };

    if (animateDuringDirection === 'to-top') {
      tweenAnimateDuringSlide.y = `-=${canvasDistance}`;
    } else if (animateDuringDirection === 'to-bottom') {
      tweenAnimateDuringSlide.y = `+=${canvasDistance}`;
    } else if (animateDuringDirection === 'to-left') {
      tweenAnimateDuringSlide.x = `-=${canvasDistance}`;
    } else if (animateDuringDirection === 'to-right') {
      tweenAnimateDuringSlide.x = `+=${canvasDistance}`;
    }

    toTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetDuringElement,
      duration: animationDuringDuration,
      animation: tweenAnimateDuringSlide,
      time: `Start+=${startingTime}`,
    });
  } else if (animateDuringValue === 'slow-scale-in') {
    const scale = 1 + (1 / 40 * animationDuringDuration) * animateDuringSpeed;
    // console.log('scale', scale)

    toTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetDuringElement,
      duration: animationDuringDuration,
      animation: {
        scale,
        ease: Linear.easeNone,
      },
      time: `Start+=${startingTime}`,
    });
  } else if (animateDuringValue === 'slow-scale-out') {
    const scale = 1 - (1 / 40 * animationDuringDuration) * animateDuringSpeed;

    toTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetDuringElement,
      duration: animationDuringDuration,
      animation: {
        scale,
        ease: Linear.easeNone,
      },
      time: `Start+=${startingTime}`,
    });
  } else if (animateDuringValue === 'swing') {
    const speed = 0.50 / animateDuringSpeed;
    const swingAnim = {
      rotationZ: -2,
      transformOrigin: 'center top',
      repeat: -1,
      yoyo: true,
      ease: Sine.easeInOut,
    };

    toTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetDuringElement,
      duration: speed,
      animation: swingAnim,
      time: `Start+=${startingTime}`,
    });
    // console.log(parseFloat(animationDuringStart + 0.1))
  } else if (animateDuringValue === 'rotate-ccw' || animateDuringValue === 'rotate-cw') {
    const secondPerRotation = 12;
    const duration = secondPerRotation / animateDuringSpeed;
    const rotationZ = animateDuringValue === 'rotate-ccw' ? -360 : 360;

    const rotateAnim = {
      rotationZ,
      transformOrigin: 'center center',
      repeat: -1,
      ease: Linear.easeNone,
    };

    if (startingTime <= fauxZero) startingTime = fauxZero;

    toTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetDuringElement,
      duration,
      animation: rotateAnim,
      time: `Start+=${startingTime}`,
    });
  } else if (animateDuringValue === 'floating') {
    const duration = 1.5 / animateDuringSpeed;
    const y = -30 * canvasZoom;
    const floatAnim = {
      y,
      repeat: -1,
      yoyo: true,
      ease: Sine.easeInOut,
    };

    if (startingTime <= fauxZero) startingTime = fauxZero;

    toTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetDuringElement,
      duration,
      animation: floatAnim,
      time: `Start+=${startingTime}`,
    });
  } else if (animateDuringValue === 'flash') {
    const duration = 0.5 / animateDuringSpeed;
    const flashAnim = {
      autoAlpha: 0,
      repeat: -1,
      yoyo: true,
      ease: Sine.easeInOut,
    };

    if (startingTime <= fauxZero) startingTime = fauxZero;

    toTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetDuringElement,
      duration,
      animation: flashAnim,
      time: `Start+=${startingTime}`,
    });
  } else if (animateDuringValue === 'heartbeat') {
    const duration = 1 / animateDuringSpeed;
    const durationPerAnim = duration / 4;

    const maxLoop = Math.ceil(animationDuringDuration / duration);

    if (startingTime <= fauxZero) startingTime = fauxZero;
    let loopedStartingTime = startingTime;

    for (let i = 0; i < maxLoop; i += 1) {
      toTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetDuringElement,
        duration: durationPerAnim,
        animation: {
          scale: 1.1,
          ease: Sine.easeInOut,
        },
        time: `Start+=${loopedStartingTime}`,
      });

      toTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetDuringElement,
        duration: 2 * durationPerAnim,
        animation: {
          scale: 1,
          ease: Sine.easeInOut,
        },
        time: `Start+=${loopedStartingTime + 2 * durationPerAnim}`,
      });

      loopedStartingTime += duration;
    }
  } else if (animateDuringValue === 'snake' && targetElementText.length) {
    const duration = 1 / animateDuringSpeed;
    const stagger = 0.2 / animateDuringSpeed;
    const snakeAnim = {
      repeat: -1,
      yoyo: true,
      ease: Sine.easeInOut,
    };

    if (startingTime <= fauxZero) startingTime = fauxZero;

    if (targetDuringElementText.length) {
      snakeAnim.y = targetDuringElementText[0].offsetHeight / 3;

      staggerTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetDuringElementText,
        duration,
        animation: snakeAnim,
        stagger,
        time: `Start+=${startingTime}`,
      });
    }
  } else if (animateDuringValue === 'peek' && targetDuringElementText.length) {
    // each letter will take 1 / animateDuringSpeed to animate
    // the animation is divided into 4 steps
    const duration = 1 / animateDuringSpeed;
    const durationPerAnim = duration / 4;

    const totalAnimationDuration = durationPerAnim * targetDuringElementText.length;
    const maxLoop = Math.ceil(animationDuringDuration / totalAnimationDuration);

    if (startingTime <= fauxZero) startingTime = fauxZero;
    let loopedStartingTime = startingTime;

    for (let index = 0; index < targetDuringElementText.length; index += 1) {
      const y = targetDuringElementText[index].offsetHeight / 9;
      loopedStartingTime = startingTime;

      // we need to loop this animation multiple times
      for (let i = 0; i < maxLoop; i += 1) {
        // from 0 - 0.25
        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetDuringElementText[index],
          duration: durationPerAnim,
          animation: {
            rotationX: '30deg',
            rotation: '-13deg',
            y,
            scale: 1,
          },
          time: `Start+=${loopedStartingTime}`,
        });

        // from 0.25 - 0.5
        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetDuringElementText[index],
          duration: durationPerAnim,
          animation: {
            rotationX: '10deg',
            rotation: '3deg',
            y: y * -2,
            scale: 1.1,
          },
          time: `Start+=${loopedStartingTime + durationPerAnim}`,
        });

        // from 0.5 - 1
        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetDuringElementText[index],
          duration: durationPerAnim * 2,
          animation: {
            rotationZ: 0,
            rotation: 0,
            y: 0,
            scale: 1,
          },
          time: `Start+=${loopedStartingTime + durationPerAnim * 2}`,
        });

        loopedStartingTime += totalAnimationDuration;
      }

      startingTime += durationPerAnim;
    }
  }

  if (isAnimateLightsweepEffect) {
    const {
      elementType,
      selectedAngle,
      radius,
      isLoop,
      elementRatio,
      elementProportion,
    } = lightsweepEffectSettings;
    const isTextLightSweep = elementType === groups.TEXTS;

    const angleX = selectedAngle && selectedAngle.x;
    const angleY = selectedAngle && selectedAngle.y;
    const angle = angleToNumber(angleX, angleY);
    const isVerticalSweep = isVerticalLightSweep(angle);
    const isNotStraightSweep = angleX !== 'center' && angleY !== 'center'; // to target 135 and 45 degree angles

    let angleType = 'straight-horizontal';
    if (isVerticalSweep) angleType = 'straight-vertical';
    if (isNotStraightSweep) angleType = 'tilt'; // means 135 / 45 degree

    if (startingTime <= fauxZero) startingTime = fauxZero;
    // console.log('got in lightsweep', lightsweepEffectSpeed, lightsweepEffectSettings, elementType, angleType);
    // console.log({ startingTime }, !!targetElementEffectContainer, isTextLightSweep);
    if (radius) {
      if (isTextLightSweep) {
        const convertedElementFontSize = fontSize * canvasZoom;
        const getTextLightsweepWidth = targetElementEffectContainer.lightsweep.offsetWidth;
        const getTextLightsweepHeight = targetElementEffectContainer.lightsweep.offsetHeight;

        const convertedBackgroundSize = getTextLightSweepBackgroundSize(convertedElementFontSize, getTextLightsweepHeight, angleType, radius);
        const convertedSpeed = effectsMaxValues.TEXT_LIGHTSWEEP_SPEED - (lightsweepEffectSpeed * 0.03) + effectsMinValues.TEXT_LIGHTSWEEP_SPEED; // because the values are reversed

        const isReverseAngle = isReversedAngle(angleX, angleY);
        const bgPositionStartPoint = angleType === 'straight-vertical' ? `50% -${convertedBackgroundSize}px` : `-${convertedBackgroundSize}px center`; // must minus so the lightsweep cannot be seen at the start time
        const bgPositionEndPoint = angleType === 'straight-vertical' ? `50% ${getTextLightsweepHeight}px` : `${getTextLightsweepWidth}px center`;
        const startPoint = !isReverseAngle ? bgPositionStartPoint : bgPositionEndPoint;
        const endPoint = !isReverseAngle ? bgPositionEndPoint : bgPositionStartPoint;

        const textLightSweepAnim = {
          backgroundPosition: endPoint,
          repeat: isLoop ? -1 : 0, // -1 for infinite loop, 0 for no loop
          ease: Sine.easeIn,
        };

        if (targetElementEffectContainer.lightsweep) {
          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementEffectContainer.lightsweep,
            animation: { backgroundPosition: startPoint },
            time: `Start+=${startingTime}`,
          });
          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementEffectContainer.lightsweep,
            duration: convertedSpeed,
            animation: textLightSweepAnim,
            time: `Start+=${startingTime}`,
          });
        }
      } else {
        // for image, svg, mask, branded graphic elements
        const convertedSpeed = effectsMaxValues.MEDIA_LIGHTSWEEP_SPEED - (lightsweepEffectSpeed * 0.05) + effectsMinValues.MEDIA_LIGHTSWEEP_SPEED; // because the values are reversed
        const isPortraitProportion = elementProportion === 'portrait';
        const isSquareProportion = elementProportion === 'square';
        const reverseNotSupport = isSquareProportion || isPortraitProportion;
        const isReverseAngle = isReversedAngle(angleX, angleY, reverseNotSupport);

        const maskSize = 100 + (radius * 100);
        let startMaskPosition = '0 0';
        let endMaskPosition = '0 0';

        if (angleType !== 'tilt') {
          startMaskPosition = imageStraightLightSweepMaskPosition[angleType][maskSize].maskStartPoint;
          endMaskPosition = imageStraightLightSweepMaskPosition[angleType][maskSize].maskEndPoint;
        } else {
          startMaskPosition = getTiltLightSweepMaskPosition(elementRatio, maskSize, elementProportion).maskStartPoint;
          endMaskPosition = getTiltLightSweepMaskPosition(elementRatio, maskSize, elementProportion).maskEndPoint;
        }

        const startPoint = !isReverseAngle ? startMaskPosition : endMaskPosition;
        const endPoint = !isReverseAngle ? endMaskPosition : startMaskPosition;

        const imageLightSweepAnim = {
          webkitMaskPosition: endPoint,
          repeat: isLoop ? -1 : 0, // -1 for infinite loop, 0 for no loop
          ease: Sine.easeIn,
        };

        if (targetElementEffectContainer.lightsweep) {
          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementEffectContainer.lightsweep,
            animation: { webkitMaskPosition: startPoint },
            time: `Start+=${startingTime}`,
          });
          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementEffectContainer.lightsweep,
            duration: convertedSpeed,
            animation: imageLightSweepAnim,
            time: `Start+=${startingTime}`,
          });
        }
      }
    }
  }

  /* eslint-disable */
  if (isAnimateGlitchEffect) {
    const {
      elementType,
      distance,
    } = glitchEffectSettings;
    const isTextGlitch = elementType === groups.TEXTS;

    if (startingTime <= fauxZero) startingTime = fauxZero;
    // console.log('got in glitch', glitchEffectSpeed, glitchEffectSettings, glitchEffectSettings.elementType);
    // console.log({ startingTime }, targetElementEffectContainer);

    if (isTextGlitch) {
      const glitchSteps = 20;
      const convertedGlitchDuration = effectsMaxValues.TEXT_GLITCH_SPEED - (glitchEffectSpeed * 0.05) + effectsMinValues.TEXT_GLITCH_SPEED; // because the values are reversed
      // preset of glitch duration so can perform well
      const glitchTotalDuration = {
        main: convertedGlitchDuration,
        before: 5 + convertedGlitchDuration,
        after: convertedGlitchDuration,
      };

      const durationPerGlitchBeforeAnim = glitchTotalDuration.before / glitchSteps;
      const durationPerGlitchAfterAnim = glitchTotalDuration.after / glitchSteps;
      const maxGlitchBeforeAnimLoop = Math.ceil(animationDuringDuration / glitchTotalDuration.before);
      const maxGlitchAfterAnimLoop = Math.ceil(animationDuringDuration / glitchTotalDuration.after);

      const getTextGlitchHeight = targetElementEffectContainer.glitchBefore.offsetHeight;
      const convertedElementFontSize = fontSize * canvasZoom;

      // on a text element, getting how many line the text has ( entered sentences or text )
      let getTextVerticalLineAmount = convertedElementFontSize ? Math.round(getTextGlitchHeight / convertedElementFontSize) : 1;
      getTextVerticalLineAmount = getTextVerticalLineAmount || 1;

      // because the bigger the font size is, the bigger the glitch should be. ( since text glitch cannot use percentage, and the current sequence is for fonts around 100px )
      let getNeededTextGlitchMultiplier = 1;
      if (convertedElementFontSize > 250 * canvasZoom) getNeededTextGlitchMultiplier = 3;
      else if (convertedElementFontSize > 200 * canvasZoom) getNeededTextGlitchMultiplier = 2.5;
      else if (convertedElementFontSize > 150 * canvasZoom) getNeededTextGlitchMultiplier = 2;
      else if (convertedElementFontSize > 100 * canvasZoom) getNeededTextGlitchMultiplier = 1.5;

      const getTextGlitchMultiplier = getTextVerticalLineAmount * getNeededTextGlitchMultiplier;

      let loopedTextGlitchBeforeAnimStartingTime = startingTime;
      let loopedTextGlitchAfterAnimStartingTime = startingTime;

      // loop the animation multiple times for glitch--before section
      for (let i = 0; i < maxGlitchBeforeAnimLoop; i++) {
        for (let index = 0; index < glitchSteps; index++) {
          // calculate current step with duration, to create keyframe scheme
          // e.g - 10 * (1 / 20) = 10 * 0.05 = 0.5 , then calculate with duration -> e.g 5s, then 5 * 0.5 = 2.5s. and so on because "getCurrentStep" value is based by index
          const getCurrentStep = index * (1 / glitchSteps);
          const glitchBeforeStartPoint = glitchTotalDuration.before * getCurrentStep;
          const convertedClipBeforeSequence0 = textGlitchSequence[index].clipBefore[0] * canvasZoom * getTextGlitchMultiplier; // avoid issue glitch not showing on 2nd line or 3rd line of a text element
          const convertedClipBeforeSequence1 = textGlitchSequence[index].clipBefore[1] * canvasZoom * getTextGlitchMultiplier; // avoid issue glitch not showing on 2nd line or 3rd line of a text element

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementEffectContainer.glitchBefore,
            duration: durationPerGlitchBeforeAnim,
            animation: {
              clip: `rect(${convertedClipBeforeSequence0}px, 9999px, ${convertedClipBeforeSequence1}px, 0)`,
            },
            time: `Start+=${loopedTextGlitchBeforeAnimStartingTime + glitchBeforeStartPoint}`,
          });
        }

        loopedTextGlitchBeforeAnimStartingTime = glitchTotalDuration.before + loopedTextGlitchBeforeAnimStartingTime;
      }

      // loop the animation multiple times for glitch--after section
      for (let i = 0; i < maxGlitchAfterAnimLoop; i++) {
        for (let index = 0; index < glitchSteps; index++) {
          // calculate current step with duration, to create keyframe scheme
          // e.g - 10 * (1 / 20) = 10 * 0.05 = 0.5 , then calculate with duration -> e.g 5s, then 5 * 0.5 = 2.5s. and so on because "getCurrentStep" value is based by index
          const getCurrentStep = index * (1 / glitchSteps);
          const glitchAfterStartPoint = glitchTotalDuration.after * getCurrentStep;
          const convertedClipAfterSequence0 = textGlitchSequence[index].clipAfter[0] * canvasZoom * getTextGlitchMultiplier; // avoid issue glitch not showing on 2nd line or 3rd line of a text element
          const convertedClipAfterSequence1 = textGlitchSequence[index].clipAfter[1] * canvasZoom * getTextGlitchMultiplier; // avoid issue glitch not showing on 2nd line or 3rd line of a text element

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementEffectContainer.glitchAfter,
            duration: durationPerGlitchAfterAnim,
            animation: {
              clip: `rect(${convertedClipAfterSequence0}px, 9999px, ${convertedClipAfterSequence1}px, 0)`,
            },
            time: `Start+=${loopedTextGlitchAfterAnimStartingTime + glitchAfterStartPoint}`,
          });
        }

        loopedTextGlitchAfterAnimStartingTime = glitchTotalDuration.after + loopedTextGlitchAfterAnimStartingTime;
      }
    } else {
      // image glitch section
      const horizontalGap = 10 * distance * canvasZoom;
      const verticalGap = 5 * distance * canvasZoom;
      const imageGlitchAnimationTime = effectsMaxValues.IMAGE_GLITCH_SPEED - (glitchEffectSpeed * 0.07) + effectsMinValues.IMAGE_GLITCH_SPEED; // because the values are reversed

      // "transform: translate3d()" parameters = "translate3d(tx, ty, tz)"
      const txCalculation = -1 * horizontalGap + 'px';
      const tyCalculation = -1 * verticalGap + 'px';
      const xTranslateImageGlitchItem2Values = [
        txCalculation,
        txCalculation,
        0,
        0,
      ];
      const yTranslateImageGlitchItem3Values = [
        tyCalculation,
        tyCalculation,
        0,
        0,
      ];

      const durationPerImageGlitchAnimation = imageGlitchAnimationTime / 100; // css keyframe started from 0 to 100%. if 5 seconds, means 1% = 0.05s.
      const maxImageGlitchLoop = Math.ceil(animationDuringDuration / imageGlitchAnimationTime);

      // #image-glitch-item-2 section
      let loopedImageGlitch2AnimStartingTime = startingTime;

      for (let i = 0; i < maxImageGlitchLoop; i++) {
        const imageGlitchItem2 = imageGlitchSequence.imageGlitchItem2;

        // clip-path section
        for (let index = 0; index < imageGlitchItem2.totalClipPathSequence; index++) {
          const currentStep = imageGlitchItem2.clipPathSequence[index];
          const getCurrentClipPathValue = imageGlitchItem2.clipPathValues[index];

          // example -> startPoint = 10 * 4(duration) / 100 = 0.4s, so 10th step or 10% of animation with 4 seconds duration is 0.4s
          const startPoint = currentStep * durationPerImageGlitchAnimation;

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementEffectContainer.glitch2,
            duration: durationPerImageGlitchAnimation,
            animation: {
              webkitClipPath: getCurrentClipPathValue,
            },
            time: `Start+=${loopedImageGlitch2AnimStartingTime + startPoint}`,
          });
        }

        // opacity transform section
        for (let index = 0; index < imageGlitchItem2.totalOpacityTransformSequence; index++) {
          const currentStep = imageGlitchItem2.opacityTransformSequence[index];
          const getCurrentOpacityValue = imageGlitchItem2.opacityValues[index];
          const getCurrentXTranslateValue = xTranslateImageGlitchItem2Values[index];

          // example -> startPoint = 10 * 4(duration) / 100 = 0.4s, so 10th step or 10% of animation with 4 seconds duration is 0.4s
          const startPoint = currentStep * durationPerImageGlitchAnimation;

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementEffectContainer.glitch2,
            duration: durationPerImageGlitchAnimation,
            animation: {
              x: getCurrentXTranslateValue,
              y: 0,
              z: 0,
              opacity: getCurrentOpacityValue,
            },
            time: `Start+=${loopedImageGlitch2AnimStartingTime + startPoint}`,
          });
        }

        loopedImageGlitch2AnimStartingTime = imageGlitchAnimationTime + loopedImageGlitch2AnimStartingTime;
      }

      // #image-glitch-item-3 section
      let loopedImageGlitch3AnimStartingTime = startingTime;

      for (let i = 0; i < maxImageGlitchLoop; i++) {
        const imageGlitchItem3 = imageGlitchSequence.imageGlitchItem3;

        // clip-path section
        for (let index = 0; index < imageGlitchItem3.totalClipPathSequence; index++) {
          const currentStep = imageGlitchItem3.clipPathSequence[index];
          const getCurrentClipPathValue = imageGlitchItem3.clipPathValues[index];

          // example -> startPoint = 10 * 4(duration) / 100 = 0.4s, so 10th step or 10% of animation with 4 seconds duration is 0.4s
          const startPoint = currentStep * durationPerImageGlitchAnimation;

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementEffectContainer.glitch3,
            duration: durationPerImageGlitchAnimation,
            animation: {
              webkitClipPath: getCurrentClipPathValue,
            },
            time: `Start+=${loopedImageGlitch3AnimStartingTime + startPoint}`,
          });
        }

        // opacity transform section
        for (let index = 0; index < imageGlitchItem3.totalOpacityTransformSequence; index++) {
          const currentStep = imageGlitchItem3.opacityTransformSequence[index];
          const getCurrentOpacityValue = imageGlitchItem3.opacityValues[index];
          const getCurrentYTranslateValue = yTranslateImageGlitchItem3Values[index];

          // example -> startPoint = 10 * 4(duration) / 100 = 0.4s, so 10th step or 10% of animation with 4 seconds duration is 0.4s
          const startPoint = currentStep * durationPerImageGlitchAnimation;

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementEffectContainer.glitch3,
            duration: durationPerImageGlitchAnimation,
            animation: {
              x: 0,
              y: getCurrentYTranslateValue,
              z: 0,
              scaleX: -1,
              scaleY: -1,
              scaleZ: 1,
              opacity: getCurrentOpacityValue,
            },
            time: `Start+=${loopedImageGlitch3AnimStartingTime + startPoint}`,
          });
        }

        loopedImageGlitch3AnimStartingTime = imageGlitchAnimationTime + loopedImageGlitch3AnimStartingTime;
      }
    }
  }
  /* eslint-enable */

  // console.log('starting item', startingTime)
};

const setAnimation = (item) => {
  // console.log('!!!setAnimationAsync!!!', item);
  const {
    activeTimeline,
    sceneDuration,
    sceneTransition,
    startingTime,
    targetElement,
    targetElementEffectContainer,
    targetElementLine,
    targetElementWord,
    targetElementText,
    timelineSettings,
    currentSceneStartTime,
    sceneId,
    canvasZoom,
    canvasSize,
    tweenMaxTimeline,
    fontSize,
  } = item;

  let { endingTime } = item;

  // make sure ending time is not 0.4999999
  endingTime = Math.round(endingTime * 100) / 100;

  let {
    animateInDuration,
    animateOutDuration,
    animateOutStart,
  } = timelineSettings;

  const {
    animateInValue,
    animateInDirection,
    animateOutValue,
    animateOutDirection,
    animationDuration,
    animateInOption,
    animateOutOption,
    animateDuringValue,
    isAnimateGlitchEffect,
    isAnimateLightsweepEffect,
  } = timelineSettings;

  const animateIn = getAnimateIn({
    value: animateInValue,
    direction: animateInDirection,
    target: targetElement,
    canvasZoom,
    canvasSize,
  });

  // need to use let cause pertext will have different size
  let tweenAnimateIn = animateIn.animateIn;
  let tweenAnimateInMid = animateIn.animateInMid;
  let tweenAnimateInEnding = animateIn.animateInEnding;

  let wrapTweenAnimateIn = null;
  let wrapTweenAnimateInEnding = null;

  if (animateInValue === 'wipe-in' || animateInValue === 'mask-in') {
    const isWrapper = true;
    const targetWrap = targetElement.getElementsByClassName('element-wrap')[0];
    if (targetWrap) {
      const wrapAnimateIn = getAnimateIn({
        value: animateInValue,
        direction: animateInDirection,
        target: targetWrap,
        isWrapper,
        canvasZoom,
        canvasSize,
      });

      wrapTweenAnimateIn = wrapAnimateIn.animateIn;
      wrapTweenAnimateInEnding = wrapAnimateIn.animateInEnding;
    }
  }

  const animateOut = getAnimateOut({
    value: animateOutValue,
    direction: animateOutDirection,
    target: targetElement,
    canvasZoom,
    canvasSize,
  });

  // need to use let cause pertext will have different size
  let tweenAnimateOut = animateOut.animateOut;
  let tweenAnimateOutMid = animateOut.animateOutMid;
  let tweenAnimateOutStart = animateOut.animateOutStart;

  let wrapTweenAnimateOut = null;
  let wrapTweenAnimateOutStart = null;

  if (animateOutValue === 'wipe-out' || animateOutValue === 'mask-out') {
    const isWrapper = true;
    const targetWrap = targetElement.getElementsByClassName('element-wrap')[0];
    const wrapAnimateOut = getAnimateOut({
      value: animateOutValue,
      direction: animateOutDirection,
      target: targetWrap,
      isWrapper,
      canvasZoom,
      canvasSize,
    });

    wrapTweenAnimateOut = wrapAnimateOut.animateOut;
    wrapTweenAnimateOutStart = wrapAnimateOut.animateOutStart;
  }

  // need to update animateOutStart when there is multiple scenes
  animateOutStart += currentSceneStartTime;

  animateInDuration = animateInDuration || 1;
  animateOutDuration = animateOutDuration || 1;

  const isScenePreview = activeTimeline === 'sceneTimeline';
  const isAllScenesTimeline = activeTimeline === 'allScenesTimeline';

  // this will be the duration for continuous animation
  const animationDuringDuration = endingTime - startingTime;

  // add checker whether element exist
  if (!targetElement) return tweenMaxTimeline;

  const targetTextContainer = targetElement.getElementsByClassName('element-text-background')[0];
  const targetTypewriter = targetElement.getElementsByClassName('typewriter-line')[0];

  const targetDuringElement = targetElement.getElementsByClassName('scene__during')[0];
  const targetWrap = targetElement.getElementsByClassName('element-wrap')[0];
  const targetWrapWrapper = targetElement.getElementsByClassName('element__wipe')[0];

  // console.log('targetWrapWrapper', targetWrapWrapper)

  // console.log(SYNC', activeTimeline, startingTime, endingTime)

  // ============================
  // CLEAR ALL ANIMATION FIRST
  // ============================

  // remove these styles from the element wrap before animating it
  // transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y
  setTweenTimeline({
    timeline: tweenMaxTimeline,
    target: targetElement,
    animation: { clearProps: 'transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y, autoAlpha, opacity, visibility' },
    time: 'Start+=0',
    sceneId,
  });

  if (targetWrap) {
    setTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetWrap,
      animation: { clearProps: 'transform' },
      time: 'Start+=0',
      sceneId,
    });
  }

  if (targetWrapWrapper) {
    setTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetWrapWrapper,
      animation: { clearProps: 'transform, overflow' },
      time: 'Start+=0',
      sceneId,
    });
  }

  // ============================
  // CLEAR ALL TEXT ANIMATION &  BACKGROUND
  // ============================
  for (let index = 0; index < targetElementLine.length; index += 1) {
    // remove these styles from the element wrap before animating it
    // transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y
    const targetTextWrap = targetElementLine[index].getElementsByClassName('split-line__wrap')[0];
    if (targetTextWrap) {
      setTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetTextWrap,
        animation: { clearProps: 'transform' },
        time: 'Start+=0',
        sceneId,
      });
    }

    setTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetElementLine[index],
      animation: { clearProps: 'transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y, autoAlpha, opacity, visibility' },
      time: 'Start+=0',
      sceneId,
    });
  }

  for (let index = 0; index < targetElementWord.length; index += 1) {
    // remove these styles from the element wrap before animating it
    // transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y
    const targetTextWrap = targetElementWord[index].getElementsByClassName('split-word__wrap')[0];
    if (targetTextWrap) {
      setTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetTextWrap,
        animation: { clearProps: 'transform' },
        time: 'Start+=0',
        sceneId,
      });
    }

    setTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetElementWord[index],
      animation: { clearProps: 'transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y, autoAlpha, opacity, visibility' },
      time: 'Start+=0',
      sceneId,
    });
  }

  for (let index = 0; index < targetElementText.length; index += 1) {
    // remove these styles from the element wrap before animating it
    // transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y
    const targetTextWrap = targetElementText[index].getElementsByClassName('split-text__wrap')[0];
    if (targetTextWrap) {
      setTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetTextWrap,
        animation: { clearProps: 'transform' },
        time: 'Start+=0',
        sceneId,
      });
    }

    setTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetElementText[index],
      animation: { clearProps: 'transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y, autoAlpha, opacity, visibility' },
      time: 'Start+=0',
      sceneId,
    });
  }

  if (targetTextContainer) {
    setTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetTextContainer,
      animation: { clearProps: 'transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y, autoAlpha, opacity, visibility' },
      time: 'Start+=0',
    });
  }


  // ============================
  // SET THE BEGINNING TO OPACITY 0
  // ============================
  // always set opacity to 0 when it first start
  // scene preview only need hide and show
  // console.log('startingTime', startingTime);
  if (startingTime > 0.05 || (animateInValue && animateInOption !== 'default')) {
    if (targetTypewriter) {
      setTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetTypewriter,
        animation: { display: 'none' },
        time: 'Start+=0',
      });
    }

    if (!isScenePreview
      && (animateInOption === 'per-line'
      || animateInOption === 'per-word'
      || animateInOption === 'per-text')) {
      // set the container to 0 as well so the text-background is hidden
      setTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetElement,
        animation: { autoAlpha: 0 },
        time: 'Start+=0',
      });

      let targetElementOption = null;
      if (animateInOption === 'per-line') {
        targetElementOption = targetElementLine;
      } else if (animateInOption === 'per-word') {
        targetElementOption = targetElementWord;
      } else if (animateInOption === 'per-text') {
        targetElementOption = targetElementText;
      }

      // add timeout delay to make sure DOM is properly loaded;
      // console.log('animateInOption', animateInOption, targetElementText.length)
      for (let index = 0; index < targetElementOption.length; index += 1) {
        if (animateInValue === 'typewriter') {
          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            animation: { display: 'none' },
            time: 'Start+=0',
          });
        } else {
          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            animation: { autoAlpha: 0 },
            time: 'Start+=0',
          });
        }
      }

      if (targetTextContainer) {
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetTextContainer,
          animation: { autoAlpha: 0 },
          time: 'Start+=0',
        });
      }
    } else {
      setTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetElement,
        animation: { autoAlpha: 0 },
        time: 'Start+=0',
      });
    }
  }

  // animate in and out won't be showing
  // if the full duration is less than minDuration.showAnimation
  let disabledAnimation = false;
  if (animationDuration < minDuration.showAnimation) {
    disabledAnimation = true;
  }

  // ============================
  // SET THE ANIMATE DURING
  // ============================

  // if it's scene preview, don't need to show animation during
  if (!isScenePreview) {
    // remove these styles from the element wrap before animating it
    // transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y
    setTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetDuringElement,
      animation: { clearProps: 'transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y, autoAlpha, opacity, visibility' },
      time: 'Start+=0',
    });

    const targetDuringElementText = targetDuringElement.getElementsByClassName('split-text__during');

    if (targetDuringElementText.length) {
      for (let index = 0; index < targetDuringElementText.length; index += 1) {
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetDuringElementText[index],
          animation: { clearProps: 'transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, x, y, autoAlpha, opacity, visibility' },
          time: 'Start+=0',
        });
      }
    }

    // this function is to set the continuous animation
    const isAnimatingEffect = isAnimateGlitchEffect || isAnimateLightsweepEffect;
    if ((animateDuringValue && animateDuringValue !== 'none') || isAnimatingEffect) {
      // console.log('animateDuringValue exist', startingTime)
      setAnimationDuring({
        startingTime,
        targetDuringElement,
        animationDuringDuration,
        activeTimeline,
        timelineSettings,
        targetElementEffectContainer,
        targetElementText,
        canvasZoom,
        tweenMaxTimeline,
        fontSize,
      });

      if (animateDuringValue === 'swing') {
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetDuringElement,
          animation: {
            rotationZ: 2,
            transformOrigin: 'center top',
            repeat: -1,
            yoyo: true,
            ease: Sine.easeInOut,
          },
          time: 'Start+=0',
        });
      }
    }
  }

  // ============================
  // SET THE ANIMATE IN
  // ============================

  // scene preview only need hide and show
  if (!isScenePreview && animateInValue && animateInValue !== 'none' && !disabledAnimation) {
    // delay text letter time
    let letterStartingTime = startingTime;
    // console.log('tweenAnimateIn', tweenAnimateIn, startingTime)

    // if (isAllScenesTimeline) console.log('startingTime', startingTime)

    // console.log('animateInValue', animateInOption, animateInValue)
    if (animateInOption === 'per-line'
      || animateInOption === 'per-word'
      || animateInOption === 'per-text') {
      // set the container to 1 as well so the text-background is shown
      setTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetElement,
        animation: { autoAlpha: 1, x: 0, y: 0 },
        time: `Start+=${startingTime}`,
      });

      if (targetTextContainer) {
        // also animate text background
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetTextContainer,
          animation: tweenAnimateIn,
          time: `Start+=${letterStartingTime}`,
        });

        let letterEndingStartingTime = letterStartingTime;
        let duration = animateInDuration;

        if (tweenAnimateInMid) {
          // if there is animation midway
          // e.g. fly in
          duration = animateInDuration / 2;
          letterEndingStartingTime = letterStartingTime + duration;
          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetTextContainer,
            duration,
            animation: tweenAnimateInMid,
            time: `Start+=${letterStartingTime}`,
          });
        }

        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetTextContainer,
          duration,
          animation: tweenAnimateInEnding,
          time: `Start+=${letterEndingStartingTime}`,
        });

        // for wipe in, need to reset overflow to visible after animating
        if (animateInValue === 'wipe-in' || animateInValue === 'mask-in') {
          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetTextContainer,
            animation: { overflow: 'visible' },
            time: `Start+=${letterEndingStartingTime + duration}`,
          });
        }
      }

      if (animateInValue === 'typewriter' && targetTypewriter) {
        // for typewriter, show the typewriter line during animate in effect
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetTypewriter,
          animation: { display: 'inline-block' },
          time: `Start+=${startingTime}`,
        });

        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetTypewriter,
          animation: { display: 'none' },
          time: `Start+=${startingTime + animateInDuration}`,
        });
      }

      // add timeout delay to make sure DOM is properly loaded;
      if (!targetElementLine && !targetElementWord && !targetElementText) {
        return tweenMaxTimeline;
      }

      let targetElementOption = null;
      let durationValue = 1;

      switch (animateInOption) {
        case 'per-line':
          targetElementOption = targetElementLine;
          break;
        case 'per-word':
          targetElementOption = targetElementWord;
          break;
        case 'per-text':
          targetElementOption = targetElementText;
          break;
        default:
          break;
      }

      const totalTexts = targetElementOption.length;

      // if it's only 1, then don't need to be shorten
      if (totalTexts > 3) durationValue = 0.5;
      else if (totalTexts > 1) durationValue = 0.7;

      // each letter animation duration will be 0.5 of the full duration
      const letterDuration = durationValue * animateInDuration;
      // the remaining 0.5 of the full duration will be divided for the overlap
      const textRatio = totalTexts > 1 ? (totalTexts - 1) : 1;
      const letterTimingRatio = (animateInDuration - letterDuration) / textRatio;
      const typewriterTimingRatio = animateInDuration / totalTexts;

      // console.table({
      //   letterDuration,
      //   letterTimingRatio,
      //   totalTexts,
      // });

      // loop animation in targetElement
      for (let index = 0; index < targetElementOption.length; index += 1) {
        if (animateInValue === 'typewriter') {
          // for typewriter, show the letter as inline block
          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            animation: { display: '' },
            time: `Start+=${letterStartingTime}`,
          });
          letterStartingTime += typewriterTimingRatio;
        } else {
          // animateIn is per text so that it can get the right size
          const textAnimateIn = getAnimateIn({
            value: animateInValue,
            direction: animateInDirection,
            target: targetElementOption[index],
            canvasZoom,
            canvasSize,
          });

          tweenAnimateIn = textAnimateIn.animateIn;
          tweenAnimateInMid = textAnimateIn.animateInMid;
          tweenAnimateInEnding = textAnimateIn.animateInEnding;

          // this will be the starting styles of the animate in
          // to see what is the starting styles, console.log(tweenAnimateIn)
          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            animation: tweenAnimateIn,
            time: `Start+=${letterStartingTime}`,
          });

          let letterEndingStartingTime = letterStartingTime;
          let duration = letterDuration;

          if (tweenAnimateInMid) {
            // if there is animation midway
            // e.g. fly in
            duration /= 2;
            letterEndingStartingTime = letterStartingTime + duration;
            toTweenTimeline({
              timeline: tweenMaxTimeline,
              target: targetElementOption[index],
              duration,
              animation: tweenAnimateInMid,
              time: `Start+=${letterStartingTime}`,
            });
          }

          // this will be the final state of styles of animate in
          // to see what is the starting styles, console.log(tweenAnimateInEnding);
          // to see how long is the duration, console.log(animateInDuration);
          if (animateInValue === 'wipe-in' || animateInValue === 'mask-in') {
            duration -= letterTimingRatio;
          }

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            duration,
            animation: tweenAnimateInEnding,
            time: `Start+=${letterEndingStartingTime}`,
          });

          // console.log('tweenAnimateIn', letterEndingStartingTime, tweenAnimateIn);
          // console.log('tweenAnimateInEnding', letterEndingStartingTime + duration, tweenAnimateInEnding);

          if (animateInValue === 'wipe-in' || animateInValue === 'mask-in') {
            setTweenTimeline({
              timeline: tweenMaxTimeline,
              target: targetElementOption[index],
              animation: { overflow: 'visible' },
              time: `Start+=${letterEndingStartingTime + duration}`,
            });

            const isWrapper = true;
            let targetTextWrap = '';

            if (animateInOption === 'per-line') {
              // eslint-disable-next-line
              targetTextWrap = targetElementOption[index].getElementsByClassName('split-line__wrap')[0];
            } else if (animateInOption === 'per-word') {
              // eslint-disable-next-line
              targetTextWrap = targetElementOption[index].getElementsByClassName('split-word__wrap')[0];
            } else if (animateInOption === 'per-text') {
              // eslint-disable-next-line
              targetTextWrap = targetElementOption[index].getElementsByClassName('split-text__wrap')[0];
            }

            if (targetTextWrap) {
              const wrapAnimateIn = getAnimateIn({
                value: animateInValue,
                direction: animateInDirection,
                target: targetTextWrap,
                isWrapper,
                canvasZoom,
                canvasSize,
              });

              wrapTweenAnimateIn = wrapAnimateIn.animateIn;
              wrapTweenAnimateInEnding = wrapAnimateIn.animateInEnding;

              setTweenTimeline({
                timeline: tweenMaxTimeline,
                target: targetTextWrap,
                animation: wrapTweenAnimateIn,
                time: `Start+=${letterStartingTime}`,
              });

              toTweenTimeline({
                timeline: tweenMaxTimeline,
                target: targetTextWrap,
                duration,
                animation: wrapTweenAnimateInEnding,
                time: `Start+=${letterStartingTime}`,
              });
            }
          }

          letterStartingTime += letterTimingRatio;
        }
      }
    } else {
      if (!targetElement) {
        return tweenMaxTimeline;
      }
      // this will be the starting styles of the animate in
      // to see what is the starting styles, console.log(tweenAnimateIn);
      let endingStartingTime = startingTime;
      let duration = animateInDuration;

      if (animateInValue !== 'wipe-in' && animateInValue !== 'mask-in') {
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElement,
          animation: tweenAnimateIn,
          time: `Start+=${startingTime}`,
        });

        if (tweenAnimateInMid) {
          // if there is animation midway
          // e.g. fly in
          duration = animateInDuration / 2;
          endingStartingTime = startingTime + duration;
          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElement,
            duration,
            animation: tweenAnimateInMid,
            time: `Start+=${startingTime}`,
          });
        }

        // this will be the final state of styles of animate in
        // to see what is the starting styles, console.log(tweenAnimateInEnding);
        // to see how long is the duration, console.log(animateInDuration);
        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElement,
          duration,
          animation: tweenAnimateInEnding,
          time: `Start+=${endingStartingTime}`,
        });
      } else {
        // set the container opacity to 0
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElement,
          animation: { autoAlpha: 1 },
          time: `Start+=${startingTime}`,
        });
      }

      // for wipe, we need to add extra container and animate it
      if (targetWrap && wrapTweenAnimateIn) {
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetWrapWrapper,
          animation: tweenAnimateIn,
          time: `Start+=${startingTime}`,
        });

        // console.log('tweenAnimateIn', tweenAnimateIn, tweenAnimateIn.x)

        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetWrapWrapper,
          duration,
          animation: tweenAnimateInEnding,
          time: `Start+=${startingTime}`,
        });

        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetWrap,
          animation: wrapTweenAnimateIn,
          time: `Start+=${startingTime}`,
        });

        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetWrap,
          duration,
          animation: wrapTweenAnimateInEnding,
          time: `Start+=${startingTime}`,
        });
      }

      // for wipe in, need to reset overflow to visible after animating
      if (targetWrapWrapper
        && (animateInValue === 'wipe-in' || animateInValue === 'mask-in')) {
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetWrapWrapper,
          animation: { overflow: 'visible' },
          time: `Start+=${startingTime + animateInDuration}`,
        });
      }
    }
  } else {
    if (!targetElement) {
      return tweenMaxTimeline;
    }

    // if there is animate in, or animation is disabled,
    // set opacity to 1 and transform position to  be 0, 0
    setTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetElement,
      animation: { autoAlpha: 1, x: 0, y: 0 },
      time: `Start+=${startingTime}`,
    });

    if (animateInOption === 'per-line'
      || animateInOption === 'per-word'
      || animateInOption === 'per-text') {
      if (targetTextContainer) {
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetTextContainer,
          animation: { autoAlpha: 1, x: 0, y: 0 },
          time: `Start+=${startingTime}`,
        });
      }

      let targetElementOption = null;
      if (animateInOption === 'per-line') {
        targetElementOption = targetElementLine;
      } else if (animateInOption === 'per-word') {
        targetElementOption = targetElementWord;
      } else if (animateInOption === 'per-text') {
        targetElementOption = targetElementText;
      }

      // console.log('animateInOption', animateInOption, targetElementText.length)
      for (let index = 0; index < targetElementOption.length; index += 1) {
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElementOption[index],
          animation: { autoAlpha: 1, x: 0, y: 0 },
          time: `Start+=${startingTime}`,
        });
      }
    }
  }


  // ============================
  // SET THE ANIMATE OUT
  // ============================

  // NOTE:
  // if this is isAllScenesTimeline and there is transition, add 1 second to the animate out

  // console.log('set animate out', animateOutValue, animateOutStart);
  if (!isScenePreview && animateOutValue && animateOutValue !== 'none' && !disabledAnimation) {
    if (!targetElement) {
      return tweenMaxTimeline;
    }

    // delay text letter time
    let letterStartEndingTime = animateOutStart;
    // console.log('animate out is', animateOutOption, !!targetTextContainer);

    if (animateOutOption === 'per-line'
      || animateOutOption === 'per-word'
      || animateOutOption === 'per-text') {
      if (animateOutValue === 'typewriter' && targetTypewriter) {
        // for typewriter, show the typewriter line during animate out effect
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetTypewriter,
          animation: { display: 'inline-block' },
          time: `Start+=${animateOutStart}`,
        });
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetTypewriter,
          animation: { display: 'none' },
          time: `Start+=${animateOutStart + animateOutDuration}`,
        });
      }

      let targetElementOption = null;
      let durationValue = 1;

      switch (animateOutOption) {
        case 'per-line':
          targetElementOption = targetElementLine;
          break;

        case 'per-word':
          targetElementOption = targetElementWord;
          break;

        case 'per-text':
          targetElementOption = targetElementText;
          break;

        default:
          break;
      }

      const totalTexts = targetElementOption.length;

      // if it's only 1, then don't need to be shorten
      if (totalTexts > 3) durationValue = 0.5;
      else if (totalTexts > 1) durationValue = 0.7;

      // each letter animation duration will be 0.5 of the full duration
      const letterDuration = durationValue * animateOutDuration;

      // the remaining 0.5 of the full duration will be divided for the overlap
      const textRatio = totalTexts > 1 ? (totalTexts - 1) : 1;
      const letterTimingRatio = (animateOutDuration - letterDuration) / textRatio;
      const typewriterTimingRatio = animateOutDuration / totalTexts;

      // console.log('letterTimingRatio', letterTimingRatio, totalTexts);

      const lastIndex = targetElementOption.length - 1;
      for (let index = 0; index < targetElementOption.length; index += 1) {
        // console.log('index', index)
        if (animateOutValue === 'typewriter') {
          // for typewriter, show the letter as none
          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[lastIndex - index],
            animation: { display: 'none' },
            time: `Start+=${letterStartEndingTime}`,
          });

          letterStartEndingTime += typewriterTimingRatio;
        } else if (animateOutValue === 'hinge') {
          const textAnimateOut = getAnimateOut({
            value: animateOutValue,
            direction: animateOutDirection,
            target: targetElementOption[index],
            canvasZoom,
            canvasSize,
          });

          // console.log('textAnimateOut', animateOutValue, animateOutDirection, textAnimateOut)

          tweenAnimateOut = textAnimateOut.animateOut;
          tweenAnimateOutMid = textAnimateOut.animateOutMid;
          tweenAnimateOutStart = textAnimateOut.animateOutStart;

          const letterEndingTime = letterStartEndingTime;

          const duration = animateOutDuration / 7;
          const { transformOrigin } = tweenAnimateOut;

          const isTop = transformOrigin.indexOf('top') >= 0;
          const isRight = transformOrigin.indexOf('right') >= 0;

          let angle1 = isTop ? '80deg' : '170deg';
          let angle2 = isTop ? '60deg' : '150deg';
          let angle3 = isTop ? '30deg' : '120deg';

          if (isRight) {
            angle1 = `-${angle1}`;
            angle2 = `-${angle2}`;
            angle3 = `-${angle3}`;
          }

          tweenAnimateOut.rotationZ = angle3;

          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            animation: tweenAnimateOutStart,
            time: `Start+=${letterEndingTime}`,
          });

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            duration,
            animation: { rotationZ: angle1, ease: Sine.easeInOut },
            time: `Start+=${letterEndingTime}`,
          });

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            duration,
            animation: { rotationZ: angle2, ease: Sine.easeInOut },
            time: `Start+=${letterEndingTime + duration}`,
          });

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            duration,
            animation: { rotationZ: angle1, ease: Sine.easeInOut },
            time: `Start+=${letterEndingTime + 2 * duration}`,
          });

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            duration,
            animation: { rotationZ: angle2, ease: Sine.easeInOut, autoAlpha: 1 },
            time: `Start+=${letterEndingTime + 3 * duration}`,
          });

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElementOption[index],
            duration,
            animation: tweenAnimateOut,
            time: `Start+=${letterEndingTime + 4 * duration}`,
          });

          letterStartEndingTime += letterTimingRatio;
        } else {
          // this will be the starting styles of the animate in
          // to see what is the starting styles, console.log(tweenAnimateOut);
          // to see how long is the duration, console.log(animateOutDuration);
          // to see when the animate out will start, console.log(animateOutStart);

          const target = targetElementOption[index];

          // for animate per word and letter, there is extra padding need to be added to wipe out
          let extraPaddingX = 0;
          let extraPaddingY = 0;
          let targetTextWrap = '';

          if (animateOutValue === 'wipe-out' || animateOutValue === 'mask-out') {
            if (animateOutOption === 'per-line') {
              // eslint-disable-next-line prefer-destructuring
              targetTextWrap = target.getElementsByClassName('split-line__wrap')[0];
            } else if (animateOutOption === 'per-word') {
              // eslint-disable-next-line prefer-destructuring
              targetTextWrap = target.getElementsByClassName('split-word__wrap')[0];
              if (target && targetTextWrap) {
                extraPaddingX = target.clientWidth - targetTextWrap.clientWidth;
                extraPaddingY = target.clientHeight - targetTextWrap.clientHeight;
              }
            } else if (animateOutOption === 'per-text') {
              // eslint-disable-next-line prefer-destructuring
              targetTextWrap = target.getElementsByClassName('split-text__wrap')[0];
              if (target && targetTextWrap) {
                extraPaddingX = target.clientWidth - targetTextWrap.clientWidth;
                extraPaddingY = target.clientHeight - targetTextWrap.clientHeight;
              }
            }
          }

          const textAnimateOut = getAnimateOut({
            value: animateOutValue,
            direction: animateOutDirection,
            target,
            canvasZoom,
            canvasSize,
          });

          // console.log('textAnimateOut', animateOutValue, animateOutDirection, textAnimateOut)

          tweenAnimateOut = textAnimateOut.animateOut;
          tweenAnimateOutMid = textAnimateOut.animateOutMid;
          tweenAnimateOutStart = textAnimateOut.animateOutStart;

          let letterEndingTime = letterStartEndingTime;
          let duration = letterDuration;

          // for wipe out, need to set overflow to hidden before animating
          if (animateOutValue === 'wipe-out' || animateOutValue === 'mask-out') {
            setTweenTimeline({
              timeline: tweenMaxTimeline,
              target,
              animation: { overflow: 'hidden' },
              time: `Start+=${letterStartEndingTime}`,
            });
          }

          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target,
            animation: tweenAnimateOutStart,
            time: `Start+=${letterEndingTime}`,
          });
          // console.log('tweenAnimateoutstart', letterEndingTime, tweenAnimateOutStart);

          if (tweenAnimateOutMid) {
            // if there is animation midway
            // e.g. fly out
            duration /= 2;
            letterEndingTime = letterStartEndingTime + duration;
            toTweenTimeline({
              timeline: tweenMaxTimeline,
              target,
              duration,
              animation: tweenAnimateOutMid,
              time: `Start+=${letterStartEndingTime}`,
            });
            // console.log('tweenAnimateOutMid', letterEndingTime, tweenAnimateOutMid);
          }

          // if animate out ends on 4s, make it ends at 3.95s
          if (letterEndingTime + duration === endingTime
            || letterEndingTime + duration === endingTime + fauxZero) {
            duration = Math.round((duration - fauxZero) * 100) / 100; // sometimes the time become 0.0499999
          }

          if (animateOutValue === 'wipe-out' || animateOutValue === 'mask-out') {
            duration -= letterTimingRatio;
          }

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target,
            duration,
            animation: tweenAnimateOut,
            time: `Start+=${letterEndingTime}`,
          });
          // console.log('tweenAnimateout', letterEndingTime + duration, tweenAnimateOut);
          // console.log('---');

          if (animateOutValue === 'wipe-out' || animateOutValue === 'mask-out') {
            const isWrapper = true;

            if (targetTextWrap) {
              const wrapAnimateOut = getAnimateOut({
                value: animateOutValue,
                direction: animateOutDirection,
                target: targetTextWrap,
                extra: extraPaddingX || extraPaddingY ? { extraPaddingX, extraPaddingY } : null,
                isWrapper,
                canvasZoom,
                canvasSize,
              });

              wrapTweenAnimateOut = wrapAnimateOut.animateOut;
              wrapTweenAnimateOutStart = wrapAnimateOut.animateOutStart;

              setTweenTimeline({
                timeline: tweenMaxTimeline,
                target: targetTextWrap,
                animation: wrapTweenAnimateOutStart,
                time: `Start+=${letterEndingTime}`,
              });

              toTweenTimeline({
                timeline: tweenMaxTimeline,
                target: targetTextWrap,
                duration,
                animation: wrapTweenAnimateOut,
                time: `Start+=${letterEndingTime}`,
              });
            }
          }

          // console.log('Element out,', targetElementText[index], letterStartEndingTime, (animateOutDuration - letterTimingRatio) )

          letterStartEndingTime += letterTimingRatio;

          // for slide-out and rotate, the animation will somehow end too early
          // so will need to add the autoalpha 0 to force it to disappear
          if (animateOutValue === 'slide-out' || animateOutValue === 'rotate-out') {
            const animateOutExtra = letterEndingTime + (0.4 * duration);
            toTweenTimeline({
              timeline: tweenMaxTimeline,
              target,
              duration: (0.6 * duration),
              animation: { autoAlpha: 0 },
              time: `Start+=${animateOutExtra}`,
            });
          }
        }
      }

      if (targetTextContainer) {
        let animateOutStartTime = animateOutStart;
        let duration = animateOutDuration;

        if (tweenAnimateOutMid) {
          // if there is animation midway
          // e.g. fly out
          duration = animateOutDuration / 2;
          animateOutStartTime = animateOutStart + duration;
          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetTextContainer,
            duration,
            animation: tweenAnimateOutMid,
            time: `Start+=${animateOutStart}`,
          });
        }

        // also animate text background
        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetTextContainer,
          duration,
          animation: tweenAnimateOut,
          time: `Start+=${animateOutStartTime}`,
        });

        if (animateOutValue === 'slide-out' || animateOutValue === 'rotate-out') {
          const animateOutExtra = animateOutStart + (0.4 * animateOutDuration);
          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetTextContainer,
            duration: 0.6 * animateOutDuration,
            animation: { autoAlpha: 0 },
            time: `Start+=${animateOutExtra}`,
          });
        }
      }
    } else {
      if (!targetElement) {
        return tweenMaxTimeline;
      }

      let animateOutStartTime = animateOutStart;
      let duration = animateOutDuration;

      // if animate out ends on 4s, make it ends at 3.95s
      if (animateOutStart + duration === endingTime
        || animateOutStart + duration === endingTime + fauxZero) {
        duration = Math.round((duration - fauxZero) * 100) / 100; // sometimes the time become 0.0499999
      }

      if (animateOutValue === 'hinge') {
        duration = animateOutDuration / 5;
        const { transformOrigin } = tweenAnimateOut;

        const isTop = transformOrigin.indexOf('top') >= 0;
        const isRight = transformOrigin.indexOf('right') >= 0;

        let angle1 = isTop ? '80deg' : '170deg';
        let angle2 = isTop ? '60deg' : '150deg';
        let angle3 = isTop ? '30deg' : '120deg';

        if (isRight) {
          angle1 = `-${angle1}`;
          angle2 = `-${angle2}`;
          angle3 = `-${angle3}`;
        }

        tweenAnimateOut.rotationZ = angle3;

        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElement,
          animation: tweenAnimateOutStart,
          time: `Start+=${animateOutStart}`,
        });

        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElement,
          duration,
          animation: { rotationZ: angle1, ease: Sine.easeInOut },
          time: `Start+=${animateOutStart}`,
        });

        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElement,
          duration,
          animation: { rotationZ: angle2, ease: Sine.easeInOut },
          time: `Start+=${animateOutStart + duration}`,
        });

        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElement,
          duration,
          animation: { rotationZ: angle1, ease: Sine.easeInOut },
          time: `Start+=${animateOutStart + 2 * duration}`,
        });

        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElement,
          duration,
          animation: { rotationZ: angle2, ease: Sine.easeInOut, autoAlpha: 1 },
          time: `Start+=${animateOutStart + 3 * duration}`,
        });

        toTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElement,
          duration,
          animation: tweenAnimateOut,
          time: `Start+=${animateOutStart + 4 * duration}`,
        });
      } else {
        // for wipe out, need to set overflow to hidden before animating
        if (animateOutValue === 'wipe-out' || animateOutValue === 'mask-out') {
          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetWrapWrapper,
            animation: tweenAnimateOutStart,
            time: `Start+=${animateOutStartTime}`,
          });

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetWrapWrapper,
            duration,
            animation: tweenAnimateOut,
            time: `Start+=${animateOutStartTime}`,
          });
        } else {
          if (tweenAnimateOutMid) {
            // if there is animation midway
            // e.g. fly in
            duration = animateOutDuration / 2;
            animateOutStartTime = animateOutStart + duration;
            toTweenTimeline({
              timeline: tweenMaxTimeline,
              target: targetElement,
              duration,
              animation: tweenAnimateOutMid,
              time: `Start+=${animateOutStart}`,
            });
          }

          // console.log('animation in not per text')
          // console.table({
          //   animateOutDuration,
          //   tweenAnimateOut,
          //   animateOutStart,
          //   })
          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElement,
            duration,
            animation: tweenAnimateOut,
            time: `Start+=${animateOutStartTime}`,
          });
        }

        // for slide-out and rotate, the animation will somehow end too early
        // so will need to add the autoalpha 0 to force it to disappear
        if (animateOutValue === 'slide-out' || animateOutValue === 'rotate-out') {
          const animateOutExtra = animateOutStart + (0.4 * duration);
          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetElement,
            duration: 0.6 * duration,
            animation: { autoAlpha: 0 },
            time: `Start+=${animateOutExtra}`,
          });
        }

        // for wipe, we need to add extra container and animate it
        if (targetWrap && wrapTweenAnimateOut) {
          setTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetWrap,
            animation: wrapTweenAnimateOutStart,
            time: `Start+=${animateOutStart}`,
          });

          // if animate out ends on 4s, make it ends at 3.95s
          if (animateOutStart + animateOutDuration === endingTime
            || animateOutStart + animateOutDuration === endingTime + fauxZero) {
            animateOutDuration = Math.round((animateOutDuration - fauxZero) * 100) / 100; // sometimes the time become 0.0499999
          }

          toTweenTimeline({
            timeline: tweenMaxTimeline,
            target: targetWrap,
            duration: animateOutDuration,
            animation: wrapTweenAnimateOut,
            time: `Start+=${animateOutStart}`,
          });
        }
      }
    }
  } else {
    if (!targetElement) {
      return tweenMaxTimeline;
    }

    const currentSceneEndingTime = currentSceneStartTime + sceneDuration;

    if (isAllScenesTimeline
      && sceneTransition.value
      && sceneTransition.value !== 'none'
      && (endingTime === currentSceneEndingTime || endingTime - 0.05 === currentSceneEndingTime)) {
      // if this is allScenesTimeline and has transition,
      // and if this time out is same as the duration of the scene
      // move the animate out start one second later
      animateOutStart += 1;
      endingTime += 1;
    }
    // console.log('animateout', animateOutStart, endingTime, currentSceneEndingTime)

    // if there is no animate out,
    // make sure the opacity will be 0 and transform position to be 0, 0
    // when it reached the endingTime
    // to see when the whole animation would end, console.log(endingTime);
    if (animateInOption === 'per-line'
      || animateInOption === 'per-word'
      || animateInOption === 'per-text') {
      let targetElementOption = null;

      if (animateInOption === 'per-line') {
        targetElementOption = targetElementLine;
      } else if (animateInOption === 'per-word') {
        targetElementOption = targetElementWord;
      } else if (animateInOption === 'per-text') {
        targetElementOption = targetElementText;
      }

      // console.log('animateInOption', animateInOption, targetElementText.length)
      for (let index = 0; index < targetElementOption.length; index += 1) {
        setTweenTimeline({
          timeline: tweenMaxTimeline,
          target: targetElementOption[index],
          animation: { autoAlpha: 0 },
          time: `Start+=${endingTime}`,
        });
      }
    }
  }

  // console.log('setAnimation', endingTime)

  // always set the container to 0 at the end of the duration
  setTweenTimeline({
    timeline: tweenMaxTimeline,
    target: targetElement,
    animation: { autoAlpha: 0, x: 0, y: 0 },
    time: `Start+=${endingTime}`,
  });

  return tweenMaxTimeline;
};

const setTransition = (item) => {
  const {
    animateInDuration,
    animateInDirection,
    animateInValue,
    animateOutDuration,
    animateOutDirection,
    animateOutValue,
    endingTime,
    startingTime,
    targetElement,
    tweenMaxTimeline,
    canvasZoom,
    canvasSize,
  } = item;

  // console.log('setTransition', canvasZoom, targetElement, tweenMaxTimeline);

  const animateIn = getTransitionIn({
    value: animateInValue,
    direction: animateInDirection,
    target: targetElement,
    canvasZoom,
    canvasSize,
  });
  const animateOut = getTransitionOut({
    value: animateOutValue,
    direction: animateOutDirection,
    target: targetElement,
    canvasZoom,
    canvasSize,
  });
  // animate out starts when the next scene animating in
  const animateOutStart = endingTime - animateOutDuration;

  const tweenAnimateIn = animateIn.animateIn;
  // const tweenAnimateToIn = animateIn.animateToIn;
  const tweenAnimateInEnding = animateIn.animateInEnding;

  const tweenAnimateOut = animateOut.animateOut;

  // Reset transition
  setTweenTimeline({
    timeline: tweenMaxTimeline,
    target: targetElement,
    animation: {
      autoAlpha: 0,
      x: 0,
      y: 0,
      clearProps: 'transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, filter, opacity, x, y, autoAlpha',
    },
    time: 'Start+=0',
  });

  // set the canvas opacity to 0 before the times
  setTweenTimeline({
    timeline: tweenMaxTimeline,
    target: targetElement,
    animation: {
      autoAlpha: 0,
      x: 0,
      y: 0,
    },
    time: 'Start+=0',
  });

  if (animateInValue && animateInValue !== 'none' && tweenAnimateIn) {
    setTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetElement,
      animation: tweenAnimateIn,
      time: `Start+=${startingTime}`,
    });

    toTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetElement,
      duration: animateInDuration,
      animation: tweenAnimateInEnding,
      time: `Start+=${startingTime}`,
    });
  } else {
    setTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetElement,
      animation: {
        autoAlpha: 1,
        x: 0,
        y: 0,
      },
      time: `Start+=${startingTime}`,
    });
  }

  if (animateOutValue && animateOutValue !== 'none' && tweenAnimateOut) {
    // console.log('animateOutStart', animateOutStart)
    toTweenTimeline({
      timeline: tweenMaxTimeline,
      target: targetElement,
      duration: animateOutDuration,
      animation: tweenAnimateOut,
      time: `Start+=${animateOutStart}`,
    });

    if (animateOutValue === 'slide-out' || animateOutValue === 'rotate-out') {
      const animateOutExtra = animateOutStart + (0.4 * animateOutDuration);
      toTweenTimeline({
        timeline: tweenMaxTimeline,
        target: targetElement,
        duration: 0.6 * animateOutDuration,
        animation: {
          autoAlpha: 0,
        },
        time: `Start+=${animateOutExtra}`,
      });
    }
  }

  setTweenTimeline({
    timeline: tweenMaxTimeline,
    target: targetElement,
    animation: {
      autoAlpha: 0,
      x: 0,
      y: 0,
    },
    time: `Start+=${endingTime}`,
  });

  return tweenMaxTimeline;
};

export {
  getAnimateIn,
  getAnimateOut,
  getTransitionIn,
  getTransitionOut,
  addTweenTimeline,
  setTweenTimeline,
  toTweenTimeline,
  fromToTweenTimeline,
  staggerTweenTimeline,
  setAnimation,
  setTransition,
  convertAnimationDuringSpeed,
};
