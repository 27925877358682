<template lang="pug">
label(
    :class="['radio-button', wrapperClass]"
  )
  input(
    type="radio"
    :name="name"
  )
  span(
    class="radio-checkmark"
  )
</template>

<script>
export default {
  name: 'BaseRadioButton',
  props: {
    wrapperClass: { type: String, default: '' },
    name: { type: String, default: '' },
  },
  computed: {
  },
};
</script>

<style lang="scss">
// Radio button
.radio-button {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 24px;
  width: 24px;

  &:hover input~.radio-checkmark {
    background-color: $light;
    border: 3px solid $btnPrimaryBackground;
    transition: background-color 0.5s;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked~.radio-checkmark {
      background-color: $light;
      border: 3px solid $btnPrimaryBackground;
      transition: background-color 0.5s;

      &:after {
        display: block;
      }
    }
  }

  .radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $light;
    border: 3px solid $checkmarkBorder;
    border-radius: 50%;
    transition: background-color 0.5s;

    &:after {
      content: "";
      position: absolute;
      display: none;

      top: calc(calc(100% - 10px) / 2);
      left: calc(calc(100% - 10px) / 2);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $btnPrimaryBackground;
      transition: background-color 0.5s;
    }
  }
}
</style>
