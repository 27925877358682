import axios from 'axios';

export default {
  uploadFile(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/upload-file`,
      data: params,
    });
  },

  uploadTemporaryFile(file, params = {}, version = 'v2') {
    const formData = new FormData();

    // eslint-disable-next-line
    for (let i = 0; i < file.length; i++) {
      if (typeof file[i] !== 'undefined') formData.append(`file[${i}]`, file[i]);
    }

    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });

    return axios.post(`/${version}/tmp-upload-file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getTemporaryFile(userId, version = 'v1') {
    return axios({
      method: 'get',
      url: `/${version}/tmp-user-files/${userId}`,
    });
  },

  cancelExport(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/cancel-export`,
      data: params,
    });
  },

  exportProject(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/export-project`,
      data: params,
    });
  },

  exportProgress(params = {}, version = 'v1') {
    return axios.get(`/${version}/export-progress`, { params });
  },
  captureFrameResource(params = {}, version = 'v2') {
    return axios.get(`/${version}/capture-frame-details`, { params });
  },
  customFonts(file = '', version = 'v2') {
    return axios({
      method: 'get',
      url: `/${version}/custom-fonts/${file}`,
    });
  },
  customProjectFonts(file = '', version = 'v1') {
    return axios({
      method: 'get',
      url: `/${version}/custom-project-fonts/${file}`,
    });
  },
  getOrCreateBrowserTab(params = {}, version = 'v1') {
    return axios.get(`/${version}/browser-tabs`, { params });
  },
  updateBrowserTab(channelId, params = {}, version = 'v1') {
    return axios({
      method: 'put',
      url: `/${version}/browser-tabs/${channelId}`,
      data: params,
    });
  },
  removeBg(params = {}, headers, version = 'v2') {
    // eslint-disable-next-line
    params.provider = 'remove-bg';
    return axios({
      method: 'post',
      url: `/${version}/upload-file`,
      data: params,
      headers: {
        'upload-metadata-additional': JSON.stringify(headers),
      },
    });
  },
  getRemoveBgPackages(version = 'v1') {
    return axios({
      method: 'get',
      url: `/${version}/remove-bg-packages`,
    });
  },
  removeBgPurchaseCredit(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/remove-bg-purchase-credit`,
      data: params,
    });
  },
  getBraintreeClientToken(version = 'v1') {
    return axios({
      method: 'get',
      url: `/${version}/braintree/client-token`,
    });
  },
  getPlanDetails(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/plan-details`,
      data: params,
    });
  },
  checkPromoCode(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/check-promo-code`,
      data: params,
    });
  },
  validateReCaptcha(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/validate-recaptcha`,
      data: params,
    });
  },
  subscribe(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/subscribe`,
      data: params,
    });
  },
  deleteTemporaryFile(temporaryUserId, id, version = 'v1') {
    return axios({
      method: 'delete',
      url: `/${version}/tmp-user-files/${temporaryUserId}/${id}`,
    });
  },
  getStoryBoardPreview(params = {}, version = 'v1') {
    return axios.get(`/${version}/storyboard-preview`, { params });
  },
  getPersonalizedSmartCreate(slug, version = 'v1') {
    return axios({
      method: 'get',
      url: `/${version}/personalized-smart-create/${slug}`,
    });
  },
  scrapeImages(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/scrape-images`,
      data: params,
    });
  },
  captureAssets(params = {}, version = 'v1') {
    return axios.get(`/${version}/capture-assets`, { params });
  },
  checkIfFileReady(id, version = 'v1') {
    return axios({
      method: 'get',
      url: `/${version}/check-if-file-ready/${id}`,
    });
  },
  retryUpload(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/retry-upload`,
      data: params,
    });
  },
  dataTracking(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/collections/data-tracking`,
      data: params,
    });
  },
  searchCategoryTags(params = {}, version = 'v1') {
    return axios.get(`/${version}/search-cat-tags`, { params });
  },
  userSearch(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/user-search`,
      data: params,
    });
  },
  sendPreviewEmails(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/send-preview-emails`,
      data: params,
    });
  },
  smartCreateTracking(params = {}, id = '', version = 'v1') {
    let url = `/${version}/smart-create-tracking`;
    let method = 'post';

    if (id) {
      url += `/${id}`;
      method = 'put';
    }

    return axios({
      method,
      url,
      data: params,
    });
  },
  brandFetchDataTracking(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/brand-fetch-tracking`,
      data: params,
    });
  },
  brandFontDataTracking(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/brand-fonts-tracking`,
      data: params,
    });
  },
  exportProjectDetails(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/export-project-details`,
      data: params,
    });
  },
  importProjectDetails(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/import-project-details`,
      data: params,
    });
  },
  trimUserVideoFiles(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/trim-user-video-files`,
      data: params,
    });
  },
  fbeBusiness(params = {}, version = 'v1') {
    return axios({
      method: 'get',
      url: `/${version}/fbe-business`,
      params,
    });
  },
  googleVisionFileUpload(file) {
    const formData = new FormData();
    formData.append('image', file);
    return axios.post('/google-vision', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  pingExportProgress(params = {}, version = 'v1') {
    return axios({
      method: 'get',
      url: `/${version}/ping-export-progress`,
      params,
    });
  },
};
