import axios from 'axios';
import { serialize } from '@/assets/scripts/utilities'; // eslint-disable-line

export default {
  getItems(endpoint = '', params = {}, version = 'v1') {
    return axios.get(`/${version}/collections/${endpoint}`, { params });
  },
  getItem(endpoint = '', id, params = {}, version = 'v1') {
    return axios.get(`/${version}/collections/${endpoint}/${id}`, { params });
  },
  getCategories(params = {}, version = 'v1') {
    return axios.get(`/${version}/collections/general-categories`, { params });
  },

  getDesignCategories(params = {}, version = 'v1') {
    return axios.get(`/${version}/collections/template-categories`, { params });
  },

  getPresetFormats(id, params = {}, version = 'v1') {
    let url = `/${version}/collections/preset-format`;
    if (id) url += `/${id}`;
    if (Object.keys(params).length) url += `${serialize(params)}`;
    return axios.get(url);
  },

  getBrand(version = 'v1') {
    return axios.get(`/${version}/collections/brands`);
  },
  getMusicCategories(version = 'v1') {
    return axios.get(`/${version}/collections/music-categories`);
  },
  getTemplatePacks(version = 'v1') {
    return axios.get(`/${version}/collections/template-packs`);
  },
  getCustomElements(version = 'v1') {
    return axios.get(`/${version}/collections/custom/elements`);
  },
  getDesignDetails(designId, version = 'v1') {
    return axios.get(`/${version}/collections/designs/${designId}`);
  },
  getDesignPreview(designId, version = 'v1') {
    // `id` or `slug` allowed
    return axios.get(`/${version}/collections/designs/${designId}/preview`);
  },
  deleteModularTemplateCategory(id, version = 'v1') {
    return axios({
      method: 'delete',
      url: `/${version}/collections/modular-template-categories/${id}`,
    });
  },
  updateColorPalette(params = {}, version = 'v1') {
    return axios({
      method: 'put',
      url: `/${version}/collections/brands`,
      data: params,
    });
  },
  getElementMenus(params = {}, version = 'v1') {
    return axios.get(`/${version}/collections/element-menus`, { params });
  },
  getBackgroundMenus(params = {}, version = 'v1') {
    return axios.get(`/${version}/collections/background-menus`, { params });
  },
};
