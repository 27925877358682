<template lang="pug">
.dialog-modal(
  :class="[{ 'is-show': isShow, 'multiline-buttons' : multilineButtons }, extraClass]"
)
  .dialog-modal__overlay(
    @click="closeModal"
  )
  .dialog-modal__content(
    :style="styleObject"
  )
    i.icon.dialog-icon(
      :class="{ 'icon-dialog-info': isPrimary, 'icon-dialog-check': isSuccess, 'icon-dialog-exclamation': isDanger || isAlert, 'is-danger': isDanger, 'is-alert': isAlert }"
    )
    button.dialog-modal__closebtn(
      type='button'
      @click="closeModal"
      v-if="!hideCloseButton"
    )
      i.icon.icon-cancel
    h4.dialog-modal__title(
      v-if="title"
    ) {{ title }}
    p.dialog-modal__text(
      v-if="text"
      v-html="text"
    )
    slot
    .dialog-modal__buttons(
      v-if="!noButton"
      :class="{ 'is-edit': isEditModal, 'is-full-width': isFullWidthButtons, 'is-dark': isDarkMode }"
    )
      BaseButton(
        :isDanger="isDanger"
        :is-canvas="true"
        :isAlert="isAlert"
        :isSuccess="isSuccess"
        :isTall="true"
        :isPrimary="!isDanger&&!isAlert&&!isSuccess"
        @click="$emit('confirm')"
      ) {{ button1Text }}

      BaseButton(
        v-if="!noCancel"
        :is-canvas="true"
        isHollow=true
        isTall=true
        @click="button2Click"
      ) {{ button2Text }}

      BaseButton.btn-upgrade-subscription(
        v-if="!noUpgrade"
        isPrimaryHollow=true
        @click="$emit('open-upgrade-subscription')"
        iconAfter="icon-star"
      ) Upgrade to Premium

  GlobalEvents(
    :filter="(event, handler, eventName) => event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && event.target.getAttribute('contenteditable') !== 'true'"
    @keydown.prevent.esc="closeModal"
  )
</template>

<script>
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'BaseDialog',
  props: {
    align: { type: String, default: 'center' },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    isShow: { type: Boolean, default: false },
    isAlert: { type: Boolean, default: false },
    isDanger: { type: Boolean, default: false },
    isPrimary: { type: Boolean, default: false },
    isSuccess: { type: Boolean, default: false },
    isEditModal: { type: Boolean, default: false },
    isFullWidthButtons: { type: Boolean, default: false },
    noCancel: { type: Boolean, default: false },
    noButton: { type: Boolean, default: false },
    noUpgrade: { type: Boolean, default: true },
    hideCloseButton: { type: Boolean, default: false },
    button1Text: { type: String, default: 'Confirm' },
    button2Text: { type: String, default: 'Cancel' },
    button2NoClose: { type: Boolean, default: false },
    width: { type: String, default: '' },
    multilineButtons: { type: Boolean, default: false },
    extraClass: { type: String, default: '' },
  },
  mixins: [darkModeMixin],
  computed: {
    styleObject() {
      const styleObject = {};

      styleObject.textAlign = this.align;

      if (this.width) {
        styleObject.width = this.width;
        styleObject.maxWidth = this.width;
      }

      let borderTop = '6px solid ';

      if (this.isPrimary) {
        borderTop += '#0C66FF';
      } else if (this.isSuccess) {
        borderTop += '#0BB07B';
      } else if (this.isAlert) {
        borderTop += '#FFAD0D';
      } else if (this.isDanger) {
        borderTop += '#F03D3D';
      } else {
        borderTop += '#0C66FF';
      }

      styleObject.borderTop = borderTop;

      return styleObject;
    },
  },
  methods: {
    button2Click() {
      if (this.button2NoClose) {
        this.$emit('button-two-click');
      } else {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit('closeOverlay');
    },
  },
};
</script>

<style lang="scss">
.dialog-modal {
  align-items: center;
  bottom: 0;
  color: var(--darkgrey-white);
  display: none;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 20px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 100;

  &.is-show {
    display: flex;
  }

  &.is-shaken {
    .dialog-modal__content {
      animation: shaken 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
  }

  &.multiline-buttons {
    .dialog-modal__buttons {
      flex-direction: column;
      .btn {
        margin: 5px;
      }
    }
  }
}

.dialog-modal__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.dialog-modal__content {
  position: relative;
  z-index: 2;
  width: 100%;
  background: var(--white-darkgrey700);
  max-width: 570px;
  max-height: 90vh;
  border-radius: $componentBorderRadius;
  overflow: visible;
  padding: 50px;
  box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  line-height: 1.4;

  .mobile-project & {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    background: $darkGrey700;
    color: $light;
  }
}

.dialog-modal__closebtn {
  background: none;
  border: 0;
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: 20px;
  z-index: 2;
  font-size: 20px;
  color: var(--darkgrey-white);

  &:hover,
  &:focus {
    background: transparent;
  }
}

.dialog-modal__text {
  margin-top: 0;
}

.dialog-modal__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  margin: 20px auto 0;

  &.is-full-width {
    width: 100%;
  }

  &.is-dark {
    .btn {
      color: $light;

      &:hover {
        background: $darkBlue4;
      }
    }
  }

  .btn {
    margin: 0 10px;
    width: 100%;
    transition: box-shadow 0.3s cubic-bezier(0, 0, 0, 1);

    &.btn--success {
      background: $green600;
    }

    &.btn--danger {
      &:focus {
        background: $red600;
        color: $light;
      }
    }

    &:hover {
      &.btn--primary {
        background: $blue2;
        color: $light;
      }

      &.btn--success {
        background: $green;
        color: $light;
      }

      &.btn--alert {
        background: $orange800;
        color: $light;
      }

      &.btn--danger {
        background: $red700;
        color: $light;
      }
    }
  }

  @include mobile {
    width: 100%;
  }
}
@-webkit-keyframes shaken {
  10%,
  90% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-8px, 0, 0);
    transform: translate3d(-8px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(8px, 0, 0);
    transform: translate3d(8px, 0, 0);
  }
}

@keyframes shaken {
  10%,
  90% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-8px, 0, 0);
    transform: translate3d(-8px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(8px, 0, 0);
    transform: translate3d(8px, 0, 0);
  }
}

.dialog-icon {
  font-size: 30px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding-top: 10px;

  .mobile-project & {
    display: none;
  }
}

.icon-dialog-check {
  background-color: #0bb07b;
}

.icon-dialog-info {
  background-color: #0c66ff;
}

.icon-dialog-exclamation {
  &.is-danger {
    background-color: #f03d3d;
  }

  &.is-alert {
    background-color: #ffad0d;
  }
}
</style>
