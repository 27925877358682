<template lang="pug">
#input-fields
  template(v-if='showInputFields')
    h2 Project
    BaseInputField(
      v-model='projectId',
      @change='openProject',
      placeholder='Enter project ID Here...'
    )
    p
    BaseButton(@click='createProject()', isPrimary) Or Create new project

    hr

    h2 Template
    BaseInputField(
      v-model='templateId',
      @change='openTemplate',
      placeholder='Enter template ID Here...'
    )
    p
    BaseButton(@click='createTemplate()', isPrimary) Or Create new template

  CanvasPreloader(v-else, text='Redirecting...')
</template>

<script>
import CanvasPreloader from '@/components/ProjectCanvas/PageOverlay/CanvasPreloader.vue';
import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi'; // eslint-disable-line
import { isDevMode, dashboardUrl } from '@/assets/scripts/variables';

export default {
  name: 'Home',
  components: {
    CanvasPreloader,
  },
  data() {
    return {
      showInputFields: false,
      projectId: '',
      templateId: '',
    };
  },
  methods: {
    createProject() {
      this.showInputFields = false;
      ProjectApi.create()
        .then((response) => {
          const { project_id } = response.data.results;
          this.$router.push({
            name: 'project',
            params: { projectId: project_id },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openProject() {
      this.showInputFields = false;
      this.$router.push({
        name: 'project',
        params: { projectId: this.projectId },
      });
    },
    createTemplate() {
      this.showInputFields = false;
      TemplateApi.create()
        .then((response) => {
          const { id } = response.data.results;
          this.$router.push({ name: 'template', params: { templateId: id } });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openTemplate() {
      this.showInputFields = false;
      this.$router.push({
        name: 'template',
        params: { templateId: this.templateId },
      });
    },
  },
  beforeMount() {
    if (isDevMode()) {
      this.showInputFields = true;
    } else {
      console.log('redirect');
      window.location.href = dashboardUrl();
    }
  },
};
</script>
