import Vue from 'vue';
import TeamApi from '@/services/api/TeamApi';
import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi'; // eslint-disable-line

import OffeoFonts from '@/assets/data/fonts.json';
import { normalizeColorBrand } from '@/assets/scripts/utilities'; // eslint-disable-line
import { defaultFallbackFont, defaultFontSize } from '../assets/scripts/variables';
import cloneDeep from 'lodash.clonedeep'; // eslint-disable-line

const customFontParams = {
  extraLightFontFile: '200',
  extraLightItalicFontFile: '200-i',
  lightFontFile: '300',
  lightItalicFontFile: '300-i',
  regularFontFile: '400',
  regularItalicFontFile: '400-i',
  mediumFontFile: '500',
  mediumItalicFontFile: '500-i',
  semiBoldFontFile: '600',
  semiBoldItalicFontFile: '600-i',
  boldFontFile: '700',
  boldItalicFontFile: '700-i',
};

const team = {
  namespaced: true,
  state: {
    teamId: 0,
    teamSlug: '',
    teamName: '',
    teamRoleId: -1,
    userTeamRole: 'member',
    /**
     * example of teamBrands byId: {
     *   73: {
     *     "id": 73,
     *     "team_id": 158,
     *     "branded_style_id": 4,
     *     "brand_name": "Offeo",
     *     "colors": null,
     *     "fonts": [
     *       "Abel,100px, ,"
     *     ],
     *     "logo": null,
     *     "brand_meta": [{}], // only added in collection call
     *   }
     * }
     */
    teamBrands: {
      byId: {},
      allIds: [],
    },
    /**
     * categorised team logo based on id
     */
    teamLogos: {
      byId: {},
      allIds: [],
    },
    extraTeamLogo: {}, // separate as inside "TeamLogos" need to use different keys
    totalFetchedTeamBrandsLogos: 0,
    teamBrandFontStyles: {},
    teamFontPairingList: [],
    defaultTeamFont: {
      /**
       * fontFamily: "Cabin",
       * fontWeight: "400",
       * fontStyle: "normal"
       */
      h1: {
        fontFamily: defaultFallbackFont,
        fontSize: defaultFontSize.h1,
        fontWeight: 400,
        fontStyle: 'normal',
      },
      h2: {
        fontFamily: defaultFallbackFont,
        fontSize: defaultFontSize.h2,
        fontWeight: 400,
        fontStyle: 'normal',
      },
    },
    teamOffeoFonts: cloneDeep(OffeoFonts),
    teamCustomFontsByBrand: [],

    // ----------------------------------
    // < ---------- Loaders ----------- >
    // ----------------------------------
    teamBrandIndividualLoadingType: '',
    isFetchingExtraTeamLogo: false,
  },
  getters: {
    /* eslint-disable */
    isInTeamWorkspace: (state) => state.teamId > 0,
    isTeamAdmin: (state) => state.teamRoleId === 1,
    isTeamDesigner: (state) => state.teamRoleId === 2,
    isTeamMember: (state) => state.teamRoleId === 3,

    getTeamBrands: (state) => state.teamBrands.allIds.map((brandId) => state.teamBrands.byId[brandId]),
    getTeamBrandsById: (state) => (brandId) => state.teamBrands.byId[brandId],
    /* eslint-enable */

    // eslint-disable-next-line
    getActiveTeamBrandId: (state, getters, rootState, rootGetters) => {
      return rootGetters['canvasElements/getProjectBrandId'];
    },
    getActiveTeamBrand: (state, getters) => {
      const activeTeamBrandId = getters.getActiveTeamBrandId;
      if (!getters.getActiveTeamBrandId || !state.teamBrands.byId[getters.getActiveTeamBrandId]) {
        return {};
      }
      return state.teamBrands.byId[activeTeamBrandId];
    },
    // eslint-disable-next-line
    hasTeamBrands: (state, getters) => {
      return !!getters.getActiveTeamBrand.id;
    },
    getTeamLogos: (state, getters) => {
      const activeTeamBrandId = getters.getActiveTeamBrandId;
      const isExtraLogoExist = state.extraTeamLogo.file;
      const selectedBrandLogo = state.teamBrands.byId[activeTeamBrandId].logo;

      if (!activeTeamBrandId || (!selectedBrandLogo && !isExtraLogoExist)) {
        return null;
      }
      // console.log(
      //   'vuex getTeamLogos',
      //   selectedBrandLogo,
      //   state.teamLogos.byId,
      // );

      // filter so that it returns only with values
      let filteredResult = [];

      if (selectedBrandLogo) {
        const result = selectedBrandLogo.map(
          (logoId) => state.teamLogos.byId[logoId] // eslint-disable-line
        );
        filteredResult = result.filter(item => item);
      }

      if (isExtraLogoExist) filteredResult.unshift(state.extraTeamLogo);

      return filteredResult;
    },
    getTeamLogosById: (state) => (logoId) => state.teamLogos.byId[logoId], // eslint-disable-line
    getActiveTeamColors: (state, getters, rootState, rootGetters) => {
      const activeTeamBrandId = rootGetters['canvasElements/getProjectBrandId'];
      if (!activeTeamBrandId || !state.teamBrands.byId[activeTeamBrandId]) {
        return [];
      }

      return state.teamBrands.byId[activeTeamBrandId].colors;
    },
    // eslint-disable-next-line
    isAllTeamBrandsLogosFetched: (state) => {
      return state.totalFetchedTeamBrandsLogos === state.teamBrands.allIds.length;
    },
    getActiveTeamBrandMeta: (state, getters) => {
      const activeTeamBrandId = getters.getActiveTeamBrandId;
      if (!activeTeamBrandId || !state.teamBrands.byId[activeTeamBrandId].brand_meta) {
        return null;
      }
      // filter so that it returns only with values
      return state.teamBrands.byId[activeTeamBrandId].brand_meta;
    },

    getTeamFonts: (state) => state.teamOffeoFonts, // eslint-disable-line
    getTeamFontsCategory: (state) => {
      const categoryArr = [];
      state.teamOffeoFonts.forEach((font) => {
        const catObj = {};
        if (font.category) {
          font.category.forEach((category) => {
            if (!categoryArr.some((o) => o.value === category)) { // eslint-disable-line
              catObj.value = category;
              let text = category;
              text = text.replace(/-/g, ' ');
              if (category === 'latin') {
                text = 'English/Latin';
              }

              catObj.text = text;
            }
          });
        }
        if (catObj.value) {
          categoryArr.push(catObj);
        }
      });

      categoryArr.sort((a, b) => {
        if (a.value === 'latin') return -1;
        if (b.value === 'latin') return 1;
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
        return 0;
      });

      return categoryArr;
    },
    getTeamCustomFontsByBrand: (state) => state.teamCustomFontsByBrand, // eslint-disable-line
  },
  mutations: {
    setTeamId(state, item) {
      state.teamId = isNaN(parseInt(item, 10)) ? 0 : parseInt(item, 10); // eslint-disable-line
    },
    setTeamData(state, item) {
      if (item.slug) state.teamSlug = item.slug;
      if (item.team_name) state.teamName = item.team_name;
      if (item.member_role) state.teamRoleId = item.member_role;

      if (item.current_user_id) {
        const member = item.member.find((m) => m.user_id === item.current_user_id); // eslint-disable-line
        if (member) state.userTeamRole = member.team_role;
      }
    },
    setTeamBrandIndividualLoadingType(state, value) {
      state.teamBrandIndividualLoadingType = value;
    },
    setIsFetchingExtraTeamLogo(state, boolean) {
      state.isFetchingExtraTeamLogo = boolean;
    },

    addColorBrand(state, item) {
      // normalise the color so that it will have hash inside
      const colorBrand = normalizeColorBrand(item);

      // todo : proper vuex state update since teamColors isnt used
      // state.teamColors.push(colorBrand);
      console.log({ colorBrand });
    },
    setTeamLogos(state, item) {
      const logosById = { ...state.teamLogos.byId };
      const logosAllIds = [...state.teamLogos.allIds];

      for (let i = 0; i < item.length; i += 1) {
        const logo = item[i];
        const logoId = logo.id;

        if (!logosAllIds.includes(logoId)) logosAllIds.push(logoId);
        logosById[logoId] = { ...logo };
      }

      state.teamLogos.byId = logosById;
      state.teamLogos.allIds = [...logosAllIds];
    },
    setExtraTeamLogo(state, data) {
      state.extraTeamLogo = data;
    },
    addTeamLogo(state, data) {
      const { activeTeamBrandId } = data;
      const newLogoId = data.logo.id;
      const logosById = { ...state.teamLogos.byId };

      logosById[newLogoId] = data.logo;

      state.teamLogos.byId = logosById;
      state.teamLogos.allIds.unshift(newLogoId);

      if (state.teamBrands.byId[activeTeamBrandId].logo) {
        state.teamBrands.byId[activeTeamBrandId].logo.unshift(newLogoId);
      } else {
        state.teamBrands.byId[activeTeamBrandId].logo = [newLogoId];
      }
    },
    deleteTeamLogo(state, data) {
      const { activeTeamBrandId } = data;
      const teamBrandLogoAllIds = [...state.teamBrands.byId[activeTeamBrandId].logo];
      const logosAllIds = [...state.teamLogos.allIds];
      Vue.delete(state.teamLogos.byId, data.logoId);

      const filteredLogosAllIds = logosAllIds.filter(id => id !== data.logoId);
      const filteredTeamBrandLogoAllIds = teamBrandLogoAllIds.filter(id => id !== data.logoId);

      state.teamLogos.allIds = filteredLogosAllIds;
      state.teamBrands.byId[activeTeamBrandId].logo = filteredTeamBrandLogoAllIds;
    },
    addTeamBrand(state, brand) {
      const brandId = brand.id;

      const newData = [];
      const names = Object.keys(brand.colors);
      const colors = Object.values(brand.colors);
      const paletteLength = names.length;
      for (let colorIndex = 0; colorIndex < paletteLength; colorIndex += 1) {
        newData.push({
          name: names[colorIndex],
          colors: colors[colorIndex],
        });
      }
      brand.colors = newData; // eslint-disable-line

      state.teamBrands.allIds.push(brandId);
      state.teamBrands.byId[brandId] = brand;
    },
    addTeamColor(state, item) {
      const { brandId, name, color } = item;
      const selectedBrand = state.teamBrands.byId[brandId];

      selectedBrand.colors.find((data) => { // eslint-disable-line
        if (data.name === name) {
          data.colors.push(color);
        }
      });
    },
    deleteTeamColor(state, item) {
      const { brandId, name, index } = item;

      const selectedBrand = state.teamBrands.byId[brandId];
      selectedBrand.colors.find((data) => { // eslint-disable-line
        if (data.name === name) {
          data.colors.splice(index, 1);
        }
      });
    },
    changeTeamColor(state, item) {
      const { name, color, index, brandId } = item; // eslint-disable-line

      const selectedBrand = state.teamBrands.byId[brandId];
      selectedBrand.colors.find((data) => { // eslint-disable-line
        if (data.name === name) {
          data.colors.splice(index, 1, color);
        }
      });
    },
    addTeamPalette(state, data) {
      const { brandId, palette } = data;
      const { color, title } = palette;

      const selectedBrand = state.teamBrands.byId[brandId];
      selectedBrand.colors.push({
        name: title,
        colors: color,
      });
    },
    deleteTeamPalette(state, data) {
      const { brandId, title } = data;
      const selectedBrand = state.teamBrands.byId[brandId];
      if (selectedBrand.colors) {
        selectedBrand.colors = selectedBrand.colors.filter((item) => {
          if (item.name === title) {
            return false;
          }
          return true;
        });
      }
    },
    renameTeamPalette(state, data) {
      const { brandId, index, title } = data;
      const oldTitle = Object.values(state.teamBrands.byId[brandId].colors)[index].name;

      const selectedBrand = state.teamBrands.byId[brandId];
      if (selectedBrand.colors) {
        selectedBrand.colors.find((item) => { // eslint-disable-line
          if (item.name === oldTitle) {
            item.name = title; // eslint-disable-line
          }
        });
      }
    },

    deleteTeamBrand(state, brandId) {
      const brandIndex = state.teamBrands.allIds.findIndex(
        (teamBrandId) => teamBrandId === brandId // eslint-disable-line
      );
      console.log('brandIndex', brandIndex, brandId);
      if (brandIndex >= 0) {
        state.teamBrands.allIds.splice(brandIndex, 1);
        delete state.teamBrands.byId[brandId];
      } else {
        console.error("Brand ID provided doesn't exist ", brandId);
      }
    },
    addTotalFetchedTeamBrandsLogos(state) {
      state.totalFetchedTeamBrandsLogos += 1;
    },
    decreaseTotalFetchedTeamBrandsLogos(state) {
      state.totalFetchedTeamBrandsLogos -= 1;
    },
    resetTotalFetchedTeamBrandsLogos(state) {
      state.totalFetchedTeamBrandsLogos = 0;
    },
    setTeamBrandMeta(state, item) {
      const { brandId, brandMeta } = item;
      if (!state.teamBrands.byId[brandId]) {
        console.error('missing team brand by that id', brandId);
        return;
      }

      Vue.set(state.teamBrands.byId[brandId], 'brand_meta', brandMeta);
    },
    setTeamFontPairingList(state, data) {
      state.teamFontPairingList = data;
    },
    setTeamBrandFontStyles(state, data) {
      const convertedTextStyleData = [];
      // eslint-disable-next-line
      for (const key in data.text_style) {
        const value = data.text_style[key];
        value.title = key;
        convertedTextStyleData.push(value);
      }
      data.text_style = convertedTextStyleData; // eslint-disable-line

      state.teamBrandFontStyles = data;
    },

    setDefaultBrandFont(state, item) {
      console.log('setDefaultBrandFont', item);
      if (item.h1) {
        const { fontFamily, fontSize, fontWeight, fontStyle } = item.h1; // eslint-disable-line
        if (fontFamily) state.defaultTeamFont.h1.fontFamily = fontFamily;
        if (fontSize) state.defaultTeamFont.h1.fontSize = parseInt(fontSize, 10);
        if (fontWeight) state.defaultTeamFont.h1.fontWeight = parseInt(fontWeight, 10);
        if (fontStyle) state.defaultTeamFont.h1.fontStyle = fontStyle;
      }
      if (item.h2) {
        const { fontFamily, fontSize, fontWeight, fontStyle } = item.h2; // eslint-disable-line
        if (fontFamily) state.defaultTeamFont.h2.fontFamily = fontFamily;
        if (fontSize) state.defaultTeamFont.h2.fontSize = parseInt(fontSize, 10);
        if (fontWeight) state.defaultTeamFont.h2.fontWeight = parseInt(fontWeight, 10);
        if (fontStyle) state.defaultTeamFont.h2.fontStyle = fontStyle;
      }
    },
    pushCustomFonts(state, fonts) {
      if (fonts) {
        fonts.forEach((font) => {
          const index = state.teamOffeoFonts.findIndex(
            (offeoFont) => offeoFont.fontFamily === font.font_family // eslint-disable-line
          );
          // format fontFamily in team-custom-font response
          font.fontFamily = font.font_family; // eslint-disable-line
          // don't push if it's the font family already exist
          if (index < 0) state.teamOffeoFonts.push(font);
        });
      }
    },
    deleteAllCustomFonts(state) {
      const removedCustomFonts = state.teamOffeoFonts.filter((font) => {
        const isCustom = !font.category || (Array.isArray(font.category) && font.category.includes('custom'));
        // remove all custom fonts
        return !isCustom;
      });

      state.teamOffeoFonts = removedCustomFonts;
    },
    deleteCustomFonts(state, data) {
      const { fontFamily, brandId } = data;
      const filteredFonts = state.teamOffeoFonts.filter((font) => {
        if (font.font_family === fontFamily) return false;
        return true;
      });

      const updatedTeamCustomFontsByBrand = cloneDeep(state.teamCustomFontsByBrand);
      updatedTeamCustomFontsByBrand.forEach((teamBrand) => {
        if (teamBrand.brand_id === brandId) {
          const filteredCustomFontList = teamBrand.custom_font_list.filter(font => font.fontFamily !== fontFamily);
          teamBrand.custom_font_list = filteredCustomFontList; // eslint-disable-line
        }
      });

      state.teamOffeoFonts = filteredFonts;
      state.teamCustomFontsByBrand = updatedTeamCustomFontsByBrand;
    },
    setTeamCustomFontsByBrand(state, fonts) {
      if (fonts.length) {
        fonts.forEach((font) => {
          // format fontFamily in team-custom-font response
          font.fontFamily = font.font_family; // eslint-disable-line
        });
      }

      state.teamCustomFontsByBrand = fonts;
    },
  },
  actions: {
    setTeamBrands({ state, rootState }, item) {
      const brandsById = {};
      const brandsAllIds = [];

      if (item.length) {
        const filteredItem = [...item];
        filteredItem.forEach((data) => {
          const newData = [];
          const names = Object.keys(data.colors);
          const colors = Object.values(data.colors);

          const paletteLength = names.length;
          for (let colorIndex = 0; colorIndex < paletteLength; colorIndex += 1) {
            newData.push({
              name: names[colorIndex],
              colors: colors[colorIndex],
            });
          }
          data.colors = newData; // eslint-disable-line
        });

        for (let i = 0; i < filteredItem.length; i += 1) {
          const brand = filteredItem[i];
          const brandId = brand.id;

          // if (colors) {
          //   for (let j = 0; j < Object.keys(colors).length; j += 1) {
          //     const name = Object.keys(colors)[j];
          // const colorArr = colors[name];

          // const colorBrand = {
          //   name,
          //   colors: [...colorArr],
          // };
          // teamColors.push(normalizeColorBrand(colorBrand));
          //   }
          // }

          // const newBrand = { ...brand };
          brandsAllIds.push(brandId);
          brandsById[brandId] = brand;
        }

        state.teamBrands.allIds = brandsAllIds;
        state.teamBrands.byId = brandsById;
      }

      // if project doesnt have brand id or the active team brand is deleted from dashboard already, choose the first brand id
      const projectBrandId = rootState.canvasElements.project.brandId;
      const brandIsDeleted = !state.teamBrands.byId[projectBrandId];

      if (!projectBrandId || brandIsDeleted) {
        console.log('There is no brand id or team brand is deleted');
        if (brandsAllIds.length) {
          // eslint-disable-next-line prefer-destructuring
          rootState.canvasElements.project.brandId = brandsAllIds[0]; // eslint-disable-line
        }
      }
    },
    // eslint-disable-next-line
    initTeam({ commit, dispatch, rootState }, teamId) {
      TeamApi.teamDetails(teamId)
        .then((response) => {
          if (response.data.success) {
            const { results } = response.data;
            results.current_user_id = rootState.userData.userId;

            dispatch('fetchTeamBrands', teamId);
            commit('setTeamData', results);
            commit('setTeamId', teamId);
          }
        })
        .catch((error) => {
          console.log(error);

          // dont allow if not part of team - usually can be triggered at smart create page
          if (error && error.response.status === 401) {
            alert('Has no permission to create a project for this team.'); // eslint-disable-line
            window.location.href = `${process.env.VUE_APP_DASHBOARD_URL}`;
          }
        });
    },
    fetchTeamBrands({ dispatch, commit }, teamId) {
      TeamApi.getTeamBrands(teamId)
        .then((response) => {
          if (response.data.success) {
            const { results } = response.data;
            dispatch('setTeamBrands', results);
            commit('resetTotalFetchedTeamBrandsLogos');
            dispatch('fetchAllTeamLogos');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    switchTeamBrand({ rootGetters, rootState, commit }, brandId) {
      const APIREQUEST = rootGetters.getIsTemplate ? TemplateApi : ProjectApi;
      const projectId = rootGetters['canvasElements/getProjectId'];

      // set the brandId first before api call to make it look faster
      // reset it if it fails
      const oldBrandId = rootState.canvasElements.project.brandId;
      commit('canvasElements/setProjectBrandId', brandId, { root: true });

      const params = {
        brand_id: brandId,
      };

      APIREQUEST.update(projectId, params)
        .then((response) => {
          if (!response.data.success) {
            commit('canvasElements/setProjectBrandId', oldBrandId, { root: true });
          }
        })
        .catch((error) => {
          console.log(error);
          commit('canvasElements/setProjectBrandId', oldBrandId, { root: true });
        });
    },
    fetchAllTeamLogos({ dispatch, commit, state }) {
      for (let i = 0; i < state.teamBrands.allIds.length; i += 1) {
        const brandId = state.teamBrands.allIds[i];
        const brand = state.teamBrands.byId[brandId];

        if (brand.logo) {
          setTimeout(() => {
            dispatch('fetchTeamLogos', { teamId: state.teamId, brandId });
          }, 300);
        } else {
          commit('addTotalFetchedTeamBrandsLogos');
        }
      }
    },
    fetchTeamLogos({ commit }, data) {
      const { teamId, brandId } = data;

      TeamApi.getTeamLogos(teamId, brandId)
        .then((response) => {
          if (response.data.success) {
            const { results } = response.data;
            commit('setTeamLogos', results);
            commit('addTotalFetchedTeamBrandsLogos');
          }
        })
        .catch((error) => {
          console.log(error);
          commit('addTotalFetchedTeamBrandsLogos');
        });
    },
    checkExtraTeamLogo({ state, getters, commit }) {
      const teamId = state.teamId; // eslint-disable-line
      const brandId = getters.getActiveTeamBrandId;

      commit('setIsFetchingExtraTeamLogo', true);

      TeamApi.getTeamBrandSetupDetails({ teamId, brandId })
        .then((response) => {
          const { results } = response.data;

          if (results.is_show_logo_on_team_brand) {
            commit('setExtraTeamLogo', {
              id: 'brand_setup',
              file: results.initial_logo.meta_value,
              preview_url: results.initial_logo.meta_value,
            });
          } else {
            commit('setExtraTeamLogo', {});
          }
        })
        .catch((error) => {
          console.log(error);
          commit('setExtraTeamLogo', {});
        })
        .finally(() => {
          commit('setIsFetchingExtraTeamLogo', false);
        });
    },
    addTeamBrandLogo({ state, getters, commit }, logo) {
      commit('setTeamBrandIndividualLoadingType', 'add-team-logo');

      const teamId = state.teamId; // eslint-disable-line
      const brandId = getters.getActiveTeamBrandId;

      TeamApi.addTeamLogo(teamId, brandId, logo)
        .then((response) => {
          const { user_files } = response.data.results;
          commit('addTeamLogo', { activeTeamBrandId: brandId, logo: user_files });
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Successfully added new logo',
              duration: 5000,
              type: 'success',
            },
            { root: true },
          );
          commit('setTeamBrandIndividualLoadingType', '');
        })
        .catch((error) => {
          console.log(error);
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Adding logo failed',
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
          commit('setTeamBrandIndividualLoadingType', '');
        });
    },
    // eslint-disable-next-line
    removeTeamLogo({ state, getters, commit, dispatch }, logoId) {
      let isFromSmartCreate = false;
      let teamLogoId = logoId;

      if (typeof logoId === 'object') teamLogoId = logoId.id;
      if (logoId.isSmartCreate) isFromSmartCreate = true;

      if (teamLogoId === 'brand_setup') {
        dispatch('toggleIsShowTeamBrandSetupLogo', logoId);
        return;
      }

      if (!isFromSmartCreate) {
        commit('setTeamBrandIndividualLoadingType', 'remove-team-logo');
      }

      const teamId = state.teamId; // eslint-disable-line
      const brandId = getters.getActiveTeamBrandId;

      TeamApi.deleteTeamLogo(teamId, brandId, teamLogoId)
        .then(() => {
          if (!isFromSmartCreate) {
            commit('deleteTeamLogo', { activeTeamBrandId: brandId, logoId: teamLogoId });
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Successfully removed logo',
                duration: 5000,
                type: 'success',
              },
              { root: true },
            );
            commit('setTeamBrandIndividualLoadingType', '');
            commit('assetsSidebar/setShowDeleteUserFileModal', false, { root: true });
          }
        })
        .catch((error) => {
          console.log(error);

          if (!isFromSmartCreate) {
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Removing logo failed',
                duration: 5000,
                type: 'error',
              },
              { root: true },
            );
            commit('setTeamBrandIndividualLoadingType', '');
            commit('assetsSidebar/setShowDeleteUserFileModal', false, { root: true });
          }
        });
    },
    toggleIsShowTeamBrandSetupLogo({ getters, commit }, logoId) {
      let isFromSmartCreate = false;
      if (typeof logoId === 'object' && logoId.isSmartCreate) isFromSmartCreate = true;

      if (!isFromSmartCreate) {
        commit('setTeamBrandIndividualLoadingType', 'remove-team-logo');
      }

      const brandId = getters.getActiveTeamBrandId;

      TeamApi.toggleIsShowTeamBrandSetupLogo(brandId)
        .then(() => {
          commit('setExtraTeamLogo', {});
          if (!isFromSmartCreate) {
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Successfully removed logo',
                duration: 5000,
                type: 'success',
              },
              { root: true },
            );
            commit('setTeamBrandIndividualLoadingType', '');
            commit('assetsSidebar/setShowDeleteUserFileModal', false, { root: true });
          }
        })
        .catch((error) => {
          console.log(error);

          if (!isFromSmartCreate) {
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Removing logo failed',
                duration: 5000,
                type: 'error',
              },
              { root: true },
            );
            commit('setTeamBrandIndividualLoadingType', '');
            commit('assetsSidebar/setShowDeleteUserFileModal', false, { root: true });
          }
        });
    },
    addTeamBrand({ commit }, data) {
      const { teamId, brandName } = data;
      TeamApi.addTeamBrand(teamId, brandName)
        .then((response) => {
          if (response.data.success) {
            const { results } = response.data;
            commit('addTeamBrand', results);
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Successfully create a new brand.',
                duration: 5000,
                type: 'success',
              },
              { root: true },
            );
          }
        })
        .catch((error) => {
          const errorMessage = (error
            && error.response
            && error.response.data
            && error.response.data.message)
            || '';

          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Error!  ' + errorMessage, // eslint-disable-line
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },
    renameTeamBrand({ state, commit, dispatch }, data) {
      commit('setTeamBrandIndividualLoadingType', 'rename-brand');

      const renameData = {
        new_brand_name: data.newBrandName,
      };
      const ids = {
        teamId: state.teamId,
        brandId: data.brandId,
      };

      TeamApi.renameTeamBrand(ids, renameData)
        .then((response) => {
          dispatch('setTeamBrands', response.data.results);
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Successfully rename brand.',
              duration: 5000,
              type: 'success',
            },
            { root: true },
          );
          commit('setTeamBrandIndividualLoadingType', '');
        })
        .catch((error) => {
          const errorMessage = (error
              && error.response
              && error.response.data
              && error.response.data.message)
              || '';

          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Error!  ' + errorMessage, // eslint-disable-line
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
          commit('setTeamBrandIndividualLoadingType', '');
        });
    },
    fetchTeamBrandMeta({ commit }, data) {
      const { brandId } = data;
      if (!brandId) return;

      TeamApi.getTeamBrandCollection(data)
        .then((response) => {
          if (response.data.success && response.data.results) {
            const brandMeta = response.data.results.brand_meta;
            if (brandMeta && brandMeta.length) {
              commit('setTeamBrandMeta', {
                brandId,
                brandMeta,
              });
              // console.log('brandMeta', brandMeta, brandMeta, brandMeta[0].selected_h1);
              if (brandMeta[0].selected_h1 && brandMeta[1].selected_h2) {
                commit('setDefaultBrandFont', {
                  h1: brandMeta[0].selected_h1,
                  h2: brandMeta[1].selected_h2,
                });
              }
              if (brandMeta.selected_h1) {
                commit('setDefaultBrandFont', {
                  h1: brandMeta.selected_h1,
                  h2: brandMeta.selected_h2,
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addTeamColorAction({ commit, state }, data) {
      const params = {
        brand_id: data.brandId,
        palette_name: data.paletteName,
        color: data.color || '#000000',
      };

      // add the team color before api call to make it look faster
      // reset it if it fails
      const newTeamColor = {
        name: params.palette_name,
        brandId: params.brand_id,
        index: state.teamBrands.byId[params.brand_id].colors.length - 1,
      };
      commit('addTeamColor', {
        name: params.palette_name,
        brandId: params.brand_id,
        color: params.color,
      });

      TeamApi.addTeamColor(data.teamId, params)
        .then((response) => {
          if (response.data.success) {
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Successfully added new color',
                duration: 5000,
                type: 'success',
              },
              { root: true },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit('deleteTeamColor', newTeamColor);
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Adding color failed',
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },
    deleteTeamColorAction({ commit, state }, data) {
      const params = {
        brand_id: data.brandId,
        palette_name: data.paletteName,
        color_idx: data.index,
      };

      // delete the team color before api call to make it look faster
      // reset it if it fails
      let oldColor = '';
      const selectedBrand = state.teamBrands.byId[params.brand_id];
      // eslint-disable-next-line
      selectedBrand && selectedBrand.colors.find((color) => {
        if (color.name === params.palette_name) {
          oldColor = color.colors[params.color_idx];
        }
      });

      const oldTeamColor = {
        name: params.palette_name,
        brandId: params.brand_id,
        color: oldColor,
      };
      commit('deleteTeamColor', {
        name: params.palette_name,
        brandId: params.brand_id,
        index: params.color_idx,
      });

      TeamApi.deleteTeamColor(data.teamId, params)
        .then((response) => {
          if (response.data.success) {
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Successfully deleted color',
                duration: 5000,
                type: 'success',
              },
              { root: true },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit('addTeamColor', oldTeamColor);
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Deleting color failed',
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },
    changeTeamColorAction({ commit, state }, data) {
      const params = {
        brand_id: data.brandId,
        palette_name: data.paletteName,
        color_idx: data.index,
        color: data.color,
      };

      // change the team color before api call to make it look faster
      // reset it if it fails
      const newColor = {
        name: params.palette_name,
        brandId: params.brand_id,
        index: params.color_idx,
        color: params.color,
      };
      const oldColor = { ...newColor };
      const selectedBrand = state.teamBrands.byId[params.brand_id];
      // eslint-disable-next-line
      selectedBrand && selectedBrand.colors.find((color) => {
        if (color.name === params.palette_name) {
          oldColor.color = color.colors[params.color_idx];
        }
      });
      // oldColor.color = state.teamBrands.byId[params.brandId].colors[params.palette_name][params.color_idx];
      commit('changeTeamColor', newColor);

      TeamApi.changeTeamColor(data.teamId, params)
        .then((response) => {
          if (response.data.success) {
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Successfully changing color',
                duration: 5000,
                type: 'success',
              },
              { root: true },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit('changeTeamColor', oldColor);
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Changing color failed',
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },

    addTeamPalette({ getters, rootGetters, state, commit }) { // eslint-disable-line
      const selectedBrand = getters.getActiveTeamBrand;
      const brandId = rootGetters['canvasElements/getProjectBrandId'];
      const paletteLength = (selectedBrand.colors && Object.keys(selectedBrand.colors).length) || 0;
      let paletteName = `Color Palette #${paletteLength + 1}`;

      // Avoid adding the same palette name
      const colorPalette = selectedBrand.colors && Object.values(selectedBrand.colors);
      const isPaletteNameExist = colorPalette.find(
        (palette) => palette.name.toLowerCase() === paletteName.toLowerCase() // eslint-disable-line
      );

      if (isPaletteNameExist && isPaletteNameExist !== {}) {
        let highestNumber = 0;
        colorPalette.find((palette) => { // eslint-disable-line
          if (palette.name.toLowerCase().includes('color palette #')) {
            const getNumber = parseInt(palette.name.replace(/[^0-9]/g, '')); // eslint-disable-line
            highestNumber = Math.max(getNumber, highestNumber);
          }
        });
        paletteName = `Color Palette #${highestNumber + 1}`;
      }

      const params = {
        palette_name: paletteName,
        brand_id: brandId,
      };

      // add before api call to make it look faster
      // reset it if it fails
      const data = {
        brandId,
        palette: {
          color: ['#000000'],
          title: paletteName,
        },
      };
      const newData = {
        brandId,
        title: paletteName,
      };
      commit('addTeamPalette', data);

      TeamApi.addTeamPalette(state.teamId, params)
        .then((response) => {
          if (response.data.success) {
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Successfully added new palette',
                duration: 5000,
                type: 'success',
              },
              { root: true },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit('deleteTeamPalette', newData);
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Adding palette failed',
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },
    deleteTeamPalette({ rootGetters, state, commit }, name) {
      const data = {
        teamId: state.teamId,
        brandId: rootGetters['canvasElements/getProjectBrandId'],
      };
      const params = {
        palette_name: name,
      };

      // delete before api call to make it look faster
      // reset it if it fails
      const deletedData = {
        brandId: data.brandId,
        palette: {
          color: state.teamBrands.byId[data.brandId].colors[params.palette_name],
          title: params.palette_name,
        },
      };
      commit('deleteTeamPalette', {
        brandId: data.brandId,
        title: params.palette_name,
      });

      TeamApi.deleteTeamPalette(data, params)
        .then((response) => {
          if (response.data.success) {
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Successfully delete palette',
                duration: 5000,
                type: 'success',
              },
              { root: true },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit('addTeamPalette', deletedData);
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Deleting palette failed',
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },
    renameTeamPalette({ rootGetters, getters, state, commit }, item) { // eslint-disable-line
      const { index, name } = item;
      const selectedBrand = getters.getActiveTeamBrand;
      const data = {
        teamId: state.teamId,
        brandId: rootGetters['canvasElements/getProjectBrandId'],
      };

      const colorPalette = selectedBrand.colors && Object.values(selectedBrand.colors);
      const isPaletteNameExist = colorPalette.find(
        (palette) => palette.name.toLowerCase() === name.toLowerCase() // eslint-disable-line
      );

      if (isPaletteNameExist && isPaletteNameExist !== {}) {
        commit(
          'setBottomRightNotif',
          {
            title: 'Hey there!',
            message: 'This palette name is already exist',
            duration: 5000,
            type: 'error',
          },
          { root: true },
        );
      } else {
        // rename before api call to make it look faster
        // reset it if it fails
        const params = {
          palette_idx: index,
          palette_name: name,
        };
        const oldData = {
          index,
          title: Object.values(selectedBrand.colors)[index].name,
          brandId: data.brandId,
        };

        TeamApi.renameTeamPalette(data, params)
          .then((response) => {
            if (response.data.success) {
              const newData = {
                brandId: data.brandId,
                index,
                title: name,
              };
              commit('renameTeamPalette', newData);
              commit(
                'setBottomRightNotif',
                {
                  title: 'Hey there!',
                  message: 'Successfully renamed palette',
                  duration: 5000,
                  type: 'success',
                },
                { root: true },
              );
            }
          })
          .catch((error) => {
            console.log(error);
            commit('renameTeamPalette', oldData);
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Renaming palette failed',
                duration: 5000,
                type: 'error',
              },
              { root: true },
            );
          });
      }
    },

    fetchTeamFontPairingList({ state, commit }, brandId) {
      if (!brandId) return;

      const showFontPairingData = {
        show_font_pairing_list: true,
      };
      const ids = {
        teamId: state.teamId,
        brandId,
      };

      TeamApi.getTeamBrandSetupDetails(ids, showFontPairingData)
        .then((response) => {
          commit('setTeamFontPairingList', response.data.results.font_pairing_lists);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    fetchTeamBrandFontStyles({ state, commit }, brandId) {
      if (!brandId) return;

      commit('setTeamBrandIndividualLoadingType', 'team-brand-font');
      const ids = {
        teamId: state.teamId,
        brandId,
      };

      TeamApi.getTeamBrandFontStyles(ids)
        .then((response) => {
          commit('setTeamBrandFontStyles', response.data.results);
          commit('setTeamBrandIndividualLoadingType', '');
        })
        .catch((error) => {
          console.error(error);
          commit('setTeamBrandIndividualLoadingType', '');
        });
    },
    addTeamBrandFontStyle({ state, commit }, brandId) {
      commit('setTeamBrandIndividualLoadingType', 'whole-team-font-style');
      const ids = {
        teamId: state.teamId,
        brandId,
      };

      TeamApi.addTeamBrandFontStyle(ids)
        .then((response) => {
          commit('setTeamBrandFontStyles', response.data.results);
          commit('setTeamBrandIndividualLoadingType', '');

          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Successfully add a new brand font style.',
              duration: 5000,
              type: 'success',
            },
            { root: true },
          );
        })
        .catch((error) => {
          const errorMessage = (error && error.response && error.response.data && error.response.data.message) || '';
          commit('setTeamBrandIndividualLoadingType', '');
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Error!  ' + errorMessage, // eslint-disable-line
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },
    updateTeamBrandFontStyle({ state, commit }, data) {
      if (data.from === 'font-pairing') {
        commit('setTeamBrandIndividualLoadingType', 'whole-team-font-style');
      } else {
        commit('setTeamBrandIndividualLoadingType', 'team-font-style');
      }

      const getStyleName = data.type.includes('text') ? data.type : '';
      const params = {
        styleName: getStyleName,
        teamId: state.teamId,
        brandId: data.brandId,
      };

      if (data.type === 'heading') {
        params.type = 'heading';
      } else if (data.type === 'sub-heading') {
        params.type = 'sub-heading';
      }

      const updateData = {
        font_style: data.fontDetail.fontStyle,
        font_weight: data.fontDetail.fontWeight,
        font_size: data.fontDetail.fontSize,
        font_color: data.fontDetail.fontColor,
        font_family: data.fontDetail.fontFamily,
        category: data.fontDetail.category,
        align: data.fontDetail.align,
        letter_spacing: data.fontDetail.letterSpacing,
        line_height: data.fontDetail.lineHeight,
      };

      TeamApi.updateTeamBrandFontStyle(params, updateData)
        .then((response) => {
          commit('setTeamBrandFontStyles', response.data.results);
          commit('setTeamBrandIndividualLoadingType', '');

          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: `Successfully updated ${data.type} brand font style.`,
              duration: 5000,
              type: 'success',
            },
            { root: true },
          );
        })
        .catch((error) => {
          const errorMessage = (error && error.response && error.response.data && error.response.data.message) || '';
          commit('setTeamBrandIndividualLoadingType', '');
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Error!  ' + errorMessage, // eslint-disable-line
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },
    deleteTeamBrandFontStyle({ state, commit }, data) {
      commit('setTeamBrandIndividualLoadingType', 'whole-team-font-style');

      const ids = {
        teamId: state.teamId,
        brandId: data.brandId,
      };
      const deleteData = {
        style_name: data.styleName,
      };

      TeamApi.deleteTeamBrandFontStyle(ids, deleteData)
        .then((response) => {
          commit('setTeamBrandFontStyles', response.data.results);
          commit('setTeamBrandIndividualLoadingType', '');
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Successfully delete brand font style.',
              duration: 5000,
              type: 'success',
            },
            { root: true },
          );
        })
        .catch((error) => {
          const errorMessage = (error && error.response && error.response.data && error.response.data.message) || '';
          commit('setTeamBrandIndividualLoadingType', '');
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Error!  ' + errorMessage, // eslint-disable-line
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },

    fetchTeamCustomFonts({ commit, state }) {
      commit('setTeamBrandIndividualLoadingType', 'custom-font');

      TeamApi.getAllTeamCustomFont(state.teamId)
        .then((response) => {
          const { results } = response.data;
          let customFontList = []; // list of all custom font combined from any brand in a team
          for (let index = 0; index < results.length; index++) { // eslint-disable-line
            customFontList = [...customFontList, ...results[index].custom_font_list];
          }

          commit('deleteAllCustomFonts'); // avoid mixed custom fonts between brands
          commit('pushCustomFonts', customFontList);
          commit('setTeamCustomFontsByBrand', results);
          commit('setTeamBrandIndividualLoadingType', '');
        })
        .catch((error) => {
          const errorMessage = (error && error.response && error.response.data && error.response.data.message) || '';
          commit('deleteAllCustomFonts'); // set custom-fonts empty
          commit('setTeamBrandIndividualLoadingType', '');
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Error!  ' + errorMessage, // eslint-disable-line
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },

    addCustomFonts({ commit, state }, data) {
      commit('setTeamBrandIndividualLoadingType', 'custom-font');

      // params with "-i" from 'customFontParams' considered italic.
      const fontFiles = {};
      // eslint-disable-next-line
      for (let key of Object.keys(data.fontData)) {
        if (typeof customFontParams[key] !== 'undefined') {
          fontFiles[customFontParams[key]] = data.fontData[key];
        }
      }

      const fontData = {
        teamId: state.teamId,
        brandId: data.brandId,
        fontName: data.fontData.fontName,
      };

      TeamApi.addTeamCustomFont(fontData, fontFiles)
        .then((response) => {
          commit('pushCustomFonts', [response.data.results.user_files]);
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Successfully uploaded your fonts.',
              duration: 5000,
              type: 'success',
            },
            { root: true },
          );

          // reload to re-get css file for font's font-family & disable "Reload Site?" window.alert
          window.onbeforeunload = null;
          window.location.reload();
          commit('setTeamBrandIndividualLoadingType', '');
        })
        .catch((error) => {
          const errorMessage = (error && error.response && error.response.data && error.response.data.message) || '';
          commit('setTeamBrandIndividualLoadingType', '');
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Error!  ' + errorMessage, // eslint-disable-line
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },

    updateCustomFonts({ commit, state, dispatch }, data) {
      commit('setTeamBrandIndividualLoadingType', 'custom-font');

      const fontFiles = {};
      // eslint-disable-next-line
      for (let key of Object.keys(data.fontData)) {
        if (typeof customFontParams[key] !== 'undefined') {
          // append font files
          fontFiles[customFontParams[key]] = data.fontData[key];
        }
      }

      const fontData = {
        teamId: state.teamId,
        brandId: data.brandId,
        fontName: data.fontData.fontName,
      };

      if (data.fontData.fileId) {
        fontData.fileId = data.fontData.fileId;
      }

      TeamApi.updateTeamCustomFont(fontData, fontFiles)
        .then(() => {
          dispatch('fetchTeamCustomFonts');
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Successfully updated a custom font.',
              duration: 5000,
              type: 'success',
            },
            { root: true },
          );
        })
        .catch((error) => {
          const errorMessage = (error && error.response && error.response.data && error.response.data.message) || '';
          commit('setTeamBrandIndividualLoadingType', '');
          console.error(error);
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Error!  ' + errorMessage, // eslint-disable-line
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },

    deleteCustomFonts({ commit, state }, data) {
      commit('setTeamBrandIndividualLoadingType', 'custom-font');

      const deleteFontData = {
        font_family: data.fontFamily,
      };

      const ids = {
        teamId: state.teamId,
        brandId: data.brandId,
      };

      TeamApi.deleteTeamCustomFont(ids, deleteFontData)
        .then(() => {
          commit('deleteCustomFonts', { fontFamily: data.fontFamily, brandId: data.brandId });
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Successfully deleted a custom font.',
              duration: 5000,
              type: 'success',
            },
            { root: true },
          );

          commit('setTeamBrandIndividualLoadingType', '');
        })
        .catch((error) => {
          const errorMessage = (error && error.response && error.response.data && error.response.data.message) || '';
          commit('setTeamBrandIndividualLoadingType', '');
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Error!  ' + errorMessage, // eslint-disable-line
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        });
    },
  },
};

export default team;
