import { timelineZooms } from '@/assets/scripts/variables';

/* eslint-disable no-param-reassign */
const layoutData = {
  namespaced: true,
  state: {
    bottomBarHeight: 54,
    canvasSectionPadding: {
      top: 0,
      right: 0,
      bottom: 0, // pushing more not to cover up
      left: 0,
    },
    rightSidebarWidth: 200,
    timelineZoomLevel: 0,
  },
  getters: {
    canvasSectionPadding: state => state.canvasSectionPadding,
    canvasSectionPaddingBottom: state => state.canvasSectionPadding.bottom,
    canvasSectionPaddingRight: state => state.canvasSectionPadding.right,
    isCanvasSectionPaddingBottomUpdated: state => (state.canvasSectionPadding.bottom !== 0),
    rightSidebarWidth: state => state.rightSidebarWidth,
    timelineZoomLevel: state => state.timelineZoomLevel,
    timelineMultiplier(state) {
      return timelineZooms[state.timelineZoomLevel].multiplier;
    },
    timelineMargin(state) {
      return timelineZooms[state.timelineZoomLevel].margin;
    },
  },
  mutations: {
    setBottomBarHeight(state, item) {
      state.bottomBarHeight = item;
    },
    setCanvasSectionPaddingTop(state, item) {
      state.canvasSectionPadding.top = item;
    },
    setCanvasSectionPaddingRight(state, item) {
      state.canvasSectionPadding.right = item;
    },
    setCanvasSectionPaddingBottom(state, item) {
      // extra push with bottom bar height along side with timeline
      state.canvasSectionPadding.bottom = (item + state.bottomBarHeight);
    },
    setCanvasSectionPaddingLeft(state, item) {
      state.canvasSectionPadding.left = item;
    },
    setRightSidebarWidth(state, item) {
      state.rightSidebarWidth = item;
    },
    setTimelineZoomLevel(state, item) {
      state.timelineZoomLevel = parseInt(item, 10);
    },
  },
};

export default layoutData;
