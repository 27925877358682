import Vue from 'vue';
import cloneDeep from 'lodash.clonedeep';
import CollectionsApi from '@/services/api/CollectionsApi'; // eslint-disable-line
// eslint-disable-next-line
import {
  flattenArray, mapDesigns, mapText, mapMediaVideo, filterMyFiles,
} from '@/assets/scripts/utilities';
import {
  level, groups, subgroups, fileCategories, designTabs,
} from '@/assets/scripts/enums';
import {
  designsPerRow,
  designsRows,
  defaultCategory,
} from '@/assets/scripts/variables';
import UserApi from '@/services/api/UserApi';
import TeamApi from '@/services/api/TeamApi';
import Api from '@/services/api/Api';
import HTMLShapes from '@/assets/data/shapes.json';

const mergeMediaResponse = (state, item, data, stockVersion) => {
  const { group, subgroup, category } = item;

  // Media - option selection
  let selectedOption = 'shutterstock';
  if (stockVersion === 'v1') {
    selectedOption = subgroup === 'photo' ? 'unsplash' : 'pixabay';
  }

  if (typeof state.sidebarElements[`${group}/${subgroup}/${category.slug}`] !== 'undefined') {
    const sidebarData = state.sidebarElements[`${group}/${subgroup}/${category.slug}`];
    // prevent duplicate of element
    // eslint-disable-next-line
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const elementIsExist = sidebarData.findIndex(el => el.id === element.id) >= 0;

      if (!elementIsExist) {
        sidebarData.push(element);
      }
    }

    return sidebarData;
  }

  // Avoid getting wrong media - stock assets
  if (group === 'media' && data.length) {
    const filteredElements = data.filter((element) => {
      const assetThumb = subgroup === 'photo' ? element.urls.thumb : element.thumbnailUrl;
      if (assetThumb.includes(selectedOption)) return true;
      return false;
    });

    return filteredElements;
  }

  return data;
};

const AssetsSidebar = {
  namespaced: true,
  state: {
    activeGroup: 'texts',
    activeSubGroup: 'text',
    activeFavFilter: groups.ELEMENTS,
    activeCategory: Object.assign({}, defaultCategory),
    activeKeyword: '',
    activeTag: '',
    activeFileCategory: fileCategories.MYUPLOADS,
    activeMediaOption: 'photo-shutterstock',
    searchElements: {},
    // example of sidebarElements
    // `group/subgroup/slugOrKeyword`: []
    sidebarElements: {},
    sidebarCategories: {
      all: {},
      graphics: {},
      overlays: {},
      effects: {},
      photo: {},
      video: {},
      masks: {},
      images: {},
      videos: {},
      music: {},
      customElements: {},
    },
    musicCategories: {
      byId: {},
      allIds: [],
    },
    featuredMusicCategories: {
      byId: {},
      allIds: [],
    },
    templatePacks: {
      byId: {},
      allIds: [],
    },
    customElements: {
      byId: {},
      allIds: [],
    },
    activeDesignFormat: 'square',
    currentPagination: 1,
    showDesignPreloader: true,
    isFetchingMaskElements: false,
    userFolders: [],
    teamFolders: [],
    teamMusicFoldersSearchResult: [], // need to have another state when searching at team music uploads, since when searching still need "teamFolders" state as reference when user click back button
    activeFolder: null,
    isMultipleFileSelection: false,
    multipleSelectedFiles: [],
    moveAllFilesActiveFolder: null,
    showMoveMultipleFilesContainer: false,
    showDeleteUserFileModal: false,
    deleteConfirmationText: '',
    deleteItemId: '',
    deleteItemIsfolder: false,
    deleteItemIsBrand: false,
    deleteItemIsPalette: false,
    deleteItemIsTeamBrandLogo: false,
    deleteItemIsSmartCreate: false,
    deleteItemIsTemporaryFile: false,
    /**
     * activeDesignTab options:
     * designTabs.ALLDESIGNS | designTabs.BRANDEDDESIGNS
     */
    activeDesignTab: designTabs.ALLDESIGNS,
    /**
     * example
     * designsByPresetFormat: {
     *   3: [53, 69, 101]
     * }
     * this designsByPresetFormat is what used on the design listing
     * this list is filtered id based on preset format, color and filter
     */
    designsByPresetFormat: {},
    brandedDesignsByPresetFormat: {},
    /**
     * filtered designs is for lazy loading the designs
     * it will load 6 pages * designsPerRow * designsRow on first load
     * it will use designsByPresetFormat listing
     */
    filteredDesigns: [],
    filteredDesignsTotalPages: 1,
    filteredBrandedDesigns: [],
    filteredBrandedDesignsTotalPages: 1,
    /**
     * designCategories
     * byId: {
     *   id: 13,
     *   preset_format: "",
     *   name: "Typography",
     *   slug: "typography",
     *   background_image": null,
     *   children": [],
     *   templates: [53, 69, 101]
     * }
     */
    designCategories: {
      byId: {},
      allIds: [],
    },
    /**
     * example:
     * - style
     * - type
     * - seasonal
     * designCategoriesGroup: [
     *  {
     *    type: "Style",
     *    children: [13, 12, 11]
     *  }
     * ]
     */
    designCategoriesGroup: [],
    /**
     * designCategoriesColor example
     * - red
     * - yellow
     * byId: {
     *  id: 49,
     *  preset_format: "",
     *  name: "Green",
     *  slug: "green",
     *  background_image: null,
     *  children: [],
     *  templates: [121, 1422, 1738
     * }
     */
    designCategoriesColor: {
      byId: {},
      allIds: [],
    },
    activeDesignCategoriesColorId: -1,
    isDesignListLoading: false,
    /**
     * presetFormats example
     * - facebook/instagram post
     * - instagram story
     * - youtube hd
     * byId: {
     *   created_at: "2020-09-10 09:42:47"
     *   format: "Facebook/Instagram Post"
     *   height: 1080
     *   id: 1
     *   updated_at: "2020-09-10 09:42:47"
     *   width: 1080
     * }
     */
    presetFormats: {
      byId: {},
      allIds: [],
    },
    activePresetFormatId: -1,
    // this is for temporary uploading
    skeletonList: {},
    brandedDesignColors: {
      light: '#FFFFFF',
      vibrant: '#0C66FF',
      dark: '#000000',
    },
    shutterstockSearchId: '',
    generalCategories: {
      byId: {},
      allIds: [],
    },
    sidebarMenus: {
      elements: {},
      backgrounds: {},
    },
    isTrimmingVideosBeforeUpload: false,
    isUploadingFile: false,
    isUploadingMusicFile: false,
  },
  getters: {
    getActiveGroup: state => state.activeGroup,
    getActiveSubGroup: state => state.activeSubGroup,
    getActiveCategory: state => state.activeCategory,
    getActiveKeyword: state => state.activeKeyword,
    getActiveGroupings: state => ({
      [level.GROUP]: state.activeGroup,
      [level.SUBGROUP]: state.activeSubGroup,
      [level.CATEGORY]: state.activeCategory,
      [level.KEYWORD]: state.activeKeyword,
    }),
    getTagSearch: state => state.tagSearch,
    getActiveTag: state => state.activeTag,
    getActiveFileCategory: state => state.activeFileCategory,
    getActiveMediaOption: state => state.activeMediaOption,
    getSearchElements: state => state.searchElements,
    getSidebarElements: state => state.sidebarElements,
    getSidebarCategories: state => state.sidebarCategories,
    getMusicCategories: state => state.musicCategories.allIds.map(id => state.musicCategories.byId[id]),
    getFeaturedMusicCategories: state => state.featuredMusicCategories.allIds.map(id => state.featuredMusicCategories.byId[id]),
    getTemplatePacks: state => state.templatePacks.allIds.map(id => state.templatePacks.byId[id]),
    getDesignCategories: state => state.designCategories.allIds.map(id => state.designCategories.byId[id]),
    getDesignCategoriesById: state => categoryId => state.designCategories.byId[categoryId],
    getPresetFormats: state => state.presetFormats.allIds.map(id => state.presetFormats.byId[id]),
    getDesignCategoriesColor: state => state.designCategoriesColor.allIds.map(id => state.designCategoriesColor.byId[id]),
    getDesignsByPresetFormat: state => (item) => {
      const { formatId, quantity, isBranded } = item;
      // console.table(item)
      // this formatDesignIds is filtered id based on preset format, color and filter
      const formatDesignIds = isBranded
        ? state.brandedDesignsByPresetFormat[formatId]
        : state.designsByPresetFormat[formatId];
      const keyword = state.activeKeyword || 'all';
      const designs = state.sidebarElements[`designs/designs/${keyword}`] || [];

      if (!designs.length) return [];

      // only return if template is not undefined
      const selectedTemplates = formatDesignIds
        .map(id => designs.find(template => template.id === id))
        .filter(template => template);

      if (!selectedTemplates.length) return [];

      // console.log('getDesignsByPresetFormat', formatId, formatDesignIds.slice(0, 10), selectedTemplates);
      if (quantity) {
        return selectedTemplates.slice(0, quantity);
      }
      return selectedTemplates;
    },
    getActiveDesignCategoriesColor: state => state.designCategoriesColor.byId[state.activeDesignCategoriesColorId] || {},
    getActivePresetFormat: state => state.presetFormats.byId[state.activePresetFormatId] || {},
    getCustomElements: state => state.customElements.allIds.map(id => state.customElements.byId[id]),
    getBrandedDesignColors: state => state.brandedDesignColors,
    getShutterstockSearchId: state => state.shutterstockSearchId,
    getGeneralCategories: state => state.generalCategories.allIds.map(id => state.generalCategories.byId[id]),
    hasGeneralCategories: state => Object.keys(state.generalCategories.allIds).length > 0 || false,
    hasElementMenus: state => Object.keys(state.sidebarMenus.elements).length > 0 || false,
    hasBackgroundMenus: state => Object.keys(state.sidebarMenus.backgrounds).length > 0 || false,

    /**
     * Get stock endpoint version
     */
    // eslint-disable-next-line consistent-return
    getStockEndpointVersion: (state) => {
      /**
       * to track stock endpoint version
       * 1. Photo - Shutterstock using v1, Unsplash using v2
       * 2. Video - Shutterstock using v1, Pixabay using v2
       */
      const { activeGroup, activeSubGroup, activeMediaOption } = state;
      let version = 'v2';


      if (activeGroup === groups.MUSIC && activeSubGroup === subgroups.MUSIC) version = 'v1';

      if (activeGroup === groups.MEDIA && (activeSubGroup === subgroups.PHOTOS || activeSubGroup === subgroups.VIDEOS)) {
        /**
         * "isMediaShutterstockAssets"
         * no matter the media's subgroup is 'photo' or 'video'
         * because "activeMediaOption" return:
         *  - Photo - photo-shutterstock
         *  - Video - video-shutterstock
         */
        // const isMediaShutterstockAssets = activeMediaOption.includes('shutterstock');
        // if (isMediaShutterstockAssets) version = 'v2';
        // else version = 'v1';

        version = 'v1'; // to restore using of shutterstock, use v2 endpoint
      }

      return version;
    },
  },
  mutations: {
    setActiveGroup(state, item) {
      state.activeGroup = item;

      if (item === groups.TEXTS) {
        state.activeSubGroup = 'text';
      }

      if (item === groups.MUSIC) {
        state.activeSubGroup = 'music';
      }
    },
    setActiveSubGroup(state, item) {
      state.activeSubGroup = item;
    },
    setActiveCategory(state, item) {
      Vue.set(state, 'activeCategory', item);
    },
    clearActiveCategory(state) {
      Vue.set(state, 'activeCategory', Object.assign({}, defaultCategory));
    },
    setActiveKeyword(state, item) {
      state.activeKeyword = item;
    },
    clearActiveKeyword(state) {
      state.activeKeyword = '';
    },
    setTagSearch(state, val) {
      state.tagSearch = val;
    },
    setActiveTag(state, item) {
      state.activeTag = item;
    },
    setActiveFileCategory(state, item) {
      state.activeFileCategory = item;
    },
    setActiveMediaOption(state, option) {
      state.activeMediaOption = option;
    },
    setSearchElements(state, { data, getters }) {
      const { group, subgroup } = getters.getActiveGroupings;

      Vue.set(state.searchElements, `${group}/${subgroup}`, data);
    },
    addElementToSidebar(state, item) {
      const stockVersion = this.getters['assetsSidebar/getStockEndpointVersion'];

      const {
        group, subgroup, folderId, activeFolder, mergeResults, // eslint-disable-line
      } = item;
      let { slug, data } = item;

      if (Array.isArray(data[0])) {
        data = flattenArray(data);
      }

      // console.log('vuex addElementToSidebar', group, activeFolder, mergeResults, folderId, item);
      // console.log( group, subgroup.PHOTOS )

      // Parse template_category_id
      // data.map((v) => {
      //   if (v.template_category_id) {
      //     v.template_category_id = JSON.parse(v.template_category_id);
      //   }
      //   return v;
      // });
      if (
        group === groups.DESIGNS
        || group === groups.BRANDEDDESIGNS
        || (group === groups.FAVOURITES && state.activeFavFilter === groups.DESIGNS)
      ) {
        if (!slug) slug = 'all';
        data = mapDesigns(data);
      }

      // Map text templates
      if (
        group === groups.TEXTS
        || (group === groups.FAVOURITES && state.activeFavFilter === groups.TEXTS)
      ) {
        data = mapText(data);
      }

      if (group === groups.MYFILES) {
        data = filterMyFiles(data);
      }

      // Map stock images
      if (group === groups.MEDIA && subgroup === subgroups.PHOTOS) {
        data = mergeMediaResponse(state, item, data, stockVersion);
      }

      // Map stock videos
      if (group === groups.MEDIA && subgroup === subgroups.VIDEOS) {
        data = mapMediaVideo(data);
        data = mergeMediaResponse(state, item, data, stockVersion);
      }

      // Map stock music
      if (
        stockVersion === 'v2'
        && group === groups.MUSIC
        && subgroup === subgroups.MUSIC
      ) {
        data = mergeMediaResponse(state, item, data, stockVersion);
      }

      // Map favorites
      // if (group === groups.FAVOURITES) {
      // data = mapFavourites(data);
      // data = data.map((v) => {
      //   if (v.type === 'text') {
      //     return mapText(v.item);
      //   }

      //   return v.item;
      // });
      // }

      if (slug === 'shapes' && group === groups.ELEMENTS) {
        data = HTMLShapes.concat(data);
      }

      const sidebarElementKey = `${group}/${subgroup}/${slug}`;

      const sidebarElements = state.sidebarElements[sidebarElementKey];
      // console.log('sudebar', `${group}/${subgroup}/${slug}`);

      // console.log('sidebarElement', `${group}/${subgroup}/${slug}`, data);
      if (`${group}/${subgroup}/${slug}` === `${groups.MYFILES}/${subgroups.LOGO}/${state.activeFileCategory}`) data = data.reverse(); // keep new uploaded logos at top


      // console.log('sidebarElementKey', mergeResults, sidebarElementKey, state.sidebarElements);

      if (
        (mergeResults === true
          || (group === groups.MEDIA && (subgroup === subgroups.PHOTOS || subgroups.VIDEOS))
          || (group === groups.MYFILES && subgroup !== subgroups.LOGO)
          || (stockVersion === 'v2'
            && group === groups.MUSIC
            && subgroup === subgroups.MUSIC))
        && sidebarElements
        && sidebarElements.length
      ) {
        // only concat on stock videos and photos
        const newData = sidebarElements.concat(data);
        // prevent duplicate of data
        const dataSet = new Set();
        const nonDuplicateData = newData.filter((el) => {
          const duplicate = dataSet.has(el.id);
          dataSet.add(el.id);
          return !duplicate;
        });
        // console.log('1', data.length, newData.length, nonDuplicateData.length);
        Vue.set(state.sidebarElements, sidebarElementKey, nonDuplicateData);
      } else {
        // console.log('addData', state.sidebarElements, sidebarElementKey, data);
        Vue.set(state.sidebarElements, sidebarElementKey, data);
      }
    },
    addCategoriesToSidebar(state, item) {
      const { subgroup, data } = item;

      state.sidebarCategories[subgroup] = data;
    },
    updateMusicCategory(state, item) {
      // eslint-disable-next-line
      for (let i = 0; i < item.length; i++) {
        const category = item[i];
        const {
          id, name, children, background_image, is_root, root_parent_id, order,
        } = category;

        if (background_image !== null) {
          if (name.toLowerCase() !== 'acoustic' && name.toLowerCase() !== 'ambient') {
            // TODO: hard coded cause shutterstock doesn't have any music under these categories
            state.featuredMusicCategories.byId[id] = {
              id,
              name,
              background_image,
              root_parent_id,
              order,
            };
            state.featuredMusicCategories.allIds.push(id);
          }
        }

        if (children.length) {
          // if it has children, put under musicCategories.byParentCategory
          state.musicCategories.byId[id] = {
            id,
            name,
            children: [],
            is_root,
            order,
          };

          if (background_image !== null) {
            state.musicCategories.byId[id].background_image = background_image;
          }

          // eslint-disable-next-line
          for (let j = 0; j < children.length; j++) {
            const child = children[j];

            if (child.name.toLowerCase() !== 'acoustic' && child.name.toLowerCase() !== 'ambient') {
              // TODO: hard coded cause shutterstock doesn't have any music under these categories
              state.musicCategories.byId[id].children.push(child);
            }
          }
          state.musicCategories.allIds.push(id);
        }
      }
    },
    updateTemplatePacks(state, item) {
      // eslint-disable-next-line
      for (let i = 0; i < item.length; i++) {
        const {
          id, name, preview, description, templates,
        } = item[i];

        state.templatePacks.byId[id] = {
          id,
          name,
          preview,
          description,
          templates,
        };
        state.templatePacks.allIds.push(id);
      }
    },
    updateCustomElements(state, item) {
      // eslint-disable-next-line
      for (let i = 0; i < item.length; i++) {
        const {
          id,
          type,
          animation_type,
          name,
          preview,
          element_category,
          element_category_id,
          element_category_meta,
          file,
          preview_url,
          bg_color,
          is_file_exists,
        } = item[i];

        state.customElements.byId[id] = {
          id,
          type,
          animation_type,
          name,
          preview,
          element_category,
          element_category_id,
          element_category_meta,
          file,
          preview_url,
          bg_color,
          is_file_exists,
        };
        state.customElements.allIds.push(id);
      }
    },
    setActiveDesignFormat(state, item) {
      state.activeDesignFormat = item;
    },
    setCurrentPagination(state, item) {
      state.currentPagination = parseInt(item || 1); // eslint-disable-line
    },
    setShowDesignPreloader(state, item) {
      state.showDesignPreloader = item;
    },
    setIsFetchingMaskElements(state, item) {
      state.isFetchingMaskElements = item;
    },
    setUserFolders(state, item) {
      Vue.set(state, 'userFolders', item);
    },
    setTeamFolders(state, folders) {
      if (folders.length) {
        folders.forEach((item) => {
          const { name: replaceByKey, ...rest } = item;
          const newObj = {
            folder_name: replaceByKey,
            ...rest,
          };

          // Avoid duplicate folders
          let isExist = false;
          if (state.teamFolders.length) {
            isExist = state.teamFolders.find(folder => folder.id === newObj.id);
          }
          if (!isExist) state.teamFolders.push(newObj);
        });
      }
    },
    setTeamMusicFoldersSearchResult(state, data) {
      data.forEach((item) => {
        item.folder_name = item.name; // eslint-disable-line
      });

      state.teamMusicFoldersSearchResult = data;
    },
    setActiveFolder(state, item) {
      state.activeFolder = item;
    },
    setIsMultipleFileSelection(state, item) {
      state.isMultipleFileSelection = item;
    },
    setMultipleSelectedFiles(state, item) {
      state.multipleSelectedFiles = item;
    },
    setMoveAllFilesFolder(state, item) {
      state.moveAllFilesActiveFolder = item;
    },
    setShowMoveMultipleFilesContainer(state, item) {
      state.showMoveMultipleFilesContainer = item;
    },
    setShowDeleteUserFileModal(state, item) {
      state.showDeleteUserFileModal = item;
    },
    setDeleteConfirmationText(state, item) {
      state.deleteConfirmationText = item;
    },
    setDeleteItemId(state, item) {
      state.deleteItemId = item;
    },
    setDeleteItemIsfolder(state, item) {
      state.deleteItemIsfolder = item;
    },
    setDeleteItemIsBrand(state, item) {
      state.deleteItemIsBrand = item;
    },
    setDeleteItemIsPalette(state, item) {
      state.deleteItemIsPalette = item;
    },
    setDeleteItemIsTeamBrandLogo(state, item) {
      state.deleteItemIsTeamBrandLogo = item;
    },
    setDeleteItemIsSmartCreate(state, item) {
      state.deleteItemIsSmartCreate = item;
    },
    setActiveFavFilter(state, item) {
      state.activeFavFilter = item;
    },
    setDeleteItemIsTemporaryFile(state, item) {
      state.deleteItemIsTemporaryFile = item;
    },
    setDesignCategoriesColor(state, item) {
      const categoriesColorAllIds = [];
      const categoriesColorById = {};

      if (!item.length) return;

      item.forEach((design) => {
        // only save if there is template in the category
        if (design.templates.length === 0) return;
        const {
          id, name, slug, background_image, children, templates,
        } = design;

        categoriesColorById[id] = {
          id,
          name,
          slug,
          background_image,
          children,
          templates,
        };
        categoriesColorAllIds.push(id);
      });

      Vue.set(state, 'designCategoriesColor', {
        byId: categoriesColorById,
        allIds: categoriesColorAllIds,
      });
    },
    setDesignCategoriesGroup(state, item) {
      Vue.set(state, 'designCategoriesGroup', item);
    },
    appendDesignCategoriesGroup(state, item) {
      const categoriesGroupAllIds = [];
      const categoriesGroupById = {};

      if (!item.length) return;

      item.forEach((design) => {
        // only save if there is template in the category
        if (design.templates.length === 0) return;
        const {
          id, name, slug, background_image, children, templates,
        } = design;

        categoriesGroupById[id] = {
          id,
          name,
          slug,
          background_image,
          children,
          templates,
        };
        categoriesGroupAllIds.push(id);
      });

      // console.log('categoriesGroupAllIds', categoriesGroupAllIds);

      Vue.set(state.designCategories, 'allIds', [
        ...state.designCategories.allIds,
        ...categoriesGroupAllIds,
      ]);
      Vue.set(state.designCategories, 'byId', {
        ...state.designCategories.byId,
        ...categoriesGroupById,
      });
    },
    setDesignCategories(state, item) {
      const categoriesAllIds = [];
      const categoriesById = {};

      if (!item.length) return;

      item.forEach((design) => {
        // only save if there is template in the category
        if (design.templates.length === 0) return;
        const {
          id, name, slug, background_image, children, templates,
        } = design;

        categoriesById[id] = {
          id,
          name,
          slug,
          background_image,
          children,
          templates,
        };
        categoriesAllIds.push(id);
      });

      Vue.set(state, 'designCategories', { byId: categoriesById, allIds: categoriesAllIds });
    },
    setActivePresetFormatId(state, item) {
      state.activePresetFormatId = item;
    },
    setIsDesignListLoading(state, item) {
      state.isDesignListLoading = item;
    },
    setActiveDesignCategoriesColorId(state, item) {
      state.activeDesignCategoriesColorId = item;
    },
    resetActiveDesignCategoriesColorId(state) {
      state.activeDesignCategoriesColorId = -1;
    },
    setActiveDesignTab(state, item) {
      state.activeDesignTab = item;
    },
    setPresetFormats(state, item) {
      const formatAllIds = [];
      const formatById = {};

      if (!item.length) return;

      item.forEach((design) => {
        const {
          id, format, width, height,
        } = design;

        formatById[id] = {
          id,
          format,
          width,
          height,
        };
        formatAllIds.push(id);
      });

      Vue.set(state, 'presetFormats', { byId: formatById, allIds: formatAllIds });
    },
    setPresetFormatsTemplates(state, item) {
      const { id, templates } = item;

      // need to reverse since BE returns the oldest first
      const templatesList = templates.reverse();

      Vue.set(state.presetFormats.byId[id], 'templates', templatesList);

      if (templatesList.length) {
        Vue.set(state.designsByPresetFormat, id, templatesList);
      }
    },
    setPresetFormatsBrandedTemplates(state, item) {
      const { id, templates } = item;

      // need to reverse since BE returns the oldest first
      const templatesList = templates.reverse();

      Vue.set(state.presetFormats.byId[id], 'brandedTemplates', templatesList);

      if (templatesList.length) {
        Vue.set(state.brandedDesignsByPresetFormat, id, templatesList);
      }
    },
    setDesignsByPresetFormat(state, item) {
      const isBranded = item ? item.isBranded : false;
      /**
       * HOW DESIGN LIST WORKS
       * There are 3 filters:
       * - colour   [DesignColors]
       * - category [DesignFilters]
       * - formats  [DesignFormats]
       *
       * These 3 filters will have templates id in the object
       * e.g. { name: "Black", slug: "Black", templates: [1, 2, 3] }
       *
       * When there is keyword, use sidebarElements['designs/designs/keyword']
       * Otherwise, use sidebarElements['designs/designs/all']
       * then compare the templates ids
       *
       * Lazy load some of the templates that match the comparison to state.filteredDesigns
       */
      const SHOW_CONSOLE = false;
      if (SHOW_CONSOLE) console.group();
      const designsListing = isBranded
        ? state.brandedDesignsByPresetFormat
        : state.designsByPresetFormat;

      const filterDesignsByTemplatesIds = (designsIds, target) => {
        if (designsIds.length) {
          return designsIds.filter(d => (isBranded
            ? target.brandedTemplates && target.brandedTemplates.includes(d)
            : target.templates && target.templates.includes(d)));
        }
        return [];
      };

      const filterPerPresetFormat = (formatId) => {
        const keyword = state.activeKeyword || 'all';
        const sidebarElements = state.sidebarElements[`designs/designs/${keyword}`];

        if (!sidebarElements) {
          Vue.set(designsListing, formatId, []);
          return;
        }
        if (SHOW_CONSOLE) console.log('----------designsByKeywordIds----------');

        // designsByKeywordIds will be list of ids [1, 3, 4, 5, 6]
        let designsByKeywordIds = sidebarElements.map(design => design.id);
        if (SHOW_CONSOLE) console.log('step1: designsByKeywordIds', keyword, designsByKeywordIds.length);

        // filter against format
        const activeDesignFormat = state.presetFormats.byId[formatId];
        designsByKeywordIds = filterDesignsByTemplatesIds(designsByKeywordIds, activeDesignFormat);
        if (SHOW_CONSOLE) console.log('step2: against format', formatId, designsByKeywordIds.length);

        // filter against colour
        const activeDesignColor = state.designCategoriesColor.byId[state.activeDesignCategoriesColorId];
        if (activeDesignColor) {
          designsByKeywordIds = filterDesignsByTemplatesIds(designsByKeywordIds, activeDesignColor);
          if (SHOW_CONSOLE) console.log('step3: against color', activeDesignColor.slug, designsByKeywordIds.length);
        }

        // filter against category
        const activeDesignCategory = state.designCategories.byId[state.activeCategory.id];
        if (activeDesignCategory) {
          designsByKeywordIds = filterDesignsByTemplatesIds(
            designsByKeywordIds,
            activeDesignCategory,
          );
          if (SHOW_CONSOLE) {
            console.log(
              'step4: against group',
              activeDesignCategory.slug,
              designsByKeywordIds.length,
            );
          }
        }

        Vue.set(designsListing, formatId, designsByKeywordIds);
        if (SHOW_CONSOLE) console.log('designsByKeywordIds', formatId, designsByKeywordIds.slice(0, 10));
      };

      if (SHOW_CONSOLE) console.log('setDesignsByPresetFormat', Object.keys(designsListing));

      Object.keys(designsListing).forEach((formatId) => {
        filterPerPresetFormat(formatId);
      });
      if (SHOW_CONSOLE) console.groupEnd();
    },
    setFilteredDesigns(state, item) {
      const page = item ? item.page : 0;
      const isBranded = item ? item.isBranded : false;

      if (!page || state.activePresetFormatId === -1) return;

      const keyword = state.keyword || 'all';
      const filteredDesignsIds = isBranded
        ? state.brandedDesignsByPresetFormat[state.activePresetFormatId]
        : state.designsByPresetFormat[state.activePresetFormatId];

      const sidebarElements = state.sidebarElements[`designs/designs/${keyword}`] || [];

      if (!filteredDesignsIds && !sidebarElements.length) return;
      if (!filteredDesignsIds || (filteredDesignsIds && !filteredDesignsIds.length)) return;

      // console.log(
      //   'setFilteredDesigns',
      //   isBranded,
      //   filteredDesignsIds.length,
      //   `designs/designs/${keyword}`,
      //   typeof sidebarElements,
      // );
      // console.log('sidebar length', sidebarElements.length);

      // make sure don't return undefined template
      const filteredDesigns = filteredDesignsIds
        .map(id => sidebarElements.find(design => design.id === id))
        .filter(template => template);

      const activePresetFormat = state.presetFormats.byId[state.activePresetFormatId];
      let proportion = 'square';
      if (activePresetFormat.width > activePresetFormat.height) proportion = 'landscape';
      if (activePresetFormat.width < activePresetFormat.height) proportion = 'vertical';

      const designsPerPage = designsPerRow[state.activeDesignTab][proportion]
        * designsRows[state.activeDesignTab][proportion];

      // set the total pages
      state.filteredDesignsTotalPages = Math.ceil(filteredDesignsIds.length / designsPerPage);

      const totalDesigns = designsPerPage * page;
      // console.log('totalDesigns', totalDesigns)

      if (isBranded) {
        state.filteredBrandedDesignsTotalPages = Math.ceil(
          filteredDesignsIds.length / designsPerPage,
        );
        Vue.set(state, 'filteredBrandedDesigns', filteredDesigns.slice(0, totalDesigns));
      } else {
        // set the total pages
        state.filteredDesignsTotalPages = Math.ceil(filteredDesignsIds.length / designsPerPage);
        Vue.set(state, 'filteredDesigns', filteredDesigns.slice(0, totalDesigns));
      }
    },
    updateSidebarElement(state, item) {
      // to update specific lement
      const {
        id, group, subgroup, slug, data, isWebsocketMixin, // eslint-disable-line
      } = item;
      // console.log('updateSidebarElement', id, group, data, isWebsocketMixin, data.is_preparing);

      if (!id || !data) return;

      const sidebarElements = state.sidebarElements[`${group}/${subgroup}/${slug}`];

      if (typeof sidebarElements === 'undefined') return;

      const addedElement = sidebarElements.findIndex(el => el.id === id);

      // console.log('===addedElement', addedElement, data, id);
      if (addedElement === -1) {
        sidebarElements.unshift(data);
      } else {
        const newValue = sidebarElements.map((v) => {
          const newData = v;
          if (v.id === id) {
            // if (typeof data.file !== 'undefined' && typeof newData.file !== 'undefined') data.file = newData.file;
            // if (typeof data.preview_url !== 'undefined' && typeof newData.preview_url !== 'undefined') data.preview_url = newData.preview_url;
            if (typeof data.file !== 'undefined') {
              data.url = data.file;
              data.urlHd = data.file;
            }
            if (typeof data.preview_url !== 'undefined') {
              data.thumb = data.preview_url;
            }
            // console.log('stater.sidebarElements:newData', data);
            return data;
          }
          return newData;
        });
        Vue.set(state.sidebarElements, `${group}/${subgroup}/${slug}`, newValue);
      }

      // console.log('update sidebar element', {
      //   id, group, subgroup, slug, data,
      // }, newValue);
    },
    updateSidebarElementPreviewSceneData(state, item) {
      // to update specific element
      const {
        id, group, subgroup, slug, sceneData,
      } = item;

      if (!id || !sceneData) return;

      const sidebarElements = state.sidebarElements[`${group}/${subgroup}/${slug}`];

      if (typeof sidebarElements === 'undefined') return;

      const sidebarElement = sidebarElements.find(element => element.id === id);

      Vue.set(sidebarElement, 'preview_scene_data', sceneData);
    },
    setIsUploadingMusicFile(state, boolean) {
      state.isUploadingMusicFile = boolean;
    },
    addSkeletonList(state, item) {
      // console.log('addSkeletonList', item.id);
      if (!state.skeletonList[item.id]) Vue.set(state.skeletonList, item.id, item);
    },
    updateSkeletonList(state, item) {
      const {
        type, id, fileType, fileName, fileProgress, isFailed, isPreparing,
      } = item;

      if (!id) return;
      if (!state.skeletonList[id]) return;

      if (typeof type !== 'undefined') state.skeletonList[id].type = type;
      if (typeof fileType !== 'undefined') state.skeletonList[id].fileType = fileType;
      if (typeof fileName !== 'undefined') state.skeletonList[id].fileName = fileName;
      if (typeof fileProgress !== 'undefined') state.skeletonList[id].fileProgress = fileProgress;
      if (typeof isFailed !== 'undefined') state.skeletonList[id].isFailed = isFailed;
      if (typeof isPreparing !== 'undefined') state.skeletonList[id].isPreparing = isPreparing;
    },
    deleteSkeletonList(state, item) {
      // console.log('deleteSkeletonList', item, !!state.skeletonList[item]);
      if (!state.skeletonList[item]) return;
      Vue.delete(state.skeletonList, item);
    },
    clearSkeletonList(state) {
      Vue.set(state, 'skeletonList', {});
    },
    setBrandedDesignColors(state, item) {
      state.brandedDesignColors = item;
    },
    setShutterstockSearchId(state, item) {
      state.shutterstockSearchId = item;
    },
    setGeneralCategories(state, item) {
      const categoriesAllIds = [];
      const categoriesById = {};

      if (!item.length) return;

      item.forEach((category) => {
        const {
          id, name, slug, children,
        } = category;

        categoriesById[id] = {
          id,
          name,
          slug,
          children,
        };
        categoriesAllIds.push(id);
      });

      Vue.set(state, 'generalCategories', { byId: categoriesById, allIds: categoriesAllIds });
    },
    insertSidebarMenus(state, item) {
      const { menu, results } = item;
      Vue.set(state.sidebarMenus, menu, results);
    },
    setIsTrimmingVideosBeforeUpload(state, boolean) {
      state.isTrimmingVideosBeforeUpload = boolean;
    },
    setIsUploadingFile(state, item) {
      state.isUploadingFile = item;
    },

    /**
     * "resetActiveMediaElements"
     * Media Option
     * - Photo => Shutterstock, Unsplash
     * - Video => Shutterstock, Pixabay
     *
     * Reset Media - sidebarElements when switching media option
    */
    resetActiveMediaElements(state) {
      const stockVersion = this.getters['assetsSidebar/getStockEndpointVersion'];
      const newUsedSlug = state.activeSubGroup === subgroups.VIDEOS ? 'abstract' : 'scenery';
      const slugElement = state.activeKeyword ? state.activeKeyword : newUsedSlug;
      const sidebarElementKey = `${state.activeGroup}/${state.activeSubGroup}/${slugElement}`;
      const sidebarData = state.sidebarElements[sidebarElementKey];

      let selectedOption = 'shutterstock'; // if stockVersion === 'v2'
      if (stockVersion === 'v1') {
        selectedOption = state.activeSubGroup === 'photo' ? 'unsplash' : 'pixabay';
      }

      let filteredData = [];
      if (sidebarData && sidebarData.length) {
        filteredData = sidebarData.filter((element) => {
          const assetThumb = state.activeSubGroup === 'photo' ? element.urls.thumb : element.thumbnailUrl;
          if (assetThumb.includes(selectedOption)) {
            return true;
          }
          return false;
        });
      }
      // Replacing "sidebarElements" data
      Vue.set(state.sidebarElements, sidebarElementKey, filteredData);
    },
  },
  actions: {
    async setSearchElements({ getters, commit }) {
      const { group, subgroup } = getters.getActiveGroupings;

      if (
        group !== 'media'
        && group !== 'texts'
        && group !== 'my-files'
        && !(getters.getStockEndpointVersion === 'v2' && group === 'music')
      ) {
        // const url = `${process.env.VUE_APP_OFFEO_API}/search-cat-tags?tab=${group}&menu=${subgroup}`;
        const params = {
          tab: group,
          menu: subgroup,
        };

        if (
          !getters.getSearchElements[`${group}/${subgroup}`]
          || getters.getSearchElements[`${group}/${subgroup}`].length === 0
        ) {
          try {
            const { data } = await Api.searchCategoryTags(params);
            commit('setSearchElements', { data: data.results, getters });
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    async addNewUserFolder({ state, commit }, item) {
      // will prepend new folder in current vuex
      const userFolders = JSON.parse(JSON.stringify(state.userFolders));
      userFolders.unshift(item);
      commit('setUserFolders', userFolders);
    },
    async renameUserFolder({ state, commit }, item) {
      const { id, folder_name } = item;
      // will prepend new fo lder in current vuex
      const userFolders = JSON.parse(JSON.stringify(state.userFolders));

      const newFolders = userFolders.map((folder) => {
        const newFolder = folder;
        if (folder.id === id) {
          newFolder.folder_name = folder_name;
        }
        return newFolder;
      });
      commit('setUserFolders', newFolders);
    },
    async removeUserFolderById({ state, commit, getters }, folderId) {
      // will prepend new folder in current vuex
      const userFolders = JSON.parse(JSON.stringify(state.userFolders));

      // this function can be called from smart create where user folders is not set in state
      // return if it is not exist
      if (!userFolders || Object.keys(userFolders).length === 0) return;

      // eslint-disable-next-line
      const newFolders = userFolders.map((folder) => {
        if (folder.id !== folderId) return folder;
      });
      commit('setActiveFolder', null);
      commit(
        'setUserFolders',
        newFolders.filter(n => n),
      ); // .filter(n => n) to remove empty array values

      // update all user files
      const { group, subgroup } = getters.getActiveGroupings;
      const userFiles = JSON.parse(
        JSON.stringify(state.sidebarElements[`${group}/${subgroup}/${getters.getActiveFileCategory}`]),
      );
      const newUserFiles = userFiles.map((file) => {
        const newFile = file;
        // eslint-disable-next-line
        if (file.folder_id === parseInt(folderId)) newFile.folder_id = null;
        return newFile;
      });
      Vue.set(state.sidebarElements, `${group}/${subgroup}/${getters.getActiveFileCategory}`, newUserFiles);
    },
    async changeUserFilesFolder({ state, getters }, item) {
      const { folderId, fileId } = item;
      const { group, subgroup } = getters.getActiveGroupings;
      const userFiles = JSON.parse(
        JSON.stringify(state.sidebarElements[`${group}/${subgroup}/${getters.getActiveFileCategory}`]),
      );
      const newUserFiles = userFiles.map((file) => {
        const newFile = file;
        // eslint-disable-next-line
        if (file.id === parseInt(fileId)) {
          newFile.folder_id = folderId <= 0 ? null : folderId;
        }
        return newFile;
      });
      Vue.set(state.sidebarElements, `${group}/${subgroup}/${getters.getActiveFileCategory}`, newUserFiles);
    },
    async addMultipleSelectedFiles({ state, commit }, item) {
      // state.multipleSelectedFiles = item;
      const multipleSelectedFiles = JSON.parse(JSON.stringify(state.multipleSelectedFiles));
      multipleSelectedFiles.push(item);
      commit(
        'setMultipleSelectedFiles',
        multipleSelectedFiles.filter((a, b) => multipleSelectedFiles.indexOf(a) === b),
      ); // remove duplicates
    },
    async removeMultipleSelectedFiles({ state, commit }, item) {
      // state.multipleSelectedFiles = item;
      const multipleSelectedFiles = JSON.parse(JSON.stringify(state.multipleSelectedFiles));
      const index = multipleSelectedFiles.indexOf(item);
      if (index > -1) multipleSelectedFiles.splice(index, 1);
      commit('setMultipleSelectedFiles', multipleSelectedFiles); // remove duplicates
    },
    async removeUserFileById({ state, getters, rootGetters }, fileId) {
      // update all user files

      const { group } = getters.getActiveGroupings;
      let { subgroup } = getters.getActiveGroupings;
      const isMusicGroup = group === groups.MUSIC;

      if (rootGetters.isLiteMode) {
        // for lite mode, set the subgroup manually
        if (isMusicGroup) {
          subgroup = subgroups.UPLOAD;
        }
      }

      const removedId = fileId.map(i => Number(i));
      const slug = isMusicGroup ? 'all' : getters.getActiveFileCategory;

      if (state.sidebarElements[`${group}/${subgroup}/${slug}`]) {
        const userFiles = JSON.parse(
          JSON.stringify(state.sidebarElements[`${group}/${subgroup}/${slug}`]),
        );
        // eslint-disable-next-line
        const newUserFiles = userFiles.map((file) => {
          if (!removedId.includes(file.id)) return file;
        });

        Vue.set(
          state.sidebarElements,
          `${group}/${subgroup}/${slug}`,
          newUserFiles.filter(n => n),
        );
      }
    },
    async moveAllFilesComplete({ state, commit }) {
      commit('setActiveFolder', JSON.parse(JSON.stringify(state.moveAllFilesActiveFolder))); // activate selected folder
      commit('setShowMoveMultipleFilesContainer', false);
      commit('setMultipleSelectedFiles', []);
      commit('setMoveAllFilesFolder', null);
      commit('setIsMultipleFileSelection', false);
    },
    async promptConfirmDeleteUserFile({ commit }, item) {
      const itemId = item.itemId || '';
      const isFolder = item.isFolder || false;
      const isBrand = item.isBrand || false;
      const isPalette = item.isPalette || false;
      const isTemporaryFile = item.isTemporaryFile || false;
      const isTeamBrandLogo = item.isTeamBrandLogo || false;
      const isSmartCreate = item.isSmartCreate || false;

      let choice = 'folder';

      if (!isFolder && !isBrand) {
        choice = 'file';
        const items = String(itemId).split('|').length;
        if (items > 1) {
          choice = `(${items}) files`;
        }
      }
      if (isBrand) {
        choice = 'brand';
      }
      if (isPalette) {
        choice = 'palette';
      }

      console.log('promptConfirmDeleteUserFile', choice, itemId, isBrand);

      commit('setDeleteItemId', itemId);
      commit('setDeleteItemIsTemporaryFile', isTemporaryFile);
      commit('setDeleteItemIsfolder', isFolder);
      commit('setDeleteItemIsBrand', isBrand);
      commit('setDeleteItemIsPalette', isPalette);
      commit('setDeleteItemIsTeamBrandLogo', isTeamBrandLogo);
      commit('setDeleteItemIsSmartCreate', isSmartCreate);
      commit('setShowDeleteUserFileModal', true);
      commit('setDeleteConfirmationText', `Are you sure you want to delete this ${choice}?`);
    },
    async confirmDeleteUserFile({ state, dispatch }) {
      console.log('confirmDeleteUserFile', state.deleteItemIsBrand, state.deleteItemIsfolder, state.deleteItemId);
      if (state.deleteItemIsfolder) {
        dispatch('actionDeleteFolder');
      } else if (state.deleteItemIsPalette) {
        dispatch('actionDeletePalette');
      } else if (state.deleteItemIsTeamBrandLogo) {
        dispatch('actionDeleteTeamBrandLogo');
      } else if (state.deleteItemIsBrand) {
        dispatch('actionDeleteBrand');
      } else {
        dispatch('actionDeleteFile');
      }
    },
    async actionDeleteFile({
      state, commit, getters, dispatch, rootState,
    }) {
      const params = {
        is_logo: getters.getActiveSubGroup === subgroups.LOGO,
      };

      if (state.deleteItemIsTemporaryFile) {
        Api.deleteTemporaryFile(rootState.inspirationList.tmpUserId, state.deleteItemId)
          .then((response) => {
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: response.data.message,
                duration: 5000,
                type: 'success',
              },
              { root: true },
            );
            // this.alertSuccess('Hey there!', response.data.message, 5000);
            commit('setShowDeleteUserFileModal', false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (params.is_logo) {
        const fileIds = String(state.deleteItemId).split('|');

        // eslint-disable-next-line
        for (let i = 0; i < fileIds.length; i++) {
          const selectedDeleteId = fileIds[i];
          UserApi.deleteUserFile(selectedDeleteId, params)
            .then((response) => {
              if (response.data.success) {
                commit(
                  'setBottomRightNotif',
                  {
                    title: 'Hey there!',
                    message: 'Logo removed',
                    duration: 5000,
                    type: 'success',
                  },
                  { root: true },
                );
                // this.alertSuccess('Hey there!', response.data.message, 5000);
                commit('userData/setStorageSpaceLeft', response.data.space_left, { root: true });
                dispatch('removeUserFileById', [selectedDeleteId]); // pass the array of file ids - set as array since it'll always be single
              } else {
                // this.alertError('Hey there!', 'Deleting file failed', 5000);
                commit(
                  'setBottomRightNotif',
                  {
                    title: 'Hey there!',
                    message: 'Deleting logo failed',
                    duration: 5000,
                    type: 'error',
                  },
                  { root: true },
                );
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .then(() => {
              commit('setDeleteItemId', '');
              commit('setDeleteItemIsfolder', false);
              commit('setShowDeleteUserFileModal', false);
              commit('setMultipleSelectedFiles', []); // remove the selected files
            });
        }
      } else {
        // not logo can use the bulk delete api

        const fileIds = String(state.deleteItemId).split('|');
        params.file_id = fileIds.map(fileId => parseInt(fileId, 10));

        UserApi.deleteMultipleFiles(params)
          .then((response) => {
            if (response.data.success) {
              commit('userData/setStorageSpaceLeft', response.data.space_left, { root: true });
              dispatch('removeUserFileById', params.file_id); // pass the array of file ids
              commit('setMultipleSelectedFiles', []); // remove the selected files
            } else {
              // this.alertError('Hey there!', 'Deleting file failed', 5000);
              commit(
                'setBottomRightNotif',
                {
                  title: 'Hey there!',
                  message: 'Deleting file failed',
                  duration: 5000,
                  type: 'error',
                },
                { root: true },
              );
            }
          })
          .catch(() => {
            // this.alertError('Hey there!', 'Deleting file failed', 5000);
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Deleting file failed',
                duration: 5000,
                type: 'error',
              },
              { root: true },
            );
          })
          .then(() => {
            dispatch('actionDeleteAlways');
          });
      }
    },
    // eslint-disable-next-line
    async actionDeleteFolder({ state, commit, getters, dispatch }) {
      // hide the modal earlier before
      commit('setShowDeleteUserFileModal', false);
      UserApi.deleteUserFolder(state.deleteItemId)
        .then((response) => {
          if (response.data.success) {
            dispatch('removeUserFolderById', state.deleteItemId);
          } else {
            commit(
              'setBottomRightNotif',
              {
                title: 'Hey there!',
                message: 'Removing folder failed',
                duration: 5000,
                type: 'error',
              },
              { root: true },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'setBottomRightNotif',
            {
              title: 'Hey there!',
              message: 'Removing folder failed',
              duration: 5000,
              type: 'error',
            },
            { root: true },
          );
        })
        .then(() => {
          dispatch('actionDeleteAlways');
        });
    },
    async actionDeletePalette({ dispatch, commit, state }) {
      commit('setShowDeleteUserFileModal', false);
      dispatch('team/deleteTeamPalette', state.deleteItemId, { root: true });
    },
    async actionDeleteBrand({
      state, commit, rootState, dispatch,
    }) {
      console.log('---actions---', rootState.team.teamId, state.deleteItemId);
      // hide the modal earlier before
      commit('setShowDeleteUserFileModal', false);

      // delete the team brand before calling the api to make it look faster
      // if failed, add it back
      const selectedTeamBrand = cloneDeep(rootState.team.teamBrands.byId[state.deleteItemId]);
      commit('team/deleteTeamBrand', state.deleteItemId, { root: true });

      TeamApi.deleteTeamBrand(rootState.team.teamId, state.deleteItemId)
        .then((response) => {
          if (response.data.success) {
            commit('setBottomRightNotif', {
              title: 'Hey there!',
              message: response.data.message,
              duration: 5000,
              type: 'success',
            }, { root: true });
          } else {
            commit('team/addTeamBrand', selectedTeamBrand, { root: true });
            commit('setBottomRightNotif', {
              title: 'Hey there!',
              message: 'Removing brand failed',
              duration: 5000,
              type: 'error',
            }, { root: true });
          }
        })
        .catch((error) => {
          console.log(error);
          commit('team/addTeamBrand', selectedTeamBrand, { root: true });
          commit('setBottomRightNotif', {
            title: 'Hey there!',
            message: 'Removing brand failed',
            duration: 5000,
            type: 'error',
          }, { root: true });
        })
        .then(() => {
          dispatch('actionDeleteAlways');
        });
    },
    async actionDeleteTeamBrandLogo({ state, dispatch }) {
      dispatch('team/removeTeamLogo', { id: state.deleteItemId, isSmartCreate: state.deleteItemIsSmartCreate }, { root: true });
    },
    async actionDeleteAlways({ commit }) {
      commit('setIsMultipleFileSelection', false);
      commit('setDeleteItemId', '');
      commit('setDeleteItemIsfolder', false);
      commit('setDeleteItemIsBrand', false);
      commit('setDeleteItemIsPalette', false);
      commit('setDeleteItemIsTeamBrandLogo', false);
      commit('setDeleteItemIsSmartCreate', false);
      commit('setShowDeleteUserFileModal', false);
    },
    async getMaskElements({ commit }) {
      commit('setIsFetchingMaskElements', true);
      const params = {
        global_search: '',
        menu: subgroups.MASKS,
        sort: 'desc',
        sort_by: 'is_featured',
      };

      CollectionsApi.getItems(groups.ELEMENTS, params).then((response) => {
        const { results } = response.data;

        const maskObj = {
          group: groups.MEDIA,
          subgroup: subgroups.MASKS,
          slug: 'all',
          data: results,
        };

        commit('addElementToSidebar', maskObj);
        commit('setIsFetchingMaskElements', false);
      });
    },
    async getDesigns({ commit, dispatch }, item) {
      // commit('setShowDesignPreloader', true);
      const keyword = item && item.keyword ? item.keyword : null;
      const page = item?.page || 1;
      // console.log('getDesigns', item);

      const params = {};
      const endpoint = 'designs';

      if (keyword) {
        params.search = keyword;
      }

      if (item?.start_id) {
        params.start_id = item.start_id;
      }

      const mergeResults = !!item?.mergeResults;

      params.type = 'template,modular';
      params.page = page;
      params.item_per_page = item?.item_per_page || 500;

      CollectionsApi.getItems(endpoint, params).then((response) => {
        const { results } = response.data; // response.data?.data -> if has pagination

        const designs = {
          group: groups.DESIGNS,
          subgroup: 'designs',
          category: 'all',
          data: results?.data || results,
          mergeResults,
        };
        if (keyword) designs.slug = keyword;

        commit('addElementToSidebar', designs);
        commit('setDesignsByPresetFormat');

        // to get the next pages
        const pagination = results?.pagination;
        // console.log('postGetDesigns.pagination', pagination, pagination.total_pages > params.page, results);
        if (pagination.total_pages > params.page) {
          setTimeout(() => {
            params.page += 1;
            // params.start_id = results?.data[results?.data.length - 1].id;
            params.mergeResults = true;
            dispatch('getDesigns', params);
          }, 250);
        }
      });
    },
    async getPresetFormats({ commit, dispatch }) {
      CollectionsApi.getPresetFormats()
        .then((response) => {
          if (response.data.success) {
            // console.log('setePrset', response.data.results);
            commit('setPresetFormats', response.data.results);
            dispatch('getPresetFormatsTemplates', response.data.results);
            dispatch('getPresetFormatsBrandedTemplates', response.data.results);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getPresetFormatsTemplates({ commit }, item) {
      item.forEach((format, index) => {
        // stagger the call
        setTimeout(() => {
          CollectionsApi.getPresetFormats(format.id, { exclude_categories: 'layout' })
            .then((response) => {
              if (response.data.success) {
                commit('setPresetFormatsTemplates', response.data.results);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }, index * 500);
      });
    },

    async getPresetFormatsBrandedTemplates({ commit }, item) {
      item.forEach((format, index) => {
        // stagger the call
        setTimeout(() => {
          CollectionsApi.getPresetFormats(format.id, { categories: 'layout' })
            .then((response) => {
              if (response.data.success) {
                commit('setPresetFormatsBrandedTemplates', response.data.results);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }, index * 500);
      });
    },

    setDesignCategoriesGroup({ commit }, item) {
      if (item.length) {
        const categoryGroups = [];

        item.forEach((category) => {
          // only continue if there are children
          if (!category.children || !category.children.length) return;

          // only get children that has templates
          const newCategory = {
            type: category.type,
            children: category.children.filter(c => c.templates.length).map(c => c.id),
          };

          commit('appendDesignCategoriesGroup', category.children);
          // console.log('newCategory', newCategory);
          categoryGroups.push(newCategory);
        });

        commit('setDesignCategoriesGroup', categoryGroups);
      }
    },

    async getDesignCategories({ commit, dispatch }) {
      CollectionsApi.getDesignCategories({
        sort: 'asc',
        sort_by: 'order',
      })
        .then((response) => {
          if (response.data.success) {
            commit('setDesignCategoriesColor', response.data.results.color);
            dispatch('setDesignCategoriesGroup', response.data.results.category);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      // designCategoriesColor
    },
    async getGeneralCategories({ commit, getters }, forceReload = false) {
      if (getters.hasGeneralCategories && !forceReload) {
        // console.log('hasGeneralCategories');
        return;
      }
      CollectionsApi.getCategories()
        .then((response) => {
          if (response.data.success) {
            commit('setGeneralCategories', response.data.results);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // eslint-disable-next-line
    async getElementMenus({ commit, getters, state }, forceReload = false) {
      if (getters.hasElementMenus && !forceReload) {
        // console.log('hasElementMenus');
        return;
      }
      CollectionsApi.getElementMenus()
        .then((response) => {
          if (response.data.success) {
            commit('insertSidebarMenus', {
              menu: 'elements',
              results: response.data.results,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // eslint-disable-next-line
    async getBackgroundMenus({ commit, getters, state }, forceReload = false) {
      if (getters.hasBackgroundMenus && !forceReload) {
        // console.log('hasBackgroundMenus');
        return;
      }
      CollectionsApi.getBackgroundMenus()
        .then((response) => {
          if (response.data.success) {
            commit('insertSidebarMenus', {
              menu: 'backgrounds',
              results: response.data.results,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

export default AssetsSidebar;
