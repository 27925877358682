/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import AssetsSidebar from '@/store/assets-sidebar'; // eslint-disable-line
import CanvasElements from '@/store/canvas-elements'; // eslint-disable-line
import ExportDetails from '@/store/export-details';
import CanvasLayouts from '@/store/canvas-layouts';
import ColorPreset from '@/store/color-preset'; // eslint-disable-line
import CanvasHistory from '@/store/canvas-history';
import UserData from '@/store/user-data';
import Team from '@/store/team'; // eslint-disable-line
import AnimationSettings from '@/store/animation-settings'; // eslint-disable-line
import InspirationList from '@/store/inspiration-list'; // eslint-disable-line
import Brand from '@/store/brand';
import Account from '@/store/account';
import OffeoFonts from '@/assets/data/fonts.json';
import FilterLists from '@/assets/data/filters.json';
import TransitionList from '@/assets/data/transition-list.json';
import AnimationList from '@/assets/data/animation-list.json';
import { mapDesigns, mapText } from '@/assets/scripts/utilities'; // eslint-disable-line

import { favouriteFilters, editTabs } from '@/assets/scripts/enums';
import { defaultFallbackFont, defaultFontSize } from '../assets/scripts/variables'; // eslint-disable-line
import HTMLShapes from '@/assets/data/shapes.json';

import FavouritesApi from '@/services/api/FavouritesApi';

Vue.use(Vuex);

const offeoAppDetailsKey = 'offeo_app_details';

const closeAllTab = (state, exceptionTab, exceptionValue, overrideBgColorTabValue) => {
  // close all tab except the passed value
  const tabList = [
    'showAnimationTab',
    'showColorTab',
    'showColorPicker',
    'showMaskTab',
    'showEditTab',
    'showFontTab',
    'showMobileFilterTab',
    'showMobileAdjustTab',
  ];

  console.log('closeAllTab', exceptionTab, exceptionValue);

  for (let i = 0; i < tabList.length; i += 1) {
    const tab = tabList[i];

    if (exceptionTab && tab === exceptionTab) {
      state[tab] = !!exceptionValue;
      if (typeof overrideBgColorTabValue !== 'undefined') state.overrideBackgroundColorTab = overrideBgColorTabValue;
    } else {
      state[tab] = false;
    }
  }
};

export default new Vuex.Store({
  modules: {
    assetsSidebar: AssetsSidebar,
    colorPreset: ColorPreset,
    canvasElements: CanvasElements,
    canvasHistory: CanvasHistory,
    exportDetails: ExportDetails,
    canvasLayouts: CanvasLayouts,
    userData: UserData,
    team: Team,
    animationSettings: AnimationSettings,
    inspirationList: InspirationList,
    brand: Brand,
    account: Account,
  },
  state: {
    offeoFonts: cloneDeep(OffeoFonts),
    currentTime: 2,
    currentAllScenesTime: 0,
    currentGenieTime: 0,
    isPlaying: false,
    isAllScenesPlaying: false,
    /**
     * Note: Currently leave it, in case in the future will need it back
     */
    isLoggedIn: false,
    isGeniePlaying: false,
    isShowSubscriptionModal: false,
    isShowAnimationModal: false,
    showSidebar: true,
    showLayer: true,
    maintainShowBackgroundColorTab: false,
    showColorTab: false,
    showBrandTabFontOverlay: false,
    showBrandTabColorEditModal: false,
    showColorPicker: false,
    isTextBackground: false,
    isTextShadow: false,
    isMediaShadow: false,
    isTextBlur: false,
    isMediaBlur: false,
    isTextGlow: false,
    isMediaGlow: false,
    isTextLightSweep: false,
    isMediaLightSweep: false,
    isTextGlitch: false,
    isMediaGlitch: false,
    isMediaRoundCorners: false,
    isMediaBorder: false,
    activeTextGlitchColorType: 'color1',
    isTextOutline: false,
    isText3D: false,
    isReflection: false,
    isGroundShadow: false,

    activeEditTabGroup: '',
    editTabEffectsType: '',
    showEditTab: false,

    showMobileFilterTab: false,
    showMobileAdjustTab: false,

    showAnimationTab: false,
    showMaskTab: false,
    showFontTab: false,
    showTimeline: false,
    showTagManager: false,
    isDownloadPreviewOpened: false,
    downloadPreviewOption: 'video',
    downloadPreviewType: 'preview',
    isProjectPreview: false,
    isTextEditing: false,
    showCustomDesignModal: false,
    showMultipleSwap: false,
    filterLists: FilterLists,
    transitionList: TransitionList,
    animationList: AnimationList,
    activeAnimationList: '',
    activeAnimationListPosition: {
      height: 0,
      width: 0,
      x: 0,
      y: 0,
      position: 'bottom',
    },
    activeAnimationListId: [],
    canvasZoom: 1,
    canvasZoomFit: 1,
    showGrid: 0,
    canvasGridTotal: 0,
    showTitleSafe: false,
    showSwap: false,
    showGenieModal: false,
    showCrop: false,
    isShowMusicToolbarTrimmer: false, // trimmer plugin in music toolbar section
    isMusicTrimToolbarLoading: false, // trimmer in music toolbar section
    showTrim: false, // trimmer in music toolbar section
    showTrimOverlay: false, // trimmer canvas-modal
    showBackgroundRemover: false, // magic edge
    showModularTemplate: false,
    showLastSelectedTemplate: false,
    isAddNewScene: false,
    isTemplate: false,
    isTextTemplate: false,
    showMusicCategories: false,
    showDesignContainer: false,
    previousDesignContainerPage: 0,
    isTempAudioPlaying: false,
    isRendering: false,
    isRenderingCompleted: false,
    showCanvasPreloader: true,
    showCommonSizes: false,
    showAuth: false,
    showRegister: false,
    showLogin: false,
    showForgotPassword: false,
    closeAuthPostCallback: true,
    showUpgradeSubscription: false,
    showSuccessfulSubscription: false,
    showMobileSmartCreatePreview: false,
    bottomRightNotif: {
      title: 'Hey there!',
      message: 'Deleting failed!',
      duration: -1,
      type: 'success',
    },
    timelineSnappingGuide: {
      left: 0,
      width: 0,
      animateInWidth: 0,
      animateOutWidth: 0,
    },
    showMultipleLoginWarning: false,
    unauthenticatedAPI: false,
    loginOptions: {
      password: true,
      facebook: true,
      google: true,
    },
    thirtySecondsWarning: false,
    showMaxSceneWarning: false,
    showTemplateWarning: false,
    showRemoveBgConfirmation: false, // remove.bg
    showRemoveBgPurchaseOverlay: false,
    removeBgPackages: {},
    favouritedCollection: {
      htmlshape: [],
      elements: [],
      text: [],
      design: [],
      audio: [],
      backgrounds: [],
      myfiles: [],
    }, // need to be per category, and save the id
    isTemplatePreviewOpened: false,
    showStoryboardOverlay: false,
    activeSceneIndex: 0,
    isModalVisible: false,
    offeoAppDetails: localStorage ? JSON.parse(localStorage.getItem(offeoAppDetailsKey)) : {},
    hasNewUpdates: false, // this will prompt user if there is new deployed updates
    defaultFont: {
      /**
       * fontFamily: "Cabin",
       * fontWeight: "400",
       * fontStyle: "normal"
       */
      h1: {
        fontFamily: defaultFallbackFont,
        fontSize: defaultFontSize.h1,
        fontWeight: 400,
        fontStyle: 'normal',
      },
      h2: {
        fontFamily: defaultFallbackFont,
        fontSize: defaultFontSize.h2,
        fontWeight: 400,
        fontStyle: 'normal',
      },
      body: {
        fontFamily: defaultFallbackFont,
        fontSize: defaultFontSize.body,
        fontWeight: 400,
        fontStyle: 'normal',
      },
    },
    isCanvasLoading: false,
    smartCreateTrackingId: '',
    selectedTemplateType: 'designer',
    signUpPage: '',
    showAddSceneModal: false,
    showPermanentTrimElement: false,
    mode: 'default',
    theme: 'dark',
    showLiteReplaceModal: false,
    showLiteUploadMusic: false,
    fbBusinessAccountForPublish: {},
    showMobileReplaceModal: false,
    showDesignerElementUploadModal: false,
    selectedDesignerElementUploadOption: '',
    designerElementUploadUserFile: '',
    vp9Videos: false, // for capture frame to toggle forcing to use vp9 videos only - will have postfix of "_vp9" if not an actual vp9 video codec
    forceReloadAllVideoElements: false, // force reload all unresponsive video elements (readyState < 3);
  },
  getters: {
    getCurrentTime: state => state.currentTime,
    getCurrentAllScenesTime: state => state.currentAllScenesTime,
    getCurrentGenieTime: state => state.currentGenieTime,
    getIsPlaying: state => state.isPlaying,
    getIsAllScenesPlaying: state => state.isAllScenesPlaying,
    isTextBackground: state => state.isTextBackground,
    isTextShadow: state => state.isTextShadow,
    isMediaShadow: state => state.isMediaShadow,
    isTextBlur: state => state.isTextBlur,
    isMediaBlur: state => state.isMediaBlur,
    isTextGlow: state => state.isTextGlow,
    isMediaGlow: state => state.isMediaGlow,
    isTextLightSweep: state => state.isTextLightSweep,
    isMediaLightSweep: state => state.isMediaLightSweep,
    isMediaRoundCorners: state => state.isMediaRoundCorners,
    isTextGlitch: state => state.isTextGlitch,
    isMediaGlitch: state => state.isMediaGlitch,
    getActiveTextGlitchColorType: state => state.activeTextGlitchColorType,
    isTextOutline: state => state.isTextOutline,
    isText3D: state => state.isText3D,
    isMediaBorder: state => state.isMediaBorder,
    isReflection: state => state.isReflection,
    isGroundShadow: state => state.isGroundShadow,
    isWatermark: state => state.isWatermark,
    isTextEditing: state => state.isTextEditing,
    getFilterLists: state => state.filterLists,
    showCustomDesignModal: state => state.showCustomDesignModal,

    getOffeoFonts: state => state.offeoFonts,
    getOffeoFontsCategory: (state) => {
      const categoryArr = [];
      state.offeoFonts.forEach((font) => {
        const catObj = {};
        if (font.category) {
          font.category.forEach((category) => {
            // eslint-disable-next-line
            if (!categoryArr.some((o) => o.value === category)) {
              catObj.value = category;
              let text = category;
              text = text.replace(/-/g, ' ');
              if (category === 'latin') {
                text = 'English/Latin';
              }

              catObj.text = text;
            }
          });
        }
        if (catObj.value) {
          categoryArr.push(catObj);
        }
      });

      categoryArr.sort((a, b) => {
        if (a.value === 'latin') return -1;
        if (b.value === 'latin') return 1;
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
        return 0;
      });

      return categoryArr;
    },
    getActiveOffeoFonts: (state, getters, rootState, rootGetters) => {
      const isInTeamWorkspace = rootGetters['team/isInTeamWorkspace'];
      const teamFonts = rootGetters['team/getTeamFonts'];
      const personalFonts = state.offeoFonts;

      return isInTeamWorkspace ? teamFonts : personalFonts;
    },
    getActiveOffeoFontsCategory: (state, getters, rootState, rootGetters) => {
      const isInTeamWorkspace = rootGetters['team/isInTeamWorkspace'];
      const teamFontsCategory = rootGetters['team/getTeamFontsCategory'];
      const personalFontsCategory = getters.getOffeoFontsCategory;

      return isInTeamWorkspace ? teamFontsCategory : personalFontsCategory;
    },
    /* eslint-disable */
    isDownloadPreviewOpened: (state) => state.isDownloadPreviewOpened,
    getDownloadPreviewOption: (state) => state.downloadPreviewOption,
    getDownloadPreviewType: (state) => state.downloadPreviewType,
    isProjectPreview: (state) => state.isProjectPreview,
    getActiveAnimationList: (state) => ({
      type: state.activeAnimationList,
      id: state.activeAnimationListId,
      position: state.activeAnimationListPosition,
    }),
    getAnimationListDirection: (state) => (type, value) => {
      let { list } = state.animationList[0];

      if (type === 'during') list = state.animationList[1].list; // eslint-disable-line
      else if (type === 'out') list = state.animationList[2].list; // eslint-disable-line

      const activeAnimation = list.filter((animation) => animation.value === value);
      // console.log('activeAnimation', activeAnimation);
      if (activeAnimation.length === 0) return [];
      return activeAnimation[0].direction;
    },
    /* eslint-enable */
    getCanvasZoom: state => state.canvasZoom,
    getCanvasZoomFit: state => state.canvasZoomFit,
    isSwapOpened: state => state.showSwap,
    getIsTemplate: state => state.isTemplate,
    getIsTempAudioPlaying: state => state.isTempAudioPlaying,
    isThirtySecondsWarning: state => state.thirtySecondsWarning,
    getFavouritedCollection: state => (filter) => {
      const isGetFavouriteElementCollectionEmpty = (
        filter === favouriteFilters.ELEMENTS
        && !state.favouritedCollection[filter].length
        && !state.favouritedCollection.htmlshape.length
      );

      // when about to get favourite element collection, only returns empty array if from favourite element collection also empty ( because it has htmlshapes included as well )
      if (
        (!filter
        || !state.favouritedCollection[filter]
        || !state.favouritedCollection[filter].length)
        && isGetFavouriteElementCollectionEmpty
      ) {
        return [];
      }

      const addFavId = true;

      // eslint-disable-next-line
      let collection = state.favouritedCollection[filter].map((item) => {
        if (item.item) {
          const element = cloneDeep(item.item) || {};

          element.favId = item.id;
          element.favType = item.type;

          return element;
        }
      });


      if (filter === favouriteFilters.ELEMENTS) {
        // if it's elements, also show htmlshapes
        const addedCollection = state.favouritedCollection.htmlshape.map((item) => {
          const elementId = item.element_id;

          // element id may be string while id is int
          // eslint-disable-next-line
          const element = HTMLShapes.find(el => el.id == elementId);

          element.favId = item.id;
          element.favType = item.type;

          return element;
        });

        collection = addedCollection.concat(collection);
      }

      collection = collection.filter((n) => n); // eslint-disable-line

      // console.log(collection[0].id, state.favouritedCollection[filter][0].id, cloneDeep(collection[0]));

      if (filter === favouriteFilters.DESIGNS) {
        collection = mapDesigns(collection, addFavId);
      } else if (filter === favouriteFilters.TEXTS) {
        collection = mapText(collection, addFavId);
      }

      // console.log('filter', filter, favouriteFilters.DESIGNS, favouriteFilters.TEXTS, collection[0])

      return collection; // make sure to remove undefined
    },
    showStoryboardOverlay: state => state.showStoryboardOverlay,
    getDefaultFont: state => state.defaultFont,
    isLiteMode: state => state.mode === 'lite',
    getFBBusinessAccountForPublish: state => state.fbBusinessAccountForPublish,
    getFontDetails: state => (fontFamily) => {
      // console.log('getFontDetails', state.offeoFonts.filter(font => fontFamily === font.fontFamily), fontFamily);
      const selectedFont = state.offeoFonts.filter(font => fontFamily === font.fontFamily);
      return selectedFont[0].text || selectedFont[0].fontFamily || fontFamily;
    },
  },
  mutations: {
    setCurrentTime(state, item) {
      console.log('currentTime is', state.currentTime, item);
      state.currentTime = item;
    },
    setCurrentAllScenesTime(state, item) {
      state.currentAllScenesTime = item;
    },
    setCurrentGenieTime(state, item) {
      state.currentGenieTime = item;
    },
    isPlaying(state, item) {
      state.isPlaying = item;
    },
    isAllScenesPlaying(state, item) {
      state.isAllScenesPlaying = item;
    },
    setIsGeniePlaying(state, item) {
      state.isGeniePlaying = item;
    },
    setIsLoggedIn(state, item) {
      state.isLoggedIn = item;
    },
    setShowSubscriptionModal(state, item) {
      state.isShowSubscriptionModal = item;
    },
    setShowSidebar(state, item) {
      if (typeof item === 'object') {
        state.showSidebar = item.boolean;
        state.showColorPicker = false;

        if (!item.maintainAnimationTabState) state.showAnimationTab = false;
        if (!item.maintainColorTabState) state.showColorTab = false;
        if (!item.maintainEditTabState) state.showEditTab = false;
        if (!item.maintainMaskTabState) state.showMaskTab = false;
        if (!item.maintainShowFontTabState) state.showFontTab = false;
        if (!item.maintainOverrideBackgroundColorTabState) state.overrideBackgroundColorTab = item.boolean;

        state.showMobileFilterTab = false;
        state.showMobileAdjustTab = false;
      } else {
        state.showSidebar = item;
        closeAllTab(state);
      }
    },
    setShowLayer(state, item) {
      state.showLayer = item;
    },
    setMaintainShowBackgroundColorTab(state, boolean) {
      state.maintainShowBackgroundColorTab = boolean;
    },
    setShowColorTab(state, item) {
      state.showAnimationTab = false;
      state.showColorTab = item;
      state.showColorPicker = false;
      state.showMaskTab = false;
      state.showFontTab = false;

      if (state.activeEditTabGroup !== editTabs.UPDATE) {
        state.showEditTab = false; // since in edit tab - edit stroke nav, there is border-color
      }

      state.showMobileFilterTab = false;
      state.showMobileAdjustTab = false;

      // avoid flicker upon toggling color tab in background colour tab
      setTimeout(() => {
        state.maintainShowBackgroundColorTab = false;
      }, 100);
    },
    setShowBrandTabFontOverlay(state, item) {
      state.showBrandTabFontOverlay = item;
    },
    setShowBrandTabColorEditModal(state, item) {
      state.showBrandTabColorEditModal = item;
    },
    setShowColorPicker(state, item) {
      // the usage where item === object can see on CanvasContainer.vue & search "maintainEffectsState".
      if (typeof item === 'object' && item.maintainEffectsState) {
        state.showColorPicker = item.boolean;
      } else {
        state.showColorPicker = item;
        setTimeout(() => {
          if (!item) this.commit('hideAllEffectsSubGroup');
        }, 250);
      }
    },
    setIsTextEditing(state, item) {
      // console.log('setIsTextEditing', item)
      state.isTextEditing = item;
    },
    setIsTextBackground(state, item) {
      state.showColorPicker = false;
      // need to give some time out
      // so that the color picker will not be triggered when isTextBackground is active
      setTimeout(() => {
        state.isTextBackground = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['text-background']);
        }
      }, 100);
    },
    setIsTextShadow(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isTextShadow = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['text-shadow']);
        }
      }, 100);
    },
    setIsTextBlur(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isTextBlur = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['text-blur']);
        }
      }, 100);
    },
    setIsTextGlow(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isTextGlow = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['text-glow']);
        }
      }, 100);
    },
    setIsTextLightSweep(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isTextLightSweep = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['text-light-sweep']);
        }
      }, 100);
    },
    setIsTextGlitch(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isTextGlitch = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['text-glitch']);
        }
      }, 100);
    },
    setActiveTextGlitchColorType(state, item) {
      state.activeTextGlitchColorType = item;
    },
    setIsTextOutline(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isTextOutline = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['text-outline']);
        }
      }, 100);
    },
    setIsText3D(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isText3D = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['text-3d']);
        }
      }, 100);
    },
    setIsMediaShadow(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isMediaShadow = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['media-shadow']);
        }
      }, 100);
    },
    setIsMediaBlur(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isMediaBlur = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['media-blur']);
        }
      }, 100);
    },
    setIsMediaGlow(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isMediaGlow = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['media-glow']);
        }
      }, 100);
    },
    setIsMediaLightSweep(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isMediaLightSweep = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['media-light-sweep']);
        }
      }, 100);
    },
    setIsMediaGlitch(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isMediaGlitch = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['media-glitch']);
        }
      }, 100);
    },
    setIsMediaRoundCorners(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isMediaRoundCorners = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['media-round-corners']);
        }
      }, 100);
    },
    setIsMediaBorder(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isMediaBorder = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['media-border']);
        }
      }, 100);
    },
    setIsReflection(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isReflection = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['reflection']);
        }
      }, 100);
    },
    setIsGroundShadow(state, item) {
      state.showColorPicker = false;
      setTimeout(() => {
        state.isGroundShadow = item;
        if (item === true) {
          this.commit('hideAllEffectsSubGroup', ['ground-shadow']);
        }
      }, 100);
    },
    hideAllEffectsSubGroup(state, exception = []) {
      /*
        hide all effects sub group except the exception param.
        exception data type = array, for multiple exception support in future.
      */
      if (!exception.includes('text-background')) state.isTextBackground = false;
      if (!exception.includes('text-shadow')) state.isTextShadow = false;
      if (!exception.includes('text-blur')) state.isTextBlur = false;
      if (!exception.includes('text-glow')) state.isTextGlow = false;
      if (!exception.includes('text-light-sweep')) state.isTextLightSweep = false;
      if (!exception.includes('text-glitch')) state.isTextGlitch = false;
      if (!exception.includes('text-outline')) state.isTextOutline = false;
      if (!exception.includes('text-3d')) state.isText3D = false;
      if (!exception.includes('media-shadow')) state.isMediaShadow = false;
      if (!exception.includes('media-blur')) state.isMediaBlur = false;
      if (!exception.includes('media-glow')) state.isMediaGlow = false;
      if (!exception.includes('media-light-sweep')) state.isMediaLightSweep = false;
      if (!exception.includes('media-glitch')) state.isMediaGlitch = false;
      if (!exception.includes('media-round-corners')) state.isMediaRoundCorners = false;
      if (!exception.includes('media-border')) state.isMediaBorder = false;
      if (!exception.includes('reflection')) state.isReflection = false;
      if (!exception.includes('ground-shadow')) state.isGroundShadow = false;
    },
    setShowMobileFilterTab(state, item) {
      closeAllTab(state, 'showMobileFilterTab', item);
    },
    setShowMobileAdjustTab(state, item) {
      closeAllTab(state, 'showMobileAdjustTab', item);
    },
    setShowFontTab(state, item) {
      closeAllTab(state, 'showFontTab', item, item);
      state.isTextBackground = false;
      state.isTextShadow = false;
    },
    setShowAnimationTab(state, item) {
      closeAllTab(state, 'showAnimationTab', item, item);
      state.isShowAnimationModal = false;
    },
    setShowEditTab(state, item) {
      closeAllTab(state, 'showEditTab', item, item);
    },
    setShowMaskTab(state, item) {
      state.showColorTab = false;
      state.showColorPicker = false;
      state.showFontTab = false;
      state.showAnimationTab = false;
      state.showMaskTab = item;
      state.showEditTab = false;

      state.showMobileFilterTab = false;
      state.showMobileAdjustTab = false;

      state.isShowAnimationModal = false;
    },
    setActiveEditTabGroup(state, item) {
      state.activeEditTabGroup = item;
    },
    setEditTabEffectsType(state, item) {
      state.editTabEffectsType = item;
    },
    setShowTimeline(state, item) {
      state.showTimeline = item;
    },
    setShowCustomDesignModal(state, item) {
      state.showCustomDesignModal = item;
    },
    setShowMultipleSwap(state, item) {
      state.showMultipleSwap = item;
    },
    setIsDownloadPreviewOpened(state, item) {
      state.isDownloadPreviewOpened = item;
      state.isProjectPreview = false; // to show the download options
    },
    setDownloadPreviewOption(state, item) {
      state.downloadPreviewOption = item;
    },
    setDownloadPreviewType(state, item) {
      state.downloadPreviewType = item;
    },
    setIsProjectPreview(state) {
      state.isProjectPreview = true;
      // state.isProjectPreview = item;
    },
    setIsShowAnimationModal(state, value) {
      state.isShowAnimationModal = value;
      state.showAnimationTab = false;
    },
    setActiveAnimationList(state, item) {
      // eslint-disable-next-line
      const { id, type, position, animationPosition, hasTransition } = item;
      // console.log('setActiveAnimationList', item, id, type);
      state.activeAnimationList = type;

      state.activeAnimationListId.length = 0;
      if (typeof id === 'object') {
        // eslint-disable-next-line
        for (let i = 0; i < id.length; i++) {
          state.activeAnimationListId.push(id[i]);
        }
      } else {
        state.activeAnimationListId.push(id);
      }

      if (typeof position !== 'undefined') {
        state.activeAnimationListPosition.width = position.width;
        state.activeAnimationListPosition.height = position.height;
        state.activeAnimationListPosition.x = position.x;
        state.activeAnimationListPosition.y = position.y;
        state.activeAnimationListPosition.position = animationPosition;
      }

      if (typeof hasTransition !== 'undefined') {
        state.activeAnimationListPosition.hasTransition = hasTransition;
      }
    },
    closeActiveAnimationList(state) {
      // console.log('closeActiveAnimationList');
      state.activeAnimationList = '';
      state.activeAnimationListPosition.position = '';
      state.activeAnimationListIndex = -1;
    },
    closeExtraLeftSidebar(state) {
      if (
        AssetsSidebar.state.activeGroup !== 'backgrounds'
        && AssetsSidebar.state.activeSubGroup !== 'colour'
      ) {
        state.showColorTab = false;
      }
      state.showAnimationTab = false;
      state.showColorPicker = false;
      state.showEditTab = false;
      state.showFontTab = false;
      state.showMaskTab = false;
      this.commit('hideAllEffectsSubGroup');

      state.showMobileFilterTab = false;
      state.showMobileAdjustTab = false;
    },
    setCanvasZoom(state, item) {
      const zoom = parseFloat(parseFloat(item).toFixed(2));
      console.log('setCanvasZoom', item, zoom);
      state.canvasZoom = zoom;
    },
    setCanvasZoomFit(state, item) {
      const zoom = parseFloat(parseFloat(item).toFixed(2));
      state.canvasZoomFit = zoom;
    },
    setShowGrid(state) {
      // grid will update to be 0, 3, 6, 9
      const gridArray = [0, 3, 6, 9];
      const index = gridArray.indexOf(state.showGrid);

      let newIndex = index + 1;

      if (newIndex >= gridArray.length) newIndex = 0;
      state.showGrid = gridArray[newIndex];
    },
    setShowTitleSafe(state, item) {
      state.showTitleSafe = item;
    },
    setIsSwapOpened(state, item) {
      if (item === false) this.commit('canvasElements/updateSwappedElement', {});
      state.showSwap = item;
    },
    setIsShowGenieModal(state, item) {
      state.showGenieModal = item;
    },
    setShowCrop(state, item) {
      if (item === false) this.commit('canvasElements/updateCroppedElement', {});
      state.showCrop = item;
    },
    setIsMusicTrimToolbarLoading(state, item) {
      state.isMusicTrimToolbarLoading = item;
    },
    setIsShowMusicToolbarTrimmer(state, item) {
      state.isShowMusicToolbarTrimmer = item;
    },
    setShowTrim(state, item) {
      // if (item === false) this.commit('canvasElements/updateCroppedElement', {});
      state.showTrim = item;
    },
    setShowTrimOverlay(state, item) {
      state.showTrimOverlay = item;
    },
    setShowTagManager(state, item) {
      state.showTagManager = item;
    },
    setShowBackgroundRemover(state, item) {
      state.showBackgroundRemover = item;
    },
    setProjectDetails(state, item) {
      state.projectDetails = item;
    },
    setShowModularTemplate(state, item) {
      state.showModularTemplate = item;
      if (item) state.showLastSelectedTemplate = false;
      console.log('showModularTemplate is', item);
    },
    setShowLastSelectedTemplate(state, item) {
      state.showLastSelectedTemplate = item;
      if (item) state.showModularTemplate = false;
      console.log('setShowLastSelectedTemplate is', item);
    },
    setIsTemplate(state, item) {
      state.isTemplate = !!item;
    },
    setIsTextTemplate(state, item) {
      state.isTextTemplate = !!item;
    },
    setIsAddNewScene(state, item) {
      state.isAddNewScene = item;
    },
    setShowMusicCategories(state, item) {
      state.showMusicCategories = item;
    },
    setShowDesignContainer(state, item) {
      state.showDesignContainer = item;
    },
    setPreviousDesignContainerPage(state, item) {
      state.previousDesignContainerPage = item;
    },
    isTempAudioPlaying(state, item) {
      state.isTempAudioPlaying = item;
    },
    setIsRendering(state, item) {
      state.isRendering = item;
    },
    setIsRenderingCompleted(state, item) {
      state.isRenderingCompleted = item;
    },
    setShowCanvasPreloader(state, item) {
      state.showCanvasPreloader = item;
    },
    setShowCommonSizes(state, item) {
      state.showCommonSizes = item;
    },
    setShowAuth(state, item) {
      state.showAuth = item;
    },
    setShowRegister(state, item) {
      state.showRegister = item;
    },
    setShowLogin(state, item) {
      state.showLogin = item;
    },
    setShowForgotPassword(state, item) {
      state.showForgotPassword = item;
    },
    setcloseAuthPostCallback(state, item) {
      state.closeAuthPostCallback = item;
    },
    setShowUpgradeSubscription(state, item) {
      state.showUpgradeSubscription = item;
    },
    setShowSuccessfulSubscription(state, item) {
      state.showSuccessfulSubscription = item;
    },
    setShowMobileSmartCreatePreview(state, item) {
      state.showMobileSmartCreatePreview = item;
    },
    pushCustomFonts(state, item) {
      const index = state.offeoFonts.findIndex(font => font.fontFamily === item.fontFamily);
      // don't push if it's the font family already exist + dont push if sometimes API return spaced string ( e.g " " )
      if (index < 0 && item.fontFamily) state.offeoFonts.push(item);
    },
    setBottomRightNotif(state, item) {
      state.bottomRightNotif = item;
    },
    setTimelineSnappingGuide(state, item) {
      state.timelineSnappingGuide = item;
    },
    setShowMultipleLoginWarning(state, item) {
      state.showMultipleLoginWarning = item;
    },
    setUnauthenticatedAPI(state, item) {
      state.unauthenticatedAPI = item;
    },
    setLoginOptions(state, item) {
      state.loginOptions = item;
    },
    setThirtySecondsWarning(state, item) {
      state.thirtySecondsWarning = item;
    },
    setShowMaxSceneWarning(state, item) {
      state.showMaxSceneWarning = item;
    },
    setShowTemplateWarning(state, item) {
      state.showTemplateWarning = item;
    },
    setShowRemoveBgConfirmation(state, item) {
      state.showRemoveBgConfirmation = item;
    },
    setShowRemoveBgPurchaseOverlay(state, item) {
      state.showRemoveBgPurchaseOverlay = item;
    },
    setRemoveBgPackages(state, item) {
      state.removeBgPackages = item;
    },
    addFavouritedCollection(state, item) {
      let { type } = item;
      const { id } = item;
      // when adding, use element id

      if (type === 'uploaded-audio') {
        type = 'audio';
        item.type = 'audio';
        if (item.item) item.item.isUserFiles = true; // as a mark that this is userFiles, so when use a favourited music, dont need to capture assets
      }

      if (type) {
        const collection = state.favouritedCollection[type];
        if (!collection) return;
        const index = collection.findIndex(element => element.id === id);

        // check if it already exist
        if (index < 0) {
          collection.push(item);
        } else {
          Vue.set(state.favouritedCollection[type], index, item);
          // console.error('Element is already favourited.');
        }
      } else {
        console.error('Missing type.');
      }
    },
    removeFavouritedCollection(state, item) {
      const { type, id } = item;

      if (type) {
        const collection = state.favouritedCollection[type];
        if (!collection) return;
        const index = collection.findIndex(element => element.id === id);

        // check if it already exist
        if (index < 0) {
          console.error("Element wasn't favourited.");
        } else {
          collection.splice(index, 1);
        }
      } else {
        console.error('Missing type.');
      }
    },
    setIsTemplatePreviewOpened(state, item) {
      state.isTemplatePreviewOpened = item;
    },
    setShowStoryboardOverlay(state, item) {
      state.showStoryboardOverlay = item;
    },
    setActiveSceneIndex(state, item) {
      state.activeSceneIndex = item;
    },
    setIsModalVisible(state, item) {
      state.isModalVisible = item;
    },
    setOffeoAppDetails(state, item) {
      state.offeoAppDetails = item;
      localStorage.setItem(offeoAppDetailsKey, JSON.stringify(state.offeoAppDetails));
    },
    setHasNewUpdates(state, item) {
      state.hasNewUpdates = item;
    },
    setDefaultFont(state, item) {
      if (item.h1) {
        const { fontFamily, fontSize, fontWeight, fontStyle } = item.h1; // eslint-disable-line
        if (fontFamily) state.defaultFont.h1.fontFamily = fontFamily;
        if (fontSize) state.defaultFont.h1.fontSize = parseInt(fontSize, 10);
        if (fontWeight) state.defaultFont.h1.fontWeight = parseInt(fontWeight, 10);
        if (fontStyle) state.defaultFont.h1.fontStyle = fontStyle;
      }
      if (item.h2) {
        const { fontFamily, fontSize, fontWeight, fontStyle } = item.h2; // eslint-disable-line
        if (fontFamily) state.defaultFont.h2.fontFamily = fontFamily;
        if (fontSize) state.defaultFont.h2.fontSize = parseInt(fontSize, 10);
        if (fontWeight) state.defaultFont.h2.fontWeight = parseInt(fontWeight, 10);
        if (fontStyle) state.defaultFont.h2.fontStyle = fontStyle;
      }
    },
    setIsCanvasLoading(state, item) {
      state.isCanvasLoading = item;
    },
    setSmartCreateTrackingId(state, item) {
      state.smartCreateTrackingId = item;
    },
    setSelectedTemplateType(state, item) {
      state.selectedTemplateType = item;
    },
    setSignUpPage(state, item) {
      state.signUpPage = item;
    },
    setShowAddSceneModal(state, item) {
      state.showAddSceneModal = item;
    },
    setShowPermanentTrimElement(state, item) {
      state.showPermanentTrimElement = item;
    },
    // SET PREFERENCE START
    setOffeoMode(state, mode) {
      state.mode = mode;
    },
    setOffeoTheme(state, theme) {
      state.theme = theme;
    },
    // SET PREFERENCE END
    // LITE MODE START
    setLiteMode(state) {
      state.mode = 'lite';
    },
    resetMode(state) {
      state.mode = 'default';
    },
    setShowLiteReplaceModal(state, item) {
      state.showLiteReplaceModal = item;
    },
    setShowLiteUploadMusic(state, item) {
      state.showLiteUploadMusic = item;
    },
    // LITE MODE END
    setFBBusinessAccountForPublish(state, item) {
      Vue.set(state, 'fbBusinessAccountForPublish', item);
    },
    // MOBILE SMART CREATE START
    setShowMobileReplaceModal(state, item) {
      state.showMobileReplaceModal = item;
    },
    // MOBILE SMART CREATE END
    setShowDesignerElementUploadModal(state, item) {
      state.showDesignerElementUploadModal = item;
    },
    setSelectedDesignerElementUploadOption(state, item) {
      state.selectedDesignerElementUploadOption = item;
    },
    setDesignerElementUploadUserFile(state, item) {
      state.designerElementUploadUserFile = item;
    },
    setVp9Videos(state, item) {
      state.vp9Videos = item;
    },
    setForceReloadAllVideoElements(state, item) {
      state.forceReloadAllVideoElements = item;
    },
  },
  actions: {
    // eslint-disable-next-line
    async setTimelineSnappingGuide({ state, commit }, item) {
      const snappingGuide = {
        left: item.left || 0,
        width: item.width || 0,
        animateInWidth: item.animateInWidth || 0,
        animateOutWidth: item.animateOutWidth || 0,
      };
      commit('setTimelineSnappingGuide', snappingGuide);
    },
    setUserFavourites({ commit }) {
      const params = {
        show_details: true,
      };

      FavouritesApi.get(params)
        .then((res) => {
          if (res.data.success) {
            const { results } = res.data;
            for (let i = 0; i < results.length; i += 1) {
              const result = results[i];

              commit('addFavouritedCollection', result);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateShowStoryboardOverlay({ commit, dispatch }, item) {
      console.log('updateShowStoryboardOverlay', item);
      commit('setShowStoryboardOverlay', item);
      dispatch('canvasHistory/catchHistory', 'storyboardOverlayVisibility', { root: true });
    },

    // eslint-disable-next-line
    toggleColorTab({ state, commit, rootState, rootGetters }, id) {
      let isOpened = true;
      let newId = id;
      const getActiveColorId = rootGetters['canvasElements/getActiveColorId'];
      const getActiveElements = rootGetters['canvasElements/getActiveElements'];

      // close colorpicker if it's still opened
      if (state.showColorPicker) {
        commit('setShowColorPicker', false);
      }

      // console.log('toggleColorTab id', id, getActiveColorId);

      // // use == because sometimes id is number while getActiveColorId is string
      // eslint-disable-next-line
      if ((id == getActiveColorId && !state.isTextBackground && !state.isTextShadow) || ((id === 'background' || id === 'branded') && state.showColorTab)) {
        isOpened = false;
        newId = null;
      }
      // // console.log('isOpened', isOpened, id, this.getActiveColorId);
      if (state.isTextBackground) commit('setIsTextBackground', false);
      if (state.isTextShadow) commit('setIsTextShadow', false);
      // // console.log('this.isTextBackground', id, this.isTextBackground, isOpened)
      if (
        getActiveElements.length
        && getActiveElements[0].type === 'texts'
        && state.isTextBackground
      ) {
        isOpened = true;
      }

      // // need to add timeout so it doesn't clash with the closing of colorpicker
      // // there is a function in colorpicker that will save the color based on the active colorid
      // console.log({ newId, isOpened });
      setTimeout(() => {
        commit('setShowColorTab', isOpened);
        commit('canvasElements/updateActiveColorId', newId);
      }, 100);
    },
    multipleLoginChecker({ commit }, payload) {
      if (!payload.matched) {
        commit('setShowMultipleLoginWarning', true);
        commit('setUnauthenticatedAPI', true);
        commit('canvasElements/clearAutoSaveInterval', 'storyboardOverlayVisibility', {
          root: true,
        });
      }
    },
    closeDesignerElementUploadModal({ commit }) {
      commit('setShowDesignerElementUploadModal', false);
      commit('setSelectedDesignerElementUploadOption', '');
      commit('setDesignerElementUploadUserFile', '');
    },
  },
});
