<template lang="pug">
div
  p Page not found
</template>

<script>

export default {
  name: 'PageNotFound',
  beforeMount() {
    // Redirect outside the app using plain old javascript
    const targetUrl = window.location.href;
    window.location.href = `${process.env.VUE_APP_PUBLIC_URL}/404-error?url=${targetUrl}`;
  },
};
</script>
