<template lang="pug">
.canvas-modal(
  :class="[{ 'is-show': isShow, 'no-overlay': noOverlay, 'is-hidden': !isShow }, extraClass]"
)
  .canvas-modal__overlay(
    @click="closeModal"
    v-if="!isCaptureFrame"
  )
  .canvas-modal__content(
    :class="{ 'is-dark': isDark, 'is-grey': isGrey, 'is-green': isGreen, 'no-padding': noPadding, 'fill-height': isFillHeight  }"
    :style="styleObject"
  )
    .canvas-inner__overlay(v-if="isInnerOverlay")
    button.canvas-modal__closebtn(
      type='button'
      @click="closeModal"
      v-if="!hideCloseButton"
      :class="{ 'no-title': !title }"
    )
      i.icon.icon-cancel

    //- Header Area
    template(v-if="title")
      .canvas-modal__header(
        :class="{ 'has-title-info': hasTitleInfoSlot }"
        v-if="!isCaptureFrame"
      )
        template(v-if="titleHasBackButton")
          BaseButton.btn-back-canvas-title(
            @click="$emit('back')"
            isPrimary=true
          )
            i.icon.icon-left-arrow-long
        template(v-if="icon")
          h2.title
            i.icon(:class="[ icon ]")
            span(v-html="title")
        template(v-else-if="hasTitleInfoSlot")
          h2.title
            span(v-html="title")
          slot(name="titleInfo")
        template(v-else)
          h2.title(v-html="title")
        template(v-if="subtitle")
          h3.subtitle(v-html="subtitle")
        slot(name="header")
    //- Main Area
    .canvas-modal__overlay(
      @click="closeMainAreaModal"
      v-if="showMainAreaOverlay"
    )
    .canvas-modal__main
      .content--top(v-if="hasTopSlot && !isCaptureFrame")
        slot(name="top")
      .content(
        ref="modalContent"
        :class="{ 'center-content': isCenterContent }"
      )
        slot(:modalContent="this.$refs.modalContent")
      .content--bottom(v-if="hasBottomSlot && !isCaptureFrame")
        slot(name="bottom")
  GlobalEvents(
    @keydown.prevent.esc="closeModal"
  )
</template>

<script>
export default {
  name: 'CanvasModal',
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    hideCloseButton: { type: Boolean, default: false },
    isShow: { type: Boolean, default: false },
    isDark: { type: Boolean, default: false }, // for dark title bar
    isGrey: { type: Boolean, default: false }, // for grey title bar
    isGreen: { type: Boolean, default: false }, // for green title bar
    noOverlay: { type: Boolean, default: false }, // if you don't want the black overlay, for example when you need to show the left sidebar when swapping
    noPadding: { type: Boolean, default: false }, // if you don't want padding on the content
    isFillHeight: { type: Boolean, default: false }, // if you want 100vh (typically for mobile)
    isCenterContent: { type: Boolean, default: false }, // if you want to center content
    maxWidth: { type: Number, default: 0 },
    extraClass: { type: String, default: '' },
    isCaptureFrame: { type: Boolean, default: false }, // if capture frame for rendering server, remove un necessary elements
    titleHasBackButton: { type: Boolean, default: false },
    showMainAreaOverlay: { type: Boolean, default: false },
    isInnerOverlay: { type: Boolean, default: false },
  },
  computed: {
    styleObject() {
      const styleObject = {};

      if (this.maxWidth !== 0) styleObject.maxWidth = `${this.maxWidth}px`;

      return styleObject;
    },
    hasTitleInfoSlot() {
      return !!this.$slots.titleInfo;
    },
    hasTopSlot() {
      return !!this.$slots.top;
    },
    hasBottomSlot() {
      return !!this.$slots.bottom;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.$emit('closeOverlay');
    },
    closeMainAreaModal() {
      this.$emit('closeMainAreaOverlay');
    },
  },
};
</script>

<style lang="scss">
.canvas-modal {
  align-items: center;
  bottom: 0;
  color: $darkGrey;
  display: none;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 20px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 100;

  &.canvas-modal--design {
    display: flex;

    &.is-show {
      &.is-slide-up {
        &.is-exiting {
          .canvas-modal__content {
            -webkit-animation: slideUpExiting 0.3s ease-out;
            -moz-animation: slideUpExiting 0.3s ease-out;
            animation: slideUpExiting 0.3s ease-out;
          }
        }

        .canvas-modal__content {
          -webkit-animation: slideUp 0.3s ease-out;
          -moz-animation: slideUp 0.3s ease-out;
          animation: slideUp 0.3s ease-out;
        }
      }

      .canvas-modal__content {
        -webkit-animation: slideDown 0.3s ease-out;
        -moz-animation: slideDown 0.3s ease-out;
        animation: slideDown 0.3s ease-out;
      }
    }

    &.is-hidden {
      animation-play-state: running;
      -webkit-animation: fadeOut 150ms linear 0s 1 normal forwards;
      -moz-animation: fadeOut 150ms linear 0s 1 normal forwards;
      animation: fadeOut 150ms linear 0s 1 normal forwards;
    }
  }

  &.is-show {
    display: flex;
  }
  &.is-shaken {
    .canvas-modal__content {
      animation: shaken 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
  }

  &.no-overlay {
    left: 426px;
  }

  .btn-back-canvas-title {
    padding: 8px;
    height: 40px;
    width: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $btnPrimaryHollowText;
    border-radius: $componentBorderRadius;
    margin-right: 20px;

    &:hover {
      background: darken($btnPrimaryHollowText, 7%);
    }

    & + .title {
      display: inline-flex;
      max-width: calc(100% - 65px);
    }

    .icon {
      font-size: 1em;
      margin: 0;
      margin-top: -2px;
    }
  }
}

.canvas-modal__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;

  .canvas-modal.no-overlay & {
    opacity: 0;
  }
}

.canvas-inner__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  z-index: 5;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes slideDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slideDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes slideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slideUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes slideUpExiting {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
}
@-moz-keyframes slideUpExiting {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }
}
@keyframes slideUpExiting {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@-webkit-keyframes shaken {
  10%,
  90% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-8px, 0, 0);
    transform: translate3d(-8px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(8px, 0, 0);
    transform: translate3d(8px, 0, 0);
  }
}

@keyframes shaken {
  10%,
  90% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-8px, 0, 0);
    transform: translate3d(-8px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(8px, 0, 0);
    transform: translate3d(8px, 0, 0);
  }
}

.canvas-modal__content {
  position: relative;
  z-index: 2;
  width: 100%;
  background: $lightBg;
  max-width: 1100px;
  max-height: 90vh;
  height: auto;
  border-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .canvas-modal--sp & {
    padding: 30px;
  }

  .canvas-modal__overlay {
    z-index: 4;
  }

  &--wide {
    max-width: 860px;
  }

  &.is-dark {
    .canvas-modal__closebtn {
      color: $textBlack;
    }

    .canvas-modal__header {
      background: $darkGrey;
      color: $lightWhite;

      .icon {
        color: $blue;
      }

      h3 {
        color: $textGrey;
      }
    }

    .canvas-modal__main {
      background-color: $darkBg2;
    }
  }

  &.is-grey {
    .canvas-modal__closebtn {
      color: $darkGrey;
    }

    .canvas-modal__header {
      background: $lightGrey;
      color: $darkGrey;

      .title .icon {
        color: $blue;
      }
    }
  }

  &.is-green {
    .canvas-modal__closebtn {
      color: $darkGrey;
    }

    .canvas-modal__header {
      background: $lightGreen;
      color: $darkGrey;

      .title .icon {
        color: $darkGrey;
      }
    }
  }

  &.fill-height {
    height: 100vh;
    max-height: 100vh;
  }
}

.canvas-modal__closebtn {
  background: none;
  border: 0;
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: 20px;
  z-index: 2;
  font-size: 20px;
  color: #fff;

  &.no-title {
    color: $darkGrey;
  }

  &:hover,
  &:focus {
    background: transparent;
  }
}

.canvas-modal__header {
  padding: 35px;
  text-align: left;
  border-radius: 4px 4px 0 0;
  background: $blue;
  color: #fff;

  &.has-title-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
      margin-bottom: 0;
    }
  }

  @include smallest {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .title .icon {
    color: #fff;
    display: inline-block;
    margin-right: 10px;
    font-size: 24px;
  }

  .title {
    margin: 0 0 5px;
    font-size: 1.5em;
    font-weight: 600;
    display: flex;
    text-align: left;
    align-items: center;

    @include smallest {
      font-size: 1.35em;
    }
  }

  .subtitle {
    font-size: 0.875em;
    font-weight: 500;
    text-align: left;
    margin: 0;
  }
}

.is-overlay-main {
  .canvas-modal__main {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0%;
      background: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }

    & * {
      pointer-events: none;
    }
  }
}

.canvas-modal__main {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .content {
    padding: 30px;
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;

    &.center-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .canvas-modal__content.no-padding & {
      padding: 0;
    }
  }

  .content--top {
    background: $lightBg;
    padding: 10px 30px;
    box-shadow: 0px 18px 18px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    position: relative;
  }

  .content--bottom {
    display: flex;
    align-items: center;
    padding: 15px 30px;
    box-shadow: 0px -10px 20px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    z-index: 3;
    position: relative;
  }
}
</style>
