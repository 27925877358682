<template lang="pug">
button.mobile-button(
  type="button"
  :class="{ 'is-active': isActive, 'has-background': hasBackground }"
  :disabled="isDisabled"
  @click="$emit('click')"
)
  i.icon(
    v-if="icon"
    :class="icon"
    :style="colorStyle"
  )
  .icon(
    v-if="image"
  )
    img.image(
      :src="src"
    )
  span.text {{ text }}
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseMobileButton',
  props: {
    icon: { type: String, default: '' },
    image: { type: String, default: '' },
    text: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false },
    isActive: { type: Boolean, default: false },
    hasBackground: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('canvasElements', [
      'getActiveElements',
    ]),
    isText() {
      return this.getActiveElements[0] && this.getActiveElements[0].type === 'texts';
    },
    elementData() {
      return (this.getActiveElements[0] && this.getActiveElements[0].data) || {};
    },
    elementColor() {
      return this.elementData.color || null;
    },
    colorStyle() {
      const styleObject = {};

      if (this.isText && this.text.toLowerCase() === 'colour') {
        styleObject.color = this.elementColor[0] || '#ffffff';
      }

      return styleObject;
    },
    src() {
      // eslint-disable-next-line
      return require(`@/assets/images/icons/${this.image}.svg`);
    },
  },
};
</script>

<style lang="scss">
.mobile-button {
  color: $light;
  border: 0;
  outline: 0;
  background: transparent;
  padding: 0px 7px 20px;
  width: 72px;
  line-height: 1;
  vertical-align: middle;

  &:focus,
  &:hover {
    background: transparent;
    color: $lightGrey400;
  }

  &:active {
    .icon {
      transform: scale(0.9);
    }
  }

  .icon {
    width: 56px;
    height: 56px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 1.375em;
    line-height: 1;
    border-radius: $componentBorderRadius;
    transition: transform 0.2s cubic-bezier(0, 0, 0, 1);
  }

  .icon-shortcut-menu,
  .icon-delete,
  .icon-flip-vertical,
  .icon-add {
    &:before {
      font-size: 1.125em;
    }
  }

  .image {
    width: 1.125em;
    height: 1.125em;
    margin: auto;
    display: flex;
  }

  .text {
    margin: 2px 0 0;
    display: block;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 400;
  }

  &.has-background {
    .icon {
      background: $darkGrey700;
    }
  }

  &.is-active:not(:disabled):not(.has-background) {
    .icon {
      color: $lightGreen;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.2;
  }
}
</style>
