import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSocialauth from 'vue-social-auth';
import VModal from 'vue-js-modal';
import Notifications from 'vue-notification';
import GlobalEvents from 'vue-global-events';
import VueCookies from 'vue-cookies';
import UUID from 'vue-uuid';
import vuescroll from 'vue-scroll';
import VueMasonry from 'vue-masonry-css';
import VueInputAutowidth from 'vue-input-autowidth';
import VueMeta from 'vue-meta';
import vClickOutside from 'v-click-outside';
import Vue2TouchEvents from 'vue2-touch-events';

import VueMask, { VueMaskDirective } from 'v-mask';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import * as BaseUI from '@/components/UI';
import BaseLayout from './BaseLayout.vue';
// eslint-disable-next-line
import store from './store/store';
import router from './router';

import { handleUnauthenticatedAPI } from '@/assets/scripts/utilities';

/**
 * NOTE: upon installing global plugins,
 * exclude the component that is not important in capture frame,
 * to avoid issues on rendering the export,
 * which might cause on interrupting or blocking the script.
 * specially if the plugin to install requires external resources.
 */
const { pathname } = window.location;

if (pathname !== '/capture-frame') {
  // g-recaptcha
  Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY,
    loaderOptions: {
      autoHideBadge: true,
    },
  });

  // social auth
  Vue.use(VueSocialauth, {
    providers: {
      google: {
        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        redirectUri: `${process.env.VUE_APP_CANVAS_URL}/auth/google/callback`,
      },
      facebook: {
        clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
        redirectUri: `${process.env.VUE_APP_CANVAS_URL}/auth/facebook/callback`,
      },
    },
  });
}

Vue.use(VueAxios, axios);
Vue.use(VueMeta);

Vue.use(VueMasonry);
Vue.use(VueInputAutowidth);
Vue.use(VueMask);
Vue.directive('mask', VueMaskDirective);
Vue.use(vClickOutside);
Vue.use(Vue2TouchEvents);

Vue.config.performance = true;
Vue.config.productionTip = false;

Vue.use(VModal, {
  dynamic: true,
  dynamicDefaults: {
    clickToClose: false,
  },
});
Vue.use(Notifications);
Vue.component('GlobalEvents', GlobalEvents);
Vue.use(VueCookies);
Vue.use(UUID);
Vue.use(vuescroll);
Vue.use(vClickOutside);

// register all BaseUI components to be universally used
Object.values(BaseUI).forEach((Component) => {
  Vue.component(Component.name, Component);
});

window.axios = axios;
window.VModal = VModal;
window.Pusher = require('pusher-js');

axios.defaults.baseURL = process.env.VUE_APP_OFFEO_API;
axios.defaults.withCredentials = true;

// this it to handle the local environment access token due to cross site issue
// only for local development purposes
// if (
//   process.env.VUE_APP_ENV === 'local'
//   && window.location.hostname === 'localhost'
// ) {
//   // set access_token_cookie
//   const localAccessCookie = `${process.env.VUE_APP_OAUTH_TOKEN_COOKIE}_${process.env.VUE_APP_ENV}`;
//   const accessToken = VueCookies.get(localAccessCookie);

//   if (accessToken) {
//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   }
// }

axios.interceptors.response.use(response => response, ((error) => {
  handleUnauthenticatedAPI(error);
  console.log('axios.interceptors:error', error);
  throw error;
}));

/*
 * DOMParser HTML extension
 * 2012-09-04
 *
 * By Eli Grey, http://eligrey.com
 * Public domain.
 * NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
 */

/*! @source https://gist.github.com/1129031 */
/* global document, DOMParser */

(function (DOMParser) {
  const proto = DOMParser.prototype;
  const nativeParse = proto.parseFromString;

  // Firefox/Opera/IE throw errors on unsupported types
  try {
    // WebKit returns null on unsupported types
    if ((new DOMParser()).parseFromString('', 'text/html')) {
      // text/html parsing is natively supported
      return;
    }
  } catch (ex) { }

  proto.parseFromString = function (markup, type) {
    if (/^\s*text\/html\s*(?:;|$)/i.test(type)) {
      const
        doc = document.implementation.createHTMLDocument('');
      if (markup.toLowerCase().indexOf('<!doctype') > -1) {
        doc.documentElement.innerHTML = markup;
      } else {
        doc.body.innerHTML = markup;
      }
      return doc;
    }
    return nativeParse.apply(this, arguments);
  };
}(DOMParser));

// Vue.mixin({
//   beforeCreate() {
//     if (this.$options.watch) {
//       Object.entries(this.$options.watch).forEach(([watcherKey, func]) => {
//         this.$options.watch[watcherKey] = new Proxy(func, {
//           apply(target, thisArg) {
//             console.log(`${thisArg.$options.name}:watcher:${target.name}()`);
//           },
//         });
//       });
//     }
//   },
// });

new Vue({
  router,
  store,
  render: h => h(BaseLayout),
}).$mount('#app');
