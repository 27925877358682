/*
// Sample format of history
const format = {
  type: 'action',
  name: 'canvasElements/setSomething',
  history: [
    {
      id: 'id1',
      opacity: 1,
    },
    {
      id: 'id2',
      opacity: 1,
    },
  ]
}

// Step by step
1. user changed opacity from 1 to 0.7
  - oldState get opacity = 1
  - newState get opacity = 0.7

2. user changed opacity from 0.7 to 0.2
  - oldState get opacity = 0.7
  - newState get opacity = 0.2

  currentStateIndex: 0;
  oldState: [
    { opacity: 0.7 },
    { opacity: 1 },
  ]
  newStateState: [
    { opacity: 0.2 },
    { opacity: 0.7 },
  ]
  // use unshift to put it to beginnning

3. user clicked undo
  - get oldState[0];
  - currentStateIndex += 1

4. user clicked undo
  - get oldState[1];
  - currentStateIndex += 1

5. user clicked redo
  - currentStateIndex -= 1
  - get newState[1];

6. user changed opacity from 0.7 to 0.5.
  need to delete those before 1.
  reset currentStateIndex to 0
  - oldState get opacity = 0.7
  - newState get opacity = 0.5

  currentStateIndex: 0;
  oldState: [
    { opacity: 0.7 },
    { opacity: 1 },
  ]
  newStateState: [
    { opacity: 0.5 },
    { opacity: 0.7 },
  ]
*/


import cloneDeep from 'lodash.clonedeep';
import { isProduction } from '@/assets/scripts/variables'; // eslint-disable-line

let savingHistoryTimeout;

const baseHistory = {
  type: 'mutation',
  name: '',
  history: {},
};

const MAX_HISTORY_LENGTH = 10; // eslint-disable-line

const canvasHistory = {
  namespaced: true,
  state: {
    currentStateIndex: 0,
    tempValue: '', // temporary holder of first history value
    oldState: [], // this is for user to undo
    newState: [], // this is for user to redo
  },
  getters: {
    hasUndo(state) {
      if (state.currentStateIndex >= state.oldState.length) return false;
      return true;
    },
    hasRedo(state) {
      if (state.currentStateIndex <= 0) return false;
      return true;
    },
  },
  mutations: {
    addToCanvasHistory(state, item) {
      if (state.currentStateIndex > 0) {
        // delete all before state.currentStateIndex
        // eslint-disable-next-line
        for (let i = 0; i < state.currentStateIndex; i++) {
          state.oldState.shift();
          state.newState.shift();
        }
        state.currentStateIndex = 0;
      }

      // eslint-disable-next-line
      const { type, name, history } = JSON.parse(JSON.stringify(state.tempValue));

      // console.log('add to canvas history', JSON.stringify(history) === JSON.stringify(item.history));
      if (JSON.stringify(history) === JSON.stringify(item.history)) return; // avoid saving if data is not changed

      // if (!isProduction()) {
      //   console.group();
      //   console.group('--- OLD State ---');
      //   console.log('TYPE: ', type);
      //   console.log('NAME: ', name);
      //   console.log('HISTORY: ', history);
      //   console.groupEnd();
      //   console.group('--- NEW State ---');
      //   console.log('TYPE: ', item.type);
      //   console.log('NAME: ', item.name);
      //   console.log('HISTORY: ', JSON.parse(JSON.stringify(item.history)));
      //   console.groupEnd();
      //   console.groupEnd();
      // }

      this.commit('canvasHistory/addToOldState', state.tempValue);
      this.commit('canvasHistory/addToNewState', item);

      state.tempValue = ''; // reset after updating the history
    },
    addToOldState(state, item) {
      const history = JSON.parse(JSON.stringify(item));
      state.oldState.unshift(history);
    },
    addToNewState(state, item) {
      const history = JSON.parse(JSON.stringify(item));
      state.newState.unshift(history);
    },
    setTempValue(state, item) {
      if (state.tempValue !== '') return;
      state.tempValue = JSON.parse(JSON.stringify(item));
      this.commit('canvasElements/updateHasChanges', true);
    },
    setCurrentStateIndex(state, item) {
      state.currentStateIndex = item;
    },
  },
  actions: {
    updateCanvas({ commit, dispatch }, item) {
      const canvasWrapper = document.getElementById('main-canvas-container');
      canvasWrapper.classList.add('smooth-transition');

      commit('canvasElements/emptyActiveElements', '', { root: true });
      const {
        type,
        name,
        history,
      } = item;

      // eslint-disable-next-line
      for (let i = 0; i < Object.keys(history).length; i++) {
        const key = Object.keys(history)[i];
        const update = history[key];
        console.log('updateCanvas type', key, type, name, update);
        if (type === 'action') {
          dispatch(name, update, { root: true });
        } else {
          commit(name, update, { root: true });
        }
      }

      setTimeout(() => {
        canvasWrapper.classList.remove('smooth-transition');
      }, 300);
    },
    undoCanvasHistory({
      getters, state, dispatch, commit,
    }) {
      if (!getters.hasUndo) return;

      // console.log('undo', JSON.parse(JSON.stringify(state.oldState[state.currentStateIndex])));

      dispatch('updateCanvas', state.oldState[state.currentStateIndex]);
      commit('setCurrentStateIndex', state.currentStateIndex + 1);
    },
    redoCanvasHistory({
      getters, state, dispatch, commit,
    }) {
      if (!getters.hasRedo) return;
      commit('setCurrentStateIndex', state.currentStateIndex - 1);

      // console.log('redo', JSON.parse(JSON.stringify(state.oldState[state.currentStateIndex])));
      dispatch('updateCanvas', state.newState[state.currentStateIndex]);
    },
    catchHistory({ dispatch }, item) {
      // console.log('catchHistory', item);
      if (item === 'element') {
        dispatch('catchElementsData');
      } else if (item === 'scene') {
        dispatch('catchSceneData');
      } else if (item === 'project') {
        dispatch('catchProjectData');
      } else if (item === 'storyboardOverlayVisibility') {
        dispatch('catchStoryboardOverlayVisibility');
      }
    },
    catchElementsData({
      commit, state, rootGetters,
    }) {
      baseHistory.name = 'canvasElements/historyResetElementData';
      baseHistory.history = {};

      if (state.tempValue === '') {
        // check if first history stored in tempValue
        // save very first data of the element
        const oldState = Object.assign({}, baseHistory);
        rootGetters['canvasElements/getActiveElements'].forEach((el) => {
          const element = cloneDeep(el);
          const { id } = element.data;
          oldState.history[id] = { id, element };
        });
        commit('setTempValue', oldState);
      }

      clearTimeout(savingHistoryTimeout);
      // now store the old and new history
      savingHistoryTimeout = setTimeout(() => {
        const newState = Object.assign({}, baseHistory);
        rootGetters['canvasElements/getActiveElements'].forEach((el) => {
          const element = cloneDeep(el);
          const { id } = element.data;
          newState.history[id] = { id, element };
        });
        commit('addToCanvasHistory', newState);
      }, 200);
    },
    catchSceneData({
      commit, state, rootGetters,
    }) {
      baseHistory.name = 'canvasElements/historyResetSceneData';
      baseHistory.history = {};

      const id = rootGetters['canvasElements/getActiveSceneId'];
      const sceneDetails = cloneDeep(rootGetters['canvasElements/getActiveSceneDetails']);

      if (state.tempValue === '') {
        // check if first history stored in tempValue
        // save very first data of the element
        const oldState = Object.assign({}, baseHistory);
        oldState.history[0] = { id, sceneDetails };
        commit('setTempValue', oldState);
      }

      clearTimeout(savingHistoryTimeout);
      // now store the old and new history
      savingHistoryTimeout = setTimeout(() => {
        const newState = Object.assign({}, baseHistory);
        newState.history[0] = { id, sceneDetails };
        commit('addToCanvasHistory', newState);
      }, 200);
    },
    catchStoryboardOverlayVisibility({
      commit, state, rootGetters,
    }) {
      baseHistory.name = 'setShowStoryboardOverlay';
      const visibility = rootGetters.showStoryboardOverlay;

      if (state.tempValue === '') {
        // check if first history stored in tempValue
        // save very first data of the element
        const oldState = Object.assign({}, baseHistory);
        oldState.history[0] = !visibility;
        commit('setTempValue', oldState);
      }

      clearTimeout(savingHistoryTimeout);
      // now store the old and new history
      savingHistoryTimeout = setTimeout(() => {
        const newState = Object.assign({}, baseHistory);
        newState.history[0] = visibility;
        commit('addToCanvasHistory', newState);
      }, 200);
    },
    catchProjectData({
      commit, state, rootGetters,
    }) {
      baseHistory.name = 'canvasElements/historyResetProjectData';
      baseHistory.history = {};

      const projectDetails = cloneDeep(rootGetters['canvasElements/getProjectDetails']);

      if (state.tempValue === '') {
        // check if first history stored in tempValue
        // save very first data of the element
        const oldState = Object.assign({}, baseHistory);
        oldState.history[0] = projectDetails;
        commit('setTempValue', oldState);
      }

      clearTimeout(savingHistoryTimeout);
      // now store the old and new history
      savingHistoryTimeout = setTimeout(() => {
        const newState = Object.assign({}, baseHistory);
        newState.history[0] = projectDetails;
        commit('addToCanvasHistory', newState);
      }, 200);
    },
    catchCanvasSize({ commit, state, rootState }) {
      baseHistory.name = 'canvasElements/updateCanvasSize';
      baseHistory.history = {};
      const canvasElementState = 'canvasSize';

      if (state.tempValue === '') {
        // check if first history stored in tempValue
        // save very first data of the element
        const oldState = Object.assign({}, baseHistory);
        const canvasElementStateDetails = cloneDeep(rootState.canvasElements[canvasElementState]);
        // console.log('canvasElementStateDetails old', canvasElementStateDetails)
        oldState.history[canvasElementState] = canvasElementStateDetails;
        commit('setTempValue', oldState);
      }

      clearTimeout(savingHistoryTimeout);
      // now store the old and new history
      savingHistoryTimeout = setTimeout(() => {
        // console.log('addToCanvasHistory baseHistory', baseHistory);
        console.log('addToCanvasHistory baseHistory');
        const newState = Object.assign({}, baseHistory);

        const canvasElementStateDetails = cloneDeep(rootState.canvasElements[canvasElementState]);
        // console.log('canvasElementStateDetails new ', canvasElementStateDetails)
        newState.history[canvasElementState] = canvasElementStateDetails;
        commit('addToCanvasHistory', newState);
      }, 200);
    },
    catchCanvasElementState({ commit, state, rootState }, item) {
      // This will be the generic action, if you want to target an specific state from canvas-elements.js

      baseHistory.name = 'canvasElements/historyResetCanvasElementState';
      baseHistory.history = {};

      if (state.tempValue === '') {
        // check if first history stored in tempValue
        // save very first data of the element
        const oldState = Object.assign({}, baseHistory);

        // eslint-disable-next-line
        for (let i = 0; i < item.length; i++) {
          const canvasElementState = cloneDeep(item[i]);
          const canvasElementStateDetails = cloneDeep(rootState.canvasElements[canvasElementState]);
          oldState.history[canvasElementState] = { canvasElementState, canvasElementStateDetails };
        }
        commit('setTempValue', oldState);
      }

      clearTimeout(savingHistoryTimeout);
      // now store the old and new history
      savingHistoryTimeout = setTimeout(() => {
        const newState = Object.assign({}, baseHistory);

        // eslint-disable-next-line
        for (let i = 0; i < item.length; i++) {
          const canvasElementState = cloneDeep(item[i]);
          const canvasElementStateDetails = cloneDeep(rootState.canvasElements[canvasElementState]);
          newState.history[canvasElementState] = { canvasElementState, canvasElementStateDetails };
        }
        commit('addToCanvasHistory', newState);
      }, 200);
    },
  },
};

export default canvasHistory;
