import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
// eslint-disable-next-line
import store from '@/store/store';
import { isDevMode, isProduction, isPreprod } from '@/assets/scripts/variables';

const randomId = () => {
  const keys = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let randomKey = '';

  // eslint-disable-next-line
  for (let i = 0; i < 10; i++) {
    randomKey += keys.charAt(Math.floor(Math.random() * keys.length));
  }

  return randomKey;
};

const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

// https://lorenstewart.me/2016/11/21/flatten-a-multi-dimensional-array-using-es6/
const flattenArray = array => [].concat(...array);

/**
 * check given variable is empty or not.
 * "empty" means empty string("") (or) null (or) undefined.
 *
 * @param val
 * @returns {boolean}
 */
const isEmpty = val => val === '' || val === null || val === undefined;

const isEmptyObject = (obj) => {
  // eslint-disable-next-line
  for (const key in obj) {
    // eslint-disable-next-line
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const isEqualFile = (file, other) => file.name === other.name
  && file.lastModified === other.lastModified
  && file.size === other.size
  && file.type === other.type;

const toUnitVector = n => Math.round((n / 255) * 1000) / 1000;

const fromUnitVector = n => Math.round(n * 255);

const componentToHex = (c) => {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = (r, g, b) => `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

const hexToComponents = hex => /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

const hexToRgb = (hex) => {
  const rgb = hexToComponents(hex);

  return rgb
    ? {
      r: parseInt(rgb[1], 16),
      g: parseInt(rgb[2], 16),
      b: parseInt(rgb[3], 16),
    }
    : null;
};

const stringRgbToHex = (colorPre) => {
  const color = colorPre
    .toString()
    .substring(4)
    .slice(0, -1);
  const colorRgb = color.replace(/ /g, '').split(',');
  const colorHex = rgbToHex(
    parseInt(colorRgb[0], 10),
    parseInt(colorRgb[1], 10),
    parseInt(colorRgb[2], 10),
  );

  return colorHex;
};

// exepcts a string and returns an object
const rgbToHSL = (r, g, b) => {
  // eslint-disable-next-line
  (r /= 255), (g /= 255), (b /= 255);

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h;
  let s;
  const l = (max + min) / 2;

  /* eslint-disable */
  if (max == min) {
    h = s = 0; // achromatic
    /* eslint-enable */
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    // eslint-disable-next-line
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return [h, s, l];
};

/* eslint-disable */
const normalizeRGBValue = (color, m) => {
  color = Math.floor((color + m) * 255);
  if (color < 0) {
    color = 0;
  }
  return color;
};
/* eslint-enable */

/* eslint-disable */
// expects an object and returns a string
const hslToRGB = (h, s, l) => {
  let r;
  let g;
  let b;

  if (s == 0) {
    // eslint-disable-next-line
    r = g = b = l; // achromatic
  } else {
    // eslint-disable-next-line
    function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [normalizeRGBValue(r, 0), normalizeRGBValue(g, 0), normalizeRGBValue(b, 0)];
};
/* eslint-enable */

const getRamp = (startColorRgb, endColorRgb, steps) => {
  // console.log('getRamp', startColorRgb, endColorRgb, steps)
  const rInc = Math.round((endColorRgb.r - startColorRgb.r) / (steps + 1));
  const gInc = Math.round((endColorRgb.g - startColorRgb.g) / (steps + 1));
  const bInc = Math.round((endColorRgb.b - startColorRgb.b) / (steps + 1));

  const r = startColorRgb.r + rInc;
  const g = startColorRgb.g + gInc;
  const b = startColorRgb.b + bInc;

  // console.log('rInc', rInc, startColorRgb.r, endColorRgb.r, steps)

  // for if there is more than 1 steps next time
  // for (var i = 0; i < steps; i++) {
  //   const r = startColorRgb.r + rInc;
  //   const g = startColorRgb.g + gInc;
  //   const b = startColorRgb.b + bInc;
  // }

  return { r, g, b };
};

/* eslint-disable */
const isNumber = (evt) => {
  evt = evt || window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  // console.log(charCode)
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
};
/* eslint-enable */

/* eslint-disable */
const isNumberInteger = (evt) => {
  evt = evt || window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode == 45) return;
  return isNumber(evt);
};
/* eslint-enable */

// angle != degree
// degree is used in the css
// 0 is on 12 o'clock
// angle is for the Anglepicker
// 0 is on 3 o'clock
const degreeToAngle = (degree) => {
  // eslint-disable-next-line
  degree = parseInt(degree);
  let angle = degree - 90;

  if (angle < 0) angle += 360;

  return angle;
};

const angleToDegree = (angle) => {
  // eslint-disable-next-line
  angle = parseInt(angle);
  let degree = angle + 90;

  if (degree >= 360) degree -= 360;

  return degree;
};

const arrayMove = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    // eslint-disable-next-line
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

const whereExist = (obj, key, value) => {
  // eslint-disable-next-line
  for (let i = 0; i < obj.length; i++) {
    if (obj[i][key] === value) {
      return i;
    }
  }
  return -1;
};

/**
 * To find `colorValue` in `colorArray`
 *
 * Note: Can't find gradient color with this method
 * @param {Array} colorArray
 * @param {String} colorValue
 * @returns {number}
 */
const findColorIndex = (colorArray, colorValue) => {
  for (let i = 0; i < colorArray.length; i += 1) {
    const currentColor = colorArray[i];
    if (typeof currentColor === 'string' && currentColor === colorValue) {
      return i;
    }
  }
  return -1;
};

/**
 * To find `colorValue` in `colorArray` at user given specific gradient color point, `key`
 *
 * @param {Array} colorArray
 * @param {String,Number} point
 * @param {String} colorValue
 * @returns {number}
 */
const findGradientColorPointIndex = (colorArray, point, colorValue) => {
  for (let i = 0; i < colorArray.length; i += 1) {
    if (colorArray[i].points[point].color === colorValue) {
      return i;
    }
  }
  return -1;
};

const getCoordinates = (angle) => {
  // theta is andle in radians
  const theta = (angle * Math.PI) / 180;
  const len = 100;
  const deltaX = len * Math.cos(theta);
  const deltaY = len * Math.sin(theta);

  let x1 = 0;
  let y1 = 0;
  let x2 = 0;
  let y2 = 0;

  if (deltaX < 0) {
    x1 = x2 - deltaX;
  } else if (deltaX > 0) {
    x2 = deltaX - x1;
  }

  if (deltaY < 0) {
    y1 = y2 - deltaY;
  } else if (deltaY > 0) {
    y2 = deltaY - y1;
  }

  return {
    x1,
    y1,
    x2,
    y2,
  };
};

const getAngleFromCoordinates = (coordinates) => {
  const x1 = coordinates.x1 ? parseFloat(coordinates.x1) : 0;
  const y1 = coordinates.y1 ? parseFloat(coordinates.y1) : 0;
  const x2 = coordinates.x2 ? parseFloat(coordinates.x2) : 0;
  const y2 = coordinates.y2 ? parseFloat(coordinates.y2) : 0;

  const dy = y2 - y1;
  const dx = x2 - x1;
  let theta = Math.atan2(dy, dx); // range (-PI, PI]
  // console.table({ x1, y1, x2, y2, dy, dx, theta });
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  // if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta;
};

const checkForDuplicateArray = (allColorArr) => {
  // console.log('checkForDuplicateArray', allColorArr);
  const newArrRgb = [];
  const gradientArr = [];

  // eslint-disable-next-line
  for (let i = 0; i < allColorArr.length; i++) {
    const currentColor = allColorArr[i];
    if (typeof currentColor === 'object') {
      // when redoing, don't separate the gradient as 2 color
      // and only separate if this is the last gradient added
      if (typeof currentColor === 'string') {
        const color = JSON.stringify(hexToRgb(currentColor)).toLowerCase();

        if (newArrRgb.indexOf(color) === -1) {
          newArrRgb.push(color);
        }
      }
      if (typeof currentColor === 'object') {
        // console.debug('color', { currentColor, gradientArr });
        const similarGradient = gradientArr.filter(
          e => typeof e === 'object' && isEqual(e, currentColor),
        );
        if (similarGradient.length === 0) {
          gradientArr.push(currentColor);
        }
      }
    } else if (hexToRgb(currentColor)) {
      const color = JSON.stringify(hexToRgb(currentColor));
      if (newArrRgb.indexOf(color) === -1) {
        // so new 'solid' colors will always stay on top of the list
        if (i + 1 === allColorArr.length) newArrRgb.unshift(color);
        else newArrRgb.push(color);
      }
    }
  }

  const newArr = newArrRgb.map((value) => {
    if (value === 'null') return;
    const { r, g, b } = JSON.parse(value);
    // eslint-disable-next-line
    return rgbToHex(r, g, b);
  });

  // console.log('checkForDuplicateArray:return', { newArr, gradientArr });
  return { newArr, gradientArr };
};

/* eslint-disable */
const getImageSize = (url, callback = null) => {
  // console.group();
  if (callback) {
    // console.log('get image', url);
    const image = new Image();
    // console.log('image created!');
    image.onload = function () {
      // console.log('image.onload loaded!');
      callback(this.width, this.height);
    };

    image.src = url;
  } else {
    return new Promise((resolve) => {
      // console.log('get image', url);
      const image = new Image();
      // console.log('image created!');
      image.onload = () => {
        // console.log('image.onload loaded!');
        resolve(image);
      };
      image.src = url;
    });
  }
  // console.groupEnd();
};
/* eslint-enable */

/* eslint-disable */
const getVideoSize = (url, callback = null) => {
  console.group();
  if (callback) {
    console.log('get video', url);
    const video = document.createElement('video');
    console.log('video created!');
    video.onloadedmetadata = function () {
      console.log('video.onload loaded!');
      callback(this.videoWidth, this.videoHeight);
    };

    video.src = url;
  } else {
    return new Promise((resolve) => {
      console.log('get video', url);
      const video = document.createElement('video');
      console.log('video created!');
      video.addEventListener(
        'loadedmetadata',
        () => {
          // retrieve dimensions
          const height = this.videoHeight;
          const width = this.videoWidth;
          // send back result
          resolve({
            height,
            width,
          });
        },
        false,
      );
      video.src = url;
    });
  }
  console.groupEnd();
};
/* eslint-enable */

const selectAllText = (el) => {
  let sel;
  let range;
  if (window.getSelection && document.createRange) {
    // Browser compatibility
    sel = window.getSelection();
    // eslint-disable-next-line
    if (sel.toString() == '') {
      // no text selection
      window.setTimeout(() => {
        range = document.createRange(); // range object
        range.selectNodeContents(el); // sets Range
        sel.removeAllRanges(); // remove all ranges from selection
        sel.addRange(range); // add Range to a Selection.
      }, 1);
    }
  } else if (document.selection) {
    // older ie
    sel = document.selection.createRange();
    // eslint-disable-next-line
    if (sel.text == '') {
      // no text selection
      range = document.body.createTextRange(); // Creates TextRange object
      range.moveToElementText(el); // sets Range
      range.select(); // make selection.
    }
  }
};

const sanitizeHTML = (string) => {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;',
  };
  const reg = /[&<>"'/]/gi;
  return string.replace(reg, match => map[match]);
};

const reverseHTML = (string) => {
  const map = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#x27;': "'",
    '&#x2F;': '/',
  };
  let newHTML = string;
  Object.keys(map).forEach((property) => {
    const reg = new RegExp(property, 'gi');
    newHTML = newHTML.replace(reg, map[property]);
  });

  return newHTML;
};

const secToDigitalStr = (sec, settings = { showMilliSeconds: true, defaultFormat: true }) => {
  const pad = (num, size) => `000${num}`.slice(size * -1);
  const time = parseFloat(sec).toFixed(2);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);
  const milliseconds = time.slice(-2);

  const { showMilliSeconds, defaultFormat } = settings;

  const checkedMinutesPad = minutes > 9 ? pad(minutes, 2) : pad(minutes, 1);
  const checkedSecondsPad = seconds > 9 ? pad(seconds, 2) : pad(seconds, 1);
  const checkedMillisecondsPad = milliseconds > 9 ? pad(milliseconds, 2) : pad(milliseconds, 1);

  if (!showMilliSeconds) return `${checkedMinutesPad}:${pad(seconds, 2)}`;
  if (!defaultFormat) {
    if (!minutes) return `${checkedSecondsPad}.${checkedMillisecondsPad}s`;
    return `${checkedMinutesPad}:${checkedSecondsPad}.${checkedMillisecondsPad}`;
  }
  return `${pad(minutes, 2)}:${pad(seconds, 2)}:${pad(milliseconds, 2)}`;
};

const reverseHTMLandStrip = (string, stripAll) => {
  let newHTML = reverseHTML(string);

  let allowed = stripAll ? '' : '<b><i><font><span>';
  // console.log('old newHTML is', newHTML);

  allowed = (`${allowed || ''}`.toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)

  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
  // console.log('commentsAndPhpTags', commentsAndPhpTags, 'tags', tags);
  newHTML = newHTML.replace(commentsAndPhpTags, '').replace(tags, ($0, $1) =>
    // console.log('$1', $0, $1, allowed.indexOf(`<${$1.toLowerCase()}>`));
    // eslint-disable-next-line
    (allowed.indexOf(`<${$1.toLowerCase()}>`) > -1 ? $0 : ''));
  // console.log('newHTML is', newHTML);
  return newHTML;
};

const toCamel = s => s.replace(/([-_][a-z])/gi, $1 => $1
  .toUpperCase()
  .replace('-', '')
  .replace('_', ''));

const isArray = a => Array.isArray(a);

const isObject = o => o === Object(o) && !isArray(o) && typeof o !== 'function';

const keysToCamel = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map(i => keysToCamel(i));
  }

  return o;
};

const cleanUpContent = (text) => {
  if (!text) return text; // fix Cannot read property 'replace' of null

  let content = text.replace(/<br>$/, ''); // if it ends with <br>, remove it
  content = content.replace(/<br> /g, '<br>'); // replace any space after br
  content = content.replace(/ <br>/g, '<br>'); // replace any space before br
  content = content.replace(/&amp;/g, '&'); // replace any &amp; with &

  return content;
};

const cleanUpContentHTMLEntity = (text) => {
  if (!text) return text; // fix Cannot read property 'replace' of null

  /* eslint-disable */
  const content = text
    .replace(/&nbsp;/g, ' ')
    .replace(/&#8232;/g, ' ')
    .replace(/\u000b/g, ' '); // Upgrade your innovationto stand out today!
  /* eslint-enable */

  return content;
};

// need to do this to convert the DomRect to object
const getBoundingClientRect = (element) => {
  const {
    top, right, bottom, left, width, height, x, y,
  } = element.getBoundingClientRect();
  return {
    top,
    right,
    bottom,
    left,
    width,
    height,
    x,
    y,
  };
};

const isMobile = () => /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const isIOS = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
};

const isMac = () =>
  // console.log('navigator', navigator);
  // eslint-disable-next-line
  navigator.platform.toUpperCase().indexOf('MAC') >= 0;
const isWindows = () => {
  console.log('navigator', navigator);
  return navigator.platform.toUpperCase().indexOf('WIN') >= 0;
};

const isFacebookApp = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1;
};

const isEmail = (email) => {
  // eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};

/* eslint-disable */
const isURL = (url, includeProtocol = true) => {
  const res = includeProtocol
    ? url.match(
      /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    )
    : url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
  return res !== null;
};
/* eslint-enable */

const showDecimalIfNeeded = (input) => {
  let value = input;
  if (value % 1 !== 0) {
    value = value.toFixed(2);

    if (value.split('.')[1] === '00') {
      // eslint-disable-next-line
      value = value.split('.')[0];
    }
  }

  return value;
};

const checkDesignPreviewUrl = (v) => {
  let previewUrl = '';
  if (typeof v.previews.images !== 'undefined') {
    if (v.scenes && v.scenes.length) {
      const firstScene = v.scenes[0];
      if (v.previews.images[firstScene]) {
        previewUrl = v.previews.images[firstScene];
      }
    }
    if (typeof v.previews.images.thumb !== 'undefined') {
      previewUrl = v.previews.images.thumb;
    }
    if (typeof v.previews.images.custom_thumb !== 'undefined') {
      previewUrl = v.previews.images.custom_thumb;
    }
  }

  return previewUrl;
};

const mapDesigns = (data, addFavId) => data.map((v) => {
  const newData = {
    id: v.id,
    preview_url: checkDesignPreviewUrl(v),
    slug: v.slug,
    template_name: v.template_name,
    template_type: v.type,
    video_preview_url: typeof v.previews.videos !== 'undefined' ? v.previews.videos.thumb : '',
    width: v.canvas_width,
    height: v.canvas_height,
    is_modular: v.is_modular,
    template_category_id: v.template_category_id,
    scenes: v.scenes,
  };
  if (addFavId) {
    newData.favId = v.favId;
  }
  return newData;
});

const mapText = (data, addFavId) => data.map((v) => {
  const newData = {
    id: v.id,
    preview_url: typeof v.previews.images !== 'undefined' ? v.previews.images.thumb : '',
    slug: v.slug,
    template_name: v.template_name,
    template_type: v.type,
    video_preview_url: '', // no video preview for text templates //typeof v.previews.videos.thumb !== 'undefined' ? v.previews.videos.thumb : '',
    width: v.canvas_width,
    height: v.canvas_height,
  };
  if (addFavId) {
    newData.favId = v.favId;
  }
  return newData;
});

const filterMyFiles = data => data.filter(item => item.file);

const mapMediaVideo = data => data.map(v => ({
  id: v.id,
  listID: `sidebar-media-${v.id}`,
  url: v.videos.tiny.url,
  urlHd: v.videos.large.url,
  preview_url: v.thumbnail,
  class: 'insert-media',
  animated: false,
  thumbnailUrl: v.thumbnail,
  isBackground: false,
  isShowThumbnail: true,
  video_preview_url: v.videos.tiny.url,
  isMedia: true,
  slug: 'videos',
  isVideo: true,
}));

const multiDimensionalUnique = (arr) => {
  const uniques = [];
  const itemsFound = {};
  // eslint-disable-next-line
  for (let i = 0, l = arr.length; i < l; i++) {
    const stringified = JSON.stringify(arr[i]);
    if (itemsFound[stringified]) {
      // eslint-disable-next-line
      continue;
    }
    uniques.push(arr[i]);
    itemsFound[stringified] = true;
  }
  return uniques;
};

const gaTrackerName = () => {
  const trackers = window.ga.getAll();
  const firstTracker = trackers[0];

  return firstTracker.get('name');
};

const handleUnauthenticatedAPI = (error) => {
  if (error.response && error.response.status === 401) {
    // TODO: before logging out, need to check first refresh_token to oa_tkn cookie,
    // meaning if state didnt matched there is new access_token available, but if it received unautheticated,
    // and the refresh_token is still the same on oa_tkn cookie, meaning it didn't refreshed the access token hence it is invalid.

    store.commit('canvasElements/clearAutoSaveInterval');
    store.commit('setShowMultipleLoginWarning', true);
    store.commit('setUnauthenticatedAPI', true);
  }
};

const neutralize = obj => JSON.parse(JSON.stringify(obj));

const getSelectedText = () => {
  let selectedText = '';

  if (window.getSelection) {
    selectedText = window.getSelection().toString();
  } else if (document.getSelection) {
    selectedText = document.getSelection();
  } else if (document.selection) {
    selectedText = document.selection.createRange().text;
  }
  // To write the selected text into the textarea
  return selectedText;
};

const unwrap = (wrapper) => {
  // place childNodes in document fragment
  const docFrag = document.createDocumentFragment();
  while (wrapper.firstChild) {
    const child = wrapper.removeChild(wrapper.firstChild);
    // console.log('unwrapunwrap', child)
    docFrag.appendChild(child);
  }

  // replace wrapper with document fragment
  wrapper.parentNode.replaceChild(docFrag, wrapper);
};

const unwrapTag = (children, tag) => {
  // console.log('child length', children.length)
  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];
    // console.log('unwrap tag', i, child, child.children, child.textContent)
    if (child.children.length) {
      unwrapTag(child.children, tag);
    }
    if (child.tagName.toLowerCase() === tag) {
      // need to set timeout so that it doesn't lose the DOM
      setTimeout(() => {
        unwrap(child);
      }, 200);
    }
  }
};

const removeDuplicates = array => [...new Set(array)];

const optional = (val, fallback = {}) => (!isEmpty(val) ? val : fallback);

const convertDateFormat = (date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const currentDatetime = new Date(date);
  // converts
  const day = currentDatetime.getDate();
  const month = months[currentDatetime.getMonth()];
  const year = currentDatetime.getFullYear();
  const hour = currentDatetime.getHours();
  const minute = currentDatetime.getMinutes();
  const second = currentDatetime.getSeconds();

  // adding "0" for number smaller than 10
  const fixDay = day < 10 ? `0${day}` : day;
  const fixMonth = month < 10 ? `0${month}` : month;
  const fixHour = hour < 10 ? `0${hour}` : hour;
  const fixMinute = minute < 10 ? `0${minute}` : minute;
  const fixSecond = second < 10 ? `0${second}` : second;

  const formattedDate = `${fixDay} ${fixMonth} ${year} ${fixHour}:${fixMinute}:${fixSecond}`;

  return formattedDate;
};

// ========================
// REGEX TEXT START
// get the regex from https://apps.timwhitlock.info/js/regex#
// ========================
const isArabicText = (text) => {
  const arabicRegex = new RegExp('[\u0600-۾ݐ-ݾ\u08a0-\u08fe]', 'gi');
  return arabicRegex.test(text);
};

const isBengaliText = (text) => {
  const bengaliRegex = new RegExp('[\u0980-\u09fe]', 'gi');
  return bengaliRegex.test(text);
};

const isBugineseText = (text) => {
  const bugineseRegex = new RegExp('[ᨀ-᨞\uaa60-\uaa7e]', 'gi');
  return bugineseRegex.test(text);
};

const isDevanagariText = (text) => {
  const devanagariRegex = new RegExp('[\u0900-ॾ]', 'gi');
  return devanagariRegex.test(text);
};

const isGujaratiText = (text) => {
  const gujaratiRegex = new RegExp('[\u0a80-\u0afe]', 'gi');
  return gujaratiRegex.test(text);
};

const isHebrewText = (text) => {
  const hebrewRegex = new RegExp('[\u0590-\u05fe]', 'gi');
  return hebrewRegex.test(text);
};

const isJavaneseText = (text) => {
  const javaneseRegex = new RegExp('[\ua980-\ua9de]', 'gi');
  return javaneseRegex.test(text);
};

const isKannadaText = (text) => {
  const kannadaRegex = new RegExp('[\u0c80-\u0cfe]', 'gi');
  return kannadaRegex.test(text);
};

const isKhmerText = (text) => {
  const khmerRegex = new RegExp('[ក-\u17fe᧠-᧾]', 'gi');
  return khmerRegex.test(text);
};

const isLaoText = (text) => {
  const laoRegex = new RegExp('[\u0e80-\u0efe]', 'gi');
  return laoRegex.test(text);
};

const isMalayalamText = (text) => {
  const malayalamRegex = new RegExp('[\u0d00-ൾ]', 'gi');
  return malayalamRegex.test(text);
};

const isMyanmarText = (text) => {
  const myanmarRegex = new RegExp('[က-႞\uaa60-\uaa7e]', 'gi');
  return myanmarRegex.test(text);
};

const isOriyaText = (text) => {
  const oriyaRegex = new RegExp('[\u0b00-\u0b7e]', 'gi');
  return oriyaRegex.test(text);
};

const isSinhalaText = (text) => {
  const sinhalaRegex = new RegExp('[\u0d80-\u0dfe]', 'gi');
  return sinhalaRegex.test(text);
};

const isTamilText = (text) => {
  const tamilRegex = new RegExp('[\u0b80-\u0bfe]', 'gi');
  return tamilRegex.test(text);
};

const isTeluguText = (text) => {
  const teluguRegex = new RegExp('[\u0c00-౾]', 'gi');
  return teluguRegex.test(text);
};

const isThaiText = (text) => {
  const thaiRegex = new RegExp('[\u0e00-\u0e7e]', 'gi');
  return thaiRegex.test(text);
};

const isTibetanText = (text) => {
  const tibetanRegex = new RegExp('[ༀ-\u0ffe]', 'gi');
  return tibetanRegex.test(text);
};

const disallowSplitText = (text) => {
  // get the regex from https://apps.timwhitlock.info/js/regex#
  if (
    isArabicText(text)
    || isBengaliText(text)
    || isBugineseText(text)
    || isDevanagariText(text)
    || isGujaratiText(text)
    || isHebrewText(text)
    || isJavaneseText(text)
    || isKannadaText(text)
    || isKhmerText(text)
    || isLaoText(text)
    || isMalayalamText(text)
    || isMyanmarText(text)
    || isOriyaText(text)
    || isSinhalaText(text)
    || isTamilText(text)
    || isTeluguText(text)
    || isThaiText(text)
    || isTibetanText(text)
  ) return true;

  return false;
};

// ========================
// REGEX TEXT END
// ========================

// either match the exact env.VUE_APP_PUBLIC_URL
// or env.VUE_APP_DASHBOARD_URL
// or if it's in staging/local, allow localhost
const isWindowEventListenerAllowed = event => event.origin.startsWith(process.env.VUE_APP_PUBLIC_URL)
  || event.origin.startsWith(process.env.VUE_APP_DASHBOARD_URL)
  || (isDevMode() && event.origin.startsWith('http://local'));

const toTitleCase = phrase => phrase
  .toLowerCase()
  .split(' ')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ');

const serialize = obj => `?${Object.keys(obj)
  .reduce((a, k) => {
    a.push(`${k}=${encodeURIComponent(obj[k])}`);
    return a;
  }, [])
  .join('&')}`;

const calcAspectRatio = (element) => {
  // eslint-disable-next-line radix
  const percentageToInt = percentage => parseInt(percentage, 10);
  // eslint-disable-next-line max-len
  const calcWholeVal = (item, key) => item.size[key] * (percentageToInt(item.data.image[key]) / 100);
  const calcElementWholeVal = key => calcWholeVal(element, key);
  const wholeWidth = calcElementWholeVal('width');
  const wholeHeight = calcElementWholeVal('height');
  const calc = (val, other) => parseFloat((val / other).toFixed(3));
  return {
    wh: calc(wholeWidth, wholeHeight),
    hw: calc(wholeHeight, wholeWidth),
  };
};

const capitalizeWord = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const upsert = (array, item) => {
  const newArray = array;
  const i = array.findIndex(_item => _item.id === item.id);
  if (i > -1) newArray[i] = item;
  else newArray.push(item);
  return newArray;
};

const unwrapAndUpdateContent = (contentObj, childNode, wrapperTag) => {
  const newObject = { ...contentObj };

  // if there is more than 1 childNodes, it means it's not a wrapping tag
  if (wrapperTag === 'FONT') {
    newObject.color = childNode.color;
  }
  if (wrapperTag === 'B') {
    newObject.bold = true;
  }
  if (wrapperTag === 'I') {
    newObject.italic = true;
  }
  if (wrapperTag === 'SPAN') {
    // if layer is bold but there is <span style="font-weight: 400"></span>
    if (childNode.style.fontWeight) {
      newObject.bold = childNode.style.fontWeight === 'bold' || childNode.style.fontWeight === '700';
    }
    if (childNode.style.color) {
      newObject.color = childNode.style.color;
    }
    if (childNode.style.fontStyle) {
      newObject.italic = childNode.style.fontStyle !== 'normal';
    }
  }

  newObject.content = childNode.innerHTML;

  return newObject;
};

const getHTMLWrapper = (content) => {
  /**
   * if content is passing "<b>Floral Fair</b>"
   * returns { content: "Floral Fair", bold: true }
   *
   * if content is passing "<font color='#FFF'>Fl<b>o</b>ral Fair</font>"
   * returns { content: "Fl<b>o</b>ral Fair", color: "#FFF" }
  //  */
  const contentObject = {
    content,
  };
  // console.log('content is', content);

  if (!content) return contentObject;

  // sometimes string may have more than 1 wrapperTag
  // eg. '<span style="font-weight: normal;"><font color="#ffdf00">Floral Fair</font></span>'
  const loopAndUnwrap = (object) => {
    let newObject = { ...object };
    const html = new DOMParser().parseFromString(newObject.content, 'text/html');
    const { childNodes } = html.body;
    const childNode = childNodes[0];
    const wrapperTag = childNode.tagName;

    if (childNodes.length === 1 && wrapperTag) {
      newObject = unwrapAndUpdateContent(newObject, childNode, wrapperTag);
      return loopAndUnwrap(newObject);
    }

    return newObject;
  };

  const cleanObject = loopAndUnwrap(contentObject);

  return cleanObject;
};

const normalizeColorBrand = (colorArray) => {
  const newColorArray = cloneDeep(colorArray);
  // eslint-disable-next-line
  for (let i = 0; i < newColorArray.colors.length; i++) {
    const color = newColorArray.colors[i];

    // if the first characted is not hash, add it
    if (color.charAt(0) !== '#') newColorArray.colors[i] = `#${color}`;
  }

  return newColorArray;
};

const mapDropDownValue = (arrayId, dropdownArray) => {
  // this will map the array id to show in dropdown values
  if (arrayId === null) {
    return [];
  }

  try {
    // eslint-disable-next-line
    const value = arrayId.map(item => dropdownArray.filter(menu => menu.value == item)[0]);
    return typeof value[0] !== 'undefined' ? value : [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

const mapDropDownValueBeforeSave = (arrayItem) => {
  // this modifies the values of MultiSelect component values to be sent to admin api server
  // admin api accepts: string separated by comma
  // map the array dropdown values to change into proper id values before passing to post request
  if (arrayItem === null) {
    return [];
  }

  try {
    return arrayItem.map(item => item.value);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const checkIsCollapseSidebarButton = (paths) => {
  const isCollapseSidebarButton = paths.findIndex(
    path => path.className
      && typeof path.className === 'string'
      && path.className.includes('btn-collapse-sidebar'),
  ) > -1;

  return isCollapseSidebarButton;
};

/* eslint-disable */
const checkIsColorTabTriggerButton = (paths) => {
  const isColorTabTriggerButton = paths.findIndex(
    (path) =>
      path.className &&
      typeof path.className === 'string' &&
      path.className.includes('btn-change-text-color')
  ) > -1;
  const isElementColorTabTriggerButton = paths.findIndex(
    (path) =>
      path.className &&
      typeof path.className === 'string' &&
      path.className.includes('btn-element-color')
  ) > -1;

  return isColorTabTriggerButton || isElementColorTabTriggerButton;
};

const convertNumberToDecimal = (number, decimalPlaces = 1) => {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.round(number * multiplier) / multiplier;
};

const angleToNumber = (angleX, angleY) => {
  const angleToNumber = {
    'to-bottom-right': 135,
    'to-center-right': 90,
    'to-top-right': 45,
    'to-top-center': 180,
    'to-bottom-center': 360,
    'to-top-left': 135,
    'to-center-left': 90,
    'to-bottom-left': 45,
  };

  return angleToNumber[`to-${angleY}-${angleX}`]
};

const isVerticalLightSweep = (angle) => {
  return angle === 0 || angle === 180 || angle === 360;
};

const isReversedAngle = (angleX, angleY, notSupport) => {
  const angleToNumber = {
    'to-bottom-right': false,
    'to-center-right': false,
    'to-top-right': false,
    'to-top-center': true,
    'to-bottom-center': false,
    'to-top-left': true,
    'to-center-left': true,
    'to-bottom-left': true,
  };

  if (notSupport) {
    // on current image lightsweep system for portrait images, this angle doesn't sync or work as expected
    angleToNumber['to-top-right'] = true;
    angleToNumber['to-bottom-left'] = false;
  }

  return angleToNumber[`to-${angleY}-${angleX}`]
};

const getTiltLightSweepMaskPosition = (elementRatio, maskSize, elementProportion) => {
  const isHorizontal = elementProportion === 'landscape';
  let maskStartPoint = '0 0';
  let maskEndPoint = '0 0';

  if (elementRatio < 1.25) {
    // square
    // this mask-points are only for ratio around 1 (square).
    const maskStartPoints = {
      200: 130,
      300: 140,
      400: 140,
      500: 150,
      650: 150,
    };
    const maskEndPoints = {
      200: -30,
      300: -40,
      400: -40,
      500: -50,
      600: -50,
    };

    const getStartPoint = maskStartPoints[maskSize];
    const getEndPoint = maskEndPoints[maskSize];

    maskStartPoint = `50% ${getStartPoint}%`;
    maskEndPoint = `50% ${getEndPoint}%`;
  } else if (elementRatio < 1.75) {
    // ratio of 1.25, 1.5, 1.75
    // this mask-points are only for ratio around 1.5.
    const maskStartPoints = {
      200: 165,
      300: 180,
      400: 180,
      500: 195,
      600: 195,
    };
    const maskEndPoints = {
      200: -65,
      300: -80,
      400: -80,
      500: -95,
      600: -95,
    };

    const getStartPoint = maskStartPoints[maskSize];
    const getEndPoint = maskEndPoints[maskSize];

    maskStartPoint = isHorizontal ? `${getStartPoint}% 50%` : `50% ${getStartPoint}%`;
    maskEndPoint = isHorizontal ? `${getEndPoint}% 50%` : `50% ${getEndPoint}%`;
  } else if (elementRatio > 1.75) {
    // after divide, ratio of 2 has a result of 8.
    // while we have base point below that starts from ratio of 2,
    // we search the defisit so we can add/minus the start/end points by 5. ( hypothesis based on manual data gathering of each ratio )
    const getDefisit = Math.round(elementRatio / 0.25) - 8;
    // this base point starts from ratio of 2.
    const baseMaskStartPoint = {
      200: 155,
      300: 165,
      400: 165,
      500: 175,
      600: 175,
    };
    const baseMaskEndPoint = {
      200: -55,
      300: -65,
      400: -65,
      500: -75,
      600: -75,
    };

    const getStartPoint = baseMaskStartPoint[maskSize] - getDefisit * 5;
    const getEndPoint = baseMaskEndPoint[maskSize] + getDefisit * 5;

    maskStartPoint = isHorizontal ? `${getStartPoint}% 50%` : `50% ${getStartPoint}%`;
    maskEndPoint = isHorizontal ? `${getEndPoint}% 50%` : `50% ${getEndPoint}%`;
  }

  return {
    maskStartPoint,
    maskEndPoint,
  }
};

const isUnsupportedEffectExist = (activeElementData) => {
  const {
    text3D,
    textGlitch,
    textLightSweep,
    textGlow,
  } = activeElementData;

  return text3D && text3D.isEnabled || textGlitch && textGlitch.isEnabled || textLightSweep && textLightSweep.isEnabled || textGlow && textGlow.isEnabled;
};

const getElementProportion = (elementRatio, elementWidth, elementHeight) => {
  if (elementRatio < 1.25) return 'square';
  return elementHeight > elementWidth ? 'portrait' : 'landscape';
};

const getTextLightSweepBackgroundSize = (convertedElementFontSize, textLightsweepHeight, angleType, textRadius) => {
  // on a text element, getting how many line the text has ( entered sentences or text ), so for 'tilt' angle, can cover cutted off issue
  let getTextVerticalLineAmount = Math.round(textLightsweepHeight / convertedElementFontSize);
  getTextVerticalLineAmount = getTextVerticalLineAmount || 1;

  // for 'straight-horizontal' angle
  let multiplier = convertedElementFontSize / 10;
  let baseValue = convertedElementFontSize; // base value is a minimum lightsweep size so there will be no lightsweep cut off issue

  if (angleType === 'tilt') {
    // for 'tilt' angle (45/135 degrees)
    baseValue = convertedElementFontSize * 2 * getTextVerticalLineAmount;
  } else if (angleType === 'straight-vertical') {
    // for 'straight-vertical' angle
    baseValue = textLightsweepHeight / 10;
    multiplier = textLightsweepHeight / 10;
  }

  baseValue = Math.round(baseValue);
  multiplier = Math.round(multiplier);

  const textLightSweepRadius = baseValue + textRadius * multiplier;
  return textLightSweepRadius;
}
/* eslint-enable */

const isDesktopApp = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return ua.toLowerCase().indexOf('offeo-desktop-app') > -1;
};

const getDownloadAppLink = () => {
  // TODO: add tracking?
  /* eslint-disable */
  let url;
  if (isProduction()) {
    url = 'https://cdn.offeo.com/offeo-desktop-app/OFFEOSetup';
  } else if (isPreprod()) {
    url = 'https://s3.us-east-2.amazonaws.com/staging-assets.offeo.com/offeo-desktop-app-preprod/OFFEOPreprod';
  } else {
    url = 'https://s3.us-east-2.amazonaws.com/staging-assets.offeo.com/offeo-desktop-app-staging/OFFEOStaging';
  }
  const ext = isMac() ? 'dmg' : 'exe';
  return `${url}.${ext}`;
};

const isMaximized = () => (
  window.outerWidth >= screen.availWidth
    && window.innerWidth >= screen.availHeight
);
/* eslint-enable */

const getHasFontWeight = (fontWeight, fontObj, type = 'all') => {
  // params:
  // fontWeight = get from getActiveElements, which has what fontWeight set to the text
  // fontObj = same as fonts.json format (the one that has 'weight' & 'italic' key in each object)
  // type = fill with 'italic' or 'all', will compare based of selected type ( italic / all font weight list )
  const fontObjAvailableWeights = fontObj.weight || [];
  const fontObjAvailableItalicWeights = fontObj.italic || [];
  const selectedFontWeightList = type === 'all' ? fontObjAvailableWeights : fontObjAvailableItalicWeights;
  return selectedFontWeightList.includes(fontWeight);
};

const getHasBoldFontWeight = (fontObj) => {
  // params:
  // fontObj = same as fonts.json format (the one that has 'weight' & 'italic' key in each object)
  const fontObjAvailableWeights = fontObj.weight || [];
  return fontObjAvailableWeights.includes(700);
};

const getHasItalicFontWeight = (fontWeight, fontObj) => {
  // params:
  // fontWeight = get from getActiveElements, which has what fontWeight set to the text
  // fontObj = same as fonts.json format (the one that has 'weight' & 'italic' key in each object)
  const fontObjAvailableItalicWeights = fontObj.italic || [];
  return fontObjAvailableItalicWeights.includes(fontWeight);
};

export {
  randomId,
  randomInt,
  flattenArray,
  isEmpty,
  isEmptyObject,
  isEqualFile,
  toUnitVector,
  fromUnitVector,
  rgbToHex,
  hexToComponents,
  componentToHex,
  hexToRgb,
  stringRgbToHex,
  rgbToHSL,
  hslToRGB,
  getRamp,
  isNumber,
  isNumberInteger,
  degreeToAngle,
  angleToDegree,
  arrayMove,
  whereExist,
  findGradientColorPointIndex,
  findColorIndex,
  getCoordinates,
  getAngleFromCoordinates,
  checkForDuplicateArray,
  getImageSize,
  getVideoSize,
  selectAllText,
  sanitizeHTML,
  reverseHTML,
  reverseHTMLandStrip,
  toCamel,
  keysToCamel,
  cleanUpContent,
  cleanUpContentHTMLEntity,
  getBoundingClientRect,
  isMobile,
  isIOS,
  isMac,
  isWindows,
  isFacebookApp,
  isEmail,
  isURL,
  showDecimalIfNeeded,
  mapDesigns,
  mapText,
  filterMyFiles,
  mapMediaVideo,
  secToDigitalStr,
  multiDimensionalUnique,
  gaTrackerName,
  handleUnauthenticatedAPI,
  neutralize,
  getSelectedText,
  unwrap,
  unwrapTag,
  removeDuplicates,
  optional,
  convertDateFormat,
  isArabicText,
  isBengaliText,
  isBugineseText,
  isGujaratiText,
  isHebrewText,
  isJavaneseText,
  isKannadaText,
  isKhmerText,
  isLaoText,
  isMalayalamText,
  isMyanmarText,
  isOriyaText,
  isSinhalaText,
  isTamilText,
  isTeluguText,
  isThaiText,
  isTibetanText,
  disallowSplitText,
  isWindowEventListenerAllowed,
  toTitleCase,
  serialize,
  calcAspectRatio,
  capitalizeWord,
  upsert,
  getHTMLWrapper,
  normalizeColorBrand,
  mapDropDownValue,
  mapDropDownValueBeforeSave,
  checkIsCollapseSidebarButton,
  checkIsColorTabTriggerButton,
  isDesktopApp,
  getDownloadAppLink,
  isMaximized,
  convertNumberToDecimal,
  angleToNumber,
  isVerticalLightSweep,
  isReversedAngle,
  getTiltLightSweepMaskPosition,
  isUnsupportedEffectExist,
  getElementProportion,
  getTextLightSweepBackgroundSize,
  getHasFontWeight,
  getHasBoldFontWeight,
  getHasItalicFontWeight,
};
