// import ColorBrand from '@/assets/data/color-brand.json';
import CollectionsApi from '../services/api/CollectionsApi';
import { normalizeColorBrand } from '@/assets/scripts/utilities';

const colorPreset = {
  namespaced: true,
  state: {
    // colorBrand: ColorBrand,
    colorBrand: [],
    colorSolid: [
      '#EF4247',
      '#F48729',
      '#FCDF18',
      '#05AA5B',
      '#06B1A2',
      '#A0ECE3',
      '#46B9E9',
      '#5A3594',
      '#C92269',
      '#FF93AE',
      '#000000',
      '#FFFFFF',
    ],
    colorGradient: [
      {
        points: [
          {
            color: '#ff9a9e',
            percentage: 0,
          },
          {
            color: '#fad0c4',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#f6d365',
            percentage: 0,
          },
          {
            color: '#fda085',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#FFC0F9',
            percentage: 0,
          },
          {
            color: '#879EED',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#005AFF',
            percentage: 0,
          },
          {
            color: '#D52FFF',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#84fab0',
            percentage: 0,
          },
          {
            color: '#8fd3f4',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#FFBD3D',
            percentage: 0,
          },
          {
            color: '#6100FF',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#f093fb',
            percentage: 0,
          },
          {
            color: '#f5576c',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#4facfe',
            percentage: 0,
          },
          {
            color: '#00f2fe',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#96fbc4',
            percentage: 0,
          },
          {
            color: '#f9f586',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#172445',
            percentage: 0,
          },
          {
            color: '#9EA0A7',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#FF44CB',
            percentage: 0,
          },
          {
            color: '#FFEA7D',
            percentage: 100,
          },
        ],
        degree: 0,
      },
      {
        points: [
          {
            color: '#00dbde',
            percentage: 0,
          },
          {
            color: '#fc00ff',
            percentage: 100,
          },
        ],
        degree: 0,
      },
    ],
  },
  getters: {
    getColorBrand: state => state.colorBrand,
    getColorSolid: state => state.colorSolid,
    getColorGradient: state => state.colorGradient,
    getColorTeamBrand: (state, getters, rootState, rootGetters) => rootGetters['team/getActiveTeamColors'],
  },
  mutations: {
    addColorBrand(state, item) {
      // normalise the color so that it will have hash inside
      const colorBrand = normalizeColorBrand(item);

      // check if state colorbrand already has existing color
      const existingColor = state.colorBrand.findIndex(color => color.name === colorBrand.name
              && color.colors.length === colorBrand.colors.length
              && color.colors[0] === colorBrand.colors[0]);

      if (existingColor < 0) state.colorBrand.push(colorBrand);
    },
    deleteColorBrand(state, item) {
      // item is index of color brand

      state.colorBrand.splice(item, 1);
    },
  },
  actions: {
    setColorBrand({ commit }, colors) {
      for (let i = 0; i < Object.keys(colors).length; i += 1) {
        const name = Object.keys(colors)[i];
        const colorArr = colors[name];

        const colorBrand = {
          name,
          colors: colorArr,
        };

        commit('addColorBrand', colorBrand);
      }
    },
    addColorPaletteToBrand({ commit }, item) {
      /*
        Expected item
        item = {
          name: name,     // string
          colors: colors, // Array of colors with hex, e.g. [ '#FFFFFF' ]
        }
      */
      const colorBrand = normalizeColorBrand(item);
      CollectionsApi.updateColorPalette(colorBrand)
        .then((res) => {
          console.log('res', res);
          if (res.data.success) {
            commit('addColorBrand', colorBrand);
          }
        }).catch((error) => {
          console.log(error);
        });
    },
    deleteColorPaletteToBrand({ commit }, item) {
      /*
        Expected item
        item = {
          name: name,     // string
          colors: colors, // Array of colors with hex, e.g. [ '#FFFFFF' ]
        }
      */
      const colorBrand = normalizeColorBrand(item);
      CollectionsApi.updateColorPalette(colorBrand)
        .then((res) => {
          console.log('res', res);
          if (res.data.success) {
            commit('addColorBrand', colorBrand);
          }
        }).catch((error) => {
          console.log(error);
        });
    },
  },
};

export default colorPreset;
