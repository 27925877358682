import axios from 'axios';

export default {
  chooseTemplate(templateId, params = {}, version = 'v2') {
    return axios({
      method: 'post',
      url: `/${version}/choose-design/${templateId}`,
      data: params,
    });
  },

  create(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/projects`,
      data: params,
    });
  },

  get(projectId, params = {}, version = 'v2') {
    return axios.get(`/${version}/projects/${projectId}`, { params });
  },

  update(projectId, params = {}, version = 'v2') {
    return axios({
      method: 'put',
      url: `/${version}/projects/${projectId}`,
      data: params,
    });
  },

  addProjectScene(projectId, params, version = 'v2') {
    return axios({
      method: 'post',
      url: `/${version}/projects/${projectId}/scenes`,
      data: params,
    });
  },

  deleteProjectScene(projectId, sceneId, version = 'v2') {
    return axios({
      method: 'delete',
      url: `/${version}/projects/${projectId}/scenes/${sceneId}`,
    });
  },

  updateProjectScene(projectId, sceneId, params = {}, version = 'v2') {
    return axios({
      method: 'put',
      url: `/${version}/projects/${projectId}/scenes/${sceneId}`,
      data: params,
    });
  },

  shareProjectAsTemplate(projectId, params = {}, version = 'v1') {
    return axios({
      method: 'put',
      url: `/${version}/projects/share-project-as-template/${projectId}`,
      data: params,
    });
  },

  trackCreateNewProject(params = {}, version = 'v1') {
    return axios({
      method: 'post',
      url: `/${version}/create-new-project-tracking`,
      data: params,
    });
  },
};
