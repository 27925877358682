import { all } from 'core-js/fn/promise';
import Vue from 'vue';

const exportDetails = {
  namespaced: true,
  state: {
    isExporting: false,
    isExportStarted: false,
    isTemplate: false,
    isTerminated: false,
    isCancelled: false,
    isCompleted: false,
    videoExportScene: 'all',
    exportId: '',
    type: 'mp4',
    quality: 'high',
    downloadLink: '',
    previewLink: '',
    cdnLink: '',
    progress: 0,
    queuePosition: 0,
    scenePreviewLinks: [],
    scenePreviewTextCount: '',
    isDownloadingImage: false,
    totalScenes: 0,
    createHeroThumbnails: false,
    publishedToFB: false,
    publishedId: 0,
    publishedUrl: '',
    publishedType: 'image',
  },
  getters: {
    getExportDetailStates: state => state,
  },
  mutations: {
    updateExportDetailState(state, item) {
      if (typeof item !== 'object') {
        console.error('Export details state must be in object type');
        return;
      }

      Object.keys(item).forEach((key) => {
        try {
          const value = item[key];
          // console.log(`updateExportDetailState: ${key}, ${value}`);
          Vue.set(state, key, value);
        } catch (error) {
          console.error(`Error on updating state [${key}]:`, error, state);
        }
      });
    },
  },
};

export default exportDetails;
