import Vue from 'vue';
import { userRoles, userLevels } from '@/assets/scripts/enums';

const isNormalUser = state => state.userRole === userRoles.NORMALUSER || state.userRole === userRoles.MARKETER;

const userData = {
  namespaced: true,
  state: {
    userLevel: 'free',
    userRole: 4,
    name: 'Testing User',
    username: 'testinguser',
    profilePicture: null,
    showUserMenu: false,
    userId: 0,
    storageSpaceLeft: 'unlimited',
    userEmail: '',
    removeBgCredits: 0,
    requiresSubscription: false,
    usedNewPricing: false,
    isNotified: false,
    isNotifiedOfExports: true,
    isSubscribed: false,
    activeProjects: 0,
    exportedProjectsAsVideos: 0,
    exportedProjectsAsImages: 0,
    currentPlan: 'free',
    previousPlan: null,
    customerId: null,
    subscriptionId: null,
    ltdTransactionId: null,
    latestProjects: [],
    teamSeats: 0,
    hasSetupBrand: false,
    createdAt: '',
    loginToken: '', // used for admin api auth (designer, master designer, admin)
  },
  getters: {
    isAdmin: state => state.userRole === userRoles.ADMIN,
    // isDesigner can be both designer and master designer
    isDesigner: state => state.userRole === userRoles.DESIGNER || state.userRole === userRoles.MASTERDESIGNER,
    isMasterDesigner: state => state.userRole === userRoles.MASTERDESIGNER,
    isFreeUser: (state, getters, rootState, rootGetters) => { // eslint-disable-line
      const isFreeUserRole = isNormalUser(state) && state.userLevel === userLevels.FREE;
      const isPartOfTeam = rootGetters['team/isInTeamWorkspace'] && (rootGetters['team/isTeamMember'] || rootGetters['team/isTeamDesigner']);

      return isFreeUserRole && !isPartOfTeam;
    },
    // isPayingUser user can be premium, business, or part of a team that is accessing team workspace
    isPayingUser: (state, getters, rootState, rootGetters) => {
      const isPremiumUserRole = isNormalUser(state) && state.userLevel !== userLevels.FREE;
      const isPartOfTeam = rootGetters['team/isInTeamWorkspace'] && (rootGetters['team/isTeamMember'] || rootGetters['team/isTeamDesigner']);

      return isPremiumUserRole || isPartOfTeam;
    },
    isFreeSubscription: state => isNormalUser(state) && state.userLevel === userLevels.FREE,
    isPremium: state => isNormalUser(state) && state.userLevel === userLevels.PREMIUM,
    isBusiness: state => isNormalUser(state) && state.userLevel === userLevels.BUSINESS,
    getInitial: (state) => {
      const nameArray = state.name.split(' ');

      if (nameArray.length > 1) {
        return `${nameArray[0].charAt(0)}${nameArray[1].charAt(0)}`;
      }

      return state.name.charAt(0);
    },
    getUserLevel: (state) => {
      let level = 'Free';

      if (state.userRole === userRoles.ADMIN) level = 'Admin';
      if (state.userRole === userRoles.DESIGNER) level = 'Designer';
      if (state.userRole === userRoles.MASTERDESIGNER) level = 'Master Designer';
      if (isNormalUser(state)) {
        if (state.userLevel === userLevels.BUSINESS) level = 'Business';
        if (state.userLevel === userLevels.PREMIUM) level = 'Premium';
      }

      return level;
    },
  },
  mutations: {
    setUserLevel(state, item) {
      state.userLevel = item;
    },
    setUserRole(state, item) {
      state.userRole = item;
    },
    setName(state, item) {
      state.name = item;
    },
    setUsername(state, item) {
      state.username = item;
    },
    setProfilePicture(state, item) {
      state.profilePicture = item;
    },
    setShowUserMenu(state, item) {
      state.showUserMenu = item;
    },
    setUserId(state, item) {
      state.userId = item;
    },
    setStorageSpaceLeft(state, item) {
      state.storageSpaceLeft = item;
    },
    setUserEmail(state, item) {
      state.userEmail = item;
    },
    setRemoveBgCredits(state, item) {
      state.removeBgCredits = item;
    },
    setRequiresSubscription(state, item) {
      state.requiresSubscription = item;
    },
    setUsedNewPricing(state, item) {
      state.usedNewPricing = item;
    },
    setUserData(state, item) {
      if (item.userLevel) state.userLevel = item.userLevel;
      if (item.userRole) state.userRole = item.userRole;
      if (item.name) state.name = item.name;
      if (item.username) state.username = item.username;
      if (item.profilePicture) state.profilePicture = item.profilePicture;
      if (item.userId) state.userId = item.userId;
      if (item.storageSpaceLeft) state.storageSpaceLeft = item.storageSpaceLeft;
      if (item.userEmail) state.userEmail = item.userEmail;
      if (item.removeBgCredits) state.removeBgCredits = item.removeBgCredits;
      if (item.usedNewPricing) state.usedNewPricing = item.usedNewPricing;
      if (item.createdAt) state.createdAt = item.createdAt;

      if (item.isNotified) state.isNotified = item.isNotified;
      if (typeof item.isNotifiedOfExports !== 'undefined') state.isNotifiedOfExports = item.isNotifiedOfExports;
      if (item.isSubscribed) state.isSubscribed = item.isSubscribed;
      if (item.activeProjects) state.activeProjects = item.activeProjects;
      if (item.exportedProjectsAsVideos) { state.exportedProjectsAsVideos = item.exportedProjectsAsVideos; }
      if (item.exportedProjectsAsImages) { state.exportedProjectsAsImages = item.exportedProjectsAsImages; }
      if (item.currentPlan) state.currentPlan = item.currentPlan;
      if (item.previousPlan) state.previousPlan = item.previousPlan;
      if (item.customerId) state.customerId = item.customerId;
      if (item.subscriptionId) state.subscriptionId = item.subscriptionId;
      if (item.ltdTransactionId) state.ltdTransactionId = item.ltdTransactionId;
      if (item.latestProjects) Vue.set(state, 'latestProjects', item.latestProjects);
      if (item.teamSeats) state.teamSeats = item.teamSeats;
      if (item.hasSetupBrand) state.hasSetupBrand = item.hasSetupBrand;
      if (item.loginToken) state.loginToken = item.loginToken;
    },
  },
};

export default userData;
