/* eslint-disable */
import Vue from 'vue';
import { TimelineLite } from 'gsap/TweenMax';
import {
  getTransitionIn,
  getTransitionOut,
  setAnimation,
  setTransition,
} from '@/assets/scripts/animations';
/* eslint-enable */

// add timeout delay before attaching animations
// seems unable to fetch the targetElementText;

const AnimationSettings = {
  namespaced: true,
  mutations: {
    // =======================
    // TIMELINE
    // =======================
    addTweenTimeline(state, item) {
      const {
        timeline,
        animation,
        timeFrom,
        timeTo,
      } = item;
      timeline.add(animation.tweenFromTo(timeFrom, timeTo));
    },
    setTweenTimeline(state, item) {
      const {
        timeline,
        target,
        animation,
        time,
      } = item;
      // console.log('setTweenTimeline ', target, animation, timeline, time);

      if (timeline) {
        timeline.set(target, animation, time);
      }
    },
    toTweenTimeline(state, item) {
      const {
        timeline,
        target,
        duration,
        animation,
        time,
      } = item;
      // console.log('toTweenTimeline', target, animation, time);

      if (timeline && animation) {
        if (time) timeline.to(target, duration, animation, time);
        else timeline.to(target, duration, animation);
      }
    },
    fromToTweenTimeline(state, item) {
      const {
        timeline,
        target,
        speed,
        fromAnimation,
        toAnimation,
        time,
      } = item;

      if (timeline) {
        timeline.fromTo(target, speed, fromAnimation, toAnimation, time);
      }
    },
    seekTweenTimeline(state, item) {
      const {
        timeline,
        time,
        isPlaying,
      } = item;

      if (timeline) {
        // console.log('state is', timeline, time)
        timeline.seek(time);
        if (isPlaying) timeline.play();
        else timeline.pause();
      }
    },
    staggerTweenTimeline(state, item) {
      const {
        timeline,
        target,
        duration,
        animation,
        time,
        stagger,
      } = item;

      if (timeline) {
        timeline.staggerTo(target, duration, animation, stagger, time);
      }
    },
  },
  actions: {
    setTweenTimeline({ commit }, item) {
      commit('setTweenTimeline', item);
    },
    toTweenTimeline({ commit }, item) {
      commit('toTweenTimeline', item);
    },
    fromToTweenTimeline({ commit }, item) {
      commit('fromToTweenTimeline', item);
    },
    staggerTweenTimeline({ commit }, item) {
      commit('staggerTweenTimeline', item);
    },
    setAllAnimation({ rootState, rootGetters }, item) {
      const {
        activeTimeline,
        elements,
        sceneId,
      } = item;
      console.log('~~~~setAllAnimation~~~~');

      let { canvasZoom } = rootState;
      const { canvasSize } = rootState.canvasElements;

      // fix for preview scene layer transition computation
      if (rootGetters.isDownloadPreviewOpened) {
        canvasZoom = 1;
      }

      const isScenePreview = activeTimeline === 'sceneTimeline';
      const isAllScenesTimeline = activeTimeline === 'allScenesTimeline';

      let tweenMaxTimeline = isAllScenesTimeline ? item.tweenMaxTimeline : new TimelineLite({ paused: true });

      // to avoid setting state multiple times,
      // we duplicate timeline first

      for (let i = 0; i < Object.keys(elements).length; i += 1) {
        const element = elements[Object.keys(elements)[i]];
        element.tweenMaxTimeline = tweenMaxTimeline;
        element.canvasZoom = isAllScenesTimeline ? 1 : canvasZoom;
        element.canvasSize = canvasSize;

        tweenMaxTimeline = setAnimation(element);
      }

      // console.log('animations settings', activeTimeline, rootState.canvasElements[activeTimeline], sceneId, tweenMaxTimeline);
      // console.log('animations settings', activeTimeline, sceneId);

      if (isScenePreview) {
        Vue.set(rootState.canvasElements[activeTimeline], sceneId, tweenMaxTimeline);
      } else {
        Vue.set(rootState.canvasElements, activeTimeline, tweenMaxTimeline);
      }
    },
    setAllTransition({ rootState, rootGetters }, item) {
      const {
        activeTimeline,
        scenes,
      } = item;

      // eslint-disable-next-line
      let { canvasZoom } = rootState;
      const { canvasSize } = rootState.canvasElements;

      // fix for preview scene layer transition computation
      if (rootGetters.isDownloadPreviewOpened) {
        canvasZoom = 1;
      }

      // to avoid setting state multiple times,
      // we duplicate timeline first
      let tweenMaxTimeline = new TimelineLite({ paused: true });

      for (let i = 0; i < Object.keys(scenes).length; i += 1) {
        const scene = scenes[Object.keys(scenes)[i]];
        scene.tweenMaxTimeline = tweenMaxTimeline;
        scene.canvasZoom = 1;
        scene.canvasSize = canvasSize;

        tweenMaxTimeline = setTransition(scene);
      }

      // console.log('animations settings', activeTimeline, rootState.canvasElements[activeTimeline], sceneId, tweenMaxTimeline);
      // console.log('setAllTransition animations settings', activeTimeline, tweenMaxTimeline, scenes);
      Vue.set(rootState.canvasElements, activeTimeline, tweenMaxTimeline);
    },
    setTransitionAsync({ commit, rootState }, item) {
      const {
        animateInDuration,
        animateInDirection,
        animateInValue,
        animateOutDuration,
        animateOutDirection,
        animateOutValue,
        activeTimeline,
        endingTime,
        startingTime,
        targetElement,
      } = item;

      const { canvasZoom } = rootState;
      const { canvasSize } = rootState.canvasElements;

      console.log(canvasZoom, canvasSize);

      const animateIn = getTransitionIn({
        value: animateInValue,
        direction: animateInDirection,
        target: targetElement,
        canvasZoom,
        canvasSize,
      });
      const animateOut = getTransitionOut({
        value: animateOutValue,
        direction: animateOutDirection,
        target: targetElement,
        canvasZoom,
        canvasSize,
      });
      // animate out starts when the next scene animating in
      const animateOutStart = endingTime;

      const tweenAnimateIn = animateIn.animateIn;
      // const tweenAnimateToIn = animateIn.animateToIn;
      const tweenAnimateInEnding = animateIn.animateInEnding;

      const tweenAnimateOut = animateOut.animateOut;

      const tweenMaxTimeline = rootState.canvasElements[activeTimeline];

      // Reset transition
      commit('setTweenTimeline', {
        timeline: tweenMaxTimeline,
        target: targetElement,
        animation: {
          autoAlpha: 0,
          x: 0,
          y: 0,
          clearProps: 'transform, transformOrigin, rotation, rotationX, rotationY, rotationZ, filter, opacity, x, y, autoAlpha',
        },
        time: 'Start+=0',
      });

      // set the canvas opacity to 0 before the times
      commit('setTweenTimeline', {
        timeline: tweenMaxTimeline,
        target: targetElement,
        animation: {
          autoAlpha: 0,
          x: 0,
          y: 0,
        },
        time: 'Start+=0',
      });

      if (animateInValue && animateInValue !== 'none' && tweenAnimateIn) {
        commit('setTweenTimeline', {
          timeline: tweenMaxTimeline,
          target: targetElement,
          animation: tweenAnimateIn,
          time: `Start+=${startingTime}`,
        });

        commit('toTweenTimeline', {
          timeline: tweenMaxTimeline,
          target: targetElement,
          duration: animateInDuration,
          animation: tweenAnimateInEnding,
          time: `Start+=${startingTime}`,
        });
      } else {
        commit('setTweenTimeline', {
          timeline: tweenMaxTimeline,
          target: targetElement,
          animation: {
            autoAlpha: 1,
            x: 0,
            y: 0,
          },
          time: `Start+=${startingTime}`,
        });
      }

      if (animateOutValue && animateOutValue !== 'none' && tweenAnimateOut) {
        // console.log('animateOutStart', animateOutStart)
        commit('toTweenTimeline', {
          timeline: tweenMaxTimeline,
          target: targetElement,
          duration: animateOutDuration,
          animation: tweenAnimateOut,
          time: `Start+=${animateOutStart}`,
        });

        if (animateOutValue === 'slide-out' || animateOutValue === 'rotate-out') {
          const animateOutExtra = animateOutStart + (0.4 * animateOutDuration);
          commit('toTweenTimeline', {
            timeline: tweenMaxTimeline,
            target: targetElement,
            duration: 0.6 * animateOutDuration,
            animation: {
              autoAlpha: 0,
            },
            time: `Start+=${animateOutExtra}`,
          });
        }
      }

      commit('setTweenTimeline', {
        timeline: tweenMaxTimeline,
        target: targetElement,
        animation: {
          autoAlpha: 0,
          x: 0,
          y: 0,
        },
        time: `Start+=${endingTime}`,
      });
    },
  },
};

export default AnimationSettings;
