import UserApi from '@/services/api/UserApi';

const account = {
  namespaced: true,
  state: {
    isAuthenticating: false,
    loginModalVisible: false,
    subscribeModalVisible: false,
    logoutModalDialogVisible: false,
  },
  mutations: {
    showLoginModal(state) {
      state.loginModalVisible = true;
    },
    hideLoginModal(state) {
      state.loginModalVisible = false;
    },
    showSubscribeModal(state) {
      state.subscribeModalVisible = true;
    },
    hideSubscribeModal(state) {
      state.subscribeModalVisible = false;
    },
    showLogoutModalDialog(state) {
      state.logoutModalDialogVisible = true;
      console.log(state.logoutModalDialogVisible);
    },
    hideLogoutModalDialog(state) {
      state.logoutModalDialogVisible = false;
    },
    setIsAuthenticating(state, item) {
      state.isAuthenticating = item;
    },
  },
  actions: {
    logOut({ commit }) {
      UserApi.userLogout()
        .then(() => {
          window.location.href = `${process.env.VUE_APP_PUBLIC_URL}`;
        })
        .catch(() => {})
        .then(() => {
          commit('hideLogoutModalDialog');
        });
    },
  },
};

export default account;
